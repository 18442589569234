import axios from 'axios';

export const fetchRefusalAccounts = async () =>
  (await axios.get('api/supervisor/queues/refusal')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const pushToPool = async (accountId, note) =>
  (
    await axios.patch(`/api/supervisor/queues/refusal/${accountId}`, {
      action: 'push_to_pool',
      data: { note },
    })
  ).data;

export const pushToAgent = async (accountId, agentId, note) =>
  (
    await axios.patch(`/api/supervisor/queues/refusal/${accountId}`, {
      action: 'push_to_agent',
      data: { agent_id: agentId, note },
    })
  ).data;

export const referToLegal = async (accountId, data) =>
  (
    await axios.patch(`/api/supervisor/queues/refusal/${accountId}`, {
      action: 'push_to_legal',
      data,
    })
  ).data;
