export default {
  NO_RIGHT_PERSON_CONTACT: { type: 1, name: 'NRPC', closesAction: true },
  JUST_RINGS: { type: 2, name: 'Just Rings', closesAction: false },
  ENGAGED: { type: 3, name: 'Engaged', closesAction: false },
  INVALID_NO: { type: 4, name: 'Invalid Number', closesAction: false },
  LEFT_MESSAGE: { type: 5, name: 'Left Message', closesAction: false },
  WRONG_NUMBER: { type: 6, name: 'Wrong Number', closesAction: false },
  PAYMENT_DISPUTE: { type: 7, name: 'Payment Dispute', closesAction: true },
  REFUSAL_TO_PAY: { type: 8, name: 'Refusal to Pay', closesAction: true },
  DEBT_REVIEW: { type: 9, name: 'Debt Review', closesAction: true },
  DIARISED: { type: 10, name: 'Diarised', closesAction: true },
  SEQUESTRATED: { type: 11, name: 'Sequestrated', closesAction: true },
  ADMIN_ORDER: { type: 12, name: 'Admin Order', closesAction: true },
  DECEASED: { type: 13, name: 'Deceased', closesAction: true },
  INCARCERATED: { type: 14, name: 'Incarcerated', closesAction: true },
  CTP: { type: 15, name: 'CTP', closesAction: true },
  CTP_BROKEN: { type: 16, name: 'Broken CTP', closesAction: true },
  FREE_FORM: { type: 18, name: 'Note', closesAction: false },
  ESCALATE: { type: 21, name: 'Escalate', closesAction: true },
  ACCEPT_DIARISED: { type: 22, name: 'Accept Diarise', closesAction: true },
  REJECT_DIARISED: { type: 23, name: 'Reject Diarise', closesAction: true },
  TRACE: { type: 30, name: 'Trace', closesAction: true },
  ACCEPT_WRITEOFF: { type: 31, name: 'Accept Writeoff', closesAction: true },
  ACCEPT_PAYMENT_DISPUTE: { type: 27, name: 'Payment Dispute Accepted', closesAction: true },
  REJECT_PAYMENT_DISPUTE: { type: 28, name: 'Payment Dispute Rejected', closesAction: true },
  TRACE_DONE: { type: 37, name: 'Trace Done', closesAction: true },
  JOURNAL: { type: 35, name: 'Journal', closesAction: true },
  AOD: { type: 44, name: 'AOD', closesAction: false },
};
