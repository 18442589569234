<template>
  <Queue
    idKey="id"
    sortKey="due_date_time"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #subtitle> Debt Review </template>
    <template #title> Proposal </template>
    <template #controls>
      <input
        v-model="query"
        type="text"
        class="form-control"
        placeholder="Search..."
      />
    </template>
    <template v-slot:default="{ item: account }">
      <DebtReviewProposalQueueItem
        :account="account"
        @action="action"
        @note="onNote"
        @defer="defer"
      />
    </template>
  </Queue>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';

import { createNote, deferAccount } from '@/api/account';
import { loadProposalAccounts } from '@/api/debtReview';

import useActionQueueSearch from '@/composables/useActionQueueSearch';

import Queue from '@/components/Shared/Queue.vue';
import DebtReviewProposalQueueItem from '@/components/Admin/DebtReviewProposalQueueItem.vue';

export default {
  name: 'DebtReviewProposalQueue',
  components: {
    Queue,
    DebtReviewProposalQueueItem,
  },
  setup() {
    const accounts = ref([]);
    const isLoading = ref(true);

    const getAccounts = async () => {
      accounts.value = await loadProposalAccounts();
      isLoading.value = false;
    };

    const removeAccount = (accountId) => {
      accounts.value = accounts.value.filter((a) => a.id !== accountId);
    };

    onMounted(getAccounts);

    const { query, accountsMatchingQuery } = useActionQueueSearch(accounts);

    const onNote = ({ accountId, note }) => createNote(accountId, note);

    return {
      query,
      accounts: accountsMatchingQuery,
      isLoading,
      onNote,
      getAccounts,
      removeAccount,
    };
  },
  methods: {
    async defer(event) {
      const { accountId, payload } = event;
      try {
        this.accounts = this.accounts.map((account) => {
          const tempAccount = account;
          if (account.id === accountId) {
            tempAccount.due_date_time = new Date(
              `${payload.data.date} 00:00:00`
            );
          }
          return tempAccount;
        });
        deferAccount(accountId, payload);
      } catch (error) {
        this.accounts = await this.getAccounts();
        this.$store.dispatch('notification/push', {
          body: `Action Failed`,
          type: 'error',
        });
      }
      this.$store.dispatch('account/clearCurrentAccount');
    },
    async action(event) {
      const { accountId, payload } = event;
      this.$store.dispatch('account/clearCurrentAccount');
      try {
        this.removeAccount(accountId);
        const url = `/api/admin/queues/debt-review-proposal/${accountId}`;
        await axios.patch(url, payload);
      } catch (error) {
        this.accounts = await this.getAccounts();
        this.$store.dispatch('notification/push', {
          body: `Action Failed`,
          type: 'error',
        });
      }
      this.$store.dispatch('account/clearCurrentAccount');
    },
  },
};
</script>
