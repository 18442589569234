<template>
  <div>
    <Splash
      v-if="
        !bootDataLoaded &&
        !(
          $route.name == 'login' ||
          $route.name == 'test' ||
          $route.name == 'queue_routes'
        )
      "
    />
    <div
      class="app"
      v-if="
        bootDataLoaded ||
        $route.name === 'login' ||
        $route.name == 'queue_routes' ||
        $route.name == 'test'
      "
    >
      <Sidebar
        v-if="
          !(
            $route.name === 'login' ||
            $route.name === 'test' ||
            $route.name === 'collectPortal' ||
            $route.name === 'collect'
          )
        "
      />
      <div class="main">
        <router-view />
      </div>
      <div id="modals"></div>
      <Notifications />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import router from '@/router';
import store from '@/store';
import { setAuthToken, setBaseURL } from '@/helpers/setup';
import Sidebar from '@/components/Shared/Sidebar.vue';
import Notifications from '@/components/Shared/Notifications.vue';
import Splash from '@/components/Shared/Splash.vue';
import '@/assets/styles.css';

export default {
  components: {
    Sidebar,
    Notifications,
    Splash,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    bootDataLoaded() {
      return !!this.user;
    },
  },
  beforeCreate() {
    setAuthToken(localStorage['auth-token']);
    setBaseURL(process.env.VUE_APP_API_URL || '');
  },
  created() {
    axios.interceptors.response.use(undefined, (err) => {
      const statuses = [401, 422];
      if (statuses.includes(err?.response?.status)) {
        store.dispatch('auth/logout');
        router.push({ name: 'login' });
      }
      return Promise.reject(err);
    });
  },
  mounted() {
    console.log("App starting!")
    // this.$store.dispatch("telephony/login");
  },
};
</script>

<style scoped>
.app {
  display: flex;
}

.main {
  flex-grow: 1;
  min-height: 100vh;
}
</style>
