<template>
  <LoadingSpinner v-if="isLoading" />
  <table class="table align-middle" v-if="!isLoading">
    <thead>
      <tr>
        <th scope="col">Team</th>
        <th scope="col">Members</th>
        <th scope="col">Active</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(team, index) in teamList" :key="index">
        <td>{{ team.name }}</td>
        <td>{{ team.size }}</td>
        <td>
          <span v-if="team.active">Active</span><span v-else>Inactive</span>
        </td>
        <td><EditButton v-on:click="editTeam(team)" /></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex';
import EditButton from '@/components/Shared/EditButton.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'TeamList',
  components: { EditButton, LoadingSpinner },
  props: {
    teams: {},
  },
  computed: {
    ...mapGetters({
      teamList: 'team/teamList',
      isLoading: 'team/isTeamListLoading',
    }),
  },
  async created() {
    await this.$store.dispatch('team/getTeamList');
  },
  methods: {
    editTeam(team) {
      this.$router.push({ name: 'team', params: { id: team.id } });
    },
  },
};
</script>
