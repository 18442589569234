<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span>
    </template>
    <template #title>{{ account.reference }}</template>
    <template #body>
      <EventNote :content="account.event_note" :multiline="true" />
    </template>
    <template #actions>
      <RoundButton
        @click="toggleForm('note')"
        large
        title="Create Note"
        class="btn-action text-secondary"
      >
        <span class="material-icons"> notes </span>
      </RoundButton>
      <RoundButton
        @click="toggleForm('pushToAgent')"
        large
        class="btn-action text-primary"
        title="Push to agent"
      >
        <span class="material-icons-outlined md-18"> headset_mic </span>
      </RoundButton>

      <RoundButton
        @click="toggleForm('pushToPool')"
        large
        class="btn-action text-primary"
        title="Push to pool"
      >
        <span class="material-icons-outlined md-18"> arrow_forward </span>
      </RoundButton>
      <RoundButton
        @click="toggleForm('pushToLegal')"
        large
        class="btn-action text-primary"
        title="Push to legal"
      >
        <span class="material-icons-outlined md-18"> gavel </span>
      </RoundButton>
    </template>
    <template #footer>
      <PushToAgentForm
        v-if="showForm === 'pushToAgent'"
        :agents="teamAgents"
        @submitted="$emit('pushToAgent', $event)"
      />
      <NoteForm
        v-if="showForm === 'pushToPool'"
        @submitted="$emit('pushToPool', $event)"
      />
      <div v-if="showForm === 'pushToLegal'">
        <div class="mb-3">
          <label class="form-label">Reason</label>
          <textarea v-model="reason" class="form-control" rows="3"></textarea>
        </div>
        <button @click="$emit('pushToLegal', reason)" class="btn btn-primary">
          Submit
        </button>
      </div>
      <div v-if="showForm === 'note'">
        <div class="mb-3">
          <label class="form-label">Note</label>
          <textarea v-model="note" class="form-control" rows="3"></textarea>
        </div>
        <button @click="createNote" class="btn btn-primary">Submit</button>
      </div>
    </template>
  </QueueItem>
</template>

<script>
import { ref } from "vue";
import QueueItem from "@/components/Shared/QueueItem_v2.vue";
import EventNote from "@/components/Shared/EventNote.vue";
import RoundButton from "@/components/Shared/_RoundButton.vue";
import PushToAgentForm from "@/components/Supervisor/PushToAgentForm.vue";
import NoteForm from "@/components/Admin/NoteForm.vue";

export default {
  name: "RefusalQueueItem",
  props: {
    account: Object,
    teamAgents: Array,
  },
  components: {
    QueueItem,
    EventNote,
    RoundButton,
    PushToAgentForm,
    NoteForm,
  },
  setup(props, { emit }) {
    const showForm = ref("");
    const reason = ref("");
    const note = ref("");
    const toggleForm = (formName) => {
      showForm.value = showForm.value !== formName ? formName : "";
    };

    const createNote = () => {
      // const { note } = note;
      emit("note", { accountId: props.account.id, note: note.value });
      showForm.value = "";
    };

    return {
      showForm,
      toggleForm,
      reason,
      note,
      createNote,
    };
  },
};
</script>
