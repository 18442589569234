<template>
  <h2 class="mb-4">Confirm</h2>
  <h6>Action {{ event }}</h6>
  <table class="table">
    <tbody>
      <tr v-for="(value, key) in details" :key="key">
        <th scope="row" v-if="typeof key === 'string'">
          {{ key }}
        </th>
        <th scope="row" v-else>{{ key }}</th>
        <td>{{ value }}</td>
      </tr>
    </tbody>
  </table>
  <div v-if="!isTreatmentSaving" class="mt-4">
    <button @click="back" class="btn btn-primary">Back</button>
    <button @click="confirm" class="btn btn-primary">Confirm</button>
  </div>
  <div v-else>
    <LoadingSpinner />
  </div>
  <p>{{ error }}</p>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'Confirm',
  props: {
    event: String,
    details: Object,
    error: String,
  },
  components: { LoadingSpinner },
  computed: {
    ...mapGetters({
      isTreatmentSaving: 'collection/isLoading',
    }),
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
