<template>
  <Queue idKey="id" sortKey="entry_date" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Legal </template>
    <template #title> Deceased </template>
    <template #default="{ item }">
      <DeceasedQueueItem :account="item" @reject="onReject(item.id)" @note="onNote" />
    </template>
  </Queue>
</template>

<script>
import useActionQueue from "@/composables/useActionQueue";
import Queue from "@/components/Shared/Queue.vue";
import DeceasedQueueItem from "@/components/Legal/DeceasedQueueItem.vue";
import { fetchDeceasedAccounts, rejectDeceasedReferral } from "@/api/legal";
import { createNote } from "@/api/account";

export default {
  components: { Queue, DeceasedQueueItem },
  setup() {
    const { accounts, isLoading, removeAccount } = useActionQueue(fetchDeceasedAccounts);

    const onReject = async (id) => {
      const undo = removeAccount(id);
      try {
        await rejectDeceasedReferral(id);
      } catch (error) {
        undo();
        console.log(error);
      }
    };

    const onNote = (payload) => {
      createNote(payload.accountId, payload.note);
    };

    return {
      accounts,
      isLoading,
      onReject,
      onNote,
    };
  },
};
</script>
