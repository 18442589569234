<template>
  <h2>CTP</h2>

  <form @submit.prevent="submit">
    <div class="mb-3">
      <label class="form-label">Date</label>
      <input
        v-model="date"
        type="date"
        class="form-control"
        :min="getNowDateString()"
        :max="getISODateString(addMonths(new Date(), 3))"
        required
      />
    </div>
    <div class="mb-3">
      <label class="form-label">Amount</label>
      <input v-model.number="amount" type="number" step="any" class="form-control" />
    </div>

    <div class="mb-3">
      <label class="form-label">Payment Method</label>
      <select v-model="payment_method" class="form-select" required>
        <option disabled value="">Select payment method</option>
        <option value="1">Debit Order</option>
        <option value="2">EFT</option>
        <option value="3">Cash</option>
      </select>
    </div>
    <div class="mb-3">
      <div class="form-check">
        <input
          v-model="isRecurring"
          class="form-check-input"
          type="checkbox"
          id="isRecurring"
        />
        <label class="form-label" for="isRecurring"> Recurring </label>
      </div>
      <div v-if="isRecurring">
        <select v-model="recurringOn" class="form-select" :required="isRecurring">
          <option disabled value="">Select day</option>
          <option value="same_date">Same Date</option>
          <option value="specific_date">Specific Date</option>
          <option value="last_day_of_month">Last Day of Month</option>
        </select>

        <div v-if="recurringOn === 'specific_date'" class="col mt-3">
          <input
            v-model="recurringDate"
            type="number"
            min="1"
            max="30"
            class="form-control"
            :required="recurringOn === 'specific_date'"
          />
        </div>
      </div>
    </div>

    <button type="submit" role="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import eventTypes from "@/helpers/eventTypes";
import { getNowDateString, addMonths, getISODateString } from "@/helpers/datetime";

export default {
  name: "RPCCTP",
  emits: ["submit"],
  setup() {
    return { getISODateString, getNowDateString, addMonths };
  },
  data() {
    return {
      ...eventTypes.CTP,
      date: null,
      dateLimit: null,
      amount: 0,
      payment_method: "",
      isRecurring: false,
      recurringOn: "",
      recurringDate: 1,
    };
  },
  methods: {
    submit() {
      const { name, type, closesAction } = eventTypes.CTP;
      const details = {
        date: this.date,
        amount: this.amount,
        payment_method: this.payment_method,
        isRecurring: this.isRecurring,
      };
      if (this.isRecurring) {
        if (this.recurringOn === "same_date") {
          details.recurringDate = new Date(this.date).getDate();
        } else if (this.recurringOn === "specific_date") {
          details.recurringDate = this.recurringDate;
        } else if (this.recurringOn === "last_day_of_month") {
          details.recurringDate = -1;
        }
      }
      this.$emit("submit", { type, name, closesAction, details });
    },
  },
  mounted() {
    this.date = this.getNowDateString();
  },
};
</script>
