<template>
  <span class="badge rounded-pill ms-1" :style="{ 'background-color': color }">{{
    queue
  }}</span>
</template>

<script>
export default {
  name: "QueueBadge",
  props: {
    queue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      color: null,
    };
  },
  mounted() {
    if (this.queue === "30 Days") {
      this.color = "#ffd500";
    } else if (this.queue === "60 Days") {
      this.color = "#F99D24";
    } else if (this.queue === "90 Days") {
      this.color = "#ff470a";
    } else if (this.queue === "120 Days") {
      this.color = "#cc0000";
    } else if (this.queue === "150 Days" || this.queue === "Early Prelegal") {
      this.color = "#D00000";
    } else if (this.queue === "180 Days" || this.queue === "Late Prelegal") {
      this.color = "#8D0207";
    } else {
      this.color = "#6c757d";
    }
  },
};
</script>

<style></style>
