const notificationStore = {
  namespaced: true,
  state() {
    return {
      notifications: [],
    };
  },
  getters: {
    notifications(state) {
      return state.notifications;
    },
  },
  mutations: {
    push(state, payload) {
      const t = new Date();
      const newNotification = { id: t.toLocaleString(), time: t.toLocaleTimeString(), ...payload };
      state.notifications.push(newNotification);
    },
    dismiss(state, id) {
      state.notifications = state.notifications.filter((n) => n.id !== id);
    },
    clear(state) {
      state.notifications = [];
    },
  },
  actions: {
    push({ commit }, payload) {
      commit('push', payload);
    },
    dismiss({ commit }, id) {
      commit('dismiss', id);
    },
    clear({ commit }) {
      commit('clear');
    },
  },
};

export default notificationStore;
