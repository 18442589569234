<template>
  <Page>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5 class="mt-5 mb-4">
            Queue
            <span v-if="!isLoading && activeQueue"
              >({{ activeQueue.name }})</span
            >
          </h5>
          <div class="card">
            <div class="card-body">
              <table
                v-if="!isLoading && activeQueue"
                class="table align-middle mt-4"
              >
                <thead>
                  <tr>
                    <th scope="col">Reference</th>
                    <th scope="col">Corp Key</th>
                    <th scope="col">Prod Key</th>
                    <th scope="col">Arrears Key</th>
                    <th scope="col">Arrears Amount</th>
                    <th scope="col">Entry Date</th>
                    <th scope="col">Entry Event</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="account in activeQueue.accounts" :key="account.id">
                    <td>{{ account.reference }}</td>
                    <td>{{ account.corp_key }}</td>
                    <td>{{ account.prod_key }}</td>
                    <td>{{ account.arrears_key }}</td>
                    <td>{{ formatCurrency(account.arrears_amount) }}</td>
                    <td>{{ formatDate(account.entry_date) }}</td>
                    <td>{{ account.event_note || '-' }}</td>
                  </tr>
                </tbody>
              </table>

              <LoadingSpinner v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex';

import Page from '@/components/Shared/_Page.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

import { formatDate, formatCurrency } from '@/helpers/format';

export default {
  name: 'Queue',
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  components: { Page, LoadingSpinner },
  setup() {
    return {
      formatDate,
      formatCurrency,
    };
  },
  computed: {
    ...mapGetters({
      activeQueue: 'queue/activeQueue',
      isLoading: 'queue/isQueueLoading',
      error: 'queue/error',
    }),
  },
  async mounted() {
    await this.$store.dispatch('queue/getQueue', this.id);
  },
};
</script>
