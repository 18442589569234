<template>
  <div>
    <button class="btn btn-primary" @click="Login">LOGIN</button>
    <button class="btn btn-primary" @click="phone">CALL</button>
  </div>
</template>

<script>
export default {
  name: "Telephone",

  data() {
    return {
      telephone: null,
      test: null,
    };
  },

  methods: {
    Login() {
      this.$store.dispatch("telephony/login");
    },
    phone() {
      this.$store.dispatch("telephony/phone");
    },
  },
  mounted() {},
};
</script>

<style></style>
