<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-start">
        <h2 class="card-title mb-4">My CTPs</h2>
        <router-link :to="{ name: 'ctps' }" class="btn btn-primary btn-rounded"
          >View</router-link
        >
      </div>
      <div v-if="!isLoading" class="ctp-summary">
        <span class="tag"
          >Due Today ({{
            summary.due_today_pending + summary.due_today_honored
          }})</span
        >
        <span class="h3 mb-4"
          >{{ summary.due_today_honored }} Honored,
          {{ summary.due_today_pending }} Pending</span
        >

        <span class="tag"
          >Due Yesterday ({{
            summary.due_yesterday_honored + summary.due_yesterday_broken
          }})</span
        >
        <span class="h3 mb-4"
          >{{ summary.due_yesterday_honored }} Honored,
          {{ summary.due_yesterday_broken }} Broken</span
        >

        <span class="tag"
          >Created Today ({{
            summary.created_today_user + summary.created_today_system
          }})</span
        >
        <span class="h3 mb-0"
          >{{ summary.created_today_user }} Created,
          {{ summary.created_today_system }} Regenerated</span
        >
      </div>
      <div v-else><p>Loading...</p></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'AgentTargetsCard',
  components: {},
  data() {
    return {
      isLoading: true,
      summary: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {},
  async mounted() {
    this.summary = (
      await axios.get(`api/users/${this.user.id}/ctps/summary`)
    ).data;
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.ctp-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .tag {
    margin-bottom: 0.5rem;
  }
}
</style>