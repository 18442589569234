<template>
  <div class="card">
    <audio loop ref="ringer">
      <source src="../../../assets/tone_ringback.wav" />
    </audio>
    <div class="card-body">
      <footer v-show="isAssignedAccount && !inbound"></footer>
      <div class="row">
        <div class="col text-center">
          <p v-if="!(isDialing || onCall)" class="fw-bold">Contact</p>
          <p class="" v-for="(number, index) in phoneNumbers" :key="index">
            {{ number }}
          </p>
          <div class="col" v-if="callButton || isReturning">
            <div class="d-grid">
              <button
                class="btn btn-primary mb-3"
                type="button"
                @click="openChatModal"
              >
                <span class="material-icons">mail_outline</span> Chat
              </button>
              <CollectMessagesModal
                :show="showChatModal"
                @hide="closeChatModal"
                @chatOpened="chatOpened = true"
              />
            </div>
            <div class="d-grid">
              <button
                class="btn btn-primary"
                type="button"
                @click="nextAccount"
              >
                Next Account
              </button>
            </div>
          </div>
          <p v-if="isDialing" class="text-center">Dialing {{ number }}...</p>
          <p
            v-if="onCall"
            class="text-center d-none"
            :class="{ highTime: highTimePoint }"
          >
            {{ formattedDuration }}
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row d-none">
        <div class="col text-center mb-3">
          <span class="h4" v-if="countDown && countDown > 0">
            Calling in:
            <span class="h2">{{ countDown }}</span>
          </span>
        </div>
      </div>

      <div v-if="isDialing || onCall" class="row mb-4 d-none">
        <div class="d-flex justify-content-center">
          <button @click="hangup" class="btn btn-danger btn-round hangupBtn">
            <span class="material-icons"> call_end </span>
          </button>
          <!-- <button class="reCallBtn me-2" @click="callSameNumber">
            <FontAwesomeIcon :icon="['fas', 'redo-alt']" />
          </button>

          <button v-if="!isDialing && !callButton" @click="call" class="callBtn">
            <FontAwesomeIcon :icon="['fas', 'phone-alt']" /> CALL BACK
          </button>
          <button v-if="isDialing" @click="hangup" class="hangupBtn">
            <FontAwesomeIcon :icon="['fas', 'phone-alt']" />
          </button>
          <audio loop ref="ringer">
            <source src="../../../assets/tone_ringback.wav" />
          </audio> -->
        </div>
      </div>
      <div v-if="!(isDialing || onCall)" class="row mb-4 d-none">
        <div class="col" v-if="!callButton">
          <div class="d-grid">
            <button class="btn btn-call" @click="callNow">
              <span class="material-icons">call</span> Call
            </button>
          </div>
        </div>
        <div class="col" v-if="callButton">
          <div class="d-grid">
            <button
              class="btn btn-primary"
              type="button"
              @click="openChatModal"
            >
              <span class="material-icons">mail_outline</span> Chat
            </button>

            <CollectMessagesModal
              :show="showChatModal"
              @hide="closeChatModal"
            />
          </div>
        </div>

        <div class="col" v-if="callBack">
          <div class="d-grid">
            <button class="btn btn-call" @click="callSameNumber">
              <span class="material-icons">phone_callback</span>Redial
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <transition name="component-fade" mode="out-in">
<script>
import { secondsToHHMMSS } from "@/helpers/format";
// icons
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhoneAlt, faRedoAlt } from "@fortawesome/free-solid-svg-icons";

    </transition> -->
</template>

<script>
import CollectMessagesModal from '@/components/Collect/CollectMessagesModal.vue';

import { secondsToHHMMSS } from '@/helpers/format';
import { mapGetters } from 'vuex';

export default {
  name: 'Phone',
  props: {
    number: String,
    // isDialing: Boolean,
    // onCall: Boolean,
    callButton: Boolean,
    isReturning: Boolean,
  },
  emits: ['done', 'NRPC'],
  components: { CollectMessagesModal },
  data() {
    return {
      timer: null,
      duration: 0,
      showChatModal: false,
      chatOpened: false,
      countDown: 30,
    };
  },
  computed: {
    ...mapGetters({
      onCall: 'telephony/onCall',
      isDialing: 'telephony/isDialing',
      failed: 'telephony/error',
      callBack: 'telephony/callBack',
      phoneNumbers: 'account/phoneNumbers',
    }),
    highTimePoint() {
      return this.duration > 5 * 60; // 5 minutes
    },
    formattedDuration() {
      return secondsToHHMMSS(this.duration);
    },
    showCallBtn() {
      return !this.isDialing && !this.onCall;
    },
    showHangupBtn() {
      // this is kind of redundant (opposite of above computed prop),
      // but may be used later when more UI elements are added
      return !this.isDialing && this.onCall;
    },
  },
  watch: {
    isDialing(newState) {
      if (newState) {
        // this.$refs.ringer.play();
      } else {
        // this.$refs.ringer.pause();
      }
    },
    // onCall(newState) {
    //   if (newState && !this.timer) {
    //     this.timer = setInterval(() => {
    //       this.duration += 1;
    //     }, 1000);
    //   } else if (!newState && this.timer) {
    //     clearInterval(this.timer);
    //     this.timer = null;

    //     console.log("Clearing call timer");
    //   }
    // },
  },
  methods: {
    // countDownTimer() {
    //   if (this.countDown <= 0) {
    //     this.call();
    //   } else {
    //     setTimeout(() => {
    //       this.countDown -= 1;
    //       this.countDownTimer();
    //     }, 1000);
    //   }
    // },
    // callNow() {
    //   this.countDown = null;
    // },
    // call() {
    //   this.$emit("call");
    // },
    // hangup() {
    //   this.$emit("hangup");
    // },
    // callSameNumber() {
    //   this.$emit("callSameNumber");
    // },
    nextAccount() {
      if (this.chatOpened) {
        this.$emit('done');
      } else {
        this.$emit('NRPC');
      }
    },
    openChatModal() {
      this.showChatModal = true;
    },
    closeChatModal() {
      this.showChatModal = false;
    },
  },
  // mounted() {
  //   this.countDownTimer();
  // },
};
</script>

<style lang="scss" scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.btn-call {
  background-color: #1fc617;
  color: #fff;
}

.highTime {
  color: red;
}
</style>
