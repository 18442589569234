import axios from 'axios';

export const fetchCollectionsWriteoffAccounts = async () =>
  (await axios.get('api/admin/queues/collections-writeoff')).data.map(
    (account) => ({
      ...account,
      entry_date: new Date(account.entry_date),
    })
  );

export const rejectCollectionsWriteoffAccount = async (accountId) => {
  await axios.patch(`api/admin/queues/collections-writeoff/${accountId}`, {
    action: 'reject',
  });
};

export const writeoffDone = async (accountId) => {
  await axios.patch(`api/admin/queues/collections-writeoff/${accountId}`, {
    action: 'done',
  });
};
