<template>
  <div class="d-flex flex-column justify-content-center h-100">
    <div class="d-grid gap-3">
      <button
        @click="$emit('submit', eventTypes.JUST_RINGS)"
        class="btn btn-lg btn-primary"
      >
        Rings
      </button>
      <button @click="$emit('submit', eventTypes.ENGAGED)" class="btn btn-lg btn-primary">
        Engaged
      </button>
      <button
        @click="$emit('submit', eventTypes.INVALID_NO)"
        class="btn btn-lg btn-primary"
      >
        Invalid
      </button>
      <button @click="$emit('choice', 'nrpc_leftMessage')" class="btn btn-lg btn-primary">
        Left Message
      </button>

      <!--  -->
      <button @click="$emit('submit', eventTypes.TRACE)" class="btn btn-lg btn-primary">
        Trace
      </button>
      <!--  -->

      <button @click="$emit('choice', 'nrpc_other')" class="btn btn-lg btn-primary">
        Other
      </button>
    </div>
  </div>
</template>

<script>
import eventTypes from "@/helpers/eventTypes";

export default {
  name: "NRPC",
  emits: ["choice", "done"],
  setup() {
    return { eventTypes };
  },
};
</script>
