<template>
  <Queue idKey="id" sortKey="entry_date" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Legal </template>
    <template #title> Legal Journal </template>
    <template #default="{ item }">
      <LegalJournalQueueItem :account="item" @accept="onAccept(item.id)" @note="onNote" />
    </template>
  </Queue>
</template>

<script>
import useActionQueue from "@/composables/useActionQueue";
import Queue from "@/components/Shared/Queue.vue";
import LegalJournalQueueItem from "@/components/Legal/LegalJournalQueueItem.vue";
import { legalJournalAccounts, legalJournalDone } from "@/api/legal";
import { createNote } from "@/api/account";

export default {
  components: { Queue, LegalJournalQueueItem },
  setup() {
    const { accounts, isLoading, removeAccount } = useActionQueue(legalJournalAccounts);

    const onAccept = async (id) => {
      const undo = removeAccount(id);
      try {
        await legalJournalDone(id);
      } catch (error) {
        undo();
        console.log(error);
      }
    };

    const onNote = (payload) => {
      createNote(payload.accountId, payload.note);
    };

    return {
      accounts,
      isLoading,
      onAccept,
      onNote,
    };
  },
};
</script>
