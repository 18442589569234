<template>
  <Page>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5 class="mb-4">Commission</h5>

          <div class="row mb-5">
            <div class="col-lg-4">
              <div class="">
                <p class="display-3 mb-0">R1500</p>
                <p class="lead mb-2">Total Commission</p>
                <p class="small text-muted">R12500 from next target</p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="">
                <p class="display-3 mb-0">R1000</p>
                <p class="lead mb-2">MIB Commission</p>
                <p class="small text-muted">R12500 from next target</p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="">
                <p class="display-3 mb-0">R500</p>
                <p class="lead mb-2">Arrears Commission</p>
                <p class="small text-muted">R2000 from next target</p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="mb-5">
                <p class="lead mb-0">Current Cycle</p>
                <small>2021-06-15 / 2021-07-14</small>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p class="lead mb-0">MIB Targets</p>
                  <AgentTargets
                    :labels="['January', 'February', 'March']"
                    :datasets="[
                      {
                        label: 'confirmed',
                        data: [75000, 90000, 90000],
                        backgroundColor: 'rgba(0, 97, 242, 0.75)',
                      },
                      {
                        label: 'projected',
                        data: [110000, 90000, 90000],
                        backgroundColor: 'rgba(200, 200, 200, 0.75)',
                      },
                    ]"
                    :targets="[
                      { amount: 100000, commission: 1000 },
                      { amount: 140000, commission: 2000 },
                      { amount: 200000, commission: 3000 },
                    ]"
                  />
                </div>
                <div class="col-md-6">
                  <p class="lead mb-0">Arrears Targets</p>
                  <AgentTargets
                    :labels="['January', 'February', 'March']"
                    :datasets="[
                      {
                        label: 'confirmed',
                        data: [75000, 90000, 90000],
                        backgroundColor: 'rgba(0, 97, 242, 0.75)',
                      },
                      {
                        label: 'projected',
                        data: [110000, 90000, 90000],
                        backgroundColor: 'rgba(200, 200, 200, 0.75)',
                      },
                    ]"
                    :targets="[
                      { amount: 100000, commission: 1000 },
                      { amount: 140000, commission: 2000 },
                      { amount: 200000, commission: 3000 },
                    ]"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="lead">Past Cycles</p>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Cycle</th>
                        <th class="text-end" scope="col">MIB Collection</th>
                        <th class="text-end" scope="col">MIB Commission</th>
                        <th class="text-end" scope="col">Arrears Collection</th>
                        <th class="text-end" scope="col">Arrears Commission</th>
                        <th class="text-end" scope="col">Total Commission</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="num in 10" :key="num">
                        <td>2021-04-15 / 2021-05-14</td>
                        <td class="currency">103541</td>
                        <td class="currency">2000</td>
                        <td class="currency">51430</td>
                        <td class="currency">1250</td>
                        <td class="currency">3250</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Shared/_Page.vue';
import AgentTargets from '@/components/Agent/AgentTargets.vue';

export default {
  name: 'AgentCommission',
  components: { Page, AgentTargets },
};
</script>