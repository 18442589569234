<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <label class="form-label">Search Users</label>
        <SearchTimedInput class="mb-5" @search="searchUsers" />
      </div>
      <CreateUserModal />
    </div>
    <div v-if="userListLoading">
      <LoadingSpinner />
    </div>
    <table class="table align-middle" v-if="!userListLoading">
      <thead>
        <tr>
          <th scope="col">Username</th>
          <th scope="col">Name</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in paginatedUsers" :key="index">
          <td>{{ user.username }}</td>
          <td>{{ user.firstname }} {{ user.lastname }}</td>
          <td>
            <DeleteButton v-on:click="deleteItem(user.id)" />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3">
            <div class="d-flex">
              <button
                :disabled="!prevPages"
                class="btn btn-primary"
                v-on:click="previousPage"
              >
                &lt;
              </button>
              <button
                :disabled="!morePages"
                class="btn btn-primary"
                v-on:click="nextPage"
              >
                &gt;
              </button>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>

    <ConfirmActionModal
      :show="showConfirm"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
    >
      <template v-slot:header>Confirm Delete</template>
      <template v-slot:body>
        Are you sure you want to delete this user?
      </template>
    </ConfirmActionModal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ConfirmActionModal from '@/components/Shared/ConfirmActionModal.vue';
import DeleteButton from '@/components/Shared/DeleteButton.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
import SearchTimedInput from '@/components/Shared/SearchTimedInput.vue';
import CreateUserModal from './CreateUserModal.vue';
// import BaseCard from "@/components/Shared/_BaseCard.vue";

export default {
  name: 'UserList',
  components: {
    // BaseCard,
    SearchTimedInput,
    CreateUserModal,
    LoadingSpinner,
    ConfirmActionModal,
    DeleteButton,
  },
  data() {
    return {
      pageNumber: 0,
      size: 10,
      morePages: true,
      prevPages: false,
      userToDelete: null,
      showConfirm: false,
    };
  },
  computed: {
    ...mapGetters({
      userList: 'user/userList',
      userListLoading: 'user/userListLoading',
    }),
    pageCount() {
      const totalUsers = this.userList.length;
      return Math.ceil(totalUsers / this.size);
    },
    paginatedUsers() {
      const start = this.pageNumber * this.size;
      const end = start + this.size;
      return this.userList.slice(start, end);
    },
  },
  methods: {
    nextPage() {
      this.pageNumber += 1;
      if (this.pageNumber > this.pageCount - 2) {
        this.morePages = false;
      }
      if (this.pageNumber > 0) {
        this.prevPages = true;
      }
    },
    previousPage() {
      this.pageNumber -= 1;
      if (this.pageNumber < this.pageCount) {
        this.morePages = true;
      }
      if (this.pageNumber <= 0) {
        this.prevPages = false;
      }
    },
    async searchUsers(term) {
      this.pageNumber = 0;
      this.prevPages = false;
      await this.$store.dispatch('user/getSearchedUserList', { term });
      if (this.userList.length <= 10) {
        this.morePages = false;
      } else {
        this.morePages = true;
      }
    },
    deleteItem(id) {
      this.userToDelete = id;
      this.showConfirm = true;
    },
    confirmDelete() {
      const url = `api/user/${this.userToDelete}`;
      axios.delete(url);
      this.$store.dispatch('user/getUserList');
      this.$store.dispatch('notification/push', {
        body: `User Deleted`,
      });
      this.showConfirm = false;
    },
    cancelDelete() {
      this.positionToDelete = null;
      this.showConfirm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttonIcon {
  position: relative;
  outline: none;
  border: none;
  text-align: center;
  width: 2.6rem;
  height: 2.6rem;
  line-height: 2.6rem;
  background: transparent;
  padding: 0;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.15s linear;
  }
  &:hover::before {
    background-color: rgba(0, 0, 0, 0.054);
  }
  &:active::before,
  &:focus::before {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
