<template>
  <div>
    <div class="mb-3">
      <div class="d-flex justify-content-between align-items-end">
        <h5>
          Incapacitated <span v-if="accounts">({{ count }})</span>
        </h5>
      </div>
    </div>
  </div>
  <div v-if="accounts">
    <Queue
      idKey="id"
      sortKey="entry_date"
      :items="accounts"
      v-slot:default="{ item: account }"
    >
      <QueueItem :account="account">
        <template #tag>
          <span class="small d-block mb-3">
            On {{ account.entry_date.toLocaleString() }}
          </span>
        </template>
        <template #title>{{ account.reference }}</template>
        <template #body>
          <ul class="list-unstyled m-0">
            <li>Arrears Amount: {{ account.arrears_amount }}</li>
            <li>Reason: [Jail (e.g.)] {{ account.event_note }}</li>
            <li>Executor of the Estate: N/A {{ account.event_note }}</li>
          </ul>
        </template>
        <template #actions>
          <button
            @click="action(account.id, 'accept')"
            class="btn btn-primary"
            type="button"
          >
            Accept
          </button>
          <button
            @click="action(account.id, 'reject')"
            class="btn btn-primary"
            type="button"
          >
            Reject
          </button>
        </template>
      </QueueItem>
    </Queue>
  </div>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>

  <!-- <div>
    <div class="position-relative" v-if="queue && accounts.length >= 1">
      <transition-group name="list-complete">
        <div v-for="account in accounts" :key="account.id" class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <span class="small d-block mb-3">
                  On {{ account.entry_date.toLocaleString() }}</span
                >

                <h5 class="card-title">
                  {{ account.reference }}
                </h5>
                <hr />
                <p class="lead">Details:</p>
                <ul class="list-unstyled m-0">
                  <li>Arrears Amount: {{ account.arrears_amount }}</li>
                  <li>Reason: Jail {{ account.event_note }}</li>
                  <li>Executor of the Estate: N/A {{ account.event_note }}</li>
                </ul>
              </div>
              <div class="d-flex flex-column gap-2 col-3">
                <button
                  @click="action(account.id, 'accept')"
                  class="btn btn-primary"
                  type="button"
                >
                  Accept
                </button>
                <button
                  @click="action(account.id, 'reject')"
                  class="btn btn-primary"
                  type="button"
                >
                  Reject
                </button>
                <button @click="view(account.id)" class="btn btn-primary" type="button">
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div> -->
  <!-- </div> -->
</template>

<script>
import axios from 'axios';
import Queue from '@/components/Shared/Queue.vue';
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'PaymentDisputeQueue',
  components: { LoadingSpinner, Queue, QueueItem },
  data() {
    return {
      accounts: null,
      isLoading: true,
    };
  },
  computed: {
    count() {
      return this.accounts?.length;
    },
  },
  async mounted() {
    const queue = (await axios.get('api/admin/queues/incapacitated')).data;
    this.accounts = queue.accounts.map((account) => {
      const tempAcc = account;
      tempAcc.event_note = JSON.parse(tempAcc.event_note);
      tempAcc.entry_date = new Date(tempAcc.entry_date);
      return tempAcc;
    });
    this.isLoading = false;
  },
};
</script>


