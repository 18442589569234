import { createRouter, createWebHistory } from 'vue-router';
import boot from '@/router/boot';
import store from '@/store';
import { roleTypes } from '@/helpers/roleTypes';

// Auth
import Login from '@/views/Login.vue';

// Dash
import Dash from '@/views/Dash.vue';

// Accounts
import Account from '@/views/Account.vue';
import Accounts from '@/views/Accounts.vue';

// Queues
import Queue from '@/views/Queue.vue';
import Queues from '@/views/Queues.vue';
import PoolQueueOrder from '@/views/PoolQueueOrder.vue';
import CollectionQueues from '@/components/Queue/QueuesCollection.vue';
import AdminQueues from '@/components/Queue/QueuesAdmin.vue';
import OtherQueues from '@/components/Queue/QueuesOther.vue';
import QueueRoutes from '@/views/QueueRoutes.vue';

// Teams
import TeamManagement from '@/components/Teams/TeamManagement.vue';
import TeamStats from '@/components/Teams/TeamStats.vue';
import Team from '@/views/Team.vue';
import Teams from '@/views/Teams.vue';

// Users
import Users from '@/views/Users.vue';

// Help
import Support from '@/views/Support.vue';
import Test from '@/views/Test.vue';

// Current user
import Profile from '@/views/Profile.vue';
import ProfileInfo from '@/components/User/ProfileInfo.vue';
import EditProfileForm from '@/components/User/EditProfileForm.vue';
import ChangePasswordForm from '@/components/User/ChangePasswordForm.vue';

// Error
import NotFound from '@/views/NotFound.vue';

// Working
import ActionQueues from '@/views/ActionQueues.vue';

// Agent
import DefaultDash from '@/components/Dash/Index.vue';
import AgentDash from '@/components/Dash/Agent/index.vue';
import AgentCommission from '@/views/AgentCommission.vue';
import Collect from '@/views/Collect.vue';
import CollectPortal from '@/views/CollectPortal.vue';
import CTPs from '@/views/CTPs.vue';
import HonouredCTPTotals from '@/views/AgentTotalCTP.vue';

// Trace
import TraceQueue from '@/components/Admin/TraceQueue.vue';

// Admin
import JournalQueue from '@/components/Admin/JournalQueue.vue';
import PaymentDisputeQueue from '@/components/Admin/PaymentDisputeQueue.vue';
import IncapacitatedQueue from '@/components/Admin/IncapacitatedQueue.vue';
import Writeoff from '@/components/Admin/WriteoffQueue.vue';
import CollectionsWriteoff from '@/components/Admin/CollectionsWriteoffQueue.vue';

// Debt Review
import debtReviewAgentDash from '@/components/Dash/Debt Review Agent/index.vue';
import DebtReviewReferralQueue from '@/components/Admin/DebtReviewReferralQueue.vue';
import DebtReviewApplicationQueue from '@/components/Admin/DebtReviewApplicationQueue.vue';
import DebtReviewProposalQueue from '@/components/Admin/DebtReviewProposalQueue.vue';
import DebtReviewCounterProposalQueue from '@/components/Admin/DebtReviewCounterProposalQueue.vue';
import DebtReviewCounterReferralAcceptedQueue from '@/components/Admin/DebtReviewCounterReferralAcceptedQueue.vue';
import DebtReviewCTPBrokenQueue from '@/components/Admin/DebtReviewCTPBrokenQueue.vue';
import DebtReviewCounterReferralQueue from '@/components/Manager/DebtReviewCounterReferralQueue.vue';

// Admin Order
import AdminOrderReferralQueue from '@/components/Admin/AdminOrderReferralQueue.vue';
import AdminOrderCTPBrokenQueue from '@/components/Admin/AdminOrderCTPBrokenQueue.vue';

// Legal
import ReferralQueue from '@/components/Legal/ReferralQueue.vue';
import ConfirmationQueue from '@/components/Legal/ConfirmationQueue.vue';
import UnconfirmedQueue from '@/components/Legal/UnconfirmedQueue.vue';
import DeceasedQueue from '@/components/Legal/DeceasedQueue.vue';
import WriteoffReferralQueue from '@/components/Legal/WriteoffReferralQueue.vue';
import LegalTraceQueue from '@/components/Legal/LegalTraceQueue.vue';
import Section129PendingQueue from '@/components/Legal/Section129PendingQueue.vue';
import Section129SendQueue from '@/components/Legal/Section129SendQueue.vue';
import Section129SentQueue from '@/components/Legal/Section129SentQueue.vue';
import ProcessQueue from '@/components/Legal/ProcessQueue.vue';
import LegalLegacyQueue from '@/components/Legal/LegalLegacyQueue.vue';
import LegalJournalQueue from '@/components/Legal/LegalJournalQueue.vue';

// Supervisor
import SupervisorEscalateQueue from '@/views/SupervisorEscalateQueue.vue';
import DiarisedRequestQueue from '@/components/Supervisor/DiarisedRequestQueue.vue';
import AgentEvents from '@/views/AgentEvents.vue';
import RefusalQueue from '@/components/Supervisor/RefusalQueue.vue';

// Manager
import ManagerDash from '@/components/Dash/Manager/index.vue';
import WriteoffRequestQueue from '@/components/Manager/WriteoffRequestQueue.vue';
import Commissions from '@/views/Commissions.vue';

// System
import SystemSupport from '@/views/SystemSupport.vue';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      publicOnly: true,
    },
  },
  {
    path: '/',
    name: 'dash',
    component: Dash,
    children: [
      {
        path: '',
        component: DefaultDash,
      },
      {
        path: 'agent',
        name: 'agentDash',
        component: AgentDash,
      },
      {
        path: 'debt-review-agent',
        name: 'debtReviewAgentDash',
        component: debtReviewAgentDash,
      },

      {
        path: 'manager',
        name: 'managerDash',
        component: ManagerDash,
      },
    ],
  },
  {
    path: '/account/:id',
    name: 'account',
    component: Account,
    props: true,
  },
  {
    path: '/agent/collect/portal',
    name: 'collectPortal',
    component: CollectPortal,
  },
  {
    path: '/agent/collect/:action_id?/:account_id?',
    name: 'collect',
    component: Collect,
    props: true,
  },
  {
    path: '/system-support',
    name: 'systemSupport',
    component: SystemSupport,
  },
  {
    path: '/commissions',
    name: 'commissions',
    component: Commissions,
  },
  {
    path: '/agent/ctps',
    name: 'ctps',
    component: CTPs,
  },
  {
    path: '/total-honoured-ctps',
    name: 'total-honoured-ctps',
    component: HonouredCTPTotals,
  },

  {
    path: '/queues',
    name: 'actionQueues',
    component: ActionQueues,
    children: [
      {
        path: 'pending-diarised',
        name: 'pendingDiarised',
        component: DiarisedRequestQueue,
      },
      {
        path: 'proposed-writeoff',
        name: 'proposedWriteoff',
        component: WriteoffRequestQueue,
      },
      {
        path: 'escalate',
        name: 'escalate',
        component: SupervisorEscalateQueue,
      },
      {
        path: 'refusal',
        name: 'refusal',
        component: RefusalQueue,
      },
      {
        path: 'journal',
        name: 'journal',
        component: JournalQueue,
      },
      {
        path: 'trace',
        name: 'trace',
        component: TraceQueue,
      },
      {
        path: 'debt-review-referral',
        name: 'debt-review-referral',
        component: DebtReviewReferralQueue,
      },
      {
        path: 'debt-review-application',
        name: 'debt-review-application',
        component: DebtReviewApplicationQueue,
      },
      {
        path: 'debt-review-proposal',
        name: 'debt-review-proposal',
        component: DebtReviewProposalQueue,
      },
      {
        path: 'debt-review-counter-proposal',
        name: 'debt-review-counter-proposal',
        component: DebtReviewCounterProposalQueue,
      },
      {
        path: 'debt-review-counter-referral',
        name: 'debt-review-counter-referral',
        component: DebtReviewCounterReferralQueue,
      },
      {
        path: 'debt-review-counter-referral-accepted',
        name: 'debt-review-counter-referral-accepted',
        component: DebtReviewCounterReferralAcceptedQueue,
      },
      {
        path: 'debt-review-ctp-broken',
        name: 'debt-review-ctp-broken',
        component: DebtReviewCTPBrokenQueue,
      },
      {
        path: 'admin-order-referral',
        name: 'admin-order-referral',
        component: AdminOrderReferralQueue,
      },
      {
        path: 'admin-order-ctp-broken',
        name: 'admin-order-ctp-broken',
        component: AdminOrderCTPBrokenQueue,
      },
      // Legal
      {
        path: 'legal-referral',
        name: 'legal-referral',
        component: ReferralQueue,
      },
      {
        path: 'legal-confirmation',
        name: 'legal-confirmation',
        component: ConfirmationQueue,
      },
      {
        path: 'legal-unconfirmed',
        name: 'legal-unconfirmed',
        component: UnconfirmedQueue,
      },
      {
        path: 'deceased',
        name: 'deceased',
        component: DeceasedQueue,
      },
      {
        path: 'writeoff-referral',
        name: 'writeoff-referral',
        component: WriteoffReferralQueue,
      },
      {
        path: 'legal-trace',
        name: 'legal-trace',
        component: LegalTraceQueue,
      },
      {
        path: 'section-129-pending',
        name: 'section-129-pending',
        component: Section129PendingQueue,
      },
      {
        path: 'section-129-sent',
        name: 'section-129-sent',
        component: Section129SentQueue,
      },
      {
        path: 'legal-journal',
        name: 'legal-journal',
        component: LegalJournalQueue,
      },
      {
        path: 'section-129-send',
        name: 'section-129-send',
        component: Section129SendQueue,
      },
      {
        path: 'legal-process',
        name: 'legal-process',
        component: ProcessQueue,
      },
      {
        path: 'legal-legacy',
        name: 'legal-legacy',
        component: LegalLegacyQueue,
      },
      {
        path: 'incapacitated',
        name: 'Incapacitated',
        component: IncapacitatedQueue,
      },
      {
        path: 'collections-writeoff',
        name: 'collections-writeoff',
        component: CollectionsWriteoff,
      },
      {
        path: 'payment-dispute',
        name: 'payment-dispute',
        component: PaymentDisputeQueue,
      },
      {
        path: 'writeoff',
        name: 'Write-off',
        component: Writeoff,
      },
    ],
  },
  {
    path: '/accounts/:search?',
    name: 'accounts',
    component: Accounts,
    props: true,
  },
  {
    path: '/queues/:id',
    name: 'queue',
    component: Queue,
    props: true,
  },
  {
    path: '/queues',
    name: 'queues',
    component: Queues,
    children: [
      {
        path: 'collection_queues',
        name: 'collection_queues',
        component: CollectionQueues,
      },
      {
        path: 'admin_queues',
        name: 'admin_queues',
        component: AdminQueues,
      },
      {
        path: 'other_queues',
        name: 'other_queues',
        component: OtherQueues,
      },
      {
        path: 'pool_order',
        name: 'pool_queue_order',
        component: PoolQueueOrder,
      },
    ],
  },
  {
    path: '/queue_routes',
    name: 'queue_routes',
    component: QueueRoutes,
  },
  {
    path: '/teams/:id',
    name: 'team',
    component: Team,
    props: true,
    children: [
      {
        path: 'management',
        name: 'team_management',
        component: TeamManagement,
        props: true,
      },
      {
        path: 'stats',
        name: 'TeamStats',
        component: TeamStats,
        props: true,
      },
    ],
  },
  {
    path: '/teams',
    name: 'teams',
    component: Teams,
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
  },
  {
    path: '/profile',
    component: Profile,
    children: [
      {
        path: '',
        name: 'view_profile',
        component: ProfileInfo,
      },
      {
        path: 'edit',
        name: 'edit_profile',
        component: EditProfileForm,
      },
      {
        path: 'password',
        name: 'change_password',
        component: ChangePasswordForm,
      },
    ],
  },
  {
    path: '/agent/commission',
    name: 'agentCommission',
    component: AgentCommission,
  },
  {
    path: '/agent_events',
    name: 'agentEvents',
    component: AgentEvents,
  },
  // Dev route
  {
    path: '/test',
    name: 'test',
    component: Test,
  },
  // 404 route
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// middleware
router.beforeEach(boot);
router.beforeEach(async (to, from, next) => {
  const publicOnly = to.matched.some((record) => record.meta.publicOnly);
  const isAuthRequired = !publicOnly;
  const isAuthenticated = store.getters['auth/isAuthenticated'];

  const roles = store.getters['auth/roles'];

  // store.dispatch('activity/setActivity', store.getters['auth/user']?.activity);

  // push to specific dash
  if (isAuthenticated && to.name === 'dash') {
    if (roles?.length === 1) {
      const role = roles[0];
      if (role === roleTypes.AGENT) return next({ name: 'agentDash' });
      if (role === roleTypes.DEBT_REVIEW_AGENT)
        return next({ name: 'debtReviewAgentDash' });
      if (role === roleTypes.MANAGER) return next({ name: 'managerDash' });
    }
  }

  if (isAuthenticated && publicOnly) {
    return next({ name: 'dash' });
  }

  if (!isAuthenticated && isAuthRequired) {
    return next({ name: 'login' });
  }

  // const isAssignedAccount = store.getters['account/isAssignedAccount'];
  // const isAssignedAccountLoading = store.getters['account/isLoading'];
  // if (isAuthenticated && (isAssignedAccount || isAssignedAccountLoading) && to.name !== 'account') {
  // return next({ name: 'account' });
  // }

  return next();
});

export default router;
