<template>
  <div v-if="!isLoading" id="queueRoutesView">
    <div class="container pt-5">
      <div class="row">
        <div class="col">
          <div
            v-for="queue in queues"
            :key="queue.data.id"
            class="form-check form-check-inline"
          >
            <input
              v-model="selectedQueues"
              type="checkbox"
              :id="queue.data.id"
              :value="queue.data.id"
              class="form-check-input"
            />
            <label :for="queue.data.id" class="form-check-label">{{
              queue.data.id
            }}</label>
          </div>
        </div>
      </div>
    </div>

    <div id="cy" ref="cy"></div>
  </div>
  <LoadingSpinner v-else />
</template>

<script>
import axios from 'axios';
import cytoscape from 'cytoscape';

import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'QueueRoutes',
  components: { LoadingSpinner },
  computed: {},
  data() {
    return {
      isLoading: false,
      queues: null,
      routes: null,
      cy: null,
      selectedQueues: [],
    };
  },
  methods: {
    async load() {
      this.isLoading = true;
      const d = (await axios.get('/api/queue_routes')).data;
      this.queues = [
        ...new Set(d.map((qr) => [qr.from_queue, qr.to_queue]).flat(1)),
      ].map((q) => ({ data: { id: q } }));

      this.routes = d.map((qr) => ({
        data: {
          id: `${qr.from_queue}-${qr.event_type}-${qr.to_queue}`,
          source: qr.from_queue,
          target: qr.to_queue,
        },
      }));

      this.isLoading = false;
    },
    render() {
      this.cy = cytoscape({
        container: this.$refs.cy,
        elements: [...this.queues, ...this.routes],

        style: [
          // the stylesheet for the graph
          {
            selector: 'node',
            style: {
              width: 30,
              height: 30,
              'background-color': '#0f52ba',
              content: 'data(id)',
            },
          },

          {
            selector: 'edge',
            style: {
              width: 1,
              'line-color': '#aaa',
              'target-arrow-color': '#aaa',
              'target-arrow-shape': 'triangle',
              'curve-style': 'bezier',
            },
          },
        ],

        layout: {
          name: 'grid',

          fit: true, // whether to fit the viewport to the graph
          padding: 30, // padding used on fit
          boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
          avoidOverlap: true, // prevents node overlap, may overflow boundingBox if not enough space
          avoidOverlapPadding: 10, // extra spacing around nodes when avoidOverlap: true
          nodeDimensionsIncludeLabels: false, // Excludes the label when calculating node bounding boxes for the layout algorithm
          spacingFactor: undefined, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
          condense: false, // uses all available space on false, uses minimal space on true
          rows: undefined, // force num of rows in the grid
          cols: undefined, // force num of columns in the grid

          sort: undefined, // a sorting function to order the nodes; e.g. function(a, b){ return a.data('weight') - b.data('weight') }
          animate: false, // whether to transition the node positions
          animationDuration: 500, // duration of animation in ms if enabled
          animationEasing: undefined, // easing of animation if enabled

          ready: undefined, // callback on layoutready
          stop: undefined, // callback on layoutstop
        },
      });
    },
  },
  watch: {
    selectedQueues() {
      const rules = this.selectedQueues.map((s) => `[id = "${s}"]`).join(',');
      this.cy.nodes().style('display', 'none');
      this.cy.nodes(rules).style('display', 'element');
    },
  },
  async created() {
    await this.load();
    this.render();
  },
};
</script>

<style scoped>
#queueRoutesView {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#cy {
  height: 100%;
  width: 100%;
}
</style>
