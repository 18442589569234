<template>
  <Queue
    idKey="id"
    sortKey="entry_date"
    :isLoading="isLoading"
    :items="accounts"
  >
    <template #subtitle> Debt Review </template>
    <template #title> Counter Referral Accepted </template>
    <template v-slot:default="{ item: account }">
      <DebtReviewCounterReferralAcceptedQueueItem
        :account="account"
        @action="action"
        @note="createNote"
      />
    </template>
  </Queue>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import Queue from '@/components/Shared/Queue.vue';
import DebtReviewCounterReferralAcceptedQueueItem from '@/components/Admin/DebtReviewCounterReferralAcceptedQueueItem.vue';

import { createNote } from '@/api/account';

export default {
  name: 'DebtReviewCounterReferralAcceptedQueue',
  components: {
    DebtReviewCounterReferralAcceptedQueueItem,
    Queue,
  },
  data() {
    return {
      accounts: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      positions: 'auth/permissions',
    }),
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    remove(accountId) {
      this.accounts = this.accounts.filter((a) => a.id !== accountId);
    },
    note(action) {
      console.log(action);
    },
    async fetchData() {
      const queue = (
        await axios.get(
          'api/admin/queues/debt-review-counter-referral-approved'
        )
      ).data;
      this.accounts = queue.accounts.map((account) => {
        const tempAcc = account;
        // tempAcc.event_note = JSON.parse(tempAcc.event_note);
        // tempAcc.entry_date = new Date(tempAcc.entry_date);
        return tempAcc;
      });
      this.isLoading = false;
    },

    async action(event) {
      const { accountId, payload } = event;
      this.$store.dispatch('account/clearCurrentAccount');
      this.remove(accountId);
      try {
        await axios.patch(
          `api/admin/queues/debt-review-counter-referral-approved/${accountId}`,
          payload
        );
      } catch (error) {
        console.error(error);
        this.fetchData();
        this.$store.dispatch('notification/push', {
          body: 'Something went wrong. Check that the situation on ILS has been changed.',
          type: 'error',
        });
      }
    },
    createNote({ accountId, note }) {
      createNote(accountId, note);
    },
  },
};
</script>
