<template>
  <ChangeUserActivityModal
    :show="showStatusModal"
    @confirm="redirectForChangeStatus"
    @cancel="closeStatusModal"
  />
  <CollectSearchModal :show="showSearchModal" @close="closeSearchModal" />
  <CollectChatsModal :show="showChatsModal" @close="closeChatsModal" />
  <div class="bg-primary init h-100 d-flex flex-column justify-content-center">
    <CircleTimer :duration="30" @timeout="next" class="timer" />
    <div class="actions">
      <router-link :to="{ name: 'collect' }" class="btn btn-outline-light me-2"
        >Next</router-link
      >
      <button @click="openChatsModal" class="btn btn-outline-light">
        Chats
      </button>
      <button @click="openSearchModal" class="btn btn-outline-light">
        Search
      </button>
      <button @click="openStatusModal" class="btn btn-outline-light">
        Exit
      </button>
    </div>
  </div>
</template>

<script>
import CircleTimer from '@/components/Collect/CircleTimer.vue';
import CollectSearchModal from '@/components/Collect/CollectSearchModal.vue';
import CollectChatsModal from '@/components/Collect/CollectChatsModal.vue';
import ChangeUserActivityModal from '@/components/User/ChangeUserActivityModal.vue';

export default {
  name: 'CollectPortal',
  components: {
    CircleTimer,
    CollectSearchModal,
    CollectChatsModal,
    ChangeUserActivityModal,
  },
  setup() {},
  data() {
    return {
      showStatusModal: false,
      showSearchModal: false,
      showChatsModal: false,
    };
  },
  methods: {
    redirectForChangeStatus() {
      this.showStatusModal = false;
      this.$router.push({ name: 'dash' });
    },
    openStatusModal() {
      this.showStatusModal = true;
    },
    closeStatusModal() {
      this.showStatusModal = false;
    },
    openSearchModal() {
      this.showSearchModal = true;
    },
    closeSearchModal() {
      this.showSearchModal = false;
    },
    openChatsModal() {
      this.showChatsModal = true;
    },
    closeChatsModal() {
      this.showChatsModal = false;
    },
    next() {
      this.$router.push({ name: 'collect' });
    },
  },
};
</script>

<style scoped>
.timer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.actions {
  position: absolute;
  padding: 2.5rem;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.actions .btn + .btn {
  margin-left: 1rem;
}
</style>
