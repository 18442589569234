<template>
  <td>
    <Form>
      <Field
        name="user"
        class="form-select"
        as="select"
        id="user"
        placeholder="user"
        v-model="assigned_user"
      >
        <option selected />
        <option v-for="user in user_list" :key="user.id" :value="user.id">
          {{ user.username }}
        </option>
      </Field>
    </Form>
  </td>
  <td>
    <AssignButton
      v-on:click="assignPosition"
      :disabled="assigned_user.length < 1"
    />
    <DeleteButton v-on:click="$emit('delete')" />
  </td>
</template>

<script>
import { mapGetters } from 'vuex';
import { Form, Field } from 'vee-validate';
import { positionService } from '@/services/position.service';
import DeleteButton from '@/components/Shared/DeleteButton.vue';
import AssignButton from '../Shared/AssignButton.vue';

export default {
  name: 'AssignPosition',
  components: { Form, Field, AssignButton, DeleteButton },
  emits: ['user-assigned', 'delete'],
  props: {
    position: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      user_list: 'user/userList',
    }),
  },
  data() {
    return {
      assigned_user: '',
    };
  },
  methods: {
    async assignPosition() {
      const response = await positionService.assignPosition(
        this.position.id,
        this.assigned_user
      );
      if (response) {
        this.$store.dispatch('notification/push', {
          body: `Position Assigned`,
        });
        this.$emit('user-assigned');
      } else {
        this.$store.dispatch('notification/push', {
          body: `Position Assign Failed`,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style></style>
