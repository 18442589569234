import axios from 'axios';
// import { wait } from '../helpers/general';

const teamStore = {
  namespaced: true,
  state() {
    return {
      allPositions: [],
      unassignedPositions: [],
      positionListLoading: false,
      positionList: [],
      isTeamListLoading: false,
      teamList: [],
      isTeamLoading: false,
      team: null,
      errors: [],
      optimisticCache: {},
      teamQueues: [],
      teamQueuesLoading: false,
      teamTimeStats: null,
      // teamMemberAccTouchedStats: null,
      // teamTotalAccTouched: null,
      // teamTotalCalls: null,
      // teamMemberCalls: null,
      memberProductivityStats: null,
      allTeamStats: null,
      reportsToUsernameList: null,
    };
  },
  mutations: {
    resportsToListLoaded(state, resportsToList) {
      state.reportsToUsernameList = resportsToList;
    },
    optimisticCacheClear(state, id) {
      delete state.optimisticCache[id];
    },

    // Positions
    positionListRequest(state) {
      state.errors = [];
      state.positionListLoading = true;
    },
    positionListLoaded(state, positionList) {
      state.positionList = positionList;
      state.positionListLoading = false;
    },
    positionListRequestFailed(state, error) {
      state.errors.push(error);
      state.positionListLoading = false;
    },
    positionListItemDelete(state, position) {
      if ('assigned_user' in position) {
        state.team.positions = state.team.positions.filter(
          (pos) => pos.id !== position.id
        );
      } else {
        state.unassignedPositions = state.unassignedPositions.filter(
          (pos) => pos.id !== position.id
        );
      }
    },
    positionUnassigned(state, payload) {
      const unassignedPosition = state.team.positions.filter(
        (pos) => pos.id === payload.position_id
      )[0];
      state.optimisticCache[payload.position_id] = unassignedPosition;
      state.team.positions = state.team.positions.filter(
        (pos) => pos.id !== payload.position_id
      );
      const newUnassignedPosition = {
        id: unassignedPosition.id,
        reports_to: unassignedPosition.reports_to,
        team_id: parseInt(payload.team_id, 10),
        position: unassignedPosition.position,
        role: unassignedPosition.role,
      };
      state.unassignedPositions = [
        ...state.unassignedPositions,
        newUnassignedPosition,
      ];
    },
    positionUnassignFailed(state, id) {
      state.team.positions.push(state.optimisticCache[id]);
    },
    allPositionsLoaded(state, positionList) {
      state.allPositions = positionList;
    },
    unassignedPositionsLoaded(state, positionList) {
      state.unassignedPositions = positionList;
    },
    positionDeleteFailed(state, position) {
      if ('assigned_user' in position) {
        state.team.positions = [...state.team.positions, position];
      } else {
        state.unassignedPositions = [...state.unassignedPositions, position];
      }
    },

    // Stats
    teamTimeStatsLoaded(state, timeStats) {
      state.teamTimeStats = timeStats;
    },
    // teamMemberAccTouchedStatsLoaded(state, accTouchedStats) {
    //   state.teamMemberAccTouchedStats = accTouchedStats;
    // },
    // teamTotalAccTouchedStatsLoaded(state, accTouchedStats) {
    //   state.teamTotalAccTouched = accTouchedStats;
    // },
    // teamTotalCallsLoaded(state, teamTotalCalls) {
    //   state.teamTotalCalls = teamTotalCalls;
    // },
    // teamMemberCallsLoaded(state, teamMemberCalls) {
    //   state.teamMemberCalls = teamMemberCalls;
    // },
    memberProductivityStatsLoaded(state, productivityStats) {
      state.memberProductivityStats = productivityStats;
    },
    allStatsLoaded(state, stats) {
      state.allTeamStats = stats;
    },
    // Teams
    teamListRequest(state) {
      state.errors = [];
      state.isTeamListLoading = true;
    },
    teamListLoaded(state, teamList) {
      state.teamList = teamList;
      state.isTeamListLoading = false;
    },
    teamListRequestFailed(state, error) {
      state.errors.push(error);
      state.isTeamListLoading = false;
    },
    teamRequest(state) {
      state.errors = [];
      state.isTeamLoading = true;
    },
    teamLoaded(state, team) {
      state.team = team;
      state.isTeamLoading = false;
    },
    teamRequestFailed(state, error) {
      state.errors.push(error);
      state.isTeamLoading = false;
    },
    clearTeam(state) {
      state.team = null;
      state.memberProductivityStats = null;
      state.teamTimeStats = null;
      state.allTeamStats = null;
      state.isTeamLoading = false;
    },
    teamQueueListLoaded(state, queueList) {
      state.teamQueues = queueList;
      state.teamQueuesLoading = false;
    },
    teamQueueRequested(state) {
      state.teamQueuesLoading = true;
    },
  },
  getters: {
    allTeamStats(state) {
      return state.allTeamStats;
    },
    teamMemberProductivityStats(state) {
      return state.memberProductivityStats;
    },
    // teamTotalCalls(state) {
    //   return state.teamTotalCalls
    // },
    teamTimeStats(state) {
      return state.teamTimeStats;
    },
    // teamMemberAccTouchedStats(state) {
    //   return state.teamMemberAccTouchedStats
    // },
    isPositionListLoading(state) {
      return state.positionListLoading;
    },
    isTeamLoading(state) {
      return state.isTeamLoading;
    },
    isTeamListLoading(state) {
      return state.isTeamListLoading;
    },
    team(state) {
      return state.team;
    },
    positionList(state) {
      return state.positionList;
    },
    teamList(state) {
      return state.teamList;
    },
    errors(state) {
      return state.errors;
    },
    allPositions(state) {
      return state.allPositions;
    },
    unassignedPositions(state) {
      return state.unassignedPositions;
    },
    teamQueues(state) {
      return state.teamQueues;
    },
    teamQueuesLoading(state) {
      return state.teamQueuesLoading;
    },
    reportsToUsernameList(state) {
      return state.reportsToUsernameList;
    },
    // teamTotalAccTouched(state) {
    //   return state.teamTotalAccTouched;
    // },
  },
  actions: {
    async getPositions({ commit }, params) {
      commit('positionListRequest');
      try {
        const positionList = (await axios.get('api/positions', { params }))
          .data;
        commit('positionListLoaded', positionList);
      } catch (error) {
        commit('positionListRequestFailed', error.message);
      }
    },
    async deletePosition({ commit }, position) {
      commit('positionListItemDelete', position);
      try {
        await axios.delete(`api/positions/${position.id}`);
        return true;
      } catch {
        commit('positionDeleteFailed', position);
        return false;
      }
    },
    async getTeamList({ commit }) {
      commit('teamListRequest');
      try {
        const teamList = (await axios.get('api/teams')).data;
        commit('teamListLoaded', teamList);
      } catch (error) {
        commit('teamListRequestFailed', error.message);
      }
    },
    async getTeam({ commit }, id) {
      commit('teamRequest');
      try {
        const team = (await axios.get(`api/teams/${id}`)).data;
        commit('teamLoaded', team);
      } catch (error) {
        commit('teamRequestFailed', error.message);
      }
    },
    clearTeam({ commit }) {
      commit('clearTeam');
    },
    async unassignPosition({ commit }, payload) {
      commit('positionUnassigned', payload);
      try {
        const unnassignURL = `api/userpositions/${payload.position_id}`;
        await axios.delete(unnassignURL, {
          params: { position_id: payload.position_id },
        });
        commit('optimisticCacheClear', payload.position_id);
        return true;
      } catch {
        commit('positionUnassignFailed', payload.position_id);
        return false;
      }
    },
    async getAllPositions({ commit }) {
      try {
        const positionList = (
          await axios.get('api/positions', { params: { unassigned: false } })
        ).data;
        commit('allPositionsLoaded', positionList);
      } catch {
        this.$store.dispatch('notification/push', {
          body: `Something went wrong`,
          type: 'error',
        });
      }
    },
    async getUnassignedPositions({ commit }) {
      try {
        const positionList = (
          await axios.get('api/positions', { params: { unassigned: true } })
        ).data;
        commit('unassignedPositionsLoaded', positionList);
      } catch {
        this.$store.dispatch('notification/push', {
          body: `Something went wrong`,
          type: 'error',
        });
      }
    },
    async getTeamQueues({ commit }, payload) {
      commit('teamQueueRequested');
      try {
        const queueList = (
          await axios.get('api/queues', { params: { team: payload } })
        ).data;
        commit('teamQueueListLoaded', queueList);
      } catch {
        this.$store.dispatch('notification/push', {
          body: `Something went wrong`,
          type: 'error',
        });
      }
    },
    async getTeamTimeStats({ commit }, teamId) {
      try {
        const urlTime = `api/team/${teamId}/stats/time`;
        const timeStats = (await axios.get(urlTime)).data;
        commit('teamTimeStatsLoaded', timeStats);
      } catch {
        console.error('something went wrong get time stats');
      }
    },
    // async getTeamTotalAccTouchedStats({commit}, teamId) {
    //   try {
    //     const urlAccTouched = `api/team/${teamId}/stats/accounts-touched/total`
    //     const accTouchedStats = ( await axios.get(urlAccTouched)).data;
    //     commit("teamTotalAccTouchedStatsLoaded", accTouchedStats)

    //   } catch {
    //     console.error("something went wrong get time stats")
    //   }
    // },
    // async getTeamTotalCalls({commit}, teamId) {
    //   try {
    //     const urlCalls = `api/team/${teamId}/stats/calls/total`
    //     const teamTotalCalls = ( await axios.get(urlCalls)).data;
    //     commit("teamTotalCallsLoaded", teamTotalCalls)

    //   } catch {
    //     console.error("something went wrong get time stats")
    //   }
    // },
    async getAllTeamStats({ commit }, teamId) {
      try {
        const urlStats = `api/team/${teamId}/stats`;
        const stats = (await axios.get(urlStats)).data;
        commit('allStatsLoaded', stats);
      } catch {
        console.error('something went wrong get time stats');
      }
    },
    async getReportsToList({ commit }, teamName) {
      try {
        const resportsToList = (
          await axios.get('api/positions/reports-to-usernames', {
            params: { team_name: teamName },
          })
        ).data;
        commit('resportsToListLoaded', resportsToList);
      } catch {
        console.error('Something went wrong');
      }
    },
  },
};

export default teamStore;
