<template>
  <h2>RPC Other</h2>
  <form @submit.prevent="submit">
    <div class="row mb-3">
      <div class="col">
        <label class="form-label">RPC Type</label>
        <select v-model="type" class="form-select" required>
          <option disabled value="">Select type</option>
          <option
            :value="eventTypeKey"
            v-for="eventTypeKey of Object.keys(NRPCOtherEventTypes)"
            :key="NRPCOtherEventTypes[eventTypeKey].type"
          >
            {{ NRPCOtherEventTypes[eventTypeKey].name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label">Name</label>
        <input v-model="name" type="text" class="form-control" />
      </div>
      <div class="col">
        <label class="form-label">Number</label>
        <input v-model="number" type="text" class="form-control" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label">Notes</label>
        <textarea v-model="notes" class="form-control"></textarea>
      </div>
    </div>
    <button type="submit" role="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import eventTypes from '@/helpers/eventTypes';

const { DEBT_REVIEW, SEQUESTRATED, ADMIN_ORDER } = eventTypes;

export default {
  name: 'RPCOther',
  emits: ['submit'],
  data() {
    return {
      type: '',
      name: '',
      number: '',
      notes: '',
      NRPCOtherEventTypes: { DEBT_REVIEW, SEQUESTRATED, ADMIN_ORDER },
    };
  },
  methods: {
    submit() {
      const { name, type, closesAction } = this.NRPCOtherEventTypes[this.type];
      const details = {
        name: this.name,
        number: this.number,
        notes: this.notes,
      };
      this.$emit('submit', { type, name, closesAction, details });
    },
  },
};
</script>
