<template>
  <Queue
    idKey="id"
    sortKey="entry_date"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #subtitle> Legal </template>
    <template #title> Trace </template>
    <template #default="{ item }">
      <LegalTraceQueueItem
        :account="item"
        @success="onSuccess(item.id)"
        @failure="onFailure(item.id)"
        @writeoff="onWriteoff(item.id)"
      />
    </template>
  </Queue>
</template>


<script>
import useActionQueue from '@/composables/useActionQueue';
import Queue from '@/components/Shared/Queue.vue';
import LegalTraceQueueItem from '@/components/Legal/LegalTraceQueueItem.vue';
import {
  fetchLegalTraceAccounts,
  successfulLegalTrace,
  failedLegalTrace,
  writeoffLegalTrace,
} from '@/api/legal';

export default {
  components: { Queue, LegalTraceQueueItem },
  setup() {
    const { accounts, isLoading, removeAccount } = useActionQueue(
      fetchLegalTraceAccounts
    );

    const onSuccess = async (id) => {
      const undo = removeAccount(id);
      try {
        await successfulLegalTrace(id);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onFailure = async (id) => {
      const undo = removeAccount(id);
      try {
        await failedLegalTrace(id);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onWriteoff = async (id) => {
      const undo = removeAccount(id);
      try {
        await writeoffLegalTrace(id);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    return {
      accounts,
      isLoading,
      onSuccess,
      onFailure,
      onWriteoff,
    };
  },
};
</script>