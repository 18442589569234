<template>
  <form @submit.prevent="onSubmit">
    <div class="mb-3">
      <label class="form-label">Note</label>
      <select class="form-select mt-0" v-model="agentId">
        <option :value="null" selected disabled>Select an agent</option>
        <option
          v-for="agent in agents"
          :value="agent.user_position_id"
          :key="agent.user_position_id"
        >
          {{ agent.username }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label class="form-label">Note</label>
      <textarea v-model="note" class="form-control" rows="2"></textarea>
    </div>
    <button @click="pushToAgent" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    agents: { type: Array, required: true },
  },
  emits: ['submitted'],
  setup(_, { emit }) {
    const agentId = ref(null);
    const note = ref(null);

    const onSubmit = () => {
      emit('submitted', { agentId: agentId.value, note: note.value });
    };

    return {
      agentId,
      note,
      onSubmit,
    };
  },
};
</script>