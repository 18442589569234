<template>
  <Queue
    idKey="id"
    sortKey="entry_date"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #subtitle> Legal </template>
    <template #title> Legacy </template>
    <template #default="{ item }">
      <!-- '', '', '', 'process', '' -->

      <LegalLegacyQueueItem
        :account="item"
        @confirmation="onConfirmation(item.id)"
        @section129="onSection129(item.id)"
        @defended="onDefended(item.id)"
        @process="onProcess(item.id)"
        @writeoff="onWriteoff(item.id)"
      />
    </template>
  </Queue>
</template>


<script>
import useActionQueue from '@/composables/useActionQueue';
import Queue from '@/components/Shared/Queue.vue';
import LegalLegacyQueueItem from '@/components/Legal/LegalLegacyQueueItem.vue';
import {
  fetchLegacyAccounts,
  confirmationLegacy,
  section129Legacy,
  defendedLegacy,
  processLegacy,
  writeoffLegacy,
} from '@/api/legal';

export default {
  components: { Queue, LegalLegacyQueueItem },
  setup() {
    const { accounts, isLoading, removeAccount } =
      useActionQueue(fetchLegacyAccounts);

    const onConfirmation = async (accountId) => {
      const undo = removeAccount(accountId);
      try {
        await confirmationLegacy(accountId);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onSection129 = async (accountId) => {
      const undo = removeAccount(accountId);
      try {
        await section129Legacy(accountId);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onDefended = async (accountId) => {
      const undo = removeAccount(accountId);
      try {
        await defendedLegacy(accountId);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onProcess = async (accountId) => {
      const undo = removeAccount(accountId);
      try {
        await processLegacy(accountId);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onWriteoff = async (accountId) => {
      const undo = removeAccount(accountId);
      try {
        await writeoffLegacy(accountId);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    return {
      accounts,
      isLoading,
      onConfirmation,
      onSection129,
      onDefended,
      onProcess,
      onWriteoff,
    };
  },
};
</script>