<template>
  <Form :validation-schema="traceOutcomeValidation" @submit="postOutcome">
    <div class="form-floating mb-3">
      <Field
        name="number"
        class="form-control"
        type="text"
        id="number"
        placeholder="Number"
        v-model="number"
      />
      <label for="number">Number</label>
      <ErrorMessage name="number" class="error-message" />
    </div>

    <div class="form-floating mb-3" v-if="number">
      <Field
        name="outcome"
        class="form-select"
        as="select"
        id="outcome"
        placeholder="Outcome"
        v-model="outcome"
        :disabled="!number"
      >
        <option v-for="(option, index) in outcomeOptions" :key="index" :value="option">
          {{ option }}
        </option>
      </Field>
      <label for="outcome">Outcome</label>
      <ErrorMessage name="outcome" class="error-message" />
    </div>

    <div class="form-floating mb-3" v-if="outcome === 'Answered'">
      <Field
        name="respondent"
        class="form-select"
        as="select"
        id="respondent"
        placeholder="Answered By"
        v-model="respondent"
      >
        <option v-for="(option, index) in respondentOptions" :key="index" :value="option">
          {{ option }}
        </option>
      </Field>
      <label for="respondent">Answered By</label>
      <ErrorMessage name="respondent" class="error-message" />
    </div>

    <div
      class="form-floating mb-3"
      v-if="respondent !== 'Client' && respondent && outcome === 'Answered'"
    >
      <Field
        name="respondentName"
        class="form-control"
        type="text"
        id="respondentName"
        placeholder="Name"
        v-model="respondentName"
      />
      <label for="respondentName">Name</label>
      <ErrorMessage name="respondentName" class="error-message" />
    </div>
    <button type="submit" class="btn btn-primary" :disabled="!outcome || !number">
      Submit
    </button>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "TraceForm",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  emits: ["traceAttempt"],
  props: {
    accountId: {
      type: Number,
      required: true,
    },
  },
  data() {
    const traceOutcomeValidation = yup.object({
      number: yup.string().required("Number is required"),
      outcome: yup.string().required("Outcome is required"),
      respondent: yup.string().when("outcome", {
        is: "Answered",
        then: yup.string().required(),
        otherwise: yup.string().nullable(true),
      }),
      respondentName: yup.string().when("respondent", {
        is: "Client",
        then: yup.string().nullable(true),
        otherwise: yup.string().required("Name is a required field."),
      }),
    });
    return {
      traceOutcomeValidation,
      number: "",
      outcome: "",
      outcomeOptions: ["Answered", "Just Rings", "Engaged", "Invalid", "Left Message"],
      respondent: "",
      respondentOptions: ["Client", "Family Member", "Colleague", "Other"],
      respondentName: "",
    };
  },
  methods: {
    postOutcome() {
      const details = {
        "Contact Number": this.number,
        Outcome: this.outcome,
        "Answered By": this.respondent,
        Name: this.respondentName,
      };
      const payload = {
        accountId: this.accountId,
        details,
      };
      this.$emit("traceAttempt", payload);
      this.outcome = "";
      this.number = "";
      this.respondent = "";
      this.respondentName = "";
    },
  },
};
</script>

<style></style>
