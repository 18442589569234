import { createStore } from 'vuex';

import authStore from './auth.store';
import accountStore from './account.store';
import collectionStore from './collection.store';
import telephonyStore from './telephony.store';
import userStore from './user.store';
import notificationStore from './notification.store';
import teamStore from './team.store';
import activityStore from './activity.store';
import queueStore from './queue.store';

const store = createStore({
  state() {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    auth: authStore,
    account: accountStore,
    collection: collectionStore,
    queue: queueStore,
    telephony: telephonyStore,
    user: userStore,
    notification: notificationStore,
    team: teamStore,
    activity: activityStore,
  },
});

export default store;
