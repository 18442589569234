<template>
  <teleport to="#modals">
    <div
      class="modal fade"
      id="docsModal"
      ref="docsModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLabel">Account Docs</h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="downloadingDoc">
              <p class="text-muted">Downloading Document...</p>
              <LoadingSpinner />
            </div>
            <div v-if="!isDocsLoading" class="container-fluid">
              <div class="row">
                <div v-for="(doc, index) in docs" :key="index" class="col-4">
                  <div class="file mb-4" v-if="doc" @click="getDoc(doc.template_code)">
                    <div class="icon">
                      <FontAwesomeIcon :icon="['fas', 'file-pdf']" />
                    </div>
                    {{ doc.template_label }}
                  </div>
                </div>
              </div>
            </div>
            <LoadingSpinner v-if="isDocsLoading" />
          </div>
          <div class="modal-footer">
            <div class="closeBtn">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFileImage,
  faFileWord,
  faFilePdf,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";

import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

import axios from "axios";

library.add(faFileImage, faFileWord, faFilePdf, faFileExcel);

export default {
  name: "DocsModal",
  components: { LoadingSpinner, FontAwesomeIcon },
  computed: {
    ...mapGetters({
      activeAccount: "account/activeAccount",
      isDocsLoading: "account/isDocsLoading",
      docs: "account/docs",
    }),
  },
  data() {
    return {
      downloadingDoc: false,
    };
  },
  methods: {
    async getDoc(doc) {
      this.$store.dispatch("account/docsDownloadRequest");
      this.downloadingDoc = true;
      await axios
        .get(`api/accounts/document`, {
          responseType: "arraybuffer",
          params: {
            doc_name: doc,
            ils_ref: this.activeAccount.account_details.ExternalAccountRef,
          },
          headers: {
            Accept: "application/pdf",
            "Cache-Control": "no-cache",
          },
        })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", `${doc}.pdf`);
          document.body.appendChild(link);
          link.click();
        });
      this.downloadingDoc = false;
    },
  },
  async mounted() {
    this.$store.dispatch(
      "account/getDocs",
      this.activeAccount.account_details.ExternalAccountRef
    );
    this.$refs.docsModal.addEventListener("show.bs.modal", () => {});
  },
  beforeUnmount() {
    this.$store.dispatch("account/clearCurrentAccount");
  },
};
</script>

<style lang="scss" scoped>
.file {
  // padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  text-align: center;
  display: block;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  .icon {
    font-size: 3rem;
    padding: 1rem;
  }
}
</style>
