<template>
  <div class="card">
    <div class="card-body">
      <div v-if="account?.account_details?.FPC_Name">
        <h4 class="mb-4">Futher Party</h4>

        <div class="data-title">Name</div>
        <div class="data-value">
          {{ account?.account_details?.FPC_Name || '-' }}
        </div>

        <div class="data-title">ID Number</div>
        <div class="data-value">
          {{ account?.account_details?.FPC_IDNum || '-' }}
        </div>

        <div class="data-title">Birthdate</div>
        <div class="data-value">
          {{ account?.account_details?.FPC_DOB || '-' }}
        </div>
      </div>

      <div v-if="account?.account_details?.EDC_name">
        <h4 class="mb-4">Third Party</h4>
        <div class="data-title">Name</div>
        <div class="data-value mb-0">
          {{ account?.account_details?.EDC_name || '-' }}
        </div>
      </div>
      <div
        v-if="
          !(
            account?.account_details?.FPC_Name ||
            account?.account_details?.EDC_name
          )
        "
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-center
          h-100
        "
      >
        <h4 class="mb-4">No Other Parties</h4>
        <span class="text-muted text-center"
          >No third/further party details on account</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountOtherPartyInfo',
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
