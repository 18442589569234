<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span> </template
    ><template #title>{{ account.reference }}</template>
    <template #actions>
      <RoundButton
        @click="$emit('confirmation')"
        large
        class="btn-action text-primary"
        title="Refer to Confirmation"
      >
        <span class="material-icons-outlined md-18"> fact_check </span>
      </RoundButton>
      <RoundButton
        @click="$emit('section129')"
        large
        class="btn-action text-primary"
        title="Section 129 Sent"
      >
        <span class="material-icons-outlined md-18"> mark_email_read </span>
      </RoundButton>
      <RoundButton
        @click="$emit('defended')"
        large
        class="btn-action text-primary"
        title="Defended"
      >
        <span class="material-icons-outlined md-18"> block </span>
      </RoundButton>
      <RoundButton
        @click="$emit('process')"
        large
        class="btn-action text-primary"
        title="Legal Process"
      >
        <span class="material-icons-outlined md-18"> gavel </span>
      </RoundButton>
      <RoundButton
        @click="$emit('writeoff')"
        large
        class="btn-action text-primary"
        title="Propose Writeoff"
      >
        <span class="material-icons-outlined md-18"> layers_clear </span>
      </RoundButton>
    </template>
  </QueueItem>
</template>


<script>
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import RoundButton from '@/components/Shared/_RoundButton.vue';

export default {
  props: {
    account: Object,
  },
  emits: ['confirmation', 'section129', 'defended', 'process', 'writeoff'],
  components: {
    QueueItem,
    RoundButton,
  },
};
</script>