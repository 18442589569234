<template>
  <div>
    <div class="mb-3">
      <div class="d-flex justify-content-between align-items-end">
        <h5>
          Writeoff <span v-if="accounts">({{ count }})</span>
        </h5>
      </div>
    </div>
  </div>

  <div v-if="accounts">
    <Queue
      idKey="id"
      sortKey="entry_date"
      :items="accounts"
      v-slot:default="{ item: account }"
    >
      <QueueItem :account="account">
        <template #tag>
          <span class="small d-block mb-3">
            On {{ account.entry_date.toLocaleString() }}
          </span>
        </template>
        <template #title>{{ account.reference }}</template>
        <template #body>
          <ul class="list-unstyled m-0">
            <li>Arrears: {{ account.arrears_amount }}</li>
            <li>Notes: {{ account.event_note }}</li>
          </ul>
        </template>
        <template #actions>
          <button
            @click="action(account.id, 'accept')"
            class="btn btn-primary"
            type="button"
          >
            Accept
          </button>
          <button
            @click="action(account.id, 'reject')"
            class="btn btn-primary"
            type="button"
          >
            Reject
          </button>
        </template>
      </QueueItem>
    </Queue>
  </div>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>

  <!-- <div v-else-if="queue.id && accounts.length === 0">Nothing in Queue</div>
  <div v-else>
    <LoadingSpinner />
  </div> -->

  <!-- <div>
    <div class="position-relative" v-if="queue && accounts.length >= 1">
      <transition-group name="list-complete">
        <div class="card mb-3" v-for="account in accounts" :key="account.id">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <span class="small d-block mb-3">
                  On {{ account.entry_date.toLocaleString() }}
                </span>
                <h5 class="card-title">
                  {{ account.reference }}
                </h5>
                <p class="lead">Dispute Details</p>
                <ul class="list-unstyled m-0">
                  <li>Debt Counsellor: {{ account.event_note }}</li>
                  <li>Counsellor No.: {{ account.event_note }}</li>
                  <li>Notes: {{ account.event_note }}</li>
                </ul>
              </div>
              <div class="d-flex flex-column gap-2 col-3">
                <button
                  @click="action(account.id, 'accept')"
                  class="btn btn-primary"
                  type="button"
                >
                  Accept
                </button>
                <button
                  @click="action(account.id, 'reject')"
                  class="btn btn-primary"
                  type="button"
                >
                  Reject
                </button>
                <button @click="view(account.id)" class="btn btn-primary" type="button">
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <div v-else-if="queue.id && accounts.length === 0">Nothing in Queue</div> -->
  <!-- </div> -->
</template>

<script>
import axios from 'axios';
import Queue from '@/components/Shared/Queue.vue';
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'PaymentDisputeQueue',
  components: { Queue, QueueItem, LoadingSpinner },
  emits: ['view', 'queue-name'],
  computed: {
    count() {
      return this.accounts?.length;
    },
  },
  data() {
    return {
      accounts: null,
      isLoading: true,
    };
  },
  async mounted() {
    const queue = (await axios.get('api/admin/queues/write-off')).data;
    this.accounts = queue.accounts.map((account) => {
      const tempAcc = account;
      tempAcc.entry_date = new Date(tempAcc.entry_date);
      return tempAcc;
    });
    this.isLoading = false;
  },
};
</script>

<style scoped>
.card {
  transition: all 0.8s ease;
  width: 100%;
}

.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.list-complete-leave-active {
  position: absolute;
}
</style>
