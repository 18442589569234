<template>
  <Page>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5 class="mt-5 mb-4">Commissions</h5>

          <div class="card">
            <div class="card-body">
              <div class="mb-5">
                <p class="d-block text-muted mb-1">Last closed cycle</p>
                <p>
                  {{ getFirstDayOfLastMonthISOString() }}
                </p>
              </div>
              <button
                v-if="!lastCycleCalculated && !isLoading"
                @click="calculate"
                class="btn btn-primary btn-rounded"
              >
                Calculate
              </button>

              <table v-if="!isLoading" class="table align-middle">
                <thead>
                  <tr>
                    <th>Cycle</th>
                    <th>Arrears Commission</th>
                    <th>Money In Bank commission</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cycle in sortedCycles" :key="cycle.id">
                    <td>{{ getISODateString(cycle.start_date) }}</td>
                    <td>{{ formatCurrency(cycle.arrears) }}</td>
                    <td>{{ formatCurrency(cycle.money_in_bank) }}</td>
                  </tr>
                </tbody>
              </table>
              <LoadingSpinner v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import axios from 'axios';

import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
import Page from '@/components/Shared/_Page.vue';
import {
  getISODateString,
  getFirstDayOfLastMonthISOString,
} from '@/helpers/datetime';
import { formatCurrency } from '@/helpers/format';

export default {
  name: 'Commission',
  components: { Page, LoadingSpinner },
  setup() {
    return {
      getISODateString,
      getFirstDayOfLastMonthISOString,
      formatCurrency,
    };
  },
  data() {
    return {
      cycles: null,
      isLoading: true,
    };
  },
  computed: {
    lastCycleCalculated() {
      const lastCycle = this.cycles?.find(
        (c) =>
          getISODateString(c.start_date) === getFirstDayOfLastMonthISOString()
      );
      return lastCycle && lastCycle.arrears && lastCycle.money_in_bank;
    },
    sortedCycles() {
      return [...this.cycles].sort((a, b) => (a < b ? 1 : -1));
    },
  },
  methods: {
    async calculate() {
      try {
        // create new cycle
        const newCycleId = (
          await axios.post('api/cycles', {
            start_date: this.getFirstDayOfLastMonthISOString(),
          })
        ).data;

        await axios.get(`api/commissions/${newCycleId}/run`);

        await this.fetchCycles();

        // await axios.get('api/cycles');
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCycles() {
      try {
        this.cycles = (await axios.get('api/cycles')).data.map((c) => ({
          ...c,
          start_date: new Date(c.start_date),
        }));
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
  },
  mounted() {
    // alert(this.getFirstDayOfLastMonthISOString());
    this.fetchCycles();
  },
};
</script>