<template>
  <div class="init h-100 d-flex flex-column justify-content-center">
    <div class="d-grid gap-3">
      <button @click="$emit('choice', 'rpc')" class="btn btn-lg btn-primary">RPC</button>
      <button @click="$emit('choice', 'escalate')" class="btn btn-lg btn-primary">
        Escalate
      </button>
      <button @click="$emit('choice', 'journal')" class="btn btn-lg btn-primary">
        Journal
      </button>
      <button @click="$emit('chats')" class="btn btn-lg btn-primary">Chats</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InitInbound",
  emits: ["choice", "chats"],
  setup() {},
};
</script>
