<template>
  <form @submit.prevent="search">
    <div class="input-group">
      <input
        v-model="term"
        type="text"
        class="form-control"
        placeholder="Search..."
        aria-label="Search"
        aria-describedby="searchBtn"
      />
      <button class="btn" type="submit" id="searchBtn" v-on:click="search">
        <span class="material-icons"> search </span>
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "SearchTimedInput",
  emits: ["search"],
  data() {
    return {
      term: "",
    };
  },
  methods: {
    search() {
      this.$emit("search", this.term);
    },
  },
};
</script>
