<template>
  <BaseCard>
    <template v-slot:body>
      <h5 class="mb-3">{{ queue.queue_name }}</h5>
      <table class="table">
        <tbody>
          <tr>
            <th scope="col" class="ps-0">Team</th>
            <td>
              <span v-if="queue.team" class="badge rounded-pill bg-primary">
                {{ queue.team }}
              </span>
              <span v-else>None</span>
            </td>
          </tr>
          <tr>
            <th scope="col" class="ps-0">Total Accounts</th>
            <td>{{ queue.account_count || '-' }}</td>
          </tr>
          <tr>
            <th scope="col" class="ps-0">Total Arrears</th>
            <td>
              {{ formatCurrency(queue.arrears_total) || '-' }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/Shared/_BaseCard.vue';
import { formatCurrency } from '@/helpers/format';

export default {
  name: 'QueueCard',
  props: {
    queue: {
      type: Object,
      required: true,
    },
  },
  components: {
    BaseCard,
  },
  setup() {
    return { formatCurrency };
  },
};
</script>

<style lang="scss" scoped></style>
