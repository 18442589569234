<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between mb-3">
        <h2 class="card-title">Due Accounts</h2>
      </div>
      <div v-if="isLoading"><small> Loading...</small></div>
      <div
        v-else-if="
          !isLoading &&
          debtReviewSummary &&
          (debtReviewSummary['Debt Review Referral'] ||
            debtReviewSummary['Debt Review Proposal'] ||
            debtReviewSummary['Debt Review Counter Proposal'])
        "
      >
        <p class="tag mb-2">Debt Review Referral</p>
        <p class="h3">
          <span v-if="debtReviewSummary['Debt Review Referral']">
            {{ debtReviewSummary["Debt Review Referral"] }}
          </span>
          <span v-else> 0 </span>
        </p>
        <p class="tag mb-2">Debt Review Proposal</p>
        <p class="h3">
          <span v-if="debtReviewSummary['Debt Review Proposal']">
            {{ debtReviewSummary["Debt Review Proposal"] }}
          </span>
          <span v-else> 0 </span>
        </p>
        <p class="tag mb-2">Debt Review Counter Proposal</p>
        <p class="h3">
          <span v-if="debtReviewSummary['Debt Review Counter Proposal']">
            {{ debtReviewSummary["Debt Review Counter Proposal"] }}
          </span>
          <span v-else> 0 </span>
        </p>
      </div>
      <div v-else><small>N/A</small></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, onMounted } from "vue";

export default {
  name: "DebtReviewApplicationQueue",
  components: {},
  emits: [],
  setup() {
    const debtReviewSummary = ref({});
    const isLoading = ref(true);

    const getSummary = async () => {
      debtReviewSummary.value = (
        await axios.get("api/admin/queues/debt-review/past-due-summary")
      ).data;
      isLoading.value = false;
    };

    onMounted(getSummary);

    return {
      debtReviewSummary,
      isLoading,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ctp-debtReviewSummary {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .tag {
    margin-bottom: 0.5rem;
  }
}
</style>
