<template>
  <div
    class="text-center h-100 d-flex flex-direction-column justify-content-center align-items-center"
  >
    <p class="lead">Dialing...</p>
  </div>
</template>

<script>
export default {
  name: 'Calling',
};
</script>

<style scoped>
</style>