<template>
  <QueueItem>
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span>
    </template>
    <template #title>{{ account.reference }}</template>
    <template #body>
      <TraceAttemptForm
        :accountId="account.id"
        @traceAttempt="(payload) => traceAttempt(payload)"
      />
    </template>
    <template #actions>
      <RoundButton @click="view" large class="btn-action text-secondary" title="View"
        ><span class="material-icons-outlined md-18"> visibility </span></RoundButton
      >
      <RoundButton
        @click="toggleForm('note')"
        large
        title="Create Note"
        class="btn-action text-secondary"
      >
        <span class="material-icons"> notes </span>
      </RoundButton>
      <RoundButton
        @click="action('success')"
        large
        class="btn-action text-success"
        title="Trace Success"
        ><span class="material-icons-outlined md-18"> done </span></RoundButton
      >
      <RoundButton
        @click="action('failed')"
        large
        class="btn-action text-danger"
        title="Trace Failed"
        ><span class="material-icons-outlined md-18"> close </span></RoundButton
      >
    </template>
    <template #footer>
      <div v-if="showForm === 'note'">
        <div class="mb-3">
          <label class="form-label">Note</label>
          <textarea v-model="noteForm.note" class="form-control" rows="3"></textarea>
        </div>
        <button @click="createNote" class="btn btn-primary">Submit</button>
      </div>
    </template>
  </QueueItem>
</template>

<script>
import { ref } from "vue";
import axios from "axios";

import QueueItem from "@/components/Shared/QueueItem.vue";
import RoundButton from "@/components/Shared/_RoundButton.vue";
import TraceAttemptForm from "@/components/Admin/TraceAttemptForm.vue";

export default {
  props: {
    account: Object,
  },
  components: {
    QueueItem,
    RoundButton,
    TraceAttemptForm,
  },
  setup(props, { emit }) {
    const showForm = ref("");
    const noteForm = ref({ note: "" });

    const toggleForm = (formName) => {
      showForm.value = showForm.value !== formName ? formName : "";
    };

    const createNote = () => {
      const { note } = noteForm.value;
      emit("note", { accountId: props.account.id, note });
      noteForm.value.note = "";
      showForm.value = "";
    };

    const view = () => {
      emit("view", props.account.id);
    };

    const action = (outcome) => {
      const payload = { accountId: props.account.id, action: outcome };
      emit("action", payload);
    };

    const traceAttempt = async (payload) => {
      try {
        await axios.post(`api/queues/trace/${payload.accountId}`, {
          details: payload.details,
        });
      } catch (error) {
        console.log(error.message);
      }
    };

    return {
      showForm,
      noteForm,
      toggleForm,
      createNote,
      view,
      action,
      traceAttempt,
    };
  },
};
</script>

<style></style>
