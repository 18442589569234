import store from '@/store';

export const userIs = (role) =>
  (store.getters['auth/roles'] && store.getters['auth/roles'].includes(role)) ||
  store.getters['auth/roles'].includes('SUPER USER');

export const userIn = (team) =>
  store.getters['auth/permissions'] &&
  store.getters['auth/permissions'].filter((p) => p.team === team).length > 0;

export default {
  userIs,
};
