<template>
  <teleport to="#modals">
    <div
      class="modal fade"
      id="notesModal"
      ref="notesModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLabel">
              Expanded Historical Notes
            </h3>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row w-50">
              <NotesSearch @search="searchNotes" />
            </div>
            <div v-if="notes && !isLoading">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Note</th>
                    <th scope="col">Agent</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(note, index) in notes" :key="index">
                    <td>
                      <div class="text-nowrap">
                        {{ note.DateCaptured }}
                      </div>
                    </td>
                    <td>{{ note.Note }}}</td>
                    <td>
                      <div class="text-nowrap">
                        {{ note.Username }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="!notes && !isLoading">
              <span class="h6">No more notes.</span>
            </div>
            <div v-if="isLoading">
              <LoadingSpinner />
            </div>
          </div>
          <div class="modal-footer">
            <p class="card-text text-muted me-auto">
              * These are notes from the legacy system and will be replaced in
              the future
            </p>
            <div
              class="btn-group mr-2"
              role="group"
              aria-label="Notes Modal Nav Buttons"
            >
              <button
                :disabled="!prevPages"
                class="btn btn-primary"
                v-on:click="previousPage"
              >
                &lt;
              </button>
              <button
                :disabled="!morePages"
                class="btn btn-primary"
                v-on:click="nextPage"
              >
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
import NotesSearch from '@/components/Shared/SearchTimedInput.vue';

export default {
  name: 'NotesModal',
  components: { LoadingSpinner, NotesSearch },
  data() {
    return {
      page: 1,
      morePages: true,
      prevPages: false,
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      notes: 'account/notes',
      isLoading: 'account/isNotesLoading',
    }),
  },
  methods: {
    async searchNotes(term) {
      this.search = term;
      this.getNotes();
      // await this.$store.dispatch("account/getNotes", {
      //   accKey: this.acc_key,
      //   pageNum: this.page,
      //   searchTerm: term,
      // });
    },
    async getNotes() {
      this.page = 1;
      this.prevPages = false;
      await this.$store.dispatch('account/getNotes', {
        page: this.page,
        search: this.search,
      });
      this.morePages = true;
      if (this.notes.length < 10) {
        this.morePages = false;
      } else {
        this.morePages = true;
      }
    },
    async nextPage() {
      this.page += 1;
      await this.$store.dispatch('account/getNotes', {
        page: this.page,
        search: this.search,
      });
      this.morePages = true;
      if (this.notes.length < 10) {
        this.morePages = false;
      } else {
        this.morePages = true;
      }
      if (this.page > 1) {
        this.prevPages = true;
      }
    },
    async previousPage() {
      this.morePages = true;
      this.page -= 1;
      await this.$store.dispatch('account/getNotes', {
        page: this.page,
        search: this.search,
      });
      if (this.notes.length < 10) {
        this.morePages = false;
      } else {
        this.morePages = true;
      }
      if (this.page <= 1) {
        this.prevPages = false;
      }
    },
  },
  mounted() {
    this.search = '';
    this.$refs.notesModal.addEventListener('show.bs.modal', () => {
      this.getNotes();
    });
  },
};
</script>