<template>
  <input
    class="btn-check"
    type="radio"
    name="SendBankDetails"
    id="SendBankDetails"
    data-bs-toggle="modal"
    data-bs-target="#SendBankDetailsModal"
    @click="getData"
  />
  <label class="btn btn-lg btn-primary" for="SendBankDetails">
    Send Banking Details
  </label>
  <teleport to="#modals">
    <div
      class="modal fade"
      id="SendBankDetailsModal"
      tabindex="-1"
      aria-labelledby="SendCorpInfoLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="SendCorpInfoLabel">Send Banking Details</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="bank_detail_message">
              <div class="">
                <div v-if="emailAddress" class="form-check form-check-inline">
                  <input
                    v-model="medium"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="emailRadio"
                    value="email"
                  />
                  <label class="form-check-label" for="emailRadio">Email</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="medium"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="smsRadio"
                    value="sms"
                  />
                  <label class="form-check-label" for="smsRadio">SMS</label>
                </div>
              </div>
              <div v-if="medium === 'email' && emailAddress" class="mt-3">
                <p class="mb-0">{{ emailAddress }}</p>
              </div>
              <div v-if="medium === 'sms'" class="mt-3">
                <select v-model="numberSelected" class="form-select form-select-sm">
                  <option disabled value="">Select a number</option>
                  <option v-for="number in phoneNumbers" :key="number" :value="number">
                    {{ number }}
                  </option>
                </select>
              </div>
              <p
                class="h6 mt-4"
                v-for="(detail, index) in bank_detail_message"
                :key="index"
              >
                {{ detail }}
              </p>
            </div>
            <div v-else-if="bankDetailsLoading">
              <LoadingSpinner />
            </div>
            <div v-else>
              <p class="text-muted">Corp details are not available for this account.</p>
            </div>
          </div>
          <div class="modal-footer">
            <div class="navButton">
              <button
                @click="send"
                :disabled="!bank_detail_message"
                data-bs-dismiss="modal"
                class="btn btn-primary"
              >
                Send
              </button>
            </div>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

export default {
  name: "SendBankDetails",
  components: { LoadingSpinner },
  data() {
    return {
      sending: false,
      medium: "",
      numberSelected: "",
      error: "",
      bank_detail_message: "",
      bankDetailsLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "account/activeAccount",
      phoneNumbers: "account/phoneNumbers",
      positions: "auth/permissions",
      actionId: "collection/actionId",
    }),
    emailAddress() {
      return this.activeAccount.customer_details?.Email;
    },
  },
  methods: {
    async getData() {
      this.bankDetailsLoading = true;
      this.bank_detail_message = (
        await axios.get(`/api/communications/send-bank-details/${this.activeAccount.id}`)
      ).data;
      this.bankDetailsLoading = false;
    },
    async send() {
      try {
        const userPositionId = this.positions.filter(
          (p) =>
            p.role.toUpperCase() === "AGENT" ||
            "INBOUND AGENT" ||
            "ADMIN" ||
            "SUPERVISOR" ||
            "MANAGER" ||
            "SUPER USER"
        )[0].user_position_id;
        if (this.medium === "sms") {
          await axios.post(
            `/api/communications/send-bank-details/${this.activeAccount.id}`,
            {
              communication_type: this.medium,
              contact_details: this.numberSelected,
              details: this.bank_detail_message,
              action_id: this.actionId,
              country_code: this.activeAccount.account_details.ProductKey,
              userPositionId,
            }
          );
        } else if (this.medium === "email") {
          await axios.post(
            `/api/communications/send-bank-details/${this.activeAccount.id}`,
            {
              communication_type: this.medium,
              contact_details: this.emailAddress,
              details: this.bank_detail_message,
              action_id: this.actionId,
              country_code: this.activeAccount.account_details.ProductKey,
              userPositionId,
            }
          );
        }
      } catch (e) {
        console.log(e);
        this.$store.dispatch("notification/push", {
          body: `Communication Failed`,
          type: "error",
        });
      }
    },
    // async send() {
    //   if (this.medium === "sms") {
    //     this.sending = true;
    //     try {
    //       this.bank_detail_message = await axios.get(
    //         `/api/communications/send-bank-details/${this.activeAccount.account_details.ExternalAccountRef}`,
    //         {
    //           params: { number: "0824653228" },
    //         }
    //       );
    //       this.$store.dispatch("notification/push", {
    //         body: "Bank details sent",
    //         type: "success",
    //       });
    //     } catch (error) {
    //       this.$store.dispatch("notification/push", {
    //         body: error.response.data.message,
    //         type: "error",
    //       });
    //     }
    //     this.sending = false;
    //   }
    // },
  },
};
</script>

<style scoped></style>
