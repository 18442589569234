<template>
  <Page>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-sm-6 mx-auto">
          <h1 class="h3">App Test</h1>
          <p>API route: {{ url }}</p>
          <p v-if="trying">Loading...</p>
          <p v-if="error" class="alert alert-danger">{{ error }}</p>
          <p v-if="connected" class="alert alert-success">Connected!</p>
          <hr />
          <p>
            <button @click="trackIdle" class="btn btn-primary">
              Track idle
            </button>
            <button
              @click="triggerNofification"
              type="button"
              class="btn btn-primary"
            >
              Show live toast
            </button>
          </p>
          <hr />
          <Telephone />
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import axios from 'axios';

import Page from '@/components/Shared/_Page.vue';
import Telephone from '@/components/Telephone.vue';

export default {
  name: 'Test',
  components: {
    Page,
    Telephone,
  },
  data() {
    return {
      trying: false,
      test: null,
      connected: null,
      error: null,
      url: axios.defaults.baseURL,
    };
  },
  methods: {
    triggerNofification() {
      this.$store.dispatch('notification/push', {
        type: 'success',
        body: 'just dropping by',
        autohide: false,
      });
    },
    trackIdle() {
      // Idle detection API
      (async () => {
        if (!('IdleDetector' in window)) return;
        this.$store.dispatch('notification/push', {
          body: 'Idle Detector Supported',
        });

        const permission = await window.IdleDetector.requestPermission();
        if (permission !== 'granted') {
          this.$store.dispatch('notification/push', {
            body: 'Idle Detector permission denied',
          });
          return;
        }

        this.$store.dispatch('notification/push', {
          body: 'Idle Detector permission granted',
        });
        try {
          const controller = new window.AbortController();
          const { signal } = controller;
          const idleDetector = new window.IdleDetector();
          idleDetector.addEventListener('change', () => {
            const { userState, screenState } = idleDetector;
            this.$store.dispatch('notification/push', {
              body: `Idle Detector: Idle change ${userState}, ${screenState}`,
            });
          });

          this.$store.dispatch('notification/push', {
            body: 'Idle Detector starting...',
          });
          await idleDetector.start({
            threshold: 60000,
            signal,
          });
        } catch (err) {
          this.$store.dispatch('notification/push', {
            body: `Idle Detector error: ${err.name} ${err.message}`,
          });
        }
      })();
    },
  },
  mounted() {
    (async () => {
      try {
        this.trying = true;
        const pong = await axios.get('/api/ping');
        if (pong.status === 200) {
          this.connected = true;
        }
      } catch (error) {
        this.error = `Unable to connect - ${error.message}`;
      } finally {
        this.trying = false;
      }
    })();
  },
};
</script>

<style></style>
