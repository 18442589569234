<template>
  <div v-for="(position, index) in supervisorPositions" :key="index">
    <EscalateQueue
      :supervisorPositionId="position.user_position_id"
      :teamId="position.team_id"
      @view="view"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EscalateQueue from '@/components/Supervisor/EscalateQueue.vue';

export default {
  name: 'SupervisorEscalateQueue',
  emits: ['view'],
  components: { EscalateQueue },
  data() {
    return {
      supervisorPositions: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      positions: 'auth/permissions',
    }),
  },
  methods: {
    view(id) {
      this.$emit('view', id);
    },
  },
  mounted() {
    this.$store.dispatch('account/clearCurrentAccount');
    this.supervisorPositions = this.positions.filter(
      (p) =>
        p.role.toUpperCase() === 'SUPERVISOR' ||
        p.role.toUpperCase() === 'MANAGER'
    );
  },
};
</script>
