<template>
  <div
    ref="toast"
    :class="bgClass"
    class="toast align-items-center"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="d-flex">
      <div class="toast-body">
        {{ body }}
      </div>
      <button
        type="button"
        :class="btnClass"
        class="btn-close me-2 m-auto"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallNotification",
  props: {
    id: {
      type: String,
      required: true,
    },
    type: String,
    time: String,
    title: String,
    body: String,
    autohide: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toast: null,
    };
  },
  computed: {
    bgClass() {
      return {
        "bg-primary": this.type === "success",
        "bg-danger": this.type === "error",
        "bg-light": !["success", "error"].includes(this.type),
        "text-white": ["success", "error"].includes(this.type),
        "border-0": ["success", "error"].includes(this.type),
      };
    },
    btnClass() {
      return {
        "btn-close-white": ["success", "error"].includes(this.type),
      };
    },
  },
  mounted() {
    const { autohide } = this;
    this.toast = new window.bootstrap.Toast(this.$refs.toast, {
      autohide,
    });
    this.toast.show();
    this.$refs.toast.addEventListener("hidden.bs.toast", () => {
      this.$emit("dismiss", this.id);
    });
  },
};
</script>
