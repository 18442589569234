<template>
  <Modal :show="show" backdrop="static" @show="populate">
    <div class="d-flex flex-column align-items-center text-center p-4">
      <div class="icon mb-5">
        <span class="material-icons-outlined"> clear </span>
      </div>
      <h2>Are you sure?</h2>
      <p class="mb-5">
        You are about to delete the CTP on account {{ CTP?.reference }}. Do you
        want to proceed?
      </p>

      <div>
        <button @click="$emit('cancel')" class="btn btn-lg btn-secondary">
          Cancel
        </button>
        <button @click="$emit('confirm')" class="btn btn-lg btn-danger ms-3">
          Delete
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Shared/_Modal.vue';

export default {
  name: 'CTPEditModal',
  props: {
    show: Boolean,
    CTP: Object,
  },
  components: { Modal },
  emits: ['cancel', 'confirm'],
};
</script>

<style lang="scss" scoped>
.icon {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  border: 4px solid var(--color-red);
  display: flex;
  align-items: center;
  justify-content: center;
  .material-icons-outlined {
    color: var(--color-red);
    font-size: 4rem;
  }
}
</style>
