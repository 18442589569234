<template>
  <div class="card">
    <div class="card-body">
      <h2 class="card-title mb-4">My Time</h2>
      <div class="row" v-if="time_stats">
        <div class="col-4 d-flex flex-column">
          <div>
            <span class="tag">Total</span>
            <span class="h5 d-block">{{
              minsToHoursAndMins(parseInt(total), 10)
            }}</span>
          </div>
          <div class="mt-1">
            <span class="tag">Active</span>
            <span class="h5 d-block">{{
              minsToHoursAndMins(parseInt(active), 10)
            }}</span>
          </div>
          <div class="mt-1">
            <span class="tag">Inactive</span>
            <span class="h5 d-block">{{
              minsToHoursAndMins(parseInt(inactive), 10)
            }}</span>
          </div>
          <div class="mt-1">
            <span class="tag">Idle</span>
            <span class="h5 d-block">{{
              minsToHoursAndMins(parseInt(idle))
            }}</span>
          </div>
        </div>
        <div class="col-8 my-2">
          <AgentTimeBreakdownChart
            :labels="Object.keys(time_stats)"
            :data="Object.values(time_stats)"
          />
        </div>
      </div>
      <div class="my-2" v-else>
        <p>Loading...</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import AgentTimeBreakdownChart from '@/components/Dash/AgentTimeBreakdownChart.vue';

export default {
  name: 'AgentTimeBreakdownCard',
  components: { AgentTimeBreakdownChart },
  data() {
    return {
      active: 0,
      inactive: 0,
      idle: 0,
      total: 0,
      time_stats: null,
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      activityTypes: 'activity/activityTypes',
    }),
  },
  methods: {
    minsToHoursAndMins(mins) {
      let hours = `${Math.floor(mins / 60)}`;
      let minutes = `${mins % 60}`;
      hours = hours.length === 1 ? `0${hours}` : hours;
      minutes = minutes.length === 1 ? `0${minutes}` : minutes;
      return `${hours}h ${minutes}m`;
    },
  },
  async mounted() {
    try {
      const url = `api/user/${parseInt(this.user.id, 10)}/time-stats`;
      this.time_stats = (await axios.get(url)).data;
      this.active +=
        this.active +
        (parseInt(this.time_stats.Active / 60, 10) || 0) +
        (parseInt(this.time_stats['In a meeting'] / 60, 10) || 0) +
        (parseInt(this.time_stats['In training'] / 60, 10) || 0);
      this.inactive +=
        (parseInt(this.time_stats['Bathroom break'] / 60, 10) || 0) +
        (parseInt(this.time_stats['Lunch break'] / 60, 10) || 0) +
        (parseInt(this.time_stats['Tea break'] / 60, 10) || 0);
      this.idle += parseInt(this.time_stats.Idle / 60, 10) || 0;
      this.total += this.idle + this.active + this.inactive;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
