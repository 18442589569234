<template>
  <div class="timer">
    <svg viewBox="0 0 100 100">
      <circle
        :stroke-dasharray="circleDasharray"
        :stroke-width="strokeWidth"
        :r="radius"
        :cx="size"
        :cy="size"
      ></circle>
    </svg>
    <span class="label"
      ><div class="time display-1">{{ formattedTimeLeft }}</div>
      To next account</span
    >
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'CircleTimer',
  props: { duration: { type: Number, required: true } },
  setup(props) {
    return {
      left: ref(props.duration),
    };
  },
  data() {
    return {
      interval: null,
      strokeWidth: 1,
      size: 50,
    };
  },
  mounted() {
    setTimeout(this.start, 1);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  computed: {
    formattedTimeLeft() {
      const minutes = Math.floor(this.left / 60);
      const seconds = this.left % 60;
      return `${minutes}:${seconds < 10 ? `0${seconds}` : `${seconds}`}`;
    },
    radius() {
      return this.size - this.strokeWidth / 2;
    },
    circleDasharray() {
      const length = 2 * 3.1519 * this.radius;
      const timeLeftFraction = this.left / this.duration;
      const smoothTimeLeftFraction =
        timeLeftFraction - (1 / this.duration) * (1 - timeLeftFraction);
      return `${(smoothTimeLeftFraction * length).toFixed(0)} ${length}`;
    },
  },
  methods: {
    start() {
      this.interval = setInterval(this.tick, 1000);
    },
    tick() {
      this.left -= 1;
      this.checkTimer();
    },
    checkTimer() {
      if (this.left <= 0) {
        this.timeout();
      }
    },
    timeout() {
      clearInterval(this.interval);
      this.$emit('timeout');
    },
  },
};
</script>

<style scoped>
.timer {
  width: 20vw;
  height: 20vw;
  position: relative;
  color: #fff;
}

.label {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label .time {
  font-variant-numeric: tabular-nums;
  font-weight: 400;
}

svg circle {
  fill: red;
  /* stroke-dasharray: 308px; */
  /* stroke-dashoffset: 308px; */
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: center;
  stroke: white;
  fill: none;
  transition: 1s linear all;
}
</style>
