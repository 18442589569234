<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-between">
            <h4 class="mb-4">Client Bank</h4>
            <a href="#newBankModal" class="link-dark d-none" data-bs-toggle="modal">
              <span class="material-icons md-18"> add </span>
            </a>
            <NewBankAccountModal />
          </div>
        </div>
      </div>
      <div class="data-title">Bank</div>

      <div class="data-value">
        {{ account?.bank_details?.bank_name || "-" }}
      </div>

      <div class="data-title">Account Number</div>
      <div class="data-value">
        {{ account?.bank_details?.bank_acc_no || "-" }}
      </div>

      <div class="data-title">Branch Code</div>
      <div class="data-value">
        {{ account?.bank_details?.branch_code || "-" }}
      </div>

      <div class="data-title">Account Type</div>
      <div class="data-value mb-0">
        {{ account?.bank_details?.accountType || "-" }}
      </div>
    </div>
  </div>
</template>

<script>
import NewBankAccountModal from "@/components/Account/NewBankAccountModal.vue";

export default {
  name: "AccountBankInfo",
  components: { NewBankAccountModal },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.material-icons-outlined {
  margin-right: -1rem;
}
</style>
