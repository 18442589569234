<template>
  <input
    class="btn-check"
    type="radio"
    name="CreateNote"
    id="CreateNote"
    data-bs-toggle="modal"
    data-bs-target="#CreateNoteModal"
  />
  <label class="btn btn-lg btn-primary" for="CreateNote"> Create Note </label>

  <teleport to="#modals">
    <div
      class="modal fade"
      id="CreateNoteModal"
      tabindex="-1"
      aria-labelledby="CreateNoteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="CreateNoteModalLabel">Note</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <textarea
              class="form-control"
              minlength="5"
              v-model="note"
              required
            ></textarea>
          </div>
          <div class="modal-footer">
            <div class="navButton">
              <button class="btn btn-primary" @click="submit" data-bs-dismiss="modal">
                Save
              </button>
            </div>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import eventTypes from "@/helpers/eventTypes";

export default {
  name: "CreateNote",
  props: {},
  computed: {},
  emits: ["done"],
  data() {
    return {
      ...eventTypes.FREE_FORM,
      note: null,
    };
  },
  methods: {
    submit() {
      const { name, type, closesAction } = eventTypes.FREE_FORM;
      const details = {
        note: this.note,
      };
      this.$emit("done", { type, name, closesAction, details });
    },
  },
};
</script>

<style scoped>
textarea {
  height: 8rem;
}
</style>
