<template>
  <div class="toast-container p-3">
    <div v-for="notification in notifications" :key="notification.id">
      <Notification
        :id="notification.id"
        :time="notification.time"
        :title="notification.title"
        :type="notification.type"
        :body="notification.body"
        :autohide="notification.autohide"
        @dismiss="dismiss"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import Notification from '@/components/Shared/Notification.vue';

export default {
  name: 'Notifications',
  components: { Notification },
  computed: {
    ...mapGetters({
      notifications: 'notification/notifications',
    }),
  },
  methods: {
    dismiss(id) {
      this.$store.dispatch('notification/dismiss', id);
    },
  },
};
</script>

<style scoped>
.toast-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
}
</style>
