<template>
  <Page>
    <p class="lead">Accounts</p>
    <!-- <div class="row g-0">
      <div class="col-5">
        <AccountsSearch @search="searchAccounts" :searchTerm="search" />
      </div>
    </div> -->
    <div class="container-fluid h-85">
      <div class="row h-100">
        <div class="col-5 position-relative">
          <div class="queue-view">
            <AccountList
              :accounts="accounts"
              v-if="!loading && currentlySearching"
            />
            <p v-else-if="!currentlySearching && !loading"></p>
            <LoadingSpinner v-else />
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import axios from 'axios';
import Page from '@/components/Shared/_Page.vue';
// import AccountsSearch from "@/components/Shared/SearchTimedInput.vue";
import AccountList from '@/components/Account/AccountList.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'Accounts',

  data() {
    return {
      accounts: [],
      loading: false,
      currentlySearching: false,
    };
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  watch: {
    search() {
      this.searchAccounts(this.search);
    },
  },
  components: {
    // AccountsSearch,
    AccountList,
    Page,
    LoadingSpinner,
  },
  methods: {
    async searchAccounts(term) {
      this.loading = true;
      if (term.length === 0) {
        this.currentlySearching = false;
        this.loading = false;
        this.accounts = [];
        return;
      }
      this.currentlySearching = true;
      this.accounts = (
        await axios.get('api/accounts/search', {
          params: { search_term: term },
        })
      ).data;
      this.loading = false;
    },
  },
  mounted() {
    this.searchAccounts(this.search);
  },
};
</script>

<style scoped lang="scss">
.account-view,
.queue-view {
  // background: green;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
