<template>
  <div id="Collect">
    <div v-if="ready">
      <transition name="fade" mode="out-in" appear>
        <template v-if="activeAccount">
          <div class="d-flex">
            <div class="main">
              <AccountDisplay :account="activeAccount" />
            </div>

            <div class="sidebar">
              <ActionPanel
                :ready="ready"
                :actionId="actionId"
                :isReturning="isReturning"
                @next="next"
                @confirm="confirm"
              />
            </div>
          </div>
        </template>
      </transition>
    </div>
    <div v-if="!ready" style="width: 100%; height: 100vh">
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
import AccountDisplay from '@/components/Account/AccountDisplay.vue';

import ActionPanel from '@/components/Account/ActionPanel/ActionPanel.vue';

export default {
  name: 'Collect',
  props: {
    action_id: {
      type: String,
    },
    account_id: {
      type: String,
    },
  },
  components: {
    LoadingSpinner,
    ActionPanel,

    AccountDisplay,
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      activeAccount: 'account/activeAccount',
      isAccountLoading: 'account/isLoading',
      actionId: 'collection/actionId',
      accountId: 'collection/accountId',
      error: 'collection/error',
      isCollectionLoading: 'collection/isCollectionLoading',
    }),
    isReturning() {
      return Boolean(this.action_id && this.account_id);
    },
  },
  watch: {
    isLoading(newVal) {
      // when we get request a new account, ensure modal is closed
      if (newVal) this.showStatusModal = false;
    },
  },
  methods: {
    openChangeUserActivityModal() {
      this.showStatusModal = true;
    },

    next() {
      this.$router.push({ name: 'collectPortal' });
    },

    async confirm(payload) {
      const { closesAction, ...p } = payload;
      try {
        const success = await this.$store.dispatch(
          'collection/confirmOutboundAccountTreatment',
          {
            ...p,
            actionId: this.actionId,
          }
        );
        if (success) {
          if (closesAction) {
            this.$router.push({ name: 'collectPortal' });
          }
        } else {
          this.$store.dispatch('notification/push', {
            type: 'error',
            body: this.error || `Action failed...`,
          });
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch('notification/push', {
          type: 'error',
          body: `Something went wrong...`,
        });
      }

      // // set timer for next account
      // this.interval = setInterval(() => {
      //   this.duration -= 1;
      //   if (this.duration <= 0) {
      //     clearInterval(this.interval);
      //     this.requestNext();
      //   }
      // }, 1000);
    },
  },
  // async beforeCreate() {},
  async created() {
    await this.$store.dispatch('telephony/login', {
      extension: this.user.extension,
    });
    if (this.action_id && this.account_id) {
      this.$store.dispatch('collection/setNextAccount', {
        actionId: this.action_id,
        accountId: this.account_id,
      });
    } else {
      await this.$store.dispatch('collection/getNextAccount');
    }

    await this.$store.dispatch('account/getAccount', this.accountId);
    this.ready = true;
    this.$store.dispatch('activity/saveActivity', {
      userId: this.user.id,
      activityId: 7,
    });
  },
  mounted() {
    this.$store.dispatch('collection/readyOutboundCollection');
  },
  async unmounted() {
    this.$store.dispatch('account/clearCurrentAccount');
    this.$store.dispatch('collection/finishOutboundCollection');
    // await this.$store.dispatch('telephony/logoff');
  },
  // beforeUnmount() {
  //   clearInterval(this.interval);
  // },
};
</script>

<style lang="scss" scoped>
#Collect {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;

  #CollectPushNext {
    min-height: 100vh;
  }
}

.main {
  width: 100%;
}

.sidebar {
  width: 30rem;
  position: sticky;
  flex-shrink: 0;
  right: 0;
  top: 0;
  padding: 2.5rem 2.5rem 2.5rem 0;
}

.fade-enter-active {
  transition: all 0.2s ease-out;
}

.fade-leave-active {
  transition: all 0.2s ease-out;
  /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
}

.fade-enter-from,
.fade-leave-to {
  /* transform: translateX(-20px); */
  opacity: 0;
}
</style>
