<template>
  <p class="lead">Currently Unavailable</p>
  <Form @submit="assignPosition" class="d-none">
    <div class="form-floating mb-3">
      <Field
        name="team"
        class="form-select"
        as="select"
        id="team"
        placeholder="Team"
        v-model="team"
        v-on:change="teamChosen"
      >
        <option v-for="(team, index) in team_options" :key="index" :value="team.id">
          {{ team.name }}
        </option>
      </Field>
      <label for="team">Team</label>
      <ErrorMessage name="team" class="error-message" />
    </div>

    <div class="form-floating mb-3" v-if="positionReady">
      <Field
        name="position"
        class="form-select"
        as="select"
        id="position"
        placeholder="Position"
        v-model="position_id"
        v-on:change="positionChosen"
      >
        <option
          v-for="(position, index) in filteredPositionOptions"
          :key="index"
          :value="position.id"
        >
          {{ position.position }}
        </option>
      </Field>
      <label for="position">Position</label>
      <ErrorMessage name="position" class="error-message" />
    </div>
    <button class="btn btn-primary" :disabled="!assignReady">Assign</button>
  </Form>
</template>

<script>
import { mapGetters } from "vuex";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "AssigUserPositionFrom",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  emits: ["position-assigned"],
  computed: {
    ...mapGetters({
      position_options: "team/unassignedPositions",
    }),
    filteredPositionOptions() {
      // Filter all unassigned positions by team_id
      return this.team
        ? this.position_options.filter(
            (position) => position.team_id === parseInt(this.team, 10)
          )
        : this.position_options;
    },
  },
  data() {
    return {
      positionReady: false,
      team: "",
      position_id: "",
      assignReady: false,
      team_options: ["poop"],
    };
  },
  methods: {
    async teamChosen() {
      this.positionReady = false;
      this.assignReady = false;
      this.positionReady = true;
    },
    positionChosen() {
      this.assignReady = true;
    },
    assignPosition() {
      this.$emit("position-assigned", this.position_id);
    },
  },
  mounted() {
    // Necessary?
    // TODO - get all teams and their unassigned positions from api
  },
};
</script>

<style></style>
