<template>
  <teleport to="body">
    <div
      ref="modal"
      class="modal fade"
      id=""
      tabindex="-1"
      aria-labelledby=""
      aria-hidden="true"
    >
      <div class="modal-dialog" :class="size ? `modal-${size}` : ''">
        <div class="modal-content">
          <div v-if="$slots['modal-title']" class="modal-header">
            <h5 class="modal-title" id="">
              <slot name="modal-title"></slot>
            </h5>
            <button
              v-if="backdrop !== 'static'"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <slot />
          </div>
          <div v-if="$slots['modal-footer']" class="modal-footer">
            <slot name="modal-footer"></slot>
            <button
              v-if="backdrop !== 'static'"
              @click="$emit('hide')"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    backdrop: {
      validator(value) {
        return typeof value === 'boolean' || value === 'static';
      },
      default: true,
    },
    size: {
      validator(value) {
        return ['sm', 'lg', 'xl'].includes(value);
      },
    },
    show: Boolean,
  },
  emits: ['show', 'shown', 'hide', 'hidden', 'confirm', 'close'],
  watch: {
    show(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (newVal) this.modal.show();
      if (!newVal) this.modal.hide();
    },
  },
  data() {
    return {
      modal: null,
    };
  },
  methods: {
    emitShow() {
      this.$emit('show');
    },
    emitShown() {
      this.$emit('shown');
    },
    emitHide() {
      this.$emit('hide');
    },
    emitHidden() {
      this.$emit('hidden');
    },
  },
  mounted() {
    this.$refs.modal.addEventListener('show.bs.modal', this.emitShow);
    this.$refs.modal.addEventListener('shown.bs.modal', this.emitShown);
    this.$refs.modal.addEventListener('hide.bs.modal', this.emitHide);
    this.$refs.modal.addEventListener('hidden.bs.modal', this.emitHidden);

    this.modal = new window.bootstrap.Modal(this.$refs.modal, {
      backdrop: this.backdrop,
    });
    if (this.show) {
      this.modal.show();
    }
  },
  beforeUnmount() {
    this.$refs.modal.removeEventListener('show.bs.modal', this.emitShow);
    this.$refs.modal.removeEventListener('shown.bs.modal', this.emitShown);
    this.$refs.modal.removeEventListener('hide.bs.modal', this.emitHide);
    this.$refs.modal.removeEventListener('hidden.bs.modal', this.emitHidden);
  },
};
</script>

<style lang="scss" scoped></style>
