import { ref, computed } from 'vue';

const useActionQueueSearch = (accounts) => {
  const query = ref('');
  const accountsMatchingQuery = computed(() =>
    accounts.value.filter((account) => `${account.reference}`.includes(query.value))
  );

  return {
    query,
    accountsMatchingQuery,
  };
};

export default useActionQueueSearch;
