<template>
  <section id="agentDash">
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col pb-4">
          <div class="row">
            <div class="col-2 pb-4">
              <DebtReviewPastDueSummary />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DebtReviewPastDueSummary from "@/components/Dash/Debt Review Agent/DebtReviewPastDueSummary.vue";

export default {
  name: "debtReviewAgentDash",
  components: { DebtReviewPastDueSummary },
};
</script>

<style></style>
