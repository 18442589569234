<template>
  <div>
    <div class="mb-4">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="small"><slot name="subtitle"></slot></span>
          <h3>
            <slot name="title"></slot>
            <span v-if="$slots.title && !isLoading"> ({{ count }})</span>
          </h3>
        </div>
        <div>
          <slot name="controls"></slot>
        </div>
      </div>
    </div>

    <LoadingSpinner v-if="isLoading" />
    <div v-else class="position-relative">
      <slot name="info"></slot>
      <transition-group name="list-complete">
        <div
          v-for="(item, index) in sortedItems"
          :key="item[idKey]"
          class="item"
        >
          <slot :item="item" :index="index"></slot>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'Queue',
  props: {
    idKey: String,
    sortKey: String,
    items: Array,
    isLoading: Boolean,
  },
  components: { LoadingSpinner },
  computed: {
    count() {
      return this.items?.length;
    },
    sortedItems() {
      return [...this.items].sort((a, b) => {
        if (!a[this.sortKey]) {
          return -1;
        }
        if (!b[this.sortKey]) {
          return 1;
        }
        return a[this.sortKey] > b[this.sortKey] ? 1 : -1;
      });
    },
  },
};
</script>
<style scoped>
.item {
  transition: all 1s ease;
  width: 100%;
}
.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>