<template>
  <div>
    <div class="mb-3">
      <div class="d-flex justify-content-between align-items-end">
        <h5>
          Journal <span v-if="!isLoading">({{ count }})</span>
        </h5>
      </div>
    </div>
    <div v-if="accounts">
      <Queue
        idKey="id"
        sortKey="entry_date"
        :items="accounts"
        v-slot:default="{ item: account }"
        v-if="queue && accounts.length >= 1"
      >
        <QueueItem :account="account">
          <template #tag>
            <span class="small d-block mb-3">
              On {{ account.entry_date.toLocaleString() }}
            </span>
          </template>
          <template #title>{{ account.reference }}</template>
          <template #body>
            <p>Arrears: {{ formatCurrency(account.arrears_amount) }}</p>
          </template>
          <template #actions>
            <ActionQueueNote
              @done="(payload) => createNote(payload, account.id)"
            />
            <RoundButton
              title="Journal Done"
              large
              @click="action(account.id, 'Done')"
              class="btn-action text-success"
              ><span class="material-icons-outlined md-18">
                done
              </span></RoundButton
            >
            <RoundButton
              title="Reject Journal"
              large
              @click="action(account.id, 'Reject')"
              class="btn-action text-danger"
              ><span class="material-icons-outlined md-18">
                close
              </span></RoundButton
            >
          </template>
        </QueueItem>
      </Queue>
    </div>
    <div v-else-if="!accounts && !isLoading">
      <p class="small text-muted">Nothing in queue.</p>
    </div>
    <div v-else>
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

import eventTypes from '@/helpers/eventTypes';
import { formatCurrency } from '@/helpers/format';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

import RoundButton from '@/components/Shared/_RoundButton.vue';
import Queue from '@/components/Shared/Queue.vue';
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import ActionQueueNote from '@/components/Shared/ActionQueueNote.vue';

import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

library.add(faFileInvoice);

export default {
  name: 'JournalQueue',
  components: {
    LoadingSpinner,
    Queue,
    QueueItem,
    ActionQueueNote,
    RoundButton,
  },
  setup() {
    return { formatCurrency };
  },
  computed: {
    ...mapGetters({
      activeAccount: 'account/activeAccount',
      positions: 'auth/permissions',
    }),
    count() {
      return this.accounts?.length;
    },
    inboundUserPositionId() {
      return this.positions.find(
        (position) => position.role.toUpperCase() === 'INBOUND AGENT'
      )?.user_position_id;
    },
  },
  data() {
    return {
      showAccountPreview: false,
      currentAccId: null,
      currentQueue: null,
      accounts: null,
      queue: [],
      eventTypes,
      isLoading: true,
    };
  },
  methods: {
    async action(accountId, outome) {
      this.$store.dispatch('account/clearCurrentAccount');
      this.remove(accountId);
      try {
        const result = (
          await axios.post('api/admin/queues/journal', {
            user_position_id: this.inboundUserPositionId,
            account_id: accountId,
            outcome: outome,
          })
        ).data;
        if (!result) {
          this.$store.dispatch('notification/push', {
            body: `Something went wrong`,
            type: 'error',
          });
          this.getData();
        }
      } catch (error) {
        this.getData();
        console.log(error);
        this.$store.dispatch('notification/push', {
          body: error,
          type: 'error',
        });
      }
    },
    async createNote(payload, accId) {
      const notePayload = {
        eventTypeId: payload.type,
        details: JSON.stringify(payload.details),
        user_position_id: this.inboundUserPositionId,
        account_id: accId,
      };
      console.log(notePayload);
      const success = await this.$store.dispatch(
        'collection/confirmInboundAccountTreatment',
        notePayload
      );
      if (success) {
        this.$store.dispatch('notification/push', {
          type: 'success',
          body: `Saving action`,
        });
      }
    },
    close() {
      this.showAccountPreview = false;
      this.currentAccId = null;
    },
    remove(accountId) {
      this.accounts = this.accounts.filter((a) => a.id !== accountId);
    },
    async getData() {
      this.queue = (await axios.get('api/admin/queues/journal')).data;
      this.accounts = this.queue.map((account) => {
        const tempAcc = account;
        // tempAcc.event_note = JSON.parse(tempAcc.event_note || "");
        tempAcc.entry_date = new Date(tempAcc.entry_date);
        return tempAcc;
      });
      this.isLoading = false;
    },
  },
  async mounted() {
    this.$store.dispatch('account/clearCurrentAccount');
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.account-view,
.queue-view {
  // background: green;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.btn-action {
  margin-left: 1rem;
  & + & {
    margin-top: 0.25rem;
  }
}
</style>
