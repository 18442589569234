<template>
  <Queue
    idKey="id"
    sortKey="entry_date"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #title> Pending Diarised </template>
    <template v-slot:default="{ item: account }">
      <DiarisedRequestQueueItem
        :account="account"
        @action="(payload) => action(payload)"
        @view="onView"
      />
    </template>
  </Queue>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

import Queue from '@/components/Shared/Queue.vue';
import DiarisedRequestQueueItem from '@/components/Supervisor/DiarisedRequestQueueItem.vue';

export default {
  name: 'TraceQueue',
  components: {
    Queue,
    DiarisedRequestQueueItem,
  },
  setup(_, { emit }) {
    const queue = ref([]);
    const accounts = ref([]);
    const isLoading = ref(true);
    const store = useStore();

    const removeAccount = (accountId) => {
      accounts.value = accounts.value.filter((a) => a.id !== accountId);
    };

    const onView = (id) => {
      store.dispatch('account/clearCurrentAccount');
      emit('view', id);
    };

    const getData = async () => {
      accounts.value = (
        await axios.get(`api/supervisor/queues/pending-diarised`)
      ).data.map((account) => ({
        ...account,
        due_date_time: new Date(account.due_date_time),
        entry_date: new Date(account.entry_date),
        event_note: JSON.parse(account.event_note),
      }));

      isLoading.value = false;
    };

    const action = async (payload) => {
      removeAccount(payload.accountId);
      try {
        await axios.patch(
          `api/supervisor/queues/pending-diarised/${payload.accountId}`,
          {
            outcome: payload.outcome,
            reason: payload.reason,
          }
        );
      } catch (error) {
        store.dispatch('notification/push', {
          body: `Something went wrong`,
          type: 'error',
        });
        getData();
        console.log(error.message);
      }
    };

    onMounted(getData);

    return {
      queue,
      accounts,
      isLoading,
      onView,
      action,
    };
  },
};
</script>
