<template>
  <div>
    <h2>NRPC Other</h2>
    <form @submit.prevent="submit">
      <div class="mb-3">
        <label class="form-label">NRPC Type</label>
        <select v-model="event" class="form-select" required>
          <option disabled value="">Select type</option>
          <option :value="eventTypes.INCARCERATED">
            {{ eventTypes.INCARCERATED.name }}
          </option>
          <option :value="eventTypes.DECEASED">
            {{ eventTypes.DECEASED.name }}
          </option>
        </select>
      </div>
      <button type="submit" role="submit" class="btn btn-primary">
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import eventTypes from '@/helpers/eventTypes';

export default {
  name: 'NRPCOther',
  emits: ['submit'],
  data() {
    return {
      event: null,
      eventTypes,
    };
  },
  methods: {
    submit() {
      const { name, type, closesAction } = this.event;
      this.$emit('submit', { type, name, closesAction });
    },
  },
};
</script>

<style></style>
