<template>
  <div class="card p-3 bg-blue text-white">
    <div class="card-body p-5">
      <div class="row">
        <div class="col-8">
          <h1 class="display-3 mb-2">Hello {{ user.firstname }}</h1>
          <div v-if="isLoading"><p>Loading...</p></div>
          <div v-if="assignmentsWithQueues" class="mb-4">
            <div
              v-for="assignment in assignmentsWithQueues"
              :key="assignment.id"
            >
              <p class="lead mt-4">
                You're a {{ assignment.role }} for team
                {{ assignment.team }}
              </p>
              <p>
                <span v-if="assignment.queues?.length">
                  <span
                    v-for="(queue, index) in assignment.queues"
                    :key="index"
                  >
                    <QueueBadge :queue="queue.name" />
                  </span>
                </span>
              </p>
            </div>
          </div>
          <slot name="actions"></slot>
        </div>
        <div class="col-4">
          <div class="img">
            <slot name="image"></slot>
          </div>
        </div>
      </div>

      <div v-if="assignmentsWithQueues" class="d-none">
        <table
          v-for="assignment in assignmentsWithQueues"
          :key="assignment.id"
          class="table table-sm align-middle table-borderless text-white"
        >
          <tbody>
            <tr>
              <th scope="col">Position</th>
              <td class="text-nowrap">
                {{ assignment.position_name }} ({{ assignment.role }})
              </td>
            </tr>
            <tr>
              <th scope="col">Team</th>
              <td class="text-nowrap">{{ assignment.team_name }}</td>
            </tr>
            <tr>
              <th scope="col">Queues</th>
              <td>
                <div v-if="assignment.queues.length"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

import QueueBadge from '@/components/Shared/QueueBadge.vue';

import agentGreetingImage from '@/assets/agent_greeting_image.svg';

export default {
  name: 'UserAssignments',
  setup() {
    return {
      agentGreetingImage,
    };
  },
  components: {
    QueueBadge,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      positions: 'auth/permissions',
    }),
  },
  data() {
    return {
      isLoading: true,
      assignmentsWithQueues: [],
    };
  },
  async mounted() {
    const queueMap = {};
    try {
      await Promise.all(
        [...this.positions].map(async (team) => {
          queueMap[team.team_id] = (
            await axios.get('api/queues', { params: { team: team.team_id } })
          ).data;
        })
      );
      this.assignmentsWithQueues = this.positions.map((a) => ({
        ...a,
        queues: queueMap[a.team_id],
      }));
    } catch (error) {
      console.error(error.message);
    }
    this.isLoading = false;
  },
};
</script>

<style scoped>
.img {
  position: absolute;
  bottom: 0rem;
  right: 5rem;
  width: 40%;
}
</style>
