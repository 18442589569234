<template>
  <QueueItem :account="account">
    <template #title>{{ account.reference }}</template>
    <template #body> <p class="mb-0">Letter generated</p> </template>
    <template #actions>
      <RoundButton
        @click="toggleForm('code')"
        large
        class="btn-action text-success"
        title="Sent"
      >
        <span class="material-icons-outlined md-18"> done </span>
      </RoundButton>
    </template>
    <template #footer>
      <Section129AttachCodeForm
        v-if="showForm === 'code'"
        @submitted="$emit('sent', $event)"
        class="mt-4"
      />
    </template>
  </QueueItem>
</template>

<script>
import { ref } from 'vue';

import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import RoundButton from '@/components/Shared/_RoundButton.vue';
import Section129AttachCodeForm from '@/components/Legal/Section129AttachCodeForm.vue';

export default {
  props: {
    account: Object,
  },
  components: { QueueItem, RoundButton, Section129AttachCodeForm },
  setup() {
    const showForm = ref('');
    const toggleForm = (formName) => {
      showForm.value = showForm.value !== formName ? formName : '';
    };

    return {
      showForm,
      toggleForm,
    };
  },
};
</script>