<template>
  <Page>
    <div class="container-fluid" v-if="team">
      <BasePageTitle>
        <template v-slot:header> Team: {{ team?.name }} </template>
      </BasePageTitle>
      <div class="row">
        <div class="col-sm-2">
          <div class="list-group mt-3" id="list-tab" role="tablist">
            <router-link
              :to="{ name: 'team_management' }"
              active-class="active"
              class="list-group-item list-group-item-action"
              >Manage</router-link
            >
            <router-link
              :to="{ name: 'TeamStats' }"
              active-class="active"
              class="list-group-item list-group-item-action"
            >
              Productivity Stats
            </router-link>
          </div>
        </div>
        <div class="col-sm-10">
          <div class="d-flex mt-3">
            <div class="queue-summary-card">
              <span class="title">Total Accounts</span>
              <span class="value" v-if="allTeamStats?.team_acc_count >= 0">
                {{ allTeamStats.team_acc_count }}
              </span>
              <h6 v-else>...Loading</h6>
            </div>
            <div class="queue-summary-card">
              <span class="title">Total Agents</span>
              <span class="value" v-if="allTeamStats?.active_positions >= 0">
                {{ allTeamStats.active_positions }}
              </span>
              <h6 v-else>...Loading</h6>
            </div>
            <div class="queue-summary-card">
              <span class="title">Accounts Worked</span>
              <span class="value" v-if="allTeamStats?.touched >= 0">
                {{ allTeamStats.touched }}
              </span>
              <h6 v-else>...Loading</h6>
            </div>
            <div class="queue-summary-card">
              <span class="title">CTP Made</span>
              <span class="value" v-if="allTeamStats?.team_total_ctps >= 0">
                {{ allTeamStats.team_total_ctps }}
              </span>
              <h6 v-else>...Loading</h6>
            </div>
            <div class="queue-summary-card">
              <span class="title">Current Queues</span>
              <div v-if="teamQueuesLoading">Queues loading...</div>
              <div v-else>
                <span
                  v-for="(queue, index) in teamQueues"
                  :key="index"
                  class="badge bg-primary me-1"
                >
                  {{ queue.name }}
                </span>
              </div>
            </div>
          </div>
          <router-view />
        </div>
      </div>
    </div>
    <LoadingSpinner v-else />
  </Page>
</template>

<script>
import Page from "@/components/Shared/_Page.vue";
import BasePageTitle from "@/components/Shared/_BasePageTitle.vue";
import { mapGetters } from "vuex";
import LoadingSpinner from "../components/Shared/LoadingSpinner.vue";

export default {
  name: "Team",
  components: { Page, BasePageTitle, LoadingSpinner },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  async created() {
    this.$store.dispatch("team/getTeam", this.id);
    this.$store.dispatch("team/getTeamQueues", this.id);
    this.$store.dispatch("team/getUnassignedPositions");
    this.$store.dispatch("user/getUserList");
    await this.$store.dispatch("team/getAllTeamStats", this.id);
    // await this.$store.dispatch("team/getTeamTimeStats", this.id);
  },
  beforeUnmount() {
    this.$store.dispatch("team/clearTeam");
  },
  computed: {
    ...mapGetters({
      teamQueues: "team/teamQueues",
      teamQueuesLoading: "team/teamQueuesLoading",
      team: "team/team",
      totalTouched: "team/teamTotalAccTouched",
      totalCalls: "team/teamTotalCalls",
      allTeamStats: "team/allTeamStats",
    }),
  },
};
</script>

<style lang="scss" scoped>
.queue-summary-card {
  width: 100%;
  padding: 1rem;
  background: #dfe5e7;
  & + .queue-summary-card {
    margin-left: 0.5rem;
  }

  .title {
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
  }

  .value {
    display: block;
    font-size: 3rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }
}
</style>
