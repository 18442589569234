<template>
  <h2>Refusal</h2>
  <form @submit.prevent="submit">
    <div class="row mb-3">
      <div class="col">
        <label class="form-label">Instruction</label>
        <select v-model="reason" class="form-select" required>
          <option disabled value="">Select reason</option>
          <option value="product_related">Product related</option>
          <option value="fraud">Fraud</option>
          <option value="contract_dispute">Contract dispute</option>
        </select>
      </div>
    </div>
    <button type="submit" role="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import eventTypes from "@/helpers/eventTypes";

export default {
  name: "RPCRefusal",
  emits: ["submit"],
  data() {
    return {
      ...eventTypes.REFUSAL_TO_PAY,
      reason: "",
    };
  },
  methods: {
    submit() {
      const { name, type, closesAction } = eventTypes.REFUSAL_TO_PAY;
      const details = {
        reason: this.reason,
      };
      this.$emit("submit", { type, name, closesAction, details });
    },
  },
};
</script>
