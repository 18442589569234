<template>
  <div class="card">
    <div class="card-body">
      <h2 class="card-title">Stuck in holding Queue</h2>
      <div v-if="!isLoading">
        <router-link
          :to="{ name: 'queue', params: { id: 22 } }"
          class="text-reset text-decoration-none"
        >
          <h3 class="my-4">{{ accountsCount }} Accounts</h3>
        </router-link>
      </div>
      <div v-else><p>Loading...</p></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'HoldingAccountsCard',
  components: {},
  data() {
    return {
      isLoading: true,
      accountsCount: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {},
  async mounted() {
    try {
      this.accountsCount = (
        await axios.get(`/api/health`, {
          params: { metric: 'accounts_stuck_in_holding' },
        })
      ).data.accounts_stuck_in_holding;
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
</style>