<template>
  <Form @submit="submitProfileEdits" :validation-schema="editProfileValidation">
    <div class="form-floating mb-3">
      <Field
        name="firstname"
        class="form-control"
        type="text"
        id="firstname"
        :placeholder="new_first_name"
        v-model="new_first_name"
      />
      <label for="firstname">First Name</label>
      <ErrorMessage name="firstname" class="error-message" />
    </div>
    <div class="form-floating mb-3">
      <Field
        name="lastname"
        class="form-control"
        type="text"
        id="lastname"
        :placeholder="new_last_name"
        v-model="new_last_name"
      />
      <label for="lastname">Last Name</label>
      <ErrorMessage name="lastname" class="error-message" />
    </div>
    <button class="btn btn-primary">Submit</button>
    <button
      class="btn btn-danger ms-2"
      type="button"
      v-on:click="this.$router.push({ name: 'view_profile' })"
    >
      Cancel
    </button>
  </Form>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'EditProfileForm',
  components: { Form, Field, ErrorMessage },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  data() {
    const editProfileValidation = yup.object({
      firstname: yup.string().required('This field cannot be empty.'),
      lastname: yup.string().required('This field cannot be empty.'),
    });
    return {
      editProfileValidation,
      new_first_name: '',
      new_last_name: '',
    };
  },
  mounted() {
    this.new_first_name = this.user.firstname;
    this.new_last_name = this.user.lastname;
  },
  methods: {
    async submitProfileEdits() {
      try {
        const url = `/api/user/${this.user.id}`;
        const response = await axios.patch(url, {
          firstname: this.new_first_name,
          lastname: this.new_last_name,
        });
        if (response.data) {
          this.$store.dispatch('auth/userUpdate', {
            new_first_name: this.new_first_name,
            new_last_name: this.new_last_name,
          });
          this.$store.dispatch('notification/push', {
            body: `Profile Edits Confirmed`,
          });
          this.$router.push({ name: 'profile' });
        } else {
          this.$store.dispatch('notification/push', {
            body: `Profile Edits Failed`,
            type: 'error',
          });
        }
      } catch {
        this.$store.dispatch('notification/push', {
          body: `Profile Edits Failed`,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style></style>
