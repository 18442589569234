import axios from 'axios';

export const fetchTeamStats = async (teamId) => {
  const stats = (
    await axios.get(`api/team/${teamId}/stats/productivity/members`)
  ).data;
  return stats;
};

/*  `api/team/${teamId}/stats/productivity/members`
 */
