<template>
  <section id="defaultDash" class="pt-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 pb-4">
          <!-- <UserAssignmentsCard> -->
          <!-- <template v-slot:image
      ><img :src="agentGreetingImage" class="img-fluid" alt=""
    /></template> -->
          <!-- </UserAssignmentsCard> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

// import UserAssignmentsCard from '@/components/Dash/UserAssignmentsCard.vue';

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  components: {
    // UserAssignmentsCard,
  },
};
</script>
