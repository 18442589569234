<template>
  <teleport to="#modals">
    <div
      ref="modal"
      class="modal fade"
      id="changeSessionStatus"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="changeSessionStatusLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="changeSessionStatusLabel">
              Change Session Status
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div v-if="!isActivityTypesLoading">
                    <div
                      v-for="option in selectableActivityTypes"
                      class="form-check"
                      :key="option.id"
                    >
                      <input
                        v-model="selected"
                        class="form-check-input"
                        type="radio"
                        name="statusOption"
                        :id="`changeStatus${option.id}`"
                        :value="option.id"
                      />
                      <label
                        class="form-check-label"
                        :for="`changeStatus${option.id}`"
                      >
                        {{ option.activity_type }}
                      </label>
                    </div>
                  </div>
                  <div v-else>Activity types loading...</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="$emit('cancel')"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button @click="changeStatus" type="button" class="btn btn-primary">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ChangeUserActivityModal',
  props: {
    show: Boolean,
  },
  emits: ['confirm', 'cancel'],
  data() {
    return {
      modal: null,
      selected: null,
    };
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (newVal) this.modal.show();
      if (!newVal) this.modal.hide();
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      activityTypes: 'activity/activityTypes',
      isActivityTypesLoading: 'activity/isActivityTypesLoading',
    }),
    selectableActivityTypes() {
      return this.activityTypes.filter((at) => at.user_selectable);
    },
  },
  methods: {
    async changeStatus() {
      await this.$store.dispatch('activity/saveActivity', {
        userId: this.user?.id,
        activityId: this.selected,
      });
      this.$emit('confirm');
    },
  },
  mounted() {
    // this.$refs.modal.addEventListener('show.bs.modal', () => {});
    this.modal = new window.bootstrap.Modal(this.$refs.modal, {
      backdrop: 'static',
    });
    this.$store.dispatch('activity/getActivityTypes');
  },
};
</script>

<style lang="scss" scoped>
</style>