<template>
  <ConfirmActionModal
    :show="showConfirmModal"
    @confirm="positionDeleteConfirmed"
    @cancel="cancelDelete"
  >
    <template v-slot:header>Confirm Delete</template>
    <template v-slot:body> Are you sure you want to delete this position? </template>
  </ConfirmActionModal>

  <ConfirmActionModal
    :show="showUnassignConfirmModal"
    @confirm="unassignPosition"
    @cancel="cancelUnassign"
  >
    <template v-slot:header>Confirm Unassign</template>
    <template v-slot:body> Are you sure you want to unassign this position? </template>
  </ConfirmActionModal>

  <CreatePositionModal
    :show="showCreateModal"
    @close="closeCreateModal"
    @confirm="closeCreateModal"
  />
  <div class="mt-4">
    <div class="row">
      <div v-if="team && !isLoading" class="col">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <!-- <span class="lead"
            >{{ team.name }}<span v-if="!team.active"> (inactive)</span></span
          > -->
          <button @click="createPosition" class="btn btn-primary btn-rounded mb-4">
            Add New
          </button>
        </div>

        <div class="row mt-4">
          <div class="col">
            <p class="lead mb-3">Currently Active Positions</p>
            <PositionList
              v-if="team && team.positions && !isLoading"
              @unassign="unassignConfirmModal"
              @edit="editItem"
              @delete="confirmDelete"
              :positions="team.positions"
            />
          </div>
          <div class="col ms-3">
            <p class="lead mb-3">Currently Unassigned Positions</p>
            <UnassignedPositionList
              class=""
              :unassignedPositions="unassignedPositions"
              :team_id="team.id"
              :user_list="user_list"
              @delete="confirmDelete"
            />
          </div>
        </div>
      </div>
      <div v-else class="col">
        <p class="lead">Loading...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ConfirmActionModal from "@/components/Shared/ConfirmActionModal.vue";
import CreatePositionModal from "@/components/Teams/CreatePositionModal.vue";
import PositionList from "./PositionList.vue";
import UnassignedPositionList from "./UnassignedPositionList.vue";

export default {
  name: "TeamManagement",
  components: {
    PositionList,
    ConfirmActionModal,
    CreatePositionModal,
    UnassignedPositionList,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      positionToDelete: null,
      showConfirmModal: false,
      showCreateModal: false,
      showUnassignConfirmModal: false,
      positionToUnassign: null,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "team/isTeamLoading",
      unassignedPositions: "team/unassignedPositions",
      team: "team/team",
      errors: "team/errors",
      user_list: "user/userList",
    }),
  },
  methods: {
    createPosition() {
      this.$store.dispatch("team/getAllPositions");
      this.showCreateModal = true;
    },
    confirmDelete(position) {
      this.positionToDelete = position;
      this.showConfirmModal = true;
    },
    editItem(id) {
      console.log(`Edit ${id}`);
    },
    async unassignPosition() {
      const response = await this.$store.dispatch("team/unassignPosition", {
        position_id: this.positionToUnassign,
        team_id: this.id,
      });
      this.showUnassignConfirmModal = false;
      if (response) {
        this.$store.dispatch("notification/push", {
          body: `Position Unassigned`,
        });
      } else {
        this.$store.dispatch("notification/push", {
          body: `Position Unassign Failed`,
          type: "error",
        });
      }
    },
    cancelDelete() {
      this.showConfirmModal = false;
    },
    async positionDeleteConfirmed() {
      const response = await this.$store.dispatch(
        "team/deletePosition",
        this.positionToDelete
      );
      this.showConfirmModal = false;
      if (response) {
        this.$store.dispatch("notification/push", {
          body: `Position Deleted`,
        });
      } else {
        this.$store.dispatch("notification/push", {
          body: `Position Delete Failed`,
          type: "error",
        });
      }
    },
    closeCreateModal() {
      this.showCreateModal = false;
    },
    cancelUnassign() {
      this.positionToUnassign = null;
      this.showUnassignConfirmModal = false;
    },
    unassignConfirmModal(id) {
      this.positionToUnassign = id;
      this.showUnassignConfirmModal = true;
    },
  },
};
</script>

<style></style>
