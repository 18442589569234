<template>
  <div class="h-100 d-flex flex-column gap-5">
    <div class="card flex-grow-1">
      <div class="card-header">
        <ul class="nav nav-pills" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              :class="{ active: activeAccount.queues.workable }"
              class="nav-link"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              :aria-selected="!activeAccount.queues.workable"
              v-if="activeAccount.queues.workable"
            >
              Treatment
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              :class="{ active: !activeAccount.queues.workable }"
              class="nav-link"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              :aria-selected="!activeAccount.queues.workable"
            >
              Action
            </button>
          </li>
        </ul>
      </div>
      <div class="card-body d-flex flex-column">
        <div v-if="ready && !accountErrors.length" class="d-flex flex-column h-100 mb-4">
          <div class="tab-content h-100" id="pills-tabContent">
            <div
              :class="{ 'active show': activeAccount.queues.workable }"
              class="tab-pane fade h-100"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <keep-alive>
                <component
                  :is="currentView"
                  :event="name"
                  :details="details"
                  :fresh="fresh"
                  :inbound="inbound"
                  @choice="update"
                  @timeout="initiateCall"
                  @back="back"
                  @submit="done"
                  @confirm="confirm"
                  @exit="exit"
                  @chats="chats"
                />
              </keep-alive>
              <InboundMessagesModal
                :show="showInboundMessages"
                @hide="hideInboundMessages"
              />
              <button
                @click="back"
                id="back"
                class="btn btn-round btn-primary"
                v-if="restartVisible"
              >
                <span class="material-icons"> arrow_back </span>
              </button>
            </div>
            <div
              :class="{ 'active show': !activeAccount.queues.workable }"
              class="tab-pane fade h-100"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div class="d-flex flex-column justify-content-center h-100">
                <div class="d-grid gap-3">
                  <AuxActions :actionId="actionId" />
                  <CreateNote @done="done" />
                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Phone
      @nextAccount="NRPCNextAccount"
      v-on:call="initiateCall"
      v-on:hangup="endCall"
      :number="callNumber"
      :is-dialing="isDialing"
      :callButton="callButton"
      @callSameNumber="callBack"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// Step 0
import Init from "@/components/Collect/ActionPanel/Inbound/Init.vue";
// Step 1
import RPC from "@/components/Account/ActionPanel/Steps/RPC.vue";
import Escalate from "@/components/Account/ActionPanel/Steps/Escalate.vue";
import Journal from "@/components/Account/ActionPanel/Steps/Journal.vue";
// Step 2
import RPCDiarise from "@/components/Account/ActionPanel/Steps/Choices/RPCDiarise.vue";
import RPCCTP from "@/components/Account/ActionPanel/Steps/Choices/RPCCTP.vue";
import RPCRefusal from "@/components/Account/ActionPanel/Steps/Choices/RPCRefusal.vue";
import RPCPaymentDispute from "@/components/Account/ActionPanel/Steps/Choices/RPCPaymentDispute.vue";
import RPCOther from "@/components/Account/ActionPanel/Steps/Choices/RPCOther.vue";
// Step 3
import Confirm from "@/components/Account/ActionPanel/Steps/Confirm.vue";
// Aux
import AuxActions from "@/components/Account/ActionPanel/AuxActions/AuxActions.vue";
import CreateNote from "@/components/Account/ActionPanel/AuxActions/CreateNote.vue";
import InboundMessagesModal from "@/components/Collect/ActionPanel/Inbound/InboundMessagesModal.vue";

export default {
  name: "InboundActionPanel",
  props: {
    ready: Boolean,
  },
  components: {
    Init,
    RPC,
    Escalate,
    Journal,
    RPCDiarise,
    RPCCTP,
    RPCRefusal,
    RPCPaymentDispute,
    RPCOther,
    Confirm,
    AuxActions,
    CreateNote,
    InboundMessagesModal,
  },
  setup() {},
  data() {
    return {
      history: [],
      currentView: Init,
      type: "",
      eventName: "",
      details: "",
      closesAction: null,
      fresh: true,
      showInboundMessages: false,
    };
  },
  computed: {
    ...mapGetters({
      accountErrors: "account/errors",
      positions: "auth/permissions",
      activeAccount: "account/activeAccount",
    }),
    restartVisible() {
      return this.history?.length > 0;
      // return this.currentView.name !== 'Init';
    },
  },
  methods: {
    hideInboundMessages() {
      this.showInboundMessages = false;
    },
    chats() {
      this.showInboundMessages = true;
    },
    update(payload) {
      const routes = {
        rpc: RPC,
        escalate: Escalate,
        journal: Journal,
        //
        rpc_diarise: RPCDiarise,
        rpc_CTP: RPCCTP,
        rpc_refusal: RPCRefusal,
        rpc_paymentDispute: RPCPaymentDispute,
        rpc_other: RPCOther,
      };
      this.history.push(this.currentView);

      this.currentView = routes[payload];
    },
    back() {
      this.details = null;
      this.currentView = this.history.pop() ?? Init;
    },
    done(payload) {
      this.type = payload.type;
      this.eventName = payload.name;
      this.details = payload.details;
      this.closesAction = payload.closesAction;
      if (payload.name === "Note") {
        let activePositionId = this.positions.find(
          (position) => position.role.toUpperCase() === "INBOUND AGENT"
        )?.user_position_id;
        if (!activePositionId) {
          activePositionId = this.positions.find(
            (position) => position.role.toUpperCase() === "MANAGER"
          )?.user_position_id;
        }
        const notePayload = {
          eventTypeId: this.type,
          details: this.details,
          user_position_id: activePositionId,
        };
        this.$emit("confirm", notePayload);
      } else {
        this.currentView = Confirm;
        this.fresh = false;
      }
    },
    async confirm() {
      if ((this.onCall || this.isDialing) && this.closesAction) {
        await this.endCall();
      }
      const payload = {
        eventTypeId: this.type,
        details: this.details,
        closesAction: this.closesAction,
      };

      this.$emit("confirm", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#back {
  position: absolute;
  bottom: 3rem;
  right: 3rem;
}
</style>
