<template>
  <div class="card">
    <div class="card-body d-flex flex-column justify-content-between">
      <div>
        <div class="data-title">Instalment</div>
        <div class="fw-bold data-value">
          {{ formatCurrency(account.account_details.InstalmentAmount) || '-' }}
        </div>
      </div>
      <div>
        <div class="data-title">Instalment Effective</div>
        <div class="fw-bold data-value">
          {{
            getISODateString(account.account_details.InstalmentEffectiveDate) ||
            '-'
          }}
        </div>
      </div>
      <div>
        <div class="data-title">Instalment Day</div>
        <div class="fw-bold data-value">
          {{ account.account_details.InstalmentDay }}
        </div>
      </div>
      <div>
        <div class="data-title">Final Instalment</div>
        <div class="fw-bold data-value">
          {{
            getISODateString(account.account_details.LastInstalmentDate) || '-'
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/format';
import { getISODateString } from '@/helpers/datetime';

export default {
  name: 'AccountInstalmentInfo',
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { formatCurrency, getISODateString };
  },
};
</script>

<style lang="scss" scoped>
</style>