<template>
  <section id="managerDash" class="pt-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-3 pb-4">
          <HoldingAccountsCard />
        </div>
        <div class="col-3 pb-4"></div>
        <div class="col-3 pb-4"></div>
        <div class="col-3 pb-4"></div>
      </div>
      <div class="row">
        <div class="col-4"></div>

        <div class="col-4"></div>
      </div>
    </div>
  </section>
</template>

<script>
// import ManagerAssignmentsCard from "@/components/Dash/Manager/ManagerAssignmentsCard.vue";
import HoldingAccountsCard from '@/components/Dash/HoldingAccountsCard.vue';

export default {
  components: {
    // ManagerAssignmentsCard,
    HoldingAccountsCard,
  },
  setup() {},
};
</script>
