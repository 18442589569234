import { deferAccount, createNote } from '@/api/account';

const useAccountActions = (updateAccount) => {
  const onNote = async (accountId, data) => {
    try {
      await createNote(accountId, data.note);
    } catch (error) {
      console.error(error);
    }
  };

  const onDefer = async (accountId, data) => {
    const undo = updateAccount(accountId, {
      due_date_time: new Date(data.date),
    });
    try {
      await deferAccount(accountId, { action: 'diarise', data });
    } catch (error) {
      console.error(error);
      undo();
    }
  };

  return {
    onNote,
    onDefer,
  };
};

export default useAccountActions;
