<template>
  <teleport to="#modals">
    <div
      class="modal fade"
      id="eventsModal"
      ref="eventsModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLabel">Account Events</h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="events && !isLoading">
              <!-- Filtering and sorting -->
              <div class="mb-2">
                <div
                  v-for="type in eventTypes"
                  :key="type"
                  class="form-check form-check-inline"
                >
                  <input
                    v-model="typeFilter"
                    :value="type"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${type}EventTypeFilter`"
                  />
                  <label
                    class="form-check-label"
                    :for="`${type}EventTypeFilter`"
                    >{{ type }}</label
                  >
                </div>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" class="w-20">Date</th>
                    <th scope="col">Type</th>
                    <th scope="col">Agent</th>
                    <th scope="col">Note</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in filteredEvents"
                    :key="index"
                    :class="{
                      rpc:
                        item.event_group === 'RPC' ||
                        item.event_group === 'CTP',
                    }"
                  >
                    <td v-if="item.occured">
                      {{ new Date(item.occured).toLocaleString() }}
                    </td>
                    <td v-else>N/A</td>
                    <td>{{ item.event_type }}</td>
                    <td>{{ item.username }}</td>
                    <td>
                      <span v-if="typeof item.event_note === 'object'">
                        <span v-for="(note, key) in item.event_note" :key="key">
                          [{{ key.toString().toUpperCase() }}: {{ note }}],
                        </span>
                      </span>
                      <span v-else> {{ item.event_note }} </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="!events && !isLoading">
              <span class="h6">No events to show.</span>
            </div>
            <LoadingSpinner v-if="isLoading" />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';

// library.add(faPhone, faPhoneSlash);

export default {
  name: 'EventsModal',
  components: {
    LoadingSpinner,
    // FontAwesomeIcon,
  },
  data() {
    return {
      page: 1,
      morePages: false,
      prevPages: false,
      typeFilter: [],
      sortedEvents: null,
      test: null,
    };
  },
  computed: {
    events() {
      return this.rawEvents.filter(
        (e) => e.event_group !== 'GBL_NRPC' && e.event_group !== 'ERROR'
      );
    },
    ...mapGetters({
      activeAccount: 'account/activeAccount',
      rawEvents: 'account/events',
      isLoading: 'account/isEventsLoading',
    }),
    eventTypes() {
      return [...new Set(this.events.map((e) => e.event_type))];
    },
    filteredEvents() {
      return this.typeFilter.length
        ? this.sortedEvents.filter((e) =>
            this.typeFilter.includes(e.event_type)
          )
        : this.sortedEvents;
    },
  },
  methods: {
    async getActivity() {
      await this.$store.dispatch('account/getEvents', this.activeAccount.id);
      this.morePages = this.events.length > this.amount;
      this.sortedEvents = this.events.sort((a, b) =>
        new Date(a.close_date) < new Date(b.close_date) ? 1 : -1
      );
      this.sortedEvents = this.sortedEvents.map((event) => {
        const temp = event;
        try {
          temp.event_note = JSON.parse(temp.event_note);
        } catch (e) {
          console.error(e);
        }
        return temp;
      });
    },
    async nextPage() {
      this.page += 1;
      await this.getActivity();
    },
    async previousPage() {
      this.page -= 1;
      await this.getActivity();
    },
  },
  mounted() {
    this.getActivity();
    this.$refs.eventsModal.addEventListener('show.bs.modal', () => {
      this.getActivity();
    });
  },
};
</script>

<style scoped lang="scss">
.rpc {
  background-color: #d1e7dd;
}
</style>
