<template>
  <div>
    <div class="mb-3">
      <div class="d-flex flex-column">
        <span class="small">Debt Review</span>
        <h3>
          Counter Referral <span v-if="accounts">({{ count }})</span>
        </h3>
      </div>
    </div>
  </div>
  <div v-if="accounts">
    <Queue
      idKey="id"
      sortKey="entry_date"
      :items="accounts"
      v-slot:default="{ item: account }"
    >
      <DebtReviewCounterReferralQueueItem
        :account="account"
        @action="action"
        @note="createNote"
      />
    </Queue>
  </div>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
</template>

<script>
import axios from 'axios';

import { createNote } from '@/api/account';

import Queue from '@/components/Shared/Queue.vue';
import DebtReviewCounterReferralQueueItem from '@/components/Manager/DebtReviewCounterReferralQueueItem.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'DebtReviewReferralQueue',
  components: {
    LoadingSpinner,
    DebtReviewCounterReferralQueueItem,
    Queue,
  },
  emits: ['view'],
  data() {
    return {
      accounts: null,
      isLoading: true,
    };
  },
  computed: {
    count() {
      return this.accounts?.length;
    },
  },
  async mounted() {
    await this.fetchData();
  },
  beforeUnmount() {
    this.$store.dispatch('account/clearCurrentAccount');
  },
  methods: {
    remove(accountId) {
      this.accounts = this.accounts.filter((a) => a.id !== accountId);
    },
    async fetchData() {
      const queue = (
        await axios.get('api/admin/queues/debt-review-counter-referral')
      ).data;
      this.accounts = queue.accounts.map((account) => {
        const tempAcc = account;
        // tempAcc.event_note = JSON.parse(tempAcc.event_note ?? {});
        tempAcc.entry_date = new Date(tempAcc.entry_date);
        return tempAcc;
      });
      this.isLoading = false;
    },
    async action(event) {
      const { accountId, payload } = event;

      this.$store.dispatch('account/clearCurrentAccount');
      if (payload.action === 'accept') {
        await this.accept(accountId, payload.note);
      } else if (payload.action === 'reject') {
        console.log(payload);
        await this.reject(accountId, payload.note);
      }
    },

    async reject(accountId, payload) {
      this.remove(accountId);
      try {
        const result = (
          await axios.patch(
            `/api/admin/queues/debt-review-counter-referral/${accountId}`,
            {
              action: 'reject',
              data: payload,
            }
          )
        ).data;
        if (result) {
          this.$store.dispatch('notification/push', {
            body: `Something went wrong`,
            type: 'error',
          });
          this.fetchData();
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch('notification/push', {
          body: error,
          type: 'error',
        });
        this.fetchData();
      }
    },
    async accept(accountId, payload) {
      this.remove(accountId);
      try {
        const result = (
          await axios.patch(
            `/api/admin/queues/debt-review-counter-referral/${accountId}`,
            {
              action: 'accept',
              data: payload,
            }
          )
        ).data;
        if (result) {
          this.$store.dispatch('notification/push', {
            body: `Something went wrong. Check that the situation on ILS has been changed.`,
            type: 'error',
          });
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/push', {
          body: 'Something went wrong. Check that the situation on ILS has been changed.',
          type: 'error',
        });
        this.fetchData();
      }
    },
    createNote({ accountId, note }) {
      createNote(accountId, note);
    },
  },
};
</script>
