<template>
  <form @submit.prevent="onSubmit">
    <div class="mb-3">
      <label class="form-label">Tracking Code</label>
      <input v-model="code" type="text" class="form-control" />
    </div>
    <button type="submit" class="btn btn-primary">Submit</button>
  </form>
</template>


<script>
import { ref } from 'vue';

export default {
  emits: ['submitted'],
  setup(_, { emit }) {
    const code = ref('');

    const onSubmit = () => emit('submitted', { code: code.value });

    return {
      code,
      onSubmit,
    };
  },
};
</script>