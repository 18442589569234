import axios from 'axios';

const userStore = {
  namespaced: true,
  state() {
    return {
      userList: [],
      userListLoading: true,
      profileInfo: [],
      profileLoading: true,
      userPositions: [],
      userPositionsLoading: true,
    };
  },
  mutations: {
    userListRequested(state) {
      state.userListLoading = true;
      state.userList = [];
    },
    userListLoaded(state, users) {
      state.userList = users;
      state.userListLoading = false;
    },
    profileInfoRequested(state) {
      state.profileLoading = true;
      state.profileInfo = [];
    },
    profileInfoLoaded(state, profileInfo) {
      state.profileInfo = profileInfo;
      state.profileLoading = false;
    },
    userPositionsLoading(state) {
      state.userPositionsLoading = true;
    },
    userPositionsLoaded(state, userPositionList) {
      state.userPositions = userPositionList;
      state.userPositionsLoading = false;
    },
  },
  getters: {
    userList(state) {
      return state.userList;
    },
    profileInfo(state) {
      return state.profileInfo;
    },
    userListLoading(state) {
      return state.userListLoading;
    },
    profileLoading(state) {
      return state.profileLoading;
    },
    userPositions(state) {
      return state.userPositions;
    },
    userPositionsLoading(state) {
      return state.userPositionsLoading;
    },
  },
  actions: {
    async getUserList({ commit }) {
      commit('userListRequested');
      try {
        const users = (await axios.get('/api/users')).data;
        commit('userListLoaded', users);
      } catch {
        console.error('Something went wrong when loading the users');
      }
    },
    async getSearchedUserList({ commit }, payload) {
      commit('userListRequested');
      try {
        const users = (
          await axios.get('/api/users/search', { params: { search_term: payload.term } })
        ).data;
        commit('userListLoaded', users);
      } catch {
        console.error('Something went wrong when loading the users');
      }
    },
    async getUserInfo({ commit }, userId) {
      commit('profileInfoRequested');
      const url = `api/user/${userId}`;
      try {
        const profileInfo = (await axios.get(url)).data;
        commit('profileInfoLoaded', profileInfo);
      } catch {
        console.error('Something went wrong when loading the user info');
      }
    },
    async getUserPositions({ commit }, userId) {
      commit('userPositionsLoading');
      try {
        const userPositionList = (
          await axios.get('/api/userpositions', { params: { user_id: userId } })
        ).data;
        commit('userPositionsLoaded', userPositionList);
      } catch {
        console.error('Something went wrong when fetching users positions');
      }
    },
  },
};

export default userStore;
