<template>
  <Queue
    idKey="id"
    sortKey="entry_date"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #subtitle> Debt Review </template>
    <template #title> Broken CTP </template>
    <template v-slot:default="{ item: account }">
      <DebtReviewCTPBrokenQueueItem
        :account="account"
        @action="action"
        @note="createNote"
      />
    </template>
  </Queue>
</template>

<script>
import axios from 'axios';

import { createNote } from '@/api/account';

import Queue from '@/components/Shared/Queue.vue';
import DebtReviewCTPBrokenQueueItem from '@/components/Admin/DebtReviewCTPBrokenQueueItem.vue';

export default {
  name: 'DebtReviewCTPBrokenQueue',
  components: {
    Queue,
    DebtReviewCTPBrokenQueueItem,
  },
  data() {
    return {
      accounts: null,
      isLoading: true,
    };
  },
  async mounted() {
    await this.loadAccounts();
    this.isLoading = false;
  },
  methods: {
    async loadAccounts() {
      try {
        this.accounts = (
          await axios.get('api/admin/queues/debt-review-ctp-broken')
        ).data.accounts;
      } catch (error) {
        console.error(error);
      }
    },
    createNote({ accountId, note }) {
      createNote(accountId, note);
    },
    async action(event) {
      const { accountId, payload } = event;

      this.$store.dispatch('account/clearCurrentAccount');
      this.accounts = this.accounts.filter((acc) => acc.id !== accountId);
      try {
        const url = `/api/admin/queues/debt-review-ctp-broken/${accountId}`;
        await axios.patch(url, payload);
      } catch (error) {
        this.loadAccounts();
        this.$store.dispatch('notification/push', {
          body: `Action Failed`,
          type: 'error',
        });
      }
    },
  },
};
</script>
