<template>
  <QueueItem>
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span>
    </template>
    <template #title>
      {{ account.event_note?.date || account.event_note?.details?.date }}
      {{ account.event_note?.time || account.event_note?.details?.time }}
      -
      {{ account.event_note?.reason || account.event_note?.details?.reason }}
    </template>
    <template #body>
      <div class="row">
        <div class="col-4">Reference</div>
        <div class="col-8">{{ account.reference }}</div>
      </div>
      <div class="row">
        <div class="col-4">Arrears</div>
        <div class="col-8">{{ account.arrears_amount }}</div>
      </div>
      <div class="row">
        <div class="col-4">Arrear key</div>
        <div class="col-8">{{ account.arrears_key }}</div>
      </div>
      <div class="row">
        <div class="col-4">Owner</div>
        <div class="col-8">{{ account.username }}</div>
      </div>
    </template>
    <template #actions>
      <RoundButton @click="view" large class="btn-action text-secondary" title="View"
        ><span class="material-icons-outlined md-18"> visibility </span></RoundButton
      >
      <RoundButton
        @click="action('accept')"
        large
        class="btn-action text-success"
        title="Trace Success"
        ><span class="material-icons-outlined md-18"> done </span></RoundButton
      >
      <RoundButton
        @click="toggleForm('reject')"
        large
        class="btn-action text-danger"
        title="Trace Failed"
        ><span class="material-icons-outlined md-18"> close </span></RoundButton
      >
    </template>
    <template #footer>
      <div v-if="shownForm === 'reject'" class="mt-5">
        <textarea
          v-model="reason"
          class="form-control"
          id=""
          rows="2"
          placeholder="Reason"
        ></textarea>
        <button class="btn btn-primary mt-2" @click="action('reject')">Done</button>
      </div>
    </template>
  </QueueItem>
</template>

<script>
import { ref } from "vue";

import QueueItem from "@/components/Shared/QueueItem.vue";
import RoundButton from "@/components/Shared/_RoundButton.vue";

export default {
  name: "DiarisedRequestQueueItem",
  components: {
    QueueItem,
    RoundButton,
  },
  props: {
    account: Object,
  },
  setup(props, { emit }) {
    const shownForm = ref("");
    const reason = ref("");

    const view = () => {
      emit("view", props.account.id);
    };

    const toggleForm = (name) => {
      shownForm.value = shownForm.value !== name ? name : "";
    };

    const action = (outcome) => {
      const payload = {
        accountId: props.account.id,
        outcome,
        reason: reason.value,
      };
      emit("action", payload);
    };

    return {
      view,
      toggleForm,
      shownForm,
      reason,
      action,
    };
  },
};
</script>

<style></style>
