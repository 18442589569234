<template>
  <UserAssignmentsCard>
    <template v-slot:image v-if="userIs('AGENT')">
      <img :src="agentGreetingImage" class="img-fluid" alt="" />
    </template>
    <template
      v-slot:actions
      v-if="
        userIs('AGENT') &&
        (userIn('Active') ||
          userIn('Early Prelegal') ||
          userIn('Late Prelegal'))
      "
    >
      <router-link
        :to="{ name: 'collectPortal' }"
        class="btn btn-outline-light me-2 mt-2"
      >
        Get started</router-link
      >
    </template>
  </UserAssignmentsCard>
</template>

<script>
import UserAssignmentsCard from '@/components/Dash/UserAssignmentsCard.vue';
import agentGreetingImage from '@/assets/agent_greeting_image.svg';

import { userIs, userIn } from '@/helpers/permissions';

export default {
  name: 'AgentAssignmentsCard',
  setup() {
    return {
      agentGreetingImage,
      userIs,
      userIn,
    };
  },
  components: {
    UserAssignmentsCard,
  },
};
</script>
