<template>
  <h2>Left Message</h2>
  <form @submit.prevent="submit">
    <div class="mb-3">
      <label class="form-label">With</label>
      <select v-model="relation" class="form-select" required>
        <option disabled value="">Select relation</option>
        <option value="spouse">Spouse</option>
        <option value="relative">Relative</option>
        <option value="colleague">Colleague</option>
        <option value="other">Other</option>
      </select>
    </div>
    <div class="mb-3">
      <label class="form-label">Name</label>
      <input v-model="name" type="text" class="form-control" />
    </div>
    <button type="submit" role="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import eventTypes from "@/helpers/eventTypes";

export default {
  name: "NRPCLeftMessage",
  emits: ["submit"],
  data() {
    return {
      ...eventTypes.LEFT_MESSAGE,
      relation: "",
      name: "",
    };
  },
  methods: {
    submit() {
      const { name, type, closesAction } = eventTypes.LEFT_MESSAGE;
      const details = {
        relation: this.relation,
        name: this.name,
      };
      this.$emit("submit", { type, name, closesAction, details });
    },
  },
};
</script>
