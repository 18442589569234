const telephonyStore = {
  namespaced: true,
  state() {
    return {
      onCall: false,
      isDialing: false,
      currentNumber: null,
      error: null,
      callBack: false,
    };
  },
  getters: {
    onCall(state) {
      return state.onCall;
    },
    isDialing(state) {
      return state.isDialing;
    },
    error(state) {
      return state.error;
    },
    callBack(state) {
      return state.callBack;
    },
  },
  mutations: {
    callBackOption(state) {
      state.callBack = true;
    },
    dialing(state, number) {
      state.callBack = false;
      state.onCall = false;
      state.isDialing = true;
      state.currentNumber = number;
    },
    answered(state) {
      state.onCall = true;
      state.isDialing = false;
    },
    failed(state, error) {
      state.onCall = false;
      state.isDialing = false;
      state.error = error;
    },
    ended(state) {
      state.onCall = false;
      state.isDialing = false;
    },
    clearErrors(state) {
      state.error = null;
    },
    resetCallBackOption(state) {
      state.callBack = false;
    },
  },
  actions: {
    async endCall({ commit }) {
      commit('ended');
      // window.Phone.HangUpCall();
    },
    async logoff() {
      // await window.AgentWorkspace.LogOff();
    },

    // eslint-disable-next-line no-unused-vars
    async login({ commit }, payload) {
      commit('clearErrors');

      //
      // TODO - get agent telephony details
      //

      // eslint-disable-next-line no-unused-vars
      const authentication = {
        Tenant: 'PBX26TestAcc',
        ApiKey: '4f1f83efc414b558210e1a333e311cdc',
        LibraryKey: '003e9872-9c68-11eb-a20c-061cf12a',
      };

      // eslint-disable-next-line no-unused-vars
      // const EuphoriaPhoneObj = {
      //   WssServer: 'pbx26.euphoria.co.za',
      //   SipUsername: '112-PBX26TestAcc',
      //   SipPassword: '2Fd883b14B33',
      //   $StatusBox: window.$('#stBox'),
      // };
      // await window.Phone.Start(EuphoriaPhoneObj, authentication);
    },
    call({ commit }, payload) {
      //
      // TODO - Remove hard coded number & uncomment endCall, strip number of dashes, brackets
      //

      commit('clearErrors');
      commit('dialing');

      commit('answered');
      // window.Phone.Dial(payload);
      // window.Phone.Dial('08000000000');

      // window.Phone.Dial('0769569147');
      console.log(payload);
      // window.Phone.Events.OnCallFailure(() => commit('callBackOption'));
      // window.Phone.Events.OnCallTermination((calline) => {
      //   commit('ended');
      //   commit('callBackOption');
      //   console.log(calline);
      // });
      // window.Phone.Events.OnCallAccept(() => {
      //   console.log(window.Phone);
      //   console.log('**********');
      // });
    },
    resetCallBack({ commit }) {
      commit('resetCallBackOption');
    },
  },
};

export default telephonyStore;
