import axios from 'axios';

const queueStore = {
  namespaced: true,
  state() {
    return {
      queues: [],
      activeQueue: null,
      isLoading: false,
      isQueueLoading: false,
      error: null,
    };
  },
  getters: {
    activeQueue(state) {
      return state.activeQueue;
    },
    isQueueLoading(state) {
      return state.isQueueLoading;
    },
    queues(state) {
      return state.queues;
    },
    isLoading(state) {
      return state.isLoading;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    queueRequest(state) {
      state.error = null;
      state.isQueueLoading = true;
    },
    queueLoaded(state, queue) {
      state.activeQueue = queue;
      state.isQueueLoading = false;
    },
    queueRequestFailed(state, error) {
      state.error = error;
      state.isQueueLoading = false;
    },
    queuesRequest(state) {
      state.error = null;
      state.isLoading = true;
    },
    queuesLoaded(state, queues) {
      state.queues = queues;
      state.isLoading = false;
    },
    queuesRequestFailed(state, error) {
      state.error = error;
      state.isLoading = false;
    },
  },
  actions: {
    async getQueue({ commit }, id) {
      commit('queueRequest');
      try {
        const queue = (await axios.get(`/api/queues/${id}`)).data;
        commit('queueLoaded', queue);
      } catch (error) {
        commit('queueRequestFailed', { message: error.response.data.error });
      }
    },
    async getQueues({ commit }) {
      commit('queuesRequest');
      try {
        const queues = (await axios.get('api/queues')).data;
        commit('queuesLoaded', queues);
      } catch (error) {
        commit('queuesRequestFailed', error.message);
      }
    },
  },
};

export default queueStore;
