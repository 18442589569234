<template>
  <Queue idKey="id" sortKey="entry_date" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Collections </template>
    <template #title> Refusal </template>
    <template #default="{ item }">
      <RefusalQueueItem
        :account="item"
        :teamAgents="teamAgents"
        @pushToPool="onPushToPool(item.id, $event)"
        @pushToAgent="onPushToAgent(item.id, $event)"
        @pushToLegal="onPushToLegal(item.id, $event)"
        @note="onNote"
      />
    </template>
  </Queue>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

import { createNote } from "@/api/account";
import useActionQueue from "@/composables/useActionQueue";
// import useAccountActions from '@/composables/useAccountActions';
import Queue from "@/components/Shared/Queue.vue";
import RefusalQueueItem from "@/components/Supervisor/RefusalQueueItem.vue";
import {
  fetchRefusalAccounts,
  pushToPool,
  pushToAgent,
  referToLegal,
} from "@/api/refusal";

export default {
  name: "RefusalQueue",
  components: {
    Queue,
    RefusalQueueItem,
  },
  emits: ["view"],
  setup() {
    const { accounts, isLoading, removeAccount } = useActionQueue(fetchRefusalAccounts);

    const teamAgents = ref([]);
    onMounted(async () => {
      teamAgents.value = (await axios.get("api/positions/collection-agents")).data;
    });

    const onPushToPool = async (id, { note }) => {
      const undo = removeAccount(id);
      try {
        await pushToPool(id, note);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onPushToAgent = async (id, { agentId, note }) => {
      const undo = removeAccount(id);
      try {
        await pushToAgent(id, agentId, note);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onNote = ({ accountId, note }) => createNote(accountId, note);

    const onPushToLegal = async (id, payload) => {
      const undo = removeAccount(id);
      try {
        await referToLegal(id, payload);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    return {
      accounts,
      isLoading,
      teamAgents,
      onPushToPool,
      onPushToAgent,
      onPushToLegal,
      onNote,
    };
  },
};
</script>

<style></style>
