import axios from 'axios';

const collectionStore = {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      isCollectionLoading: false,
      error: null,
      outboundCollection: false,
      accountId: null,
      actionId: null,
    };
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    outboundCollection(state) {
      return state.outboundCollection;
    },
    actionId(state) {
      return state.actionId;
    },
    accountId(state) {
      return state.accountId;
    },
    isCollectionLoading(state) {
      return state.isCollectionLoading;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    treatmentRequest(state) {
      state.error = null;
      state.isLoading = true;
    },
    treatmentSuccess(state) {
      state.isLoading = false;
    },
    treatmentFailed(state, error) {
      state.error = error;
      state.isLoading = false;
    },
    outboundCollectionBegun(state) {
      state.outboundCollection = true;
    },
    outboundCollectionEnded(state) {
      state.outboundCollection = false;
    },
    getNextAccountRequest(state) {
      state.error = null;
      state.isCollectionLoading = true;
    },
    getNextAccountSuccess(state, { accountId, actionId }) {
      state.accountId = accountId;
      state.actionId = actionId;
      state.isCollectionLoading = false;
    },
    getNextAccountFailure(state, error) {
      state.error = error;
      state.isCollectionLoading = false;
    },
  },
  actions: {
    async confirmOutboundAccountTreatment({ commit }, payload) {
      commit('treatmentRequest');
      try {
        const { status } = await axios.post('/api/treatment', payload);

        if (status >= 200 && status < 300) {
          commit('treatmentSuccess');
          return true;
        }
      } catch (error) {
        commit(
          'treatmentFailed',
          error?.response?.data?.message || error.message
        );
      }
      return false;
    },
    async confirmInboundAccountTreatment({ commit }, payload) {
      commit('treatmentRequest');
      try {
        const { status } = await axios.post('/api/treatment/inbound', payload);

        if (status >= 200 && status < 300) {
          commit('treatmentSuccess');
          return true;
        }
      } catch (error) {
        commit(
          'treatmentFailed',
          error.response.data?.message || error.message
        );
      }
      return false;
    },
    async getNextAccount({ commit }) {
      commit('getNextAccountRequest');
      try {
        const { account_id: accountId, action_id: actionId } = (
          await axios.get('/api/collection')
        ).data;
        commit('getNextAccountSuccess', { accountId, actionId });
      } catch (error) {
        commit('getNextAccountFailure', error);
      }
    },
    setNextAccount({ commit }, { actionId, accountId }) {
      commit('getNextAccountSuccess', { accountId, actionId });
    },
    readyOutboundCollection({ commit }) {
      commit('outboundCollectionBegun');
    },
    finishOutboundCollection({ commit }) {
      commit('outboundCollectionEnded');
    },
  },
};

export default collectionStore;
