<template>
  <Page>
    <!--  -->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5 class="mt-5 mb-4">CTPS</h5>

          <div class="card">
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-md-2">
                  <label for="" class="form-label">From</label>
                  <input v-model="startDate" type="date" class="form-control" />
                  <button class="btn btn-primary mt-3" @click="getCTPs">View</button>
                </div>
                <div class="col-md-2">
                  <label for="" class="form-label">To</label>
                  <input v-model="endDate" type="date" class="form-control" />
                </div>
              </div>
              <table class="table table-hover align-middle mt-4">
                <thead>
                  <tr>
                    <th scope="col">Username</th>
                    <th scope="col">No. of CTPs</th>
                    <th scope="col">Money in Bank</th>
                    <th scope="col">Arrears Contribution</th>
                  </tr>
                </thead>
                <tbody v-if="!isLoading && ctpList.length">
                  <tr v-for="(agent, index) in ctpList" :key="index">
                    <td>{{ agent.username }}</td>
                    <td>{{ agent.ctp_count }}</td>
                    <td>{{ formatCurrency(agent.mib) }}</td>
                    <td>{{ formatCurrency(agent.arrears_contrib) }}</td>
                  </tr>
                </tbody>
                <div class="col" v-if="isLoading">
                  <LoadingSpinner />
                </div>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import { ref, onMounted } from "vue";
import Page from "@/components/Shared/_Page.vue";
import axios from "axios";
import { formatCurrency } from "@/helpers/format";
import { getISODateString } from "@/helpers/datetime";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

export default {
  components: { Page, LoadingSpinner },
  setup() {
    const isLoading = ref(true);
    const today = ref(new Date());
    const endDate = ref(getISODateString(new Date()));
    const startDate = ref(
      getISODateString(new Date(today.value.getFullYear(), today.value.getMonth(), 1))
    );
    const ctpList = ref([]);

    const getCTPs = async () => {
      isLoading.value = true;
      ctpList.value = (
        await axios.get(`/api/honoured-ctps`, {
          params: { startDate: startDate.value, endDate: endDate.value },
        })
      ).data;
      isLoading.value = false;
    };

    onMounted(getCTPs);

    return {
      endDate,
      startDate,
      ctpList,
      formatCurrency,
      getCTPs,
      isLoading,
    };
  },
};
</script>

<style></style>
