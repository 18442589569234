<template>
  <SendBankDetails />
  <!-- <AddNumber class="d-none" /> -->
  <!-- <AddPaymentDispute class="d-none" /> -->
  <SendDoc />
  <SendCorpInfo />
</template>

<script>
// import AddNumber from "@/components/Account/ActionPanel/AuxActions/AddNumberModal.vue";
import SendBankDetails from "@/components/Account/ActionPanel/AuxActions/SendBankDetails.vue";
// import AddPaymentDispute from "@/components/Account/ActionPanel/AuxActions/AddPaymentDisputeModal.vue";
import SendDoc from "@/components/Account/ActionPanel/AuxActions/SendDocModal.vue";
import SendCorpInfo from "@/components/Account/ActionPanel/AuxActions/SendCorpInfoModal.vue";

export default {
  name: "AuxActions",
  props: {
    actionId: Number,
  },
  components: {
    // AddNumber,
    // AddPaymentDispute,
    SendDoc,
    SendCorpInfo,
    SendBankDetails,
    // CreateNote,
  },
  mounted() {},
};
</script>
