<template>
  <h2>Journal</h2>
  <form @submit.prevent="submit">
    <div class="row mb-3">
      <div class="col">
        <label class="form-label">Reason</label>
        <textarea
          class="form-control"
          maxlength="50"
          minlength="5"
          v-model="reason"
          required
        ></textarea>
      </div>
    </div>
    <button type="submit" role="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import eventTypes from '@/helpers/eventTypes';

export default {
  name: 'Journal',
  data() {
    return {
      ...eventTypes.ESCALATE,
      reason: null,
    };
  },
  methods: {
    submit() {
      const { name, type, closesAction } = eventTypes.JOURNAL;
      const details = {
        reason: this.reason,
      };
      this.$emit('submit', { type, name, closesAction, details });
    },
  },
};
</script>
