<template>
  <h2>Payment Dispute</h2>
  <form @submit.prevent="submit">
    <div class="row mb-3">
      <div class="col">
        <label class="form-label">Date</label>
        <input
          v-model="date"
          type="date"
          :max="dateLimit"
          class="form-control"
          required
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label">Amount</label>
        <input v-model.number="amount" type="number" class="form-control" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label">Details</label>
        <input v-model="details" type="text" class="form-control" />
      </div>
    </div>
    <button type="submit" role="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import eventTypes from "@/helpers/eventTypes";

export default {
  name: "RPCPaymentDispute",
  emits: ["submit"],
  data() {
    return {
      ...eventTypes.PAYMENT_DISPUTE,
      date: null,
      amount: 0,
      details: "",
      dateLimit: null,
    };
  },
  mounted() {
    const now = new Date();
    const nowTimeZoneAdjusted = new Date(
      now.getTime() - now.getTimezoneOffset() * 60 * 1000
    );

    const date = nowTimeZoneAdjusted.toISOString().split("T")[0];

    this.dateLimit = date;
  },
  methods: {
    submit() {
      const { name, type, closesAction } = eventTypes.PAYMENT_DISPUTE;
      const details = {
        date: this.date,
        amount: this.amount,
        details: this.details,
      };
      this.$emit("submit", { type, name, closesAction, details });
    },
  },
};
</script>
