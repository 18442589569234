<template>
  <div>
    <form @submit.prevent="onSubmit">
      <div class="mb-3">
        <label class="form-label">Counsellor Name</label>
        <input v-model="name" type="text" class="form-control" />
      </div>
      <div class="mb-3">
        <label class="form-label">Counsellor Number</label>
        <input v-model="number" type="text" class="form-control" />
      </div>
      <div class="mb-3">
        <label class="form-label">Counsellor Contact Number</label>
        <input v-model="tel" type="text" class="form-control" />
      </div>
      <div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'DebtReviewAddCounsellorForm',
  setup(_, { emit }) {
    const name = ref('');
    const number = ref('');
    const tel = ref('');

    const onSubmit = () => {
      emit('submitted', {
        name: name.value,
        number: number.value,
        tel: tel.value,
      });
    };

    return {
      name,
      number,
      tel,
      onSubmit,
    };
  },
};
</script>