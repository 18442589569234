<template>
  <teleport to="#modals">
    <div
      class="modal fade"
      id="editClientModal"
      ref="editClientModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Client</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p class="card-text text-muted me-auto">
              * These changes will reflect in QWise the next time the account is loaded
            </p>

            <Form
              :validation-schema="validateClientEdits"
              @submit="submitClientEdits"
              v-if="!isLoading"
            >
              <div class="form-floating mb-3">
                <Field
                  @change="updateChangeList('firstname', oldClient.cFirstName)"
                  name="firstname"
                  class="form-control"
                  type="text"
                  id="firstname"
                  placeholder="First Name"
                  v-model="client.cFirstName"
                />
                <label for="firstname">First Name</label>
                <ErrorMessage name="firstname" class="error-message" />
              </div>

              <div class="form-floating mb-3">
                <Field
                  @change="updateChangeList('lastname', oldClient.cSurname)"
                  name="lastname"
                  class="form-control"
                  type="text"
                  id="lastname"
                  placeholder="lastname"
                  v-model="client.cSurname"
                />
                <label for="lastname">Last Name</label>
                <ErrorMessage name="lastname" class="error-message" />
              </div>

              <div class="form-floating mb-3">
                <Field
                  @change="updateChangeList('email', oldClient.cEmailAddress)"
                  name="email"
                  class="form-control"
                  type="email"
                  id="email"
                  placeholder="Email"
                  v-model="client.cEmailAddress"
                />
                <label for="email">Email</label>
                <ErrorMessage name="email" class="error-message" />
              </div>

              <div class="form-floating mb-3">
                <Field
                  @change="updateChangeList('Tel Mobile', oldClient.cTelNoMobile)"
                  name="TelNoMobile"
                  class="form-control"
                  type="text"
                  id="TelNoMobile"
                  placeholder="Mobile Number"
                  v-model="client.cTelNoMobile"
                />
                <label for="TelNoMobile">Mobile Number</label>
                <ErrorMessage name="TelNoMobile" class="error-message" />
              </div>

              <div class="form-floating mb-3">
                <Field
                  @change="updateChangeList('Tel Home', oldClient.cTelNoHome)"
                  name="TelNoHome"
                  class="form-control"
                  type="text"
                  id="TelNoHome"
                  placeholder="Home Number"
                  v-model="client.cTelNoHome"
                />
                <label for="TelNoHome">Home Number</label>
                <ErrorMessage name="TelNoHome" class="error-message" />
              </div>

              <div class="form-floating mb-3">
                <Field
                  @change="updateChangeList('Tel Work', oldClient.cTelNoWork)"
                  name="TelNoWork"
                  class="form-control"
                  type="text"
                  id="TelNoWork"
                  placeholder="Work Number"
                  v-model="client.cTelNoWork"
                />
                <label for="TelNoWork">Work Number</label>
                <ErrorMessage name="TelNoWork" class="error-message" />
              </div>

              <div class="form-floating mb-3">
                <Field
                  @change="
                    updateChangeList('Street Address 1', oldClient.cStreetAddress1)
                  "
                  name="streetAddress1"
                  class="form-control"
                  type="text"
                  id="streetAddress1"
                  placeholder="streetAddress1"
                  v-model="client.cStreetAddress1"
                />
                <label for="streetAddress1">Street Address</label>
              </div>

              <div class="form-floating mb-3">
                <Field
                  @change="
                    updateChangeList('Street Address 2', oldClient.cStreetAddress2)
                  "
                  name="streetAddress2"
                  class="form-control"
                  type="text"
                  id="streetAddress2"
                  placeholder="streetAddress2"
                  v-model="client.cStreetAddress2"
                />
                <label for="streetAddress2">Street Address</label>
              </div>

              <div class="form-floating mb-3">
                <Field
                  @change="
                    updateChangeList('Street Address 3', oldClient.cStreetAddress3)
                  "
                  name="streetAddress3"
                  class="form-control"
                  type="text"
                  id="streetAddress3"
                  placeholder="streetAddress3"
                  v-model="client.cStreetAddress3"
                />
                <label for="streetAddress3">Suburb</label>
              </div>

              <div class="form-floating mb-3">
                <Field
                  @change="
                    updateChangeList('Street Address 4', oldClient.cStreetAddress4)
                  "
                  name="streetAddress4"
                  class="form-control"
                  type="text"
                  id="streetAddress4"
                  placeholder="streetAddress4"
                  v-model="client.cStreetAddress4"
                />
                <label for="streetAddress4">Town/City</label>
              </div>

              <div class="form-floating mb-3">
                <Field
                  @change="updateChangeList('Postal Code', oldClient.cPostCodeStreet)"
                  name="postalCode"
                  class="form-control"
                  type="text"
                  id="postalCode"
                  placeholder="postalCode"
                  v-model="client.cPostCodeStreet"
                />
                <label for="postalCode">Postal Code</label>
              </div>

              <button class="btn btn-primary">Submit</button>
            </Form>

            <LoadingSpinner v-else />
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "editClientModal",
  components: { Form, Field, ErrorMessage, LoadingSpinner },

  data() {
    const validateClientEdits = yup.object({
      firstname: yup.string().nullable().required("First name is required"),
      lastname: yup.string().nullable().required("Last name is required"),
      email: yup.string().nullable().email("This is not a valid email address"),
      TelNoHome: yup
        .string()
        .matches(
          /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          {
            message: "Phone not valid",
            excludeEmptyString: true,
          }
        )
        .nullable(),
      TelNoMobile: yup
        .string()
        .matches(
          /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          {
            message: "Phone not valid",
            excludeEmptyString: true,
          }
        )
        .nullable(),
      TelNoWork: yup
        .string()
        .matches(
          /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          {
            message: "Phone not valid",
            excludeEmptyString: true,
          }
        )
        .nullable(),
    });
    return {
      changeList: [],
      isLoading: false,
      validateClientEdits,
      client: {
        cFirstName: "",
        cSurname: "",
        cEmailAddress: "",
        cStreetAddress1: "",
        cStreetAddress2: "",
        cStreetAddress3: "",
        cStreetAddress4: "",
        cPostCodeStreet: "",
        CltNo: "",
        ilsNum: "",
        //
        cTelNoHome: "",
        cTelNoMobile: "",
        cTelNoWork: "",
      },
      oldClient: {
        cFirstName: "",
        cSurname: "",
        cEmailAddress: "",
        cStreetAddress1: "",
        cStreetAddress2: "",
        cStreetAddress3: "",
        cStreetAddress4: "",
        cPostCodeStreet: "",
        CltNo: "",
        ilsNum: "",
        //
        cTelNoHome: "",
        cTelNoMobile: "",
        cTelNoWork: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      account: "account/activeAccount",
      positions: "auth/permissions",
      actionId: "collection/actionId",
    }),
  },
  methods: {
    updateChangeList(field, value) {
      const edit = {};
      edit[field] = value;
      this.changeList.push(edit);
    },
    async submitClientEdits() {
      this.isLoading = true;
      const { client } = this;
      const { oldClient } = this;
      const { actionId } = this;
      const { changeList } = this;
      client.CltNo = this.account.customer_details.ClientNumber;
      const userPositionId = this.positions.filter(
        (p) =>
          p.role.toUpperCase() === "AGENT" ||
          "INBOUND AGENT" ||
          "ADMIN" ||
          "SUPERVISOR" ||
          "MANAGER" ||
          "SUPER USER"
      )[0].user_position_id;
      try {
        const response = await axios.post(`/api/accounts/${this.account.id}`, {
          client,
          oldClient,
          changeList,
          userPositionId,
          actionId,
        });
        if (response.status === 200) {
          this.$store.dispatch("notification/push", { body: `Info Update Sent` });
        } else {
          this.$store.dispatch("notification/push", {
            body: `Info Update Failed`,
            type: "error",
          });
        }
      } catch {
        this.$store.dispatch("notification/push", {
          body: `Something went wrong`,
          type: "error",
        });
      }
      this.changeList = [];
      this.isLoading = false;
    },
  },
  mounted() {
    this.client.cFirstName = this.account.customer_details.Firstname;
    this.client.cSurname = this.account.customer_details.Surname;
    this.client.cEmailAddress = this.account.customer_details.Email;
    this.client.cStreetAddress1 = this.account.customer_details.StreetAddress1;
    this.client.cStreetAddress2 = this.account.customer_details.StreetAddress2;
    this.client.cStreetAddress3 = this.account.customer_details.StreetAddress3;
    this.client.cStreetAddress4 = this.account.customer_details.StreetAddress4;
    this.client.cPostCodeStreet = this.account.customer_details.StreetPostCode;
    this.client.CltNo = this.account.customer_details.Surname;
    this.client.ilsNum = this.account.account_details.ExternalAccountRef;
    this.client.cTelNoMobile = this.account.customer_details?.Phone_Cell || "";
    this.client.cTelNoHome = this.account.customer_details?.Phone_Home || "";
    this.client.cTelNoWork = this.account.customer_details?.Phone_Work || "";
    const temp = { ...this.client };
    this.oldClient = temp;
  },
};
</script>

<style scoped></style>
