<template>
  <button v-bind="$attrs" class="btn loading-button">
    <div v-if="isLoading" class="lds-dual-ring"></div>
    <slot></slot>
  </button>
  <!--  -->
</template>

<script>
export default {
  name: 'LoadingButton',
  props: {
    isLoading: Boolean,
  },
};
</script>


<style>
.loading-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  display: inline-block;
  margin-right: 0.5rem;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  border: 0.15em solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>