// import axios from 'axios';
import axios from 'axios';

const sessionStore = {
  namespaced: true,
  state() {
    return {
      currentActivityId: null,
      isActivityTypesLoading: false,
      activityTypes: [],
      error: null,
    };
  },
  mutations: {
    saveActivitySuccess(state, activityId) {
      state.currentActivityId = activityId;
    },
    saveActivityFailed(state, error) {
      state.error = error;
    },
    activityTypesRequest(state) {
      state.error = false;
      state.isActivityTypesLoading = true;
    },
    activityTypesLoaded(state, activityTypes) {
      state.activityTypes = activityTypes;
      state.isActivityTypesLoading = false;
    },
    activityTypesFailed(state, error) {
      state.error = error;
      state.isActivityTypesLoading = true;
    },
  },
  getters: {
    currentActivityId(state) {
      return state.currentActivityId;
    },
    currentActivity(state) {
      const a = state.activityTypes.filter((t) => t.id === state.currentActivityId);
      return a && a.length ? a[0]?.activity_type : null;
    },
    activityTypes(state) {
      return state.activityTypes;
    },
    isActivityTypesLoading(state) {
      return state.isActivityTypesLoading;
    },
  },
  actions: {
    async getActivityTypes({ commit }) {
      commit('activityTypesRequest');
      try {
        const activityTypes = (await axios.get('/api/activity/types')).data;
        commit('activityTypesLoaded', activityTypes);
      } catch (error) {
        commit('activityTypesFailed');
      }
    },
    async setActivity({ commit }, activityId) {
      commit('saveActivitySuccess', activityId);
    },
    async saveActivity({ commit }, { userId, activityId }) {
      if (this.currentActivityId === activityId) return true;

      try {
        await axios.post(`/api/users/${userId}/activity`, { activity_type_id: activityId });
        commit('saveActivitySuccess', activityId);
        return true;
      } catch (error) {
        commit('saveActivityFailed', error.message);
        return false;
      }
    },
  },
};

export default sessionStore;
