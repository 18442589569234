export const roleTypes = {
  AGENT: 'AGENT',
  DEBT_REVIEW_AGENT: 'DEBT REVIEW AGENT',
  INBOUND_AGENT: 'INBOUND AGENT',
  ADMIN: 'ADMIN',
  LEGAL_ADMIN: 'LEGAL ADMIN',
  TEAM_LEADER: 'TEAM LEADER',
  SUPERVISOR: 'SUPERVISOR',
  MANAGER: 'MANAGER',
  SUPER_USER: 'SUPER USER',
};

export default {
  roleTypes,
};
