<template>
  <QueueItem :account="account">
    <template #tag>
      <!-- {{ account }} -->
      <span class="small d-block mb-2 text-muted"
        >On {{ account.entry_date.toLocaleString() }} by
        {{ account.username }} (Team {{ account.team }})</span
      >
    </template>
    <template #title>
      {{ account.event_note?.details?.reason || account.event_note?.reason }}
    </template>
    <template #body>
      <div class="row">
        <div class="col-4">Reference</div>
        <div class="col-8">{{ account.reference }}</div>
      </div>
      <div class="row">
        <div class="col-4">Arrears</div>
        <div class="col-8">{{ account.arrears_amount }}</div>
      </div>
      <div class="row">
        <div class="col-4">Arrear key</div>
        <div class="col-8">{{ account.arrears_key }}</div>
      </div>
      <div class="row">
        <div class="col-4">Owner</div>
        <div class="col-8">{{ account.username }}</div>
      </div>

      <div v-if="action" class="mt-5">
        <select
          v-if="action === 'pushToAgent'"
          class="form-select mb-3"
          v-model="selectedAgent"
        >
          <option disabled value="">Select Agent</option>
          <option
            v-for="agent in agents"
            :value="agent.user_position_id"
            :key="agent.user_position_id"
          >
            {{ agent.username }}
          </option>
        </select>
        <div class="mb-3">
          <textarea
            v-model="note"
            class="form-control"
            id=""
            rows="2"
            placeholder="Note"
          ></textarea>
        </div>
        <button @click="submit" class="btn btn-success me-2">
          <span class="material-icons-outlined md-18"> done </span> Done
        </button>
        <button @click="action = ''" class="btn btn-danger">
          <span class="material-icons-outlined md-18"> close </span> Cancel
        </button>
      </div>
    </template>
    <template #actions>
      <RoundButton
        @click="view(account.id)"
        large
        class="btn-action text-secondary"
        title="View"
        ><span class="material-icons-outlined md-18">
          visibility
        </span></RoundButton
      >

      <RoundButton
        @click="action = 'pushToPool'"
        large
        class="btn-action text-primary"
        title="Push to pool"
      >
        <span class="material-icons-outlined md-18"> arrow_forward </span>
      </RoundButton>

      <RoundButton
        @click="action = 'pushToAgent'"
        large
        class="btn-action text-primary"
        title="Push to agent"
      >
        <span class="material-icons-outlined md-18"> headset_mic </span>
      </RoundButton>

      <RoundButton
        @click="action = 'proposeWriteoff'"
        large
        title="Propose Writeoff"
        class="btn-action text-primary"
      >
        <span class="material-icons-outlined md-18"> layers_clear </span>
      </RoundButton>
    </template>
  </QueueItem>
</template>

<script>
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import RoundButton from '@/components/Shared/_RoundButton.vue';

export default {
  emits: ['view', 'action'],
  props: { account: Object, agents: Object },
  components: {
    QueueItem,
    RoundButton,
  },
  data() {
    return {
      action: '',
      note: '',
      selectedAgent: '',
    };
  },
  methods: {
    submit() {
      const payload = { note: this.note };
      if (this.action === 'pushToPool' || this.action === 'pushToAgent')
        payload.action = 'push';

      if (this.action === 'pushToAgent')
        payload.userPositionId = this.selectedAgent;

      if (this.action === 'proposeWriteoff') payload.action = 'writeoff';

      this.$emit('action', { accountId: this.account.id, payload });
    },
    view(id) {
      this.$store.dispatch('account/clearCurrentAccount');
      this.$emit('view', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-action {
  margin-left: 1rem;
}
</style>