<template>
  <div class="row">
    <div class="col-4" v-for="(queue, index) in filteredQueues" :key="index">
      <QueueCard :queue="queue" />
    </div>
  </div>
</template>

<script>
import QueueCard from '@/components/Queue/QueueCard.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'adminQueues',
  components: {
    QueueCard,
  },
  computed: {
    ...mapGetters({
      queues: 'queue/queues',
    }),
    filteredQueues() {
      return this.queues.filter((q) => q.labels.includes('admin'));
    },
  },
};
</script>

<style></style>
