<template>
  <Queue
    idKey="id"
    sortKey="entry_date"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #title> Trace </template>
    <template v-slot:default="{ item: account }">
      <TraceQueueItem
        :account="account"
        @action="action"
        @note="onNote"
        @view="onView"
      />
    </template>
  </Queue>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { createNote } from '@/api/account';

import Queue from '@/components/Shared/Queue.vue';
import TraceQueueItem from '@/components/Admin/TraceQueueItem.vue';

export default {
  name: 'TraceQueue',
  components: {
    Queue,
    TraceQueueItem,
  },
  setup(_, { emit }) {
    const queue = ref([]);
    const accounts = ref([]);
    const isLoading = ref(true);
    const store = useStore();

    const removeAccount = (accountId) => {
      accounts.value = accounts.value.filter((a) => a.id !== accountId);
    };

    const onView = (id) => {
      store.dispatch('account/clearCurrentAccount');
      emit('view', id);
    };

    const onNote = (payload) => {
      createNote(payload.accountId, payload.note);
    };

    const getData = async () => {
      accounts.value = [(await axios.get('api/queues/trace')).data].map(
        (account) => ({
          ...account,
          entry_date: new Date(account.entry_date),
        })
      );
      isLoading.value = false;
    };

    const action = async (payload) => {
      store.dispatch('account/clearCurrentAccount');
      try {
        removeAccount(payload.accountId);
        await axios.patch(`api/queues/trace/${payload.accountId}`, {
          action: payload.action,
        });
      } catch (error) {
        console.log(error.message);
      }
      getData();
    };

    onMounted(getData);

    return {
      queue,
      accounts,
      isLoading,
      onView,
      onNote,
      getData,
      action,
    };
  },
};
</script>

<style lang="scss" scoped>
.account-view,
.queue-view {
  // background: green;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
