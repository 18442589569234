import axios from 'axios';

export const positionService = {
  async assignPosition(positionId, userId) {
    try {
       await axios.post(`/api/userpositions/${positionId}`, {assigned_to: userId} );
      return true
    } catch {
      return false
    }
  },

  async createPosition(teamId, roleId, reportsTo) {
    try{
      const formData = new FormData();
      formData.set('reports_to', reportsTo);
      formData.set('role_id', roleId);
      formData.set('team_id', teamId);
      const newPosition = (
        await axios.post('api/positions', formData, {
          headers: { 'Content-type': 'multipart/form-data' },
        })
      ).data;
      return newPosition;
    } catch {
      return false
    }
  },
};

export default { positionService };
