<template>
  <Queue idKey="id" sortKey="entry_date" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Legal </template>
    <template #title> Referral </template>
    <template #default="{ item }">
      <ReferralQueueItem
        :account="item"
        @accept="onAccept(item.id)"
        @reject="onReject(item.id)"
        @note="onNote"
      />
    </template>
  </Queue>
</template>

<script>
import useActionQueue from "@/composables/useActionQueue";
import Queue from "@/components/Shared/Queue.vue";
import ReferralQueueItem from "@/components/Legal/ReferralQueueItem.vue";
import { createNote } from "@/api/account";
import {
  fetchReferralAccounts,
  acceptLegalReferral,
  rejectLegalReferral,
} from "@/api/legal";

export default {
  components: { Queue, ReferralQueueItem },
  emits: ["view"],
  setup() {
    // const { accounts, isLoading, updateAccount, removeAccount } =
    const { accounts, isLoading, removeAccount } = useActionQueue(fetchReferralAccounts);

    const onAccept = async (id) => {
      const undo = removeAccount(id);
      try {
        await acceptLegalReferral(id);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onReject = async (id) => {
      const undo = removeAccount(id);
      try {
        await rejectLegalReferral(id);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onNote = (payload) => {
      createNote(payload.accountId, payload.note);
    };

    return {
      accounts,
      isLoading,
      onAccept,
      onReject,
      onNote,
    };
  },
};
</script>
