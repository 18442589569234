<template>
  <Queue idKey="id" sortKey="entry_date" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Debt Review </template>
    <template #title> Application </template>
    <template #controls>
      <input v-model="query" type="text" class="form-control" placeholder="Search..." />
    </template>
    <template v-slot:default="{ item: account }">
      <DebtReviewApplicationQueueItem
        :account="account"
        @action="action"
        @note="onNote"
        @addCounsellor="addCounsellor"
      />
    </template>
  </Queue>
</template>

<script>
import axios from "axios";
import { ref, onMounted } from "vue";

import { createNote } from "@/api/account";
import { addCounsellorDetails } from "@/api/debtReview";

import useActionQueueSearch from "@/composables/useActionQueueSearch";

import Queue from "@/components/Shared/Queue.vue";
import DebtReviewApplicationQueueItem from "@/components/Admin/DebtReviewApplicationQueueItem.vue";

export default {
  name: "DebtReviewApplicationQueue",
  components: {
    Queue,
    DebtReviewApplicationQueueItem,
  },
  setup() {
    const accounts = ref([]);
    const isLoading = ref(true);

    const getAccounts = async () => {
      accounts.value = (
        await axios.get("api/admin/queues/debt-review-application")
      ).data.map((account) => ({
        ...account,
        entry_date: new Date(account.entry_date),
      }));
      isLoading.value = false;
    };

    onMounted(getAccounts);

    const { query, accountsMatchingQuery } = useActionQueueSearch(accounts);

    const onNote = ({ accountId, note }) => createNote(accountId, note);

    const removeAccount = (accountId) => {
      accounts.value = accounts.value.filter((a) => a.id !== accountId);
    };

    return {
      query,
      accounts: accountsMatchingQuery,
      isLoading,
      onNote,
      getAccounts,
      removeAccount,
    };
  },
  methods: {
    async action(event) {
      const { accountId, payload } = event;
      this.removeAccount(accountId);
      this.$store.dispatch("account/clearCurrentAccount");

      try {
        const url = `/api/admin/queues/debt-review-application/${accountId}`;
        await axios.patch(url, payload);
      } catch (error) {
        this.$store.dispatch("notification/push", {
          body: `Action Failed`,
          type: "error",
        });
        this.getAccounts();
      }
    },
    addCounsellor({ accountId, details }) {
      const thisAccount = this.accounts.find((acc) => acc.id === accountId);
      thisAccount.counsellor_details = JSON.stringify(details);
      addCounsellorDetails(accountId, details);
    },
  },
};
</script>
