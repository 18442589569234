<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="data-title">Outstanding Balance</div>
          <div class="h2 data-value">
            {{ formatCurrency(account.account_details.OutstandingBalance) }}
          </div>
        </div>
        <div class="col">
          <div class="data-title">Term Age</div>
          <div class="h2 data-value">
            {{ account.account_details.TermAge }}/{{
              account.account_details.Term
            }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="data-title">Amount Disbursed</div>
          <div class="h4 data-value">
            {{ formatCurrency(account.account_details.AmountDisbursed) }}
          </div>
        </div>
        <div class="col">
          <div class="data-title">Interest Rate</div>
          <div class="h4 data-value">
            {{ parseFloat(account.account_details.InterestRate).toFixed(2) }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/format';

export default {
  name: 'AccountLoanInfo',
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { formatCurrency };
  },
};
</script>

<style lang="scss" scoped></style>
