<template>
  <Queue
    idKey="id"
    sortKey="entry_date"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #subtitle> Legal </template>
    <template #title> Process</template>
    <template #default="{ item }">
      <ProcessQueueItem :account="item" @trace="onTrace(item.id)" />
    </template>
  </Queue>
</template>


<script>
import useActionQueue from '@/composables/useActionQueue';
import Queue from '@/components/Shared/Queue.vue';
import ProcessQueueItem from '@/components/Legal/ProcessQueueItem.vue';
import { fetchProcessAccounts, requestLegalTrace } from '@/api/legal';

export default {
  components: { Queue, ProcessQueueItem },
  emits: ['view'],
  setup() {
    const { accounts, isLoading, removeAccount } =
      useActionQueue(fetchProcessAccounts);

    const onTrace = async (accountId) => {
      const undo = removeAccount(accountId);
      try {
        await requestLegalTrace(accountId);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    return {
      accounts,
      isLoading,
      onTrace,
    };
  },
};
</script>