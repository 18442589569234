<template>
  <div class="card mb-4">
    <div class="card-body">
      <div class="row">
        <div class="col-12 d-flex">
          <div class="flex-grow-1 pe-4">
            <div class="text-muted">
              <slot name="tag"></slot>
            </div>
            <h5 class="my-3">
              <slot name="title"></slot>
            </h5>
            <slot name="body"></slot>
          </div>

          <div class="d-flex flex-column align-items-end">
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-body {
  padding-bottom: 2.5rem;
}
</style>