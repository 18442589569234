<template>
  <div>
    <button
      type="button"
      class="btn btn-lg btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#CreateUserModal"
      v-on:click="fetchData"
    >
      Create
    </button>
    <div
      class="modal fade"
      id="CreateUserModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add User</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <CreateUserForm
              @new-user="newUserAdded"
              v-if="step == 'createUser'"
            />
            <CreateUserPositionAssign
              v-if="step == 'positionAssign'"
              :user="user"
              @finalise-user="finaliseUser"
              @position-assigned="positionAssigned"
            />
            <CreateUserFinalise
              v-if="step == 'confirmUser'"
              :user="user"
              :position="assignedPosition"
            />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CreateUserForm from './CreateUserForm.vue';
import CreateUserFinalise from './CreateUserFinalise.vue';
import CreateUserPositionAssign from './CreateUserPositionAssign.vue';

export default {
  name: 'CreateUserModal',
  components: { CreateUserForm, CreateUserFinalise, CreateUserPositionAssign },
  data() {
    return {
      roleOptions: [],
      formData: new FormData(),
      user: {},
      step: 'createUser',
      assignedPosition: '',
    };
  },
  methods: {
    newUserAdded(newUser) {
      this.user = newUser;
      this.step = 'positionAssign';
    },
    finaliseUser() {
      this.step = 'confirmUser';
    },
    fetchData() {
      this.step = 'createUser';
      this.$store.dispatch('team/getTeamList');
      // Get all Unassigned Positions
      // this.$store.dispatch("team/getPositions", { assigned: false });
      // Get roles
      this.$store.dispatch('auth/getRoleList');
      this.$store.dispatch('team/getAllPositions');
      this.$store.dispatch('team/getUnassignedPositions');
    },
    async positionAssigned(position) {
      const url = `api/positions/${position}`;
      this.assignedPosition = (await axios.get(url)).data;
      this.finaliseUser();
    },
  },
};
</script>

<style></style>
