<template>
  <Page>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5 class="mt-5 mb-4">Agent Events</h5>
          <div class="card">
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-xl-2">
                  <label v-if="!selectedTeamId" class="form-label">Team</label>
                  <label v-if="selectedTeamId" class="form-label">
                    <a href="#" @click="backToTeamSelect">Team</a>
                    <span> &gt; Agent</span>
                  </label>
                  <div v-if="!selectedTeamId">
                    <select class="form-select" v-model="selectedTeamId">
                      <option :value="null" disabled selected>
                        Select a team
                      </option>
                      <option
                        v-for="team in teams"
                        :key="team.id"
                        :value="team.id"
                      >
                        {{ team.name }}
                      </option>
                    </select>
                  </div>
                  <div v-if="selectedTeamId">
                    <AgentSelect
                      :teamId="selectedTeamId"
                      v-model="selectedAgentId"
                    />
                  </div>
                </div>

                <div v-if="selectedAgentId" class="col-xl-4">
                  <label v-if="!fromDate" class="form-label">From</label>
                  <label v-if="fromDate" class="form-label">
                    <a href="#" @click="backToFromDateSelect">From</a>
                    <span> / To</span>
                  </label>

                  <div v-if="!fromDate" class="input-group">
                    <input
                      v-model="fromDate"
                      type="date"
                      class="form-control"
                    />
                    <input
                      v-model="fromTime"
                      type="time"
                      min="08:00"
                      max="19:30"
                      class="form-control"
                      required
                    />
                  </div>

                  <div v-if="fromDate" class="input-group">
                    <input v-model="toDate" type="date" class="form-control" />
                    <input
                      v-model="toTime"
                      type="time"
                      min="08:00"
                      max="19:30"
                      class="form-control"
                      required
                    />
                  </div>
                </div>

                <div v-if="events" class="col-xl-3">
                  <label for="" class="form-label">Account</label>
                  <div class="input-group">
                    <input
                      v-model="accountInput"
                      type="text"
                      class="form-control"
                      placeholder="Reference #"
                    />
                    <button
                      :disabled="!accountInput"
                      @click="addAnotherAccountFilter"
                      class="btn btn-outline-secondary"
                      type="button"
                    >
                      <span class="material-icons-outlined me-0"> add </span>
                      Filter
                    </button>
                  </div>
                </div>
                <div v-if="events" class="col-xl-3">
                  <label for="" class="form-label">Event</label>
                  <div class="input-group">
                    <select v-model="eventSelect" class="form-select">
                      <option :value="null" selected disabled>
                        Choose Event...
                      </option>
                      <option
                        v-for="eventType in eventTypes"
                        :key="eventType"
                        :value="eventType"
                      >
                        {{ eventType }}
                      </option>
                    </select>
                    <button
                      :disabled="!eventSelect"
                      @click="addAnotherTypeFilter"
                      class="btn btn-outline-secondary"
                      type="button"
                    >
                      <span class="material-icons-outlined me-0"> add </span>
                      Filter
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="Object.keys(filters).length" class="row mb-4">
                <div class="col-12">
                  <label for="" class="form-label">Active Filters</label>
                  <div class="active-filters">
                    <button
                      v-for="(values, key) in filters"
                      :key="key"
                      @click="removeFilter(key)"
                      class="btn btn-filter"
                    >
                      <span>{{ key }} = </span>
                      <span
                        v-for="value in values"
                        :key="value"
                        class="filter-value"
                        >{{ value }}</span
                      >

                      <span class="icon material-icons-outlined md-18">
                        remove
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <table v-if="events && !isLoading" class="table align-middle">
                    <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Occured</th>
                        <th scope="col">Reference</th>
                        <th scope="col">Action ID</th>
                        <th scope="col">Note</th>
                      </tr>
                    </thead>
                    <tbody v-if="events?.length">
                      <tr v-for="(event, i) in filteredEvents" :key="i">
                        <td>
                          <button
                            @click="addFilter('type', event.type)"
                            class="btn btn-filter"
                          >
                            {{ event.type }}
                            <span class="icon material-icons-outlined md-18">
                              add
                            </span>
                          </button>
                        </td>
                        <td>{{ event.occured }}</td>
                        <td>
                          <button
                            @click="addFilter('reference', event.reference)"
                            class="btn btn-filter"
                          >
                            {{ event.reference }}
                            <span class="icon material-icons-outlined md-18">
                              add
                            </span>
                          </button>
                        </td>
                        <td>
                          <button
                            v-if="event.action_id"
                            @click="addFilter('action_id', event.action_id)"
                            class="btn btn-filter"
                          >
                            {{ event.action_id }}
                            <span class="icon material-icons-outlined md-18">
                              add
                            </span>
                          </button>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <EventNote
                            :content="event.note"
                            :collapsable="true"
                          />
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="5">No Events</td>
                      </tr>
                    </tbody>
                  </table>
                  <LoadingSpinner v-if="isLoading" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { getISOString } from '@/helpers/datetime';
import { formatDate } from '@/helpers/format';
import Page from '@/components/Shared/_Page.vue';
import AgentSelect from '@/components/Teams/AgentSelect.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
import EventNote from '@/components/Shared/EventNote.vue';

export default {
  name: 'AgentEvents',
  components: { Page, AgentSelect, LoadingSpinner, EventNote },
  setup() {
    return {
      getISOString,
      formatDate,
    };
  },
  data() {
    return {
      isLoading: false,
      selectedTeamId: null,
      selectedAgentId: null,
      teams: [],
      fromDate: null,
      toDate: null,
      fromTime: '08:00',
      toTime: '19:30',
      events: null,
      eventSelect: null,
      accountInput: null,
      filters: {},
    };
  },
  computed: {
    ...mapGetters({
      permissions: 'auth/permissions',
    }),
    eventTypes() {
      return [...new Set(this.events?.map((e) => e.type) ?? [])].sort();
    },
    filteredEvents() {
      let events = [...this.events];
      Object.entries(this.filters).forEach(([key, values]) => {
        events = events.filter((e) => {
          console.log(
            `Comparing ${JSON.stringify(values)} to ${
              e[key]
            }, typeof e[key] = ${typeof e[key]}, ${values.includes(e[key])}`
          );
          return values.includes(e[key]);
        });
      });
      return events;
    },
  },
  methods: {
    addFilter(key, value) {
      if (this.filters[key]?.includes(value)) return;
      if (!this.filters[key]) this.filters[key] = [];
      this.filters[key].push(value);
    },
    removeFilter(key) {
      delete this.filters[key];
    },
    backToTeamSelect() {
      this.selectedTeamId = null;
      this.selectedAgentId = null;
      this.events = null;
    },
    backToFromDateSelect() {
      this.fromDate = null;
      this.toDate = null;
    },
    addAnotherTypeFilter() {
      this.addFilter('type', this.eventSelect);
      this.eventSelect = null;
    },
    addAnotherAccountFilter() {
      this.addFilter('reference', parseInt(this.accountInput, 10));
    },
    async loadAgentEvents() {
      if (
        !this.selectedAgentId ||
        !this.fromDate ||
        !this.toDate ||
        !this.fromTime ||
        !this.toTime
      )
        return;
      try {
        this.isLoading = true;
        const { selectedAgentId, fromDate, toDate, fromTime, toTime } = this;

        this.events = (
          await axios.get(`/api/userpositions/${selectedAgentId}/events`, {
            params: {
              from: getISOString(`${fromDate} ${fromTime}`),
              to: getISOString(`${toDate} ${toTime}`),
            },
          })
        ).data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    selectedAgentId() {
      this.loadAgentEvents();
    },
    fromDate() {
      this.loadAgentEvents();
    },
    toDate() {
      this.loadAgentEvents();
    },
    fromTime() {
      this.loadAgentEvents();
    },
    toTime() {
      this.loadAgentEvents();
    },
  },
  created() {
    this.teams = this.permissions
      .filter((p) => ['SUPERVISOR', 'MANAGER'].includes(p.role.toUpperCase()))
      .map((p) => ({ id: p.team_id, name: p.team }));
  },
};
</script>

<style lang="scss" scoped>
table {
  table-layout: fixed;
}
td:first-child {
  white-space: nowrap;
}

.btn-filter {
  border: none;
  padding: 0.25rem 0rem 0.25rem 1rem;
  box-shadow: 0px 4px 6px rgba(36, 36, 36, 0);
  background-color: rgba(255, 255, 255, 0);
  .icon {
    opacity: 0;
    transform: translateX(-50%);
    transition: all 150ms ease;
  }
  &:hover {
    .icon {
      opacity: 1;
      transform: translateX(0%);
    }
  }
}

.active-filters {
  .btn-filter {
    background-color: rgba(15, 82, 186, 1);
    color: #fff;
    & + .btn-filter {
      margin-left: 0.25rem;
    }
  }
  .filter-value + .filter-value::before {
    content: ', ';
  }
}

.table {
  .btn-filter {
    background-color: rgba(255, 255, 255, 1);
    &:hover {
      box-shadow: 0px 4px 6px rgba(34, 81, 155, 0.25);
    }
  }
}
</style>