<template>
  <div class="card">
    <div class="card-header" v-if="hasHeaderSlot">
      <slot name="header"></slot>
    </div>
    <div class="card-body">
      <slot name="body"></slot>
    </div>
    <div class="card-footer" v-if="hasFooterSlot">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: '_BaseCard',
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  margin-top: 1.5em;
  border: none;

  .card-header {
    background-color: transparent;
    font-weight: 500;
    border-bottom: none;
  }

  .card-display-text {
    font-size: 2rem;
  }
}
</style>
