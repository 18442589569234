import axios from 'axios';

const endpoint = '/api/account';

export const createNote = async (accountId, note) => {
  await axios.post(`${endpoint}/${accountId}/notes`, { note });
};

export const createEvent = async (accountId, payload) => {
  console.log(
    `Create event on account ${accountId}, payload - ${JSON.stringify(payload)}`
  );
};

//
// Defer Account
// payload structure = { action: "diarise", data: {date: '2022-03-23'}}
export const deferAccount = async (accountId, payload) => {
  await axios.patch(`/api/accounts/${accountId}`, payload);
};
