<template>
  <teleport to="#modals">
    <div
      ref="collectSearchModal"
      class="modal fade"
      id="collectSearchModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="collectSearchModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="collectSearchModalLabel">
              Search Accounts
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="search">
                    <div class="input-group">
                      <input
                        v-model="term"
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        aria-label="Search"
                        aria-describedby="searchBtn"
                      />
                      <button class="btn" type="submit" id="searchBtn">
                        <span class="material-icons"> search </span>
                      </button>
                    </div>
                  </form>
                  <table
                    v-if="!isLoading && hasSearched && results?.length"
                    class="table align-middle mt-3"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Reference</th>
                        <th scope="col">Name</th>
                        <th scope="col">ID Number</th>
                        <th scope="col">Corporate</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="account in results" :key="account.AccountID">
                        <td>{{ account.ExternalAccountRef }}</td>
                        <td>{{ `${account.Firstname} ${account.Surname}` }}</td>
                        <td>{{ account.IDNumber }}</td>
                        <td>{{ account.Corporate }}</td>
                        <td>
                          <button
                            @click="open(account.AccountID)"
                            class="btn btn-primary"
                          >
                            Open
                          </button>
                          <!--  -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p
                    v-if="!isLoading && hasSearched && !results?.length"
                    class="mt-3"
                  >
                    No results
                  </p>
                  <LoadingSpinner v-if="isLoading" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from 'axios';

import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'CollectSearchModal',
  props: {
    show: Boolean,
  },
  emits: ['close'],
  components: { LoadingSpinner },
  setup() {},
  data() {
    return {
      modal: null,
      term: '',
      isLoading: false,
      hasSearched: false,
      results: null,
    };
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (newVal) this.modal.show();
      if (!newVal) this.modal.hide();
    },
  },
  methods: {
    async search() {
      this.hasSearched = false;
      this.isLoading = true;
      this.results = (
        await axios.get('api/accounts/search', {
          params: { search_term: this.term },
        })
      ).data;
      this.hasSearched = true;
      this.isLoading = false;
    },
    open(accountId) {
      this.$router.push({
        name: 'account',
        params: { id: accountId },
      });
      this.modal.hide();
    },
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(this.$refs.collectSearchModal);
    this.$refs.collectSearchModal.addEventListener('hidden.bs.modal', () => {
      this.$emit('close');
    });
  },
};
</script>
