<template>
  <div class="row">
    <div class="col-md-6">
      <h5 class="mb-4">Personal Information</h5>
      <table v-if="user" class="table">
        <tbody>
          <tr>
            <th scope="row">Name:</th>
            <td>{{ user.firstname }} {{ user.lastname }}</td>
          </tr>
          <tr>
            <th scope="row">Email:</th>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <th scope="row">Extension:</th>
            <td>{{ user.extension }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-6">
      <h5 class="mb-4">Current Positions</h5>
      <table v-if="positions" class="table">
        <thead>
          <tr>
            <!-- <th>Position</th> -->
            <th>Role</th>
            <th>Team</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(position, index) in positions" :key="index">
            <!-- <td>{{ position.position_name }}</td> -->
            <td>{{ position.role }}</td>
            <td>{{ position.team }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <LoadingSpinner v-if="!(user && positions)" />
</template>

<script>
import { mapGetters } from "vuex";

import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

export default {
  name: "ProfileInfo",
  components: { LoadingSpinner },
  computed: {
    ...mapGetters({
      user: "auth/user",
      positions: "auth/permissions",
    }),
  },
};
</script>

<style></style>
