export const formatCurrency = (amount) =>
  new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(amount);

export const formatDate = (date) => new Date(date).toLocaleDateString('en-ZA');

export const secondsToHHMMSS = (secs) => {
  const secNum = parseInt(secs, 10);
  let hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);
  let seconds = secNum - hours * 3600 - minutes * 60;
  hours = hours < 10 ? `0${hours}` : `${hours}`;
  minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  seconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${hours}:${minutes}:${seconds}`;
};

export default { formatCurrency, secondsToHHMMSS };
