<template>
  <teleport to="#modals">
    <div
      class="modal fade"
      id="collectChatsModal"
      ref="collectChatsModal"
      tabindex="-1"
      aria-labelledby="collectChatsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="collectChatsModalLabel">
              Active Chats
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <table v-if="!isLoading" class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Reference</th>
                  <th scope="col">Name</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="chat in chats" :key="chat.reference">
                  <td>{{ chat.reference }}</td>
                  <td>{{ chat.client_name }}</td>
                  <td>
                    <button
                      @click="open(chat.action_id, chat.account_id)"
                      class="btn btn-primary"
                    >
                      Open
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <LoadingSpinner v-else />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'CollectChatsModal',
  props: {
    show: Boolean,
  },
  emits: ['close'],
  components: { LoadingSpinner },

  watch: {
    show(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (newVal) this.modal.show();
      if (!newVal) this.modal.hide();
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  data() {
    return {
      modal: null,
      chats: null,
      isLoading: true,
    };
  },
  methods: {
    open(actionId, accountId) {
      this.$router.push({
        name: 'collect',
        params: { action_id: actionId, account_id: accountId },
      });
      this.modal.hide();
    },
  },
  async created() {
    this.chats = (await axios.get(`/api/chats/${this.user.id}`)).data;
    this.isLoading = false;
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(this.$refs.collectChatsModal);
    this.$refs.collectChatsModal.addEventListener('hidden.bs.modal', () => {
      this.$emit('close');
    });
  },
};
</script>

<style scoped></style>
