<template>
  <div class="card">
    <div class="card-body d-flex flex-column justify-content-between">
      <div>
        <div class="data-title">Total Paid</div>
        <div class="fw-bold data-value">
          {{ formatCurrency(account.account_details.SumPayments) }}
        </div>
      </div>
      <div>
        <div class="data-title">Last Payment</div>
        <div class="fw-bold data-value">
          {{ formatCurrency(account.account_details.LastReceipt) }}
        </div>
      </div>
      <div>
        <div class="data-title">Last Payment Date</div>
        <div class="fw-bold data-value">
          {{ getISODateString(account.account_details.LastReceiptDate) || '-' }}
        </div>
      </div>
      <div>
        <div class="data-title">Debit Order Start</div>
        <div class="fw-bold data-value">
          {{ getISODateString(account.account_details.DebitOrderStartDate) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/format';
import { getISODateString } from '@/helpers/datetime';

export default {
  name: 'AccountPaymentInfo',
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { formatCurrency, getISODateString };
  },
};
</script>

<style lang="scss" scoped>
</style>