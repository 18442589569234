<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span> </template
    ><template #title>{{ account.reference }}</template>
    <template #body>
      <EventNote :content="account.event_note" :multiline="true" />
    </template>
    <template #actions>
      <RoundButton
        @click="$emit('success')"
        large
        class="btn-action text-success"
        title="Accept"
      >
        <span class="material-icons-outlined md-18"> done </span>
      </RoundButton>

      <RoundButton
        @click="$emit('failure')"
        large
        class="btn-action text-danger"
        title="Reject"
      >
        <span class="material-icons-outlined md-18"> close </span>
      </RoundButton>

      <RoundButton
        @click="$emit('writeoff')"
        large
        class="btn-action text-primary"
        title="Propose Writeoff"
      >
        <span class="material-icons-outlined md-18"> layers_clear </span>
      </RoundButton>
    </template>
  </QueueItem>
</template>


<script>
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import EventNote from '@/components/Shared/EventNote.vue';
import RoundButton from '@/components/Shared/_RoundButton.vue';

export default {
  props: {
    account: Object,
  },
  components: {
    QueueItem,
    EventNote,
    RoundButton,
  },
};
</script>