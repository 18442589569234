import axios from 'axios';

export const loadProposalAccounts = async () => {
  const queue = (await axios.get('api/admin/queues/debt-review-proposal')).data;
  const accounts = queue.accounts.map((account) => {
    const tempAcc = account;
    // tempAcc.event_note = JSON.parse(tempAcc.event_note);
    tempAcc.entry_date = new Date(tempAcc.entry_date);
    tempAcc.due_date_time = tempAcc.due_date_time ? new Date(tempAcc.due_date_time) : null;

    return tempAcc;
  });
  return accounts;
};

export const addCounsellorDetails = async (accountId, details) => {
  await axios.post(`api/legal/debt-review/${accountId}/counsellor`, {
    type: 'debtReviewCounsellor',
    details,
  });
};
