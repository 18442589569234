<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <h2 class="card-title mb-4">My Targets</h2>

        <!-- <router-link
          :to="{ name: 'agentCommission' }"
          class="btn btn-primary btn-rounded"
          >View</router-link
        > -->
      </div>
      <div v-if="!isLoading">
        <div class="mb-4">
          <p class="tag mb-2">Arrears</p>
          <p class="h3">
            {{ arrearsTargetsHit?.length }}/{{ arrearsTargets?.length }} Hit
          </p>
          <small v-if="nextArrearsTarget" class="mb-4 d-block">
            {{ percentToNextArrearsTarget }}% to next ({{
              nextArrearsTarget?.amount
            }})
          </small>
          <div v-if="nextArrearsTarget" class="progress">
            <div
              class="progress-bar pl-2"
              role="progressbar"
              :style="{ width: `${percentToNextArrearsTarget}%` }"
              :aria-valuenow="percentToNextArrearsTarget"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>

        <div class="mb-0">
          <p class="tag mb-2">Money In Bank</p>
          <p class="h3 mb-0">
            {{ moneyInBankTargetsHit?.length }}/{{ moneyInBankTargets?.length }}
            Hit
          </p>
          <small v-if="nextMoneyInBankTarget" class="mb-4 d-block">
            {{ percentToNextMoneyInBankTarget }}% to next ({{
              nextMoneyInBankTarget?.amount
            }})
          </small>
          <div v-if="nextMoneyInBankTarget" class="progress">
            <div
              class="progress-bar pl-2"
              role="progressbar"
              :style="{ width: `${percentToNextMoneyInBankTarget}%` }"
              :aria-valuenow="percentToNextMoneyInBankTarget"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div v-else><p>Loading...</p></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'AgentCTPsCard',
  data() {
    return {
      isLoading: true,
      arrearsTargets: null,
      moneyInBankTargets: null,
      currentCollectionsPending: null,
      currentCollectionsConfirmed: null,

      target: 200000,
      projected_start: 173000,
      projected: 175000,
      confirmed_start: 111500,
      confirmed: 112000,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    // projectedPercent() {
    //   return Math.round((this.projected / this.target) * 100);
    // },
    arrearsTargetsHit() {
      return this.arrearsTargets?.filter(
        (t) => t.amount <= this.currentCollectionsConfirmed?.arrears_collections
      );
    },
    moneyInBankTargetsHit() {
      return this.moneyInBankTargets.filter(
        (t) =>
          t.amount <=
          this.currentCollectionsConfirmed?.money_in_bank_collections
      );
    },
    nextArrearsTarget() {
      const outstandingTargets = this.arrearsTargets?.filter(
        (t) => t.amount > this.currentCollectionsConfirmed?.arrears_collections
      );
      return outstandingTargets.length > 0
        ? outstandingTargets.reduce((prev, next) =>
            prev.amount < next.amount ? prev : next
          )
        : undefined;
    },
    nextMoneyInBankTarget() {
      const outstandingTargets = this.moneyInBankTargets.filter(
        (t) =>
          t.amount > this.currentCollectionsConfirmed?.money_in_bank_collections
      );
      return outstandingTargets.length > 0
        ? outstandingTargets.reduce((prev, next) =>
            prev.amount < next.amount ? prev : next
          )
        : undefined;
    },
    percentToNextArrearsTarget() {
      return this.nextArrearsTarget
        ? (
            (this.currentCollectionsConfirmed.arrears_collections /
              this.nextArrearsTarget.amount) *
            100
          ).toFixed(2)
        : undefined;
    },
    percentToNextMoneyInBankTarget() {
      return this.nextMoneyInBankTarget
        ? (
            (this.currentCollectionsConfirmed.money_in_bank_collections /
              this.nextMoneyInBankTarget.amount) *
            100
          ).toFixed(2)
        : undefined;
    },
    // projectedIncreasePercent() {
    // return (
    //   ((this.projected - this.projected_start) / this.projected_start) *
    //   100
    // ).toFixed(2);
    // },
    // confirmedIncreasePercent() {
    // return (
    //   ((this.confirmed - this.confirmed_start) / this.confirmed_start) *
    //   100
    // ).toFixed(2);
    // },
    // confirmedPercent() {
    //   return Math.round((this.confirmed / this.target) * 100);
    // },
  },

  methods: {
    async load() {
      try {
        const targets = (
          await axios.get(`api/users/${this.user.id}/targets`)
        ).data.map((t) => ({
          ...t,
          amount: parseFloat(t.amount),
          commission: parseFloat(t.commission),
        }));
        this.arrearsTargets = targets.filter((t) => t.type === 'arrears');
        this.moneyInBankTargets = targets.filter(
          (t) => t.type === 'money_in_bank'
        );

        const currentCollections = (
          await axios.get(`api/users/${this.user.id}/current-collections`)
        ).data.map((c) => ({
          ...c,
          arrears_collections: parseFloat(c.arrears_collections ?? 0),
          money_in_bank_collections: parseFloat(
            c.money_in_bank_collections ?? 0
          ),
        }));

        this.currentCollectionsPending = currentCollections.find(
          (c) => c.ctp_status === 1
        );
        this.currentCollectionsConfirmed = currentCollections.find(
          (c) => c.ctp_status === 2
        );

        // this.projectedMoneyInBankCollections =
        // projectedCollections.money_in_bank_collections;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.green {
  color: #20c997;
}
.red {
  color: red;
}
</style>