<template>
  <Queue idKey="id" sortKey="due_date_time" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Legal </template>
    <template #title> Unconfirmed </template>
    <template #default="{ item }">
      <QueueItem
        :account="item"
      />
    </template>
  </Queue>
</template>

<script>
import useActionQueue from "@/composables/useActionQueue";
import Queue from "@/components/Shared/Queue.vue";
import QueueItem from "@/components/Shared/QueueItem_v2.vue";
import {
  fetchUnconfirmedAccounts,

} from "@/api/legal";

export default {
  components: { Queue, QueueItem },
  setup() {
    const { accounts, isLoading } = useActionQueue(
      fetchUnconfirmedAccounts
    );

    return {
      accounts,
      isLoading,
    };
  },
};
</script>
