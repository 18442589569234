<template>
  <button :class="{ large: large }" v-bind="$attrs">
    <slot>Button</slot>
  </button>
</template>

<script>
export default {
  name: 'RoundButton',
  props: {
    large: Boolean,
  },
};
</script>

<style lang="scss" scoped>
button {
  position: relative;
  outline: none;
  border: none;
  text-align: center;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  background: transparent;
  padding: 0;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.15s linear;
  // &.primary {
  //   background-color: red;
  // }

  &.large {
    width: 3rem;
    height: 3rem;
  }

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 0, 0, 0.054);
    }
  }

  // &:not([disabled])::before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   border-radius: 50%;
  //   background-color: transparent;
  //   transition: background-color 0.15s linear;
  //   top: 0;
  //   left: 0;
  // }
  // &:not([disabled]):hover::before {
  //   background-color: rgba(0, 0, 0, 0.054);
  // }
  // &:not([disabled]):active::before,
  // &:not([disabled]):focus::before {
  //   background-color: rgba(0, 0, 0, 0.1);
  // }

  ::v-deep .material-icons {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}
</style>

