<template>
  <div :class="{ collapsed: isCollapsed }" class="sidebar pt-0 pb-3 text-white bg-blue">
    <header class="d-flex align-items-center justify-content-between">
      <router-link
        :to="{ name: 'dash' }"
        class="logo text-decoration-none text-white d-flex align-items-center"
      >
        <div v-if="!isCollapsed">
          <img
            class="img-fluid"
            style="height: 40px; padding: 8px"
            alt="Qwise logo"
            src="@/assets/logo_icon.png"
          /><span class="flex-grow-1">Qwise</span>
        </div>
        <img
          v-else
          class="img-fluid"
          style="height: 40px; padding: 8px"
          alt="Qwise logo"
          src="@/assets/logo_icon.png"
        />
      </router-link>
      <button @click="toggleCollapse" class="sidebar-toggle">
        <span class="material-icons-outlined md-18"> chevron_left </span>
      </button>
    </header>
    <ul class="nav nav-pills flex-column mt-4">
      <li class="nav-item">
        <router-link
          :to="{ name: 'dash' }"
          active-class="active"
          class="nav-link text-white"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Dash"
          ><span class="material-icons-outlined md-18"> dashboard </span>
          <span class="nav-text">Dash</span></router-link
        >
      </li>
    </ul>

    <hr class="mt-0" />

    <ul class="nav nav-pills flex-column">
      <li
        class="nav-item dropend"
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        title="CTPs"
      >
        <a href="#" class="nav-link text-white" data-bs-toggle="dropdown" role="button">
          <span class="material-icons-outlined md-18"> receipt </span>
          <span class="nav-text">CTPs</span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <router-link
              :to="{ name: 'ctps' }"
              active-class="active"
              class="dropdown-item"
            >
              Individual CTPs
            </router-link>
          </li>
          <li v-if="userIs('SUPERVISOR') || userIs('MANAGER')">
            <router-link
              :to="{ name: 'total-honoured-ctps' }"
              active-class="active"
              class="dropdown-item"
            >
              CTP Totals Per Agent
            </router-link>
          </li>
        </ul>
      </li>

      <!-- <li class="nav-item">
        <router-link
          :to="{ name: 'ctps' }"
          active-class="active"
          class="nav-link text-white"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="CTPs"
          ><span class="material-icons-outlined md-18"> receipt </span>
          <span class="nav-text">CTP</span></router-link
        >
      </li> -->
      <li class="nav-item d-none">
        <router-link
          :to="{ name: 'agentCommission' }"
          active-class="active"
          class="nav-link text-white"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Commission"
          ><span class="material-icons-outlined md-18"> payments </span>
          <span class="nav-text">Commission</span></router-link
        >
      </li>
    </ul>

    <ul class="nav nav-pills flex-column">
      <!-- <li class="nav-item">
        <router-link
          :to="{ name: 'accounts' }"
          active-class="active"
          class="nav-link text-white"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Account Search"
          ><FontAwesomeIcon :icon="['fas', 'search-dollar']" fixed-width />
          <span class="nav-text">Account Search</span></router-link
        >
      </li> -->
      <template
        v-if="userIs('SUPERVISOR') || userIs('MANAGER') || userIs('LEGAL MANAGER')"
      >
        <hr class="mt-0" />
        <li>
          <router-link
            :to="{ name: 'systemSupport' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="System Support"
          >
            <span class="material-icons-outlined md-18"> contact_support </span>
            <span class="nav-text">System Support</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'users' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Users"
          >
            <span class="material-icons-outlined md-18"> person_outline </span
            ><span class="nav-text">Users</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'teams' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Teams"
            ><span class="material-icons-outlined md-18"> people_alt </span
            ><span class="nav-text">Teams</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'queues' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Queues"
            ><span class="material-icons-outlined md-18"> sort </span
            ><span class="nav-text">Queues</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'queue_routes' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Queue Routes"
          >
            <span class="material-icons-outlined md-18"> share </span
            ><span class="nav-text">Queue Routes</span></router-link
          >
        </li>

        <li v-if="!userIs('LEGAL MANAGER')">
          <router-link
            :to="{ name: 'agentEvents' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Agent Events"
          >
            <span class="material-icons-outlined md-18"> data_exploration </span
            ><span class="nav-text">Agent Events</span></router-link
          >
        </li>
      </template>
      <template v-if="userIs('MANAGER')">
        <li class="nav-item d-none">
          <router-link
            :to="{ name: 'commissions' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Commissions"
            ><span class="material-icons-outlined md-18"> payments </span>
            <span class="nav-text">Commission</span></router-link
          >
        </li>
      </template>
    </ul>
    <template v-if="userIs('SUPERVISOR') || userIs('MANAGER')"
      ><hr class="mt-0" />
      <ul class="nav nav-pills flex-column">
        <li>
          <router-link
            :to="{ name: 'pendingDiarised' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Pending Diarised"
            ><span class="material-icons-outlined md-18"> bookmark_border </span
            ><span class="nav-text">Pending Diarised</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'escalate' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Escalated"
          >
            <span class="material-icons-outlined md-18"> arrow_upward </span>
            <span class="nav-text">Escalated</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'refusal' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Refusal"
            ><span class="material-icons-outlined md-18"> money_off_csred </span
            ><span class="nav-text">Refusal</span></router-link
          >
        </li>
      </ul>
    </template>
    <!-- <template v-if="userIs('SUPERVISOR') || userIs('MANAGER')"
      ><hr class="mt-0" />
      <ul class="nav nav-pills flex-column">
        <li>
          <router-link
            :to="{ name: 'refusal' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Refusal"
            ><span class="material-icons-outlined"> money_off_csred </span
            ><span class="nav-text">Refusal</span></router-link
          >
        </li>
      </ul>
    </template> -->
    <template v-if="userIs('MANAGER')"
      ><hr class="mt-0" />
      <ul class="nav nav-pills flex-column">
        <li>
          <router-link
            :to="{ name: 'proposedWriteoff' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Proposed Writeoff"
            ><span class="material-icons-outlined md-18"> layers_clear </span
            ><span class="nav-text">Proposed Writeoff</span></router-link
          >
        </li>
      </ul>
    </template>
    <template v-if="userIn('Trace')">
      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            :to="{ name: 'trace' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Trace"
          >
            <span class="material-icons-outlined md-18"> travel_explore </span>
            <span class="nav-text">Trace</span></router-link
          >
        </li>
      </ul>
    </template>

    <template v-if="userIs('INBOUND AGENT')">
      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            :to="{ name: 'journal' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Journal"
          >
            <span class="material-icons-outlined"> edit_note </span>
            <span class="nav-text">Journal</span></router-link
          >
        </li>
      </ul>
    </template>

    <template
      v-if="(userIs('AGENT') && userIn('Debt Review')) || userIs('DEBT REVIEW AGENT')"
    >
      <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item">
          <router-link
            :to="{ name: 'debt-review-referral' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Debt Review Referral"
          >
            <span class="material-icons-outlined md-18"> support </span>
            <span class="nav-text">DR Referral</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'debt-review-application' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Debt Review Application"
          >
            <span class="material-icons-outlined md-18"> support </span>
            <span class="nav-text">DR Application</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'debt-review-proposal' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Debt Review Proposal"
          >
            <span class="material-icons-outlined md-18"> support </span>
            <span class="nav-text">DR Proposal</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'debt-review-counter-proposal' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Debt Review Counter Proposal"
          >
            <span class="material-icons-outlined md-18"> compare_arrows </span>
            <span class="nav-text">DR Counter Proposal</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'debt-review-counter-referral-accepted' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Debt Review Counter Referral Accepted"
          >
            <span class="material-icons-outlined md-18"> compare_arrows </span>
            <span class="nav-text">DR Counter Referral Accepted</span></router-link
          >
        </li>

        <li class="nav-item">
          <router-link
            :to="{ name: 'debt-review-ctp-broken' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Debt Review CTP Broken"
          >
            <span class="material-icons-outlined md-18"> money_off </span>
            <span class="nav-text">DR CTP Broken</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            :to="{ name: 'admin-order-referral' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Admin Order Referral"
          >
            <span class="material-icons-outlined md-18"> gavel </span>
            <span class="nav-text">Admin Order Referral</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            :to="{ name: 'admin-order-ctp-broken' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Admin Order CTP Broken"
          >
            <span class="material-icons-outlined md-18"> money_off </span>
            <span class="nav-text">Admin Order CTP Broken</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template v-if="userIs('LEGAL MANAGER')">
      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            :to="{ name: 'debt-review-counter-referral' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Debt Review Counter Referral"
          >
            <span class="material-icons-outlined md-18"> compare_arrows </span>
            <span class="nav-text">DR Counter Referral</span></router-link
          >
        </li>
      </ul>
    </template>

    <template v-if="userIn('Collections Writeoff')">
      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            :to="{ name: 'collections-writeoff' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Collections Writeoff"
          >
            <span class="material-icons-outlined md-18"> layers_clear </span>
            <span class="nav-text">Collections Writeoff</span></router-link
          >
        </li>
      </ul>
    </template>

    <template v-if="userIn('Legal')">
      <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item" v-if="userIs('LEGAL MANAGER')">
          <router-link
            :to="{ name: 'legal-referral' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Legal Referral"
          >
            <span class="material-icons-outlined md-18"> gavel </span>
            <span class="nav-text">Legal Referral</span></router-link
          >
        </li>

        <li class="nav-item" v-if="userIs('LEGAL MANAGER')">
          <router-link
            :to="{ name: 'writeoff-referral' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Proposed Writeoff"
            ><span class="material-icons-outlined md-18"> layers_clear </span
            ><span class="nav-text">Legal Proposed Writeoff</span></router-link
          >
        </li>

        <li
          class="nav-item dropend"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Legal Confirmation"
        >
          <a href="#" class="nav-link text-white" data-bs-toggle="dropdown" role="button">
            <span class="material-icons-outlined md-18"> fact_check </span>
            <span class="nav-text">Legal Confirmation</span>
          </a>
          <ul class="dropdown-menu">
            <li>
              <router-link
                :to="{ name: 'legal-confirmation' }"
                active-class="active"
                class="dropdown-item"
              >
                Legal Confirmation
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'legal-unconfirmed' }"
                active-class="active"
                class="dropdown-item"
              >
                Legal Confirmation Unconfirmed
              </router-link>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <router-link
            :to="{ name: 'deceased' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Deceased"
          >
            <span class="material-icons-outlined md-18"> monitor_heart </span>
            <span class="nav-text">Deceased</span></router-link
          >
        </li>
        <!-- test -->
        <li class="nav-item">
          <router-link
            :to="{ name: 'legal-journal' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Legal Journal"
          >
            <span class="material-icons-outlined"> edit_note </span>
            <span class="nav-text">Legal Journal</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'legal-trace' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Legal Trace"
          >
            <span class="material-icons-outlined md-18"> person_search </span>
            <span class="nav-text">Legal Trace</span></router-link
          >
        </li>

        <li
          class="nav-item dropend"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Section 129"
        >
          <a href="#" class="nav-link text-white" data-bs-toggle="dropdown" role="button">
            <span class="material-icons-outlined md-18"> gavel </span>
            <span class="nav-text">Section 129</span>
          </a>

          <ul class="dropdown-menu">
            <li>
              <router-link
                :to="{ name: 'section-129-pending' }"
                active-class="active"
                class="dropdown-item"
              >
                Section 129 Pending
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'section-129-send' }"
                active-class="active"
                class="dropdown-item"
              >
                Section 129 Process
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'section-129-sent' }"
                active-class="active"
                class="dropdown-item"
              >
                Section 129 Sent
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="userIs('LEGAL MANAGER') || userIs('PARALEGAL')">
          <router-link
            :to="{ name: 'legal-process' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Legal Process"
          >
            <span class="material-icons-outlined md-18"> gavel </span>
            <span class="nav-text">Legal Process</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'legal-legacy' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Legal Legacy"
          >
            <span class="material-icons-outlined md-18"> history </span>
            <span class="nav-text">Legal Legacy</span></router-link
          >
        </li>
      </ul>
    </template>

    <template v-if="userIs('ADMIN')">
      <hr class="mt-0" />
      <ul class="nav nav-pills flex-column mb-auto d-none">
        <li v-if="!isCollapsed">
          <a href="#" class="nav-link">
            <span class="nav-text">
              <small class="text-soft text-white d-block"> Admin Queues </small>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'Incapacitated' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Incapacitated"
          >
            <span class="material-icons-outlined md-18"> person_off </span>
            <span class="nav-text">Incapacitated</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'payment-dispute' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Payment Dispute"
          >
            <span class="material-icons-outlined md-18"> credit_card_off </span>
            <span class="nav-text">Payment Dispute</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'Write-off' }"
            active-class="active"
            class="nav-link text-white"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Writeoff"
          >
            <span class="material-icons-outlined md-18"> delete </span>
            <span class="nav-text">Writeoff</span></router-link
          >
        </li>

        <!-- <span
              class="badge bg-danger d-flex align-items-center ms-auto notification"
              v-if="accTallyLoaded && !isCollapsed"
            >
              {{ queue.acc_count }}
            </span>
            <span
              class="badge bg-danger d-flex align-items-center ms-2 notificationCollapsed"
              v-else-if="accTallyLoaded && isCollapsed === true"
            >
              {{ queue.acc_count }}
            </span>
            <span
              class="badge bg-danger d-flex align-items-center ms-2 notificationCollapsed"
              v-else-if="!accTallyLoaded && isCollapsed === true"
            >
              -
            </span>
            <span
              class="badge bg-danger d-flex align-items-center ms-auto notification"
              v-else
            >
              -
            </span> -->
      </ul>
    </template>

    <div class="mb-auto"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min";

// import axios from 'axios';

import { userIs, userIn } from "@/helpers/permissions";

export default {
  name: "Sidebar",

  setup() {
    return { userIs, userIn };
  },
  data() {
    return {
      isCollapsed: true,
      tooltips: [],
      // accTallyLoaded: false,
      // adminQueues: {},
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      currentActivity: "activity/currentActivity",
      permissions: "auth/permissions",
    }),
  },
  watch: {
    // adminQueues() {
    // this.readyAdminQueues();
    // },
    isCollapsed(value) {
      if (value) {
        this.tooltips.forEach((tooltip) => tooltip.enable());
      } else {
        this.tooltips.forEach((tooltip) => tooltip.disable());
      }
    },
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    // readyAdminQueues() {
    //   adminQueuesMeta.map((queue) => {
    //     const temp = queue;
    //     temp.acc_count =
    //       this.adminQueues.find((i) => i.id === queue.id)?.acc_count ?? 0;
    //     return temp;
    //   });
    //   this.accTallyLoaded = true;
    //   this.tooltips = Array.from(
    //     document.querySelectorAll('[data-bs-toggle="tooltip"]')
    //   ).map((tooltipNode) => new Tooltip(tooltipNode));
    //   if (!this.isCollapsed) {
    //     this.tooltips.forEach((tooltip) => tooltip.disable());
    //   }
    // },
  },
  async beforeCreate() {
    // this.adminQueues = (await axios.get(`/api/admin/queues`)).data;
  },
  mounted() {
    this.tooltips = Array.from(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    ).map((tooltipNode) => new Tooltip(tooltipNode, { trigger: "hover" }));
    if (!this.isCollapsed) {
      this.tooltips.forEach((tooltip) => tooltip.disable());
    }
  },
  unmounted() {
    this.tooltips.forEach((tooltip) => tooltip.dispose());
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  header {
    padding: 0.75rem 1.5rem;
    .logo {
      font-weight: 700;
      font-size: 1.25rem;
      letter-spacing: 0.02em;
      margin-right: 3rem;
      transform: translateX(-5px);
    }
  }

  .nav-pills .nav-link {
    padding: 0.75rem 1.5rem;
    border-radius: 0;
    display: flex;
    align-items: center;
    // margin-bottom: 0.5rem;
    position: relative;
    &:hover,
    &:focus {
      opacity: 1;
    }
    &.active {
      background: transparent;
      opacity: 1;
    }
    &.active::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 3px;
      background-color: #fff;
    }
    .material-icons-outlined {
      margin-right: 0.75rem;
    }
  }

  width: auto;
  min-height: 100vh;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .sidebar-toggle {
    color: #fff;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0 0 0 0.5rem;
  }
}

.sidebar.collapsed {
  header {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    .logo {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin: 0;
      transform: none;
    }
  }

  .nav-pills .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1.5rem;
    justify-content: center;
    .nav-text {
      display: none;
    }
    .material-icons-outlined {
      margin-right: 0rem;
    }
  }
  .sidebar-toggle {
    position: absolute;
    text-align: center;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 0;
    transform: translate(50%, 100%);
    z-index: 100;
    background: #0f52ba;
    padding: 0;
    font-size: 1rem;
    opacity: 0;
    transition: opacity 200ms ease;
  }
  &:hover .sidebar-toggle {
    opacity: 1;
  }
}

.nav-pills .nav-link {
  border-radius: 0;
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  opacity: 0.75;
  position: relative;

  &:hover,
  &:focus {
    opacity: 1;
  }
  &.active {
    background: transparent;
    opacity: 1;
  }
  &.active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: #fff;
  }
  svg {
    font-size: 0.875rem;
    margin-right: 0.75rem;
  }
}

.queueIcon {
  position: relative;
  padding-top: 20px;
  display: inline-block;
}

.notificationCollapsed {
  // width: 1rem;
  // height: 1rem;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // border-radius: 50%;

  position: absolute;
  right: 10px;
  top: 3px;
  background: red;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: white;
  // padding: 5px 10px;
  // font-size: 20px;
}

.notification {
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: white;
}
</style>
