<template>
  <Form
    @submit="submitPasswordChange"
    :validation-schema="changePasswordValidation"
    v-if="!loadingPasswordChange"
  >
    <div class="form-floating mb-3">
      <Field
        name="oldPassword"
        class="form-control"
        type="password"
        id="oldPassword"
        placeholder="Old Password"
        v-model="old_password"
      />
      <label for="password">Old Password</label>
      <ErrorMessage name="oldPassword" class="error-message" />
      <p class="error-message" v-if="!old_password_match">Incorrect Password.</p>
    </div>
    <div class="form-floating mb-3">
      <Field
        name="password"
        class="form-control"
        type="password"
        id="password"
        placeholder="Password"
        v-model="password"
      />
      <label for="password">Password</label>
      <ErrorMessage name="password" class="error-message" />
    </div>

    <div class="form-floating mb-3">
      <Field
        name="confirm_password"
        class="form-control"
        type="password"
        id="confirm_password"
        placeholder="Confirm Password"
        v-model="confirm_password"
      />
      <label for="confirm_password">Confirm Password</label>
      <ErrorMessage name="confirm_password" class="error-message" />
    </div>
    <button class="btn btn-primary">Submit</button>
    <button
      class="btn btn-danger ms-2"
      type="button"
      v-on:click="this.$router.push({ name: 'profile' })"
    >
      Cancel
    </button>
  </Form>
  <div v-else>
    <LoadingSpinner />
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { mapGetters } from "vuex";
import * as yup from "yup";
import axios from "axios";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

export default {
  name: "ChangePasswordForm",
  components: {
    Form,
    Field,
    ErrorMessage,
    LoadingSpinner,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  data() {
    const changePasswordValidation = yup.object({
      password: yup
        .string()
        .required("Password is required")
        .min(4, "Password must be at least 4 characters")
        .max(6, "Password cannot be longer than 6 characters"),
      confirm_password: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("password")], "Passwords do not match"),
      oldPassword: yup.string().required("Old Password is required"),
    });
    return {
      changePasswordValidation,
      old_password: "",
      password: "",
      confirm_password: "",
      old_password_match: true,
      loadingPasswordChange: false,
    };
  },
  methods: {
    async submitPasswordChange() {
      this.loadingPasswordChange = true;
      const url = `/api/user/${this.user.id}`;
      const response = await axios.patch(url, {
        password: this.old_password,
        new_password: this.password,
      });
      if (response.data) {
        this.loadingPasswordChange = false;
        this.$store.dispatch("notification/push", {
          body: `Password Change Confirmed`,
        });
        this.$router.push({ name: "view_profile" });
      } else {
        this.loadingPasswordChange = false;
        this.old_password_match = false;
        this.password = "";
        this.confirm_password = "";
        this.old_password = "";
      }
    },
  },
};
</script>

<style></style>
