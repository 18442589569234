<template>
  <div v-if="parsed">
    <p>{{ parsed.name }} (#{{ parsed.number }}) - {{ parsed.tel }}</p>
  </div>
  <p v-else>Error parsing details</p>
</template>

<script>
export default {
  name: 'DebtReviewCounsellorDetails',
  props: {
    details: {
      type: String,
      required: true,
    },
  },

  computed: {
    parsed() {
      try {
        return JSON.parse(this.details);
      } catch {
        return undefined;
      }
    },
  },
};
</script>