<template>
  <div id="NotFound">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="display-1">404</h1>
          <h3 class="display-6">Something went wrong</h3>
          <p class="lead">
            Unfortunately we could'nt find what you were looking for.
          </p>
          <router-link
            :to="{ name: 'dash' }"
            class="btn btn-rounded btn-outline-light"
            >Home</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss" scoped>
#NotFound {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
</style>
