import axios from 'axios';

const authStore = {
  namespaced: true,
  state() {
    return {
      tokens: {
        authToken: localStorage.getItem('auth-token'),
        refreshToken: localStorage.getItem('refresh-token'),
      },
      permissions: JSON.parse(localStorage.getItem('permissions')),
      permissionMap: JSON.parse(localStorage.getItem('permissionMap')),
      isLoading: false,
      error: null,
      user: null,
      roleList: [],
    };
  },
  getters: {
    isAuthenticated(state) {
      return !!state.tokens?.authToken;
    },
    isLoading(state) {
      return state.isLoading;
    },
    user(state) {
      return state.user;
    },
    roles(state) {
      const roles = [...new Set(state.permissions?.map((r) => r.role.toUpperCase()))];
      return roles;
    },
    roleList(state) {
      return state.roleList;
    },
    permissions(state) {
      return state.permissions;
    },
    error(state) {
      return state.error;
    },
    username(state) {
      return state.user.username;
    },
  },
  mutations: {
    loginRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    // this means we have tokens
    loginSuccess(state, { authToken, refreshToken, permissions, permissionMap }) {
      state.tokens = { authToken, refreshToken };
      state.permissions = permissions;
      state.permissionMap = permissionMap;
      state.isLoading = false;
    },
    loginFailed(state, error) {
      state.error = error;
      state.isLoading = false;
    },
    // asking for user info
    authRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    // we have user info
    authSuccess(state, user) {
      state.user = user;
      state.isLoading = false;
    },
    authFailed(state, error) {
      state.error = error;
      state.isLoading = false;
    },
    logout(state) {
      state.tokens = null;
      state.user = null;
    },
    userUpdate(state, newInfo) {
      state.user.firstname = newInfo.new_first_name;
      state.user.lastname = newInfo.new_last_name;
    },
    roleListLoaded(state, roleList) {
      state.roleList = roleList;
    },
  },
  actions: {
    async login({ commit }, loginData) {
      commit('loginRequest');
      try {
        const {
          access_token: authToken,
          refresh_token: refreshToken,
          permissions,
          permission_map: permissionMap,
        } = (await axios.post('/api/auth', loginData)).data;
        localStorage.setItem('auth-token', authToken);
        localStorage.setItem('refresh-token', refreshToken);
        localStorage.setItem('permissions', JSON.stringify(permissions));
        localStorage.setItem('permission-map', JSON.stringify(permissionMap));
        axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
        commit('loginSuccess', { authToken, refreshToken, permissions, permissionMap });
      } catch (error) {
        localStorage.removeItem('auth-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('permission-map');
        commit('loginFailed', error.message);
      }
    },
    async loadUser({ commit }) {
      commit('authRequest');
      try {
        const user = (await axios.get('/api/auth/me')).data;
        commit('authSuccess', user);
        return true;
      } catch (error) {
        commit('authFailed', error.message);
        return false;
      }
    },
    logout({ commit }) {
      localStorage.removeItem('auth-token');
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('permissions');
      localStorage.removeItem('permission-map');
      delete axios.defaults.headers.common.Authorization;
      commit('logout');
    },
    userUpdate({ commit }, payload) {
      commit('userUpdate', payload);
    },
    async getRoleList({ commit }) {
      const roleList = (await axios.get('api/auth/roles')).data;
      commit('roleListLoaded', roleList);
    },
  },
};

export default authStore;
