<template>
  <teleport to="#modals">
    <div
      class="modal fade"
      id="collectMessagesModal"
      ref="chatModal"
      tabindex="-1"
      aria-labelledby="collectMessagesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="collectMessagesModalLabel">Messages</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div ref="transcript" class="modal-body">
            <!--  -->
            <div v-if="!isLoadingChats">
              <div
                v-for="(segment, index) in transcriptSegments"
                :key="index"
                class="transcript-segment mb-5"
                :class="{
                  alert: segment.status === 'failed',
                  'alert-danger': segment.status === 'failed',
                }"
              >
                <p class="text-muted">
                  {{ getISODateString(segment.date) }}
                  {{ getFormattedTime(segment.date) }}
                  {{ segment.status }}
                </p>
                <div>{{ segment.text }}</div>
              </div>
            </div>
            <LoadingSpinner v-else />
          </div>
          <div class="modal-footer">
            <div class="container-fluid my-3">
              <div class="row">
                <div class="col-10">
                  <textarea
                    v-model="text"
                    type="text"
                    rows="8"
                    class="form-control"
                    placeholder="Recipient's username"
                    aria-label="Recipient's username with two button addons"
                  ></textarea>
                </div>
                <div class="col-2">
                  <div class="d-grid gap-2">
                    <button
                      @click="pasteTranscriptSegment"
                      class="btn btn-secondary"
                      type="button"
                    >
                      <span class="material-icons-outlined"> content_paste </span>
                      Paste
                    </button>
                    <button
                      @click="saveTranscriptSegment"
                      class="btn btn-primary"
                      type="button"
                    >
                      <span class="material-icons-outlined"> save </span> Save
                    </button>
                    <button data-bs-dismiss="modal" class="btn btn-primary" type="button">
                      <span class="material-icons-outlined"> double_arrow </span>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";

import { getISODateString, getFormattedTime } from "@/helpers/datetime";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

export default {
  name: "InboundMessagesModal",
  props: {
    show: Boolean,
  },
  emits: ["hide"],
  components: { LoadingSpinner },
  setup() {
    return { getISODateString, getFormattedTime };
  },

  data() {
    return {
      modal: null,
      text: "",
      transcriptSegments: null,
      isLoadingChats: true,
    };
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (newVal) this.modal.show();
      if (!newVal) this.modal.hide();
    },
  },
  computed: {
    ...mapGetters({
      activeAccount: "account/activeAccount",
      positions: "auth/permissions",
    }),
  },
  async mounted() {
    this.modal = new window.bootstrap.Modal(this.$refs.chatModal);
    this.$refs.chatModal.addEventListener("hidden.bs.modal", () => {
      this.$emit("hide");
    });
    let activePositionId = this.positions.find(
      (position) => position.role.toUpperCase() === "AGENT"
    )?.user_position_id;

    if (!activePositionId) {
      activePositionId = this.positions.find(
        (position) => position.role.toUpperCase() === "INBOUND AGENT"
      )?.user_position_id;
    }
    if (!activePositionId) {
      activePositionId = this.positions.find(
        (position) => position.role.toUpperCase() === "SUPERVISOR"
      )?.user_position_id;
    }
    if (!activePositionId) {
      activePositionId = this.positions.find(
        (position) => position.role.toUpperCase() === "MANAGER"
      )?.user_position_id;
    }
    //
    const result = (
      await axios.get(`/api/chats/transcript/inbound/${this.activeAccount.id}`, {
        params: { user_position_id: activePositionId },
      })
    ).data;
    this.transcriptSegments = result.map((x) => ({ ...x, status: "saved" }));
    this.isLoadingChats = false;

    this.updateTranscriptScroll();
  },
  methods: {
    async pasteTranscriptSegment() {
      const text = await navigator.clipboard.readText();
      this.text = text;
    },
    async saveTranscriptSegment() {
      const { text } = this;
      const id = uuidv4();
      this.transcriptSegments.push({
        id,
        date: new Date(),
        text,
        status: "saving",
      });
      this.text = "";
      this.updateTranscriptScroll();
      try {
        let activePositionId = this.positions.find(
          (position) =>
            (position.role.toUpperCase() === "AGENT" &&
              position.team.toUpperCase() === "ACTIVE") ||
            "EARLY PRELEGAL" ||
            "LATE PRELEGAL"
        )?.user_position_id;

        if (activePositionId) {
          await axios.post(`/api/chats/transcript/${this.activeAccount.id}`, {
            actionId: null,
            text,
            activePositionId,
          }).data;
          this.isLoadingChats = false;
        }
        if (!activePositionId) {
          activePositionId = this.positions.find(
            (position) =>
              position.role.toUpperCase() === "AGENT" ||
              "INBOUND AGENT" ||
              "SUPERVISOR" ||
              "MANAGER"
          )?.user_position_id;
          await axios.post(`/api/chats/transcript/inbound/${this.activeAccount.id}`, {
            user_position_id: activePositionId,
            text,
          });
        }
        // find item and update
        const index = this.transcriptSegments.findIndex((x) => x.id === id);
        this.transcriptSegments[index].status = "saved";
      } catch (error) {
        this.text = text;

        // find item and update
        const index = this.transcriptSegments.findIndex((x) => x.id === id);
        this.transcriptSegments[index].status = "failed";

        this.$store.dispatch("notification/push", {
          body: error.message,
          type: "error",
        });
      }
    },
    finishTranscription() {
      this.modal.hide();
      this.$router.push({ name: "collectPortal" });
    },
    updateTranscriptScroll() {
      const el = this.$refs.transcript;
      this.$nextTick(() => {
        el.scrollTop = el.scrollHeight;
      });
    },
  },
};
</script>

<style scoped>
.modal-dialog,
.modal-content {
  height: calc(100vh - 3.5rem);
}

.modal-body {
  overflow: scroll;
}
.transcript-segment {
  white-space: pre-wrap;
}
</style>
