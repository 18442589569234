<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="data-title">Total Arrears</div>
          <div class="h2 data-value">
            {{ formatCurrency(account.account_details.TotalArrears) }}
          </div>
        </div>
        <div class="col">
          <div class="data-title">Arrears Level</div>
          <div class="h2 data-value">
            <span v-if="account.account_details.arrearsKey === 6"
              >180+ Days</span
            >
            <span v-if="account.account_details.arrearsKey === 5"
              >150 Days</span
            >
            <span v-if="account.account_details.arrearsKey === 4"
              >120 Days</span
            >
            <span v-if="account.account_details.arrearsKey === 3">90 Days</span>
            <span v-if="account.account_details.arrearsKey === 2">60 Days</span>
            <span v-if="account.account_details.arrearsKey === 1">30 Days</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/format';

export default {
  name: 'AccountCoreInfo',
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { formatCurrency };
  },
};
</script>

<style lang="scss" scoped>
</style>