<template>
  <form @submit.prevent="login">
    <div :class="{ 'is-invalid': error }">
      <div class="form-floating mb-4">
        <input
          v-model="username"
          type="text"
          class="form-control"
          id="loginUsername"
          name="username"
          placeholder="Username"
        />
        <label for="loginUsername">Username</label>
      </div>
      <div class="form-floating mb-4">
        <input
          v-model="password"
          type="password"
          class="form-control"
          id="loginPassword"
          name="password"
          placeholder="Password"
        />
        <label for="loginPassword">Password</label>
      </div>
    </div>

    <div v-if="error" class="mb-3 text-center invalid-feedback">
      Authentication required
    </div>

    <div>
      <div class="d-grid gap-2 col-sm-8 mx-auto">
        <LoadingButton
          :isLoading="isLoading"
          type="submit"
          class="btn btn-lg btn-primary"
          >Login</LoadingButton
        >
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

import LoadingButton from '@/components/Shared/LoadingButton.vue';

export default {
  name: 'LoginForm',
  components: {
    LoadingButton,
  },
  data() {
    return {
      username: '',
      password: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'auth/isLoading',
      error: 'auth/error',
    }),
  },
  methods: {
    async login() {
      await this.$store.dispatch('auth/login', {
        username: this.username,
        password: this.password,
      });
      if (await this.$store.dispatch('auth/loadUser')) {
        this.$router.push({ name: 'dash' });
      } else {
        this.$store.dispatch('auth/logout');
      }
    },
  },
};
</script>
