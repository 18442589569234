<template>
  <RoundButton v-bind="$attrs"
    ><span class="material-icons"> delete </span>
  </RoundButton>

  <div mx-3></div>
</template>

<script>
import RoundButton from '@/components/Shared/_RoundButton.vue';

export default {
  name: 'DeleteButton',
  components: { RoundButton },
};
</script>


