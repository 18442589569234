<template>
  <div class="d-flex flex-column justify-content-center h-100">
    <div class="d-grid gap-3">
      <button
        @click="$emit('choice', 'rpc_diarise')"
        class="btn btn-lg btn-primary"
      >
        Diarise
      </button>
      <button
        @click="$emit('choice', 'rpc_CTP')"
        class="btn btn-lg btn-primary"
      >
        CTP
      </button>
      <button
        @click="$emit('choice', 'rpc_refusal')"
        class="btn btn-lg btn-primary"
      >
        Refusal
      </button>
      <button
        @click="$emit('choice', 'rpc_paymentDispute')"
        class="btn btn-lg btn-primary"
      >
        Payment Dispute
      </button>
      <button
        @click="$emit('choice', 'rpc_other')"
        class="btn btn-lg btn-primary"
      >
        Other
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RPC',
  emits: ['choice'],
};
</script>
