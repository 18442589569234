<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span>
    </template>
    <template #title>{{ account.reference }}</template>
    <template #body>
      <EventNote
        v-if="account.event_note"
        :content="account.event_note"
        :multiline="true"
      />
    </template>
    <template #actions>
      <RoundButton
        @click="toggleForm('ctp')"
        large
        class="btn-action text-success"
        title="Create CTP"
      >
        <span class="material-icons-outlined md-18"> attach_money </span>
      </RoundButton>
      <RoundButton
        @click="toggleForm('diarise')"
        large
        class="btn-action text-secondary"
        title="Diarise"
      >
        <span class="material-icons-outlined md-18"> event </span>
      </RoundButton>
      <RoundButton
        @click="toggleForm('note')"
        large
        title="Create Note"
        class="btn-action text-secondary"
      >
        <span class="material-icons"> notes </span>
      </RoundButton>
    </template>
    <template #footer>
      <CreateCTPForm v-if="shownForm === 'ctp'" @submitted="$emit('ctp', $event)" />
      <DiariseForm v-if="shownForm === 'diarise'" @submitted="$emit('defer', $event)" />
      <NoteForm v-if="shownForm === 'note'" @submitted="createNote($event)" />
    </template>
  </QueueItem>
</template>

<script>
import { ref } from "vue";

import QueueItem from "@/components/Shared/QueueItem_v2.vue";
import RoundButton from "@/components/Shared/_RoundButton.vue";
import EventNote from "@/components/Shared/EventNote.vue";
import CreateCTPForm from "@/components/Shared/forms/CreateCTPForm.vue";
import DiariseForm from "@/components/Admin/DiariseForm.vue";
import NoteForm from "@/components/Admin/NoteForm.vue";

export default {
  props: {
    account: Object,
  },
  emits: ["ctp", "note", "defer", "view"],
  components: {
    QueueItem,
    RoundButton,
    EventNote,
    CreateCTPForm,
    DiariseForm,
    NoteForm,
  },
  setup(props, { emit }) {
    const shownForm = ref("");
    const toggleForm = (name) => {
      shownForm.value = shownForm.value !== name ? name : "";
    };
    const createNote = (note) => {
      const payload = { accountId: props.account.id, note: note.note };
      emit("note", payload);
      shownForm.value = "";
    };

    return {
      shownForm,
      toggleForm,
      createNote,
    };
  },
};
</script>
