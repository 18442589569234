<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span> </template
    ><template #title>{{ account.reference }}</template>
    <template #body> </template>
    <template #actions>
      <RoundButton
        @click="toggleForm('note')"
        large
        title="Create Note"
        class="btn-action text-secondary"
      >
        <span class="material-icons"> notes </span>
      </RoundButton>

      <RoundButton
        @click="$emit('done')"
        large
        class="btn-action text-success"
        title="Done"
      >
        <span class="material-icons-outlined md-18"> done </span>
      </RoundButton>

      <RoundButton
        @click="$emit('reject')"
        large
        class="btn-action text-danger"
        title="Reject"
      >
        <span class="material-icons-outlined md-18"> close </span>
      </RoundButton>
    </template>
    <template #footer>
      <NoteForm v-if="showForm === 'note'" @submitted="createNote($event)" />
    </template>
  </QueueItem>
</template>

<script>
import { ref } from "vue";
import QueueItem from "@/components/Shared/QueueItem_v2.vue";
import RoundButton from "@/components/Shared/_RoundButton.vue";
import NoteForm from "@/components/Admin/NoteForm.vue";

export default {
  props: {
    account: Object,
  },
  components: {
    QueueItem,
    RoundButton,
    NoteForm,
  },
  emits: ["note", "reject", "done"],
  setup(props, { emit }) {
    const showForm = ref("");
    const toggleForm = (formName) => {
      showForm.value = showForm.value !== formName ? formName : "";
    };

    const createNote = (note) => {
      const payload = { accountId: props.account.id, note: note.note };
      emit("note", payload);
      showForm.value = "";
    };

    return {
      showForm,
      toggleForm,
      createNote,
    };
  },
};
</script>
