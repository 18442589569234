<template>
  <Modal :show="show" @show="populate" @hide="hide">
    <template #modal-title>Edit CTP for {{ CTP?.reference }}</template>
    <div class="mb-3">
      <label class="form-label">Date</label>
      <input
        v-model="date"
        :disabled="isSaving"
        type="date"
        class="form-control"
      />
    </div>
    <div class="mb-3">
      <label class="form-label">Amount</label>
      <input
        v-model="amount"
        :disabled="isSaving"
        type="number"
        step="any"
        class="form-control"
      />
    </div>
    <div class="mb-3">
      <label class="form-label">Payment Method</label>
      <select
        v-model="paymentMethod"
        :disabled="isSaving"
        class="form-select"
        required
      >
        <option
          v-for="method in paymentMethods"
          :disabled="method.id === 4"
          :key="method.id"
          :value="method.id"
        >
          {{ method.method }}
        </option>
      </select>
    </div>
    <div class="form-check mb-3">
      <input
        v-model="isRecurring"
        :disabled="isSaving"
        class="form-check-input"
        type="checkbox"
        id="isRecurring"
      />
      <label class="form-label" for="isRecurring"> Recurring </label>
    </div>
    <div v-if="isRecurring">
      <select v-model="recurringOn" :disabled="isSaving" class="form-select">
        <option disabled value="">Select day</option>
        <option value="same_date">Same Date</option>
        <option value="specific_date">Specific Date</option>
        <option value="last_day_of_month">Last Day of Month</option>
      </select>

      <div v-if="recurringOn === 'specific_date'" class="col mt-3">
        <input
          v-model="recurringDate"
          :disabled="isSaving"
          type="number"
          min="1"
          max="30"
          class="form-control"
        />
      </div>
    </div>
    <template #modal-footer>
      <button @click="save" :disabled="isSaving" class="btn btn-primary">
        <span v-if="isSaving">Saving</span>
        <span v-else>Save</span>
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Shared/_Modal.vue';

export default {
  name: 'CTPEditModal',
  props: {
    show: Boolean,
    CTP: Object,
    paymentMethods: Array,
    isSaving: Boolean,
  },
  components: { Modal },
  emits: ['save', 'hide'],
  data() {
    return {
      date: null,
      amount: null,
      paymentMethod: null,
      isRecurring: null,
      recurringOn: null,
      recurringDate: null,
    };
  },

  methods: {
    hide() {
      this.$emit('hide');
    },
    populate() {
      [this.date] = new Date(this.CTP.due_date).toISOString().split('T');
      this.amount = this.CTP.amount;
      this.isRecurring = Boolean(this.CTP.recurring_day);
      // this.recurringOn =
      if (this.CTP.recurring_day === -1) this.recurringOn = 'last_day_of_month';
      if (this.CTP.recurring_day > 0) {
        this.recurringDate = this.CTP.recurring_day;
        this.recurringOn = 'specific_date';
      }
      this.paymentMethod = this.CTP.payment_method;
    },
    save() {
      let recurringDay;
      if (this.isRecurring) {
        if (this.recurringOn === 'same_date') {
          recurringDay = new Date(this.date).getDate();
        } else if (this.recurringOn === 'specific_date') {
          recurringDay = this.recurringDate;
        } else if (this.recurringOn === 'last_day_of_month') {
          recurringDay = -1;
        }
      }

      const payload = {
        id: this.CTP.id,
        due_date: this.date,
        amount: this.amount,
        recurring_day: recurringDay,
        payment_method: this.paymentMethod,
      };

      this.$emit('save', payload);
    },
  },
};
</script>

<style lang="scss" scoped></style>
