<template>
  <span
    v-if="parsed"
    @click="collapse"
    :class="{
      collapsable: collapsable,
      collapsed: isCollapsed,
      multiline: multiline,
      root: parse,
    }"
    class="note"
  >
    <span class="field" v-for="field in Object.entries(parsed)" :key="field[0]">
      <strong>{{ field[0] }}</strong
      >:
      <span class="nested" v-if="isNested(field[1])"
        ><EventNote :content="field[1]" :parse="false" :multiline="multiline"
      /></span>
      <span v-else>{{ field[1] }}</span>
    </span>
  </span>
</template>


<script>
import { ref, computed } from 'vue';

export default {
  name: 'EventNote',
  props: {
    content: [String, Object],
    multiline: {
      Boolean,
      default: false,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    parse: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const isCollapsed = ref(props.collapsable);

    const parsed = computed(() => {
      if (!props.parse) return props.content;
      try {
        const parsedContent = JSON.parse(props.content);
        return parsedContent;
      } catch {
        return props.content ? { note: props.content } : '';
      }
    });

    const isNested = (val) =>
      typeof val === 'object' && !Array.isArray(val) && val !== null;

    const collapse = () => {
      if (props.collapsable) isCollapsed.value = !isCollapsed.value;
    };

    return {
      parsed,
      isCollapsed,
      collapse,
      isNested,
    };
  },
};
</script>

<style lang="scss" scoped>
.note {
  &:not(.multiline) .field + .field::before {
    content: ', ';
  }

  &.root,
  &.multiline {
    display: block;
  }

  &.multiline .field {
    display: block;
  }

  &.collapsable {
    cursor: pointer;
  }
  &.collapsed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nested .multiline.note {
    padding-left: 1rem;
    margin-top: 0.25rem;
    border-left: 3px solid #eff1f6;
  }
}
</style>