<template>
  <Page>
    <div class="container">
      <div class="row">
        <div class="col">
          <h5 class="mt-5 mb-4">Profile - {{ user.username }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div
            id="list-tab"
            role="tablist"
            class="nav flex-column nav-pills me-3"
            aria-orientation="vertical"
          >
            <router-link
              :to="{ name: 'view_profile' }"
              exact-active-class="active"
              class="nav-link mb-3"
            >
              Details
            </router-link>
            <router-link
              :to="{ name: 'edit_profile' }"
              exact-active-class="active"
              class="nav-link mb-3"
            >
              Edit
            </router-link>
            <router-link
              :to="{ name: 'change_password' }"
              exact-active-class="active"
              class="nav-link mb-3"
            >
              Change Password
            </router-link>
          </div>

          <!-- <button
              v-on:click="resetPin"
              class="list-group-item list-group-item-action"
            >
              Reset Pin
            </button> -->
        </div>
        <div class="col-md-9">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <UserPositions />
              </div>
              <div class="row mt-4">
                <div class="col"><router-view /></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 ms-5"></div>
      </div>
    </div>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Page from '@/components/Shared/_Page.vue';

export default {
  components: {
    Page,
  },
  name: 'Profile',
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  data() {
    return {
      show_password_change: false,
      show_edit_profile: false,
      disabled_reset_pin: false,
      disabled_edit_profile: false,
      disabled_change_password: false,
    };
  },
  methods: {
    async resetPin() {
      try {
        await axios.patch('api/auth/pin');
        this.$store.dispatch('notification/push', {
          body: `New Pin Emailed to Registered Email Address`,
        });
      } catch {
        this.$store.dispatch('notification/push', {
          body: `New Pin Request Failed`,
          type: 'error',
        });
      }
    },
  },
};
</script>

<style></style>
