<template>
  <canvas ref="chart"></canvas>
</template>

<script>
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default {
  name: 'AgentProductivityChart',
  props: {
    dataset: {
      type: Object,
      default: () => ({ total_touched: 0, group_avg_touched: 0 }),
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    dataset(newVal) {
      if (newVal) this.renderGraph();
    },
  },
  methods: {
    renderGraph() {
      const ctx = this.$refs.chart.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Touched'],
          datasets: [
            {
              label: 'Me',
              data: [this.dataset.total_touched],
              backgroundColor: ['#333'],
              borderWidth: 0,
            },
            {
              label: 'Groups Average',
              data: [this.dataset.group_avg_touched],
              backgroundColor: ['#ccc'],
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Accounts Touched Today',
            },
          },
        },
      });
    },
  },
  mounted() {
    this.renderGraph();
  },
};
</script>
