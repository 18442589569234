<template>
  <div class="bg-blue text-white card">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <div class="data-title">
          Client No {{ account.customer_details.ClientNumber }}
          <span v-if="isAppInDev()">, Internal No {{ account.id }}</span>
        </div>
        <div class="flag">
          <img
            v-if="
              account.account_details.ProductNameCountry === 'Namibia' ||
              account.account_details.ProductNameCountry === 'Namibia 2'
            "
            class="img-fluid"
            alt="Namibian Flag"
            src="@/assets/NAM.svg"
          />
          <img
            v-if="
              account.account_details.ProductNameCountry === 'South Africa 2' ||
              account.account_details.ProductNameCountry === 'South Africa 1'
            "
            class="img-fluid"
            alt="South African Flag"
            src="@/assets/RSA.svg"
          />
        </div>
      </div>

      <h2>{{ account.customer_details.Title }} {{ fullname }}</h2>

      <div class="data-title">Reference No</div>
      <div class="fw-bold data-value">
        {{ account.account_details.ExternalAccountRef }}
      </div>
      <div class="data-title">Corporate</div>
      <div class="fw-bold data-value">
        {{ account.account_details.Corporate }}
      </div>
      <div class="data-title">Product</div>
      <div class="fw-bold data-value">
        {{ account.account_details.Product_Desc }}
      </div>
      <div class="data-title">Situation</div>
      <div class="fw-bold data-value">
        {{ account.account_details.Situation }}
      </div>
      <div class="data-title">Queues</div>
      <div class="mb-3">
        <span v-if="isQueueListLoading">Loading...</span>
        <QueueBadge v-else :key="index" :queue="account.queues.queue" class="mx-1" />
      </div>
    </div>
  </div>
</template>

<script>
import { isAppInDev } from "@/helpers/general";
import { formatCurrency } from "@/helpers/format";
import axios from "axios";
import QueueBadge from "@/components/Shared/QueueBadge.vue";

export default {
  name: "AccountCoreInfo",
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  components: { QueueBadge },
  setup() {
    return { isAppInDev, formatCurrency };
  },
  data() {
    return {
      queues: null,
      isQueueListLoading: false,
    };
  },
  computed: {
    firstname() {
      return this.captitalize(this.account.customer_details.Firstname);
    },
    lastname() {
      return this.captitalize(this.account.customer_details.Surname);
    },
    fullname() {
      return `${this.firstname} ${this.lastname}`;
    },
  },
  methods: {
    captitalize(string) {
      return string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
    },
  },
  async mounted() {
    this.isQueueListLoading = true;
    try {
      this.queues = (await axios.get(`/api/accounts/${this.account.id}/queues`)).data;
    } catch (error) {
      console.error(error);
    }
    this.isQueueListLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.flag {
  img {
    height: 21px;
  }
}
.data-title {
  color: #c2d0e7;
  margin-top: 0.3rem;
}
.data-value {
  font-weight: 600;
}

.viewBank {
  color: rgb(138, 138, 138);
  font-weight: 400;
  span {
    color: white;
  }
}
</style>
