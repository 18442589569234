<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span>
    </template>
    <template #title>{{ account.reference }}</template>
    <template #body>
      <EventNote
        v-if="account.event_note"
        :content="account.event_note"
        :multiline="true"
      />
    </template>
    <template #actions>
      <RoundButton
        @click="toggleForm('ctp')"
        large
        class="btn-action text-success"
        title="Create CTP"
      >
        <span class="material-icons-outlined md-18"> attach_money </span>
      </RoundButton>
      <RoundButton
        @click="toggleForm('legalReferral')"
        large
        class="btn-action text-danger"
        title="Reject"
      >
        <span class="material-icons-outlined md-18"> close </span>
      </RoundButton>
      <RoundButton
        @click="toggleForm('note')"
        large
        title="Create Note"
        class="btn-action text-secondary"
      >
        <span class="material-icons"> notes </span>
      </RoundButton>
    </template>
    <template #footer>
      <CreateCTPForm v-if="showForm === 'ctp'" @submitted="ctp" />
      <div v-if="showForm === 'legalReferral'">
        <div class="mb-3">
          <label class="form-label">Legal referral note</label>
          <textarea
            v-model="legalReferralForm.note"
            class="form-control"
            rows="2"
          ></textarea>
        </div>
        <button @click="legalReferral" class="btn btn-primary">Submit</button>
      </div>
      <div v-if="showForm === 'note'">
        <div class="mb-3">
          <label class="form-label">Note</label>
          <textarea
            v-model="noteForm.note"
            class="form-control"
            rows="3"
          ></textarea>
        </div>
        <button @click="createNote" class="btn btn-primary">Submit</button>
      </div>
    </template>
  </QueueItem>
</template>

<script>
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import RoundButton from '@/components/Shared/_RoundButton.vue';
import EventNote from '@/components/Shared/EventNote.vue';
import CreateCTPForm from '@/components/Shared/forms/CreateCTPForm.vue';

export default {
  name: 'DebtReviewCTPBrokenQueueItem',
  props: {
    account: Object,
  },
  components: {
    QueueItem,
    RoundButton,
    EventNote,
    CreateCTPForm,
  },
  setup() {},
  data() {
    return {
      showForm: '',
      CTPForm: {
        date: '',
        amount: 0,
      },
      legalReferralForm: {
        note: '',
      },
      noteForm: {
        note: '',
      },
    };
  },
  methods: {
    toggleForm(formName) {
      this.showForm = this.showForm !== formName ? formName : '';
    },
    ctp(data) {
      this.$emit('action', {
        accountId: this.account.id,
        payload: { action: 'CTP', data },
      });
    },
    legalReferral() {
      const { legalReferralForm: data } = this;
      this.$emit('action', {
        accountId: this.account.id,
        payload: { action: 'legalReferral', data },
      });
    },
    createNote() {
      const { note } = this.noteForm;
      this.$emit('note', { accountId: this.account.id, note });
      this.noteForm.note = '';
      this.showForm = '';
    },
  },
};
</script>