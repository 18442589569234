import axios from 'axios';

export const fetchReferralAccounts = async () =>
  (await axios.get('api/queues/legal-referral')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const fetchConfirmationAccounts = async () =>
  (await axios.get('api/queues/legal-confirmation')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

  export const fetchUnconfirmedAccounts = async () => (await axios.get('api/queues/legal-unconfirmed')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }))

export const fetchSection129PendingAccounts = async () =>
  (await axios.get('api/queues/section129-pending')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const fetchSection129GeneratedAccounts = async () =>
  (await axios.get('api/queues/section129-generated')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const fetchSection129SentAccounts = async () =>
  (await axios.get('api/queues/section129-sent')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const fetchProcessAccounts = async () =>
  (await axios.get('api/queues/legal-process')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const fetchLegalTraceAccounts = async () =>
  (await axios.get('/api/queues/legal-trace')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const fetchLegacyAccounts = async () =>
  (await axios.get('api/queues/legal-legacy')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const acceptLegalReferral = async (accountId) => {
  await axios.patch(`api/queues/legal-referral/${accountId}`, {
    action: 'accept',
  });
};

export const rejectLegalReferral = async (accountId) => {
  await axios.patch(`api/queues/legal-referral/${accountId}`, {
    action: 'reject',
  });
};

export const successfulConfirmation = async (accountId) => {
  await axios.patch(`api/queues/legal-confirmation/${accountId}`, {
    action: 'success',
  });
};

export const failedConfirmation = async (accountId) => {
  await axios.patch(`api/queues/legal-confirmation/${accountId}`, {
    action: 'failure',
  });
};

export const markUnconfirmedConfirmation = async (accountId) => {
  await axios.patch(`api/queues/legal-confirmation/${accountId}`, { action: 'unconfirmed'})
}

export const writeoffConfirmation = async (accountId) => {
  await axios.patch(`api/queues/legal-confirmation/${accountId}`, {
    action: 'writeoff',
  });
};

export const generateSection129Letters = async () =>
  (
    await axios.patch('api/queues/section129-pending', null, {
      responseType: 'arraybuffer',
    })
  ).data;

export const generatedSection129Letter = async (accountId, data) =>
  (
    await axios.patch(`api/queues/section129-pending/${accountId}`, {
      action: 'generated',
      data,
    })
  ).data;

export const sentSection129Letter = async (accountId, data) =>
  (
    await axios.patch(`api/queues/section129-generated/${accountId}`, {
      action: 'sent',
      data,
    })
  ).data;

export const createCTP = async (accountId, data) =>
  (
    await axios.patch(`api/queues/section129-sent/${accountId}`, {
      action: 'ctp',
      data,
    })
  ).data;

export const requestLegalTrace = async (accountId) =>
  (
    await axios.patch(`api/queues/legal-process/${accountId}`, {
      action: 'trace',
    })
  ).data;

export const successfulLegalTrace = async (accountId) => {
  await axios.patch(`api/queues/legal-trace/${accountId}`, {
    action: 'success',
  });
};

export const failedLegalTrace = async (accountId) => {
  await axios.patch(`api/queues/legal-trace/${accountId}`, {
    action: 'failure',
  });
};

export const writeoffLegalTrace = async (accountId) => {
  await axios.patch(`api/queues/legal-trace/${accountId}`, {
    action: 'writeoff',
  });
};

// Legal legacy actions
export const confirmationLegacy = async (accountId) => {
  await axios.patch(`api/queues/legal-legacy/${accountId}`, {
    action: 'confirmation',
  });
};

export const section129Legacy = async (accountId) => {
  await axios.patch(`api/queues/legal-legacy/${accountId}`, {
    action: 'section129',
  });
};

export const defendedLegacy = async (accountId) => {
  await axios.patch(`api/queues/legal-legacy/${accountId}`, {
    action: 'defended',
  });
};

export const processLegacy = async (accountId) => {
  await axios.patch(`api/queues/legal-legacy/${accountId}`, {
    action: 'process',
  });
};

export const writeoffLegacy = async (accountId) => {
  await axios.patch(`api/queues/legal-legacy/${accountId}`, {
    action: 'writeoff',
  });
};

export const fetchDeceasedAccounts = async () =>
  (await axios.get('api/queues/deceased')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const rejectDeceasedReferral = async (accountId) => {
  await axios.patch(`api/queues/deceased/${accountId}`, {
    action: 'reject',
  });
};

export const fetchWriteoffReferralAccounts = async () =>
  (await axios.get('api/queues/writeoff-referral')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const acceptWriteoffReferral = async (accountId) => {
  await axios.patch(`api/queues/writeoff-referral/${accountId}`, {
    action: 'accept',
  });
};

export const rejectWriteoffReferral = async (accountId) => {
  await axios.patch(`api/queues/writeoff-referral/${accountId}`, {
    action: 'reject',
  });
};

export const legalJournalAccounts = async () =>
  (await axios.get('api/queues/legal-journal')).data.map((account) => ({
    ...account,
    entry_date: new Date(account.entry_date),
  }));

export const legalJournalDone = async (accountId) => {
  await axios.patch(`api/queues/legal-journal/${accountId}`, {
    action: 'done',
  });
};
