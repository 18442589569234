<template>
  <Queue idKey="id" sortKey="entry_date" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Admin Order </template>
    <template #title> Broken CTP </template>
    <template v-slot:default="{ item: account }">
      <AdminOrderCTPBrokenQueueItem
        :account="account"
        @ctp="onCTP(account.id, $event)"
        @note="onNote"
        @defer="onDefer(account.id, $event)"
      />
    </template>
  </Queue>
</template>

<script>
import Queue from "@/components/Shared/Queue.vue";
import AdminOrderCTPBrokenQueueItem from "@/components/Admin/AdminOrderCTPBrokenQueueItem.vue";
import useActionQueue from "@/composables/useActionQueue";
import useAccountActions from "@/composables/useAccountActions";
import { fetchCTPBrokenAccounts, createCTP } from "@/api/adminOrder";
import { createNote } from "@/api/account";

export default {
  components: { Queue, AdminOrderCTPBrokenQueueItem },
  setup() {
    const { accounts, isLoading, updateAccount, removeAccount } = useActionQueue(
      fetchCTPBrokenAccounts
    );

    const { onDefer } = useAccountActions(updateAccount);
    const onNote = (payload) => {
      createNote(payload.accountId, payload.note);
    };

    const onCTP = async (id, data) => {
      const undo = removeAccount(id);
      try {
        await createCTP(id, data);
      } catch (error) {
        console.error(error);
        undo();
      }
    };

    return {
      accounts,
      isLoading,
      onCTP,
      onDefer,
      onNote,
    };
  },
};
</script>
