<template>
  <div class="mb-3">
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="inlineRadioOptions"
        id="inlineRadio1"
        value="false"
        v-model="createPosition"
      />
      <label class="form-check-label" for="inlineRadio1">Assign Position</label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="inlineRadioOptions"
        id="inlineRadio2"
        value="true"
        v-model="createPosition"
      />
      <label class="form-check-label" for="inlineRadio2">Create Position</label>
    </div>
  </div>
  <AssignUserPositionForm
    v-if="createPosition == 'false'"
    @position-assigned="positionAssigned"
  />
  <CreatePositionForm
    v-if="createPosition == 'true'"
    @position-created="createAndAssignPosition"
  />
  <button class="btn btn-primary mt-3" v-on:click="skip">Skip</button>
</template>

<script>
import { positionService } from "@/services/position.service";
import CreatePositionForm from "../Teams/CreatePositionForm.vue";
import AssignUserPositionForm from "./AssignUserPositionForm.vue";

// import * as yup from "yup";

export default {
  components: {
    CreatePositionForm,
    AssignUserPositionForm,
  },
  name: "CreateUserPositionAssign",
  emits: ["finalise-user", "position-assigned"],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      createPosition: "false",
    };
  },
  methods: {
    async positionAssigned(positionId) {
      const response = await positionService.assignPosition(positionId, this.user.id);
      if (response) {
        this.$store.dispatch("notification/push", {
          body: `Position Assigned`,
        });
        this.$emit("position-assigned", positionId);
      } else {
        this.$store.dispatch("notification/push", {
          body: `Position Assigned`,
          type: "error",
        });
      }
    },
    async createAndAssignPosition(newPosition) {
      const newPositionId = await positionService.createPosition(
        newPosition.team_id,
        newPosition.role_id,
        newPosition.reports_to
      );
      if (newPositionId) {
        this.$store.dispatch("notification/push", {
          body: `Position Created`,
        });
        positionService.assignPosition(newPositionId, this.user.id);
        this.$store.dispatch("notification/push", {
          body: `Position Assigned`,
        });
        this.$emit("position-assigned", newPositionId);
      } else {
        this.$store.dispatch("notification/push", {
          body: `Position Create Failed. Try Again`,
          type: "error",
        });
      }
    },
    skip() {
      this.$emit("finalise-user");
    },
  },
};
</script>

<style></style>
