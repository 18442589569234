<template>
  <div class="d-flex justify-content-left" v-if="!aodDone">
    <span class="">
      <button class="btn btn-success aod" @click="submit">
        <span class="material-icons-outlined"> done_all </span>
      </button>
      <span class="h4 ms-3"> Acknowledgement of Debt? </span>
    </span>
  </div>
</template>

<script>
import eventTypes from '@/helpers/eventTypes';
import { formatDate } from '@/helpers/format';

export default {
  name: 'AOD',
  emits: ['submit'],
  data() {
    return {
      eventTypes,
      aodDone: false,
      showConfirm: false,
    };
  },
  setup() {
    return {
      formatDate,
    };
  },
  methods: {
    submit() {
      const { name, type, closesAction } = eventTypes.AOD;
      this.$emit('submit', { type, name, closesAction });
      this.aodDone = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.aod {
  // background-color: inherit;
  padding: 0.25rem;
}
</style>
