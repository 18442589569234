<template>
  <section id="app-login" class="bg-blue">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->

    <nav class="navbar py-4 px-4 fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Qwise</a>
      </div>
    </nav>

    <div class="container">
      <div class="row">
        <div
          class="col-sm-5 offset-sm-1 d-flex flex-column justify-content-center"
        >
          <figure class="">
            <blockquote class="blockquote">
              <p class="text-white display-5">
                If you are positive, you'll see opportunities instead of
                obstacles.”
              </p>
            </blockquote>
            <figcaption class="blockquote-footer mt-2 text-white">
              Confucius <cite title="Source Title"></cite>
            </figcaption>
          </figure>
        </div>
        <div class="col-sm-4 offset-sm-1">
          <div id="app-login-form" class="rounded shadow">
            <h2 class="mb-3">Welcome Back</h2>
            <p class="text-muted mb-5">Please login to continue</p>
            <LoginForm class="mb-4" />
            <div class="text-center">
              <a
                href="#"
                class="small text-muted text-decoration-none"
                type="button"
                id="bankDropdownMenuBtn"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Forgot Password?
              </a>
              <ul class="dropdown-menu" aria-labelledby="bankDropdownMenuBtn">
                <div class="form-floating">
                  <input
                    v-model="username"
                    type="text"
                    class="form-control form-control-sm w-75 m-1"
                    id="resetUsername"
                    name="username"
                    placeholder="Username"
                  />
                  <label for="resetUsername">Username</label>
                </div>
                <button class="btn btn-primary m-1" v-on:click="passwordReset">
                  Reset Password
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import LoginForm from '../components/User/LoginForm.vue';

export default {
  name: 'Login',
  components: { LoginForm },
  data() {
    return {
      username: null,
    };
  },
  methods: {
    passwordReset() {
      axios.post('api/auth/reset-password', { username: this.username });
    },
  },
};
</script>

<style lang="scss" scoped>
#app-login {
  .navbar-brand {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.04rem;
  }

  min-height: 100vh;
  display: flex;
  align-items: center;

  #app-login {
    background: #000046; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to bottom,
      #1cb5e0,
      #000046
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to bottom,
      #1cb5e0,
      #000046
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    figure {
      padding: 10rem;
    }
  }

  #app-login-form {
    padding: 5rem;
    background-color: #fff;
  }
  /* align-items: center;
  justify-content: center; */
}
</style>
