<template>
  <div>
    <div class="mb-3">
      <div class="d-flex justify-content-between align-items-end">
        <h5>
          Escalate <span v-if="accounts">({{ count }})</span>
        </h5>
      </div>
    </div>
    <!--  -->
    <div v-if="accounts">
      <Queue
        idKey="id"
        sortKey="entry_date"
        :items="accounts"
        v-slot:default="{ item: account }"
      >
        <EscalateQueueItem
          :account="account"
          :agents="teamAgents"
          @action="action"
          @view="view"
        />
      </Queue>
    </div>
    <!--  -->
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Queue from '@/components/Shared/Queue.vue';
import EscalateQueueItem from '@/components/Supervisor/EscalateQueueItem.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'EscalateQueue',
  emits: ['view'],
  props: {
    supervisorPositionId: Number,
    teamId: Number,
  },
  components: {
    LoadingSpinner,
    Queue,
    EscalateQueueItem,
  },
  data() {
    return {
      isLoading: true,
      accounts: null,
      team: null,
      teamAgents: null,
    };
  },
  computed: {
    count() {
      return this.accounts?.length;
    },
    // teamAgents() {
    //   return this.team?.positions?.filter((p) => p.role.toUpperCase() === "AGENT");
    // },
  },
  methods: {
    async action(event) {
      const { accountId, payload } = event;
      this.remove(accountId);
      try {
        const url = `api/supervisor/${this.supervisorPositionId}/queues/escalated/${accountId}`;
        await axios.patch(url, payload);
      } catch (error) {
        this.$store.dispatch('notification/push', {
          body: `Action Failed`,
          type: 'error',
        });
        console.error(error);
      }
    },
    remove(accountId) {
      this.accounts = this.accounts.filter((a) => a.id !== accountId);
    },
    view(id) {
      this.$emit('view', id);
    },
  },
  async mounted() {
    try {
      this.accounts = (
        await axios.get(
          `api/supervisor/${this.supervisorPositionId}/queues/escalated`
        )
      ).data.map((a) => ({
        ...a,
        due_date_time: new Date(a.due_date_time),
        entry_date: new Date(a.entry_date),
        event_note: JSON.parse(a.event_note),
      }));

      if (this.accounts.length) {
        // get team
        this.team = (await axios.get(`api/teams/${this.teamId}`)).data;
      }
    } catch (error) {
      console.error(error);
    }
    this.teamAgents = (await axios.get('api/positions/collection-agents')).data;
    console.log(this.teamAgents);
    this.isLoading = false;
  },
};
</script>
