<template>
  <select class="form-select" v-model="model">
    <option :value="null" disabled selected>Select a user</option>
    <option
      v-for="agent in teamAgents"
      :key="agent.userPositionId"
      :value="agent.userPositionId"
    >
      {{ agent.username }}
    </option>
  </select>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AgentSelect',
  props: ['teamId', 'modelValue'],
  emits: ['update:modelValue'],
  setup() {},
  data() {
    return {
      teamAgents: null,
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    async fetchTeamAgents() {
      this.teamAgents = [];
      try {
        this.teamAgents = (
          await axios.get(`/api/teams/${this.teamId}`)
        ).data.positions
          .filter((p) => p.role.toUpperCase() === 'AGENT')
          .map((p) => ({
            userPositionId: p.user_position_id,
            username: p.assigned_user,
          }));
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    teamId() {
      this.fetchTeamAgents();
    },
  },
  mounted() {
    this.fetchTeamAgents();
  },
};
</script>