<template>
  <teleport to="#modals">
    <!-- Modal -->
    <div
      class="modal fade"
      id="newBankModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Bank Account</h5>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p class="card-text text-muted me-auto">
              * This bank account will be used for any future transactions
            </p>
            <Form
              @submit="addBank"
              :validation-schema="addNewBankValidation"
              v-if="!isLoading"
            >
              <div class="form-floating mb-3">
                <Field
                  name="bank"
                  class="form-control"
                  type="text"
                  id="bank"
                  placeholder="Bank"
                  v-model="accountInfo.bank"
                />
                <label for="bank">Bank Name</label>
                <ErrorMessage name="bank" class="error-message" />
              </div>
              <div class="form-floating mb-3">
                <Field
                  name="account_number"
                  class="form-control"
                  type="text"
                  id="account_number"
                  placeholder="Account Number"
                  v-model="accountInfo.account_number"
                />
                <label for="account_number">Account Number</label>
                <ErrorMessage name="account_number" class="error-message" />
              </div>
              <div class="form-floating mb-3">
                <Field
                  name="account_holder"
                  class="form-control"
                  type="text"
                  id="account_holder"
                  placeholder="Account Holder"
                  v-model="accountInfo.accountHolder"
                />
                <label for="account_holder">Account Holder</label>
                <ErrorMessage name="account_holder" class="error-message" />
              </div>
              <div class="form-floating mb-3">
                <Field
                  name="account_type"
                  class="form-select"
                  as="select"
                  id="account_type"
                  placeholder="Account Type"
                  v-model="accountInfo.accountType"
                >
                  <option v-for="(type, index) in typeOptions" :key="index" :value="type">
                    {{ index }}
                  </option>
                </Field>
                <label for="account_type">Account Type</label>
                <ErrorMessage name="account_type" class="error-message" />
              </div>
              <div class="form-floating mb-3">
                <Field
                  name="branch_code"
                  class="form-control"
                  type="text"
                  id="branch_code"
                  placeholder="Branch Code"
                  v-model="accountInfo.branch_code"
                />
                <label for="branch_code">Branch Code</label>
                <ErrorMessage name="branch_code" class="error-message" />
              </div>
              <button class="btn btn-primary">Submit</button>
            </Form>
            <LoadingSpinner v-else />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "NewBankAccountModal",
  components: { Form, Field, ErrorMessage, LoadingSpinner },
  computed: {
    ...mapGetters({
      positions: "auth/permissions",
      account: "account/activeAccount",
    }),
  },
  data() {
    const addNewBankValidation = yup.object({
      bank: yup.string().required("Bank is required."),
      branch_code: yup
        .string()
        .required("Branch Code is required.")
        .matches(/^([0]([.][0-9]+)?|[1-9]([0-9]+)?([.][0-9]+)?)$/, {
          message: "Branch code can only contain numbers",
        }),
      account_number: yup
        .string()
        .required("Account number is required.")
        .matches(/^([0]([.][0-9]+)?|[1-9]([0-9]+)?([.][0-9]+)?)$/, {
          message: "Account number can only contain numbers",
        })
        .test("len", "Must be at least 10 characters", (val) => val.length >= 10),
      account_holder: yup.string().required("Account Holder is required."),
      // account_type: yup.required("Account number is required."),
    });
    return {
      isLoading: false,
      addNewBankValidation,
      accountInfo: {
        account_number: "",
        accountHolder: "",
        bank: "",
        branch_code: "",
        accountType: null,
        ilsNum: null,
      },
      typeOptions: {
        Current: "CUR",
        Savings: "SAV",
        Cheque: "CUR",
        Transmission: "TRA",
        Bond: "BON",
        Subscription: "sub",
      },
    };
  },
  methods: {
    async addBank() {
      const userPositionId = this.positions.filter(
        (p) =>
          p.role.toUpperCase() === "AGENT" ||
          "INBOUND AGENT" ||
          "ADMIN" ||
          "SUPERVISOR" ||
          "MANAGER" ||
          "SUPER USER"
      )[0].user_position_id;
      this.isLoading = true;
      const { accountInfo } = this;
      accountInfo.firstname = this.account.customer_details.Firstname;
      accountInfo.lastname = this.account.customer_details.Surname;
      accountInfo.initials = this.account.customer_details.Initials;
      accountInfo.ilsNum = this.account.account_details.ExternalAccountRef;
      try {
        const response = await axios.post(`/api/accounts/${this.account.id}/bank`, {
          accountInfo,
          userPositionId,
        });
        if (response.status === 200) {
          this.$store.dispatch("notification/push", {
            body: `Info Update Sent`,
          });
        } else {
          this.$store.dispatch("notification/push", {
            body: `Info Update Failed`,
            type: "error",
          });
        }
      } catch {
        this.$store.dispatch("notification/push", {
          body: `Something went wrong`,
          type: "error",
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
