<template>
  <teleport to="#modals">
    <div
      class="modal fade"
      id="transactionsModal"
      ref="transactionsModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLabel">Transactions</h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="!isLoading">
              <div class="mb-2">
                <div
                  v-for="type in transactionTypes"
                  :key="type"
                  class="form-check form-check-inline"
                >
                  <input
                    v-model="typeFilter"
                    :value="type"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${type}TransactionTypeFilter`"
                  />
                  <label
                    class="form-check-label"
                    :for="`${type}TransactionTypeFilter`"
                    >{{ type }}</label
                  >
                </div>
              </div>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <!-- <th>Date Captured</th> -->
                    <th>Date</th>
                    <th>Description</th>
                    <th class="currency">Amount</th>
                    <th class="currency">Running Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(transaction, index) in sortedTransactions"
                    :key="index"
                    :class="{
                      receipt: transaction.type === 'receipt',
                      reversal: transaction.type === 'reversal',
                    }"
                  >
                    <!-- <td>{{ transaction }}</td> -->
                    <td class="date">{{ transaction.date_effective }}</td>
                    <td>
                      {{ transaction.description }}
                    </td>
                    <td class="currency">
                      {{ formatCurrency(transaction.amount) }}
                    </td>
                    <td class="currency">
                      {{ formatCurrency(transaction.balance) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-else>
              <LoadingSpinner />
            </div>
          </div>
          <!--  <div class="modal-footer">
            <div
              class="btn-group mr-2"
              role="group"
              aria-label="Transactions Modal Nav Buttons"
            >
            <button
              :disabled="!prevPage"
              class="btn btn-primary"
              v-on:click="previousPage"
            >
              &lt;
            </button>
            <button
              :disabled="!morePages"
              class="btn btn-primary"
              v-on:click="nextPage"
            >
              >
            </button>
            </div>

          </div> -->
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

import { formatCurrency } from '@/helpers/format';

export default {
  name: 'TransactionsModal',
  components: { LoadingSpinner },
  setup() {
    return { formatCurrency };
  },
  data() {
    return {
      pageNumber: 0,
      size: 10,
      sortBy: 'date_effective',
      morePages: true,
      prevPage: false,
      pageCount: 0,
      typeFilter: [],
      // paginatedTransactions: [],
    };
  },
  computed: {
    ...mapGetters({
      transactions: 'account/transactions',
      activeAccount: 'account/activeAccount',
      isLoading: 'account/isTransactionsLoading',
    }),
    // pageCount() {
    //   const totalTransactions = this.transactions.length;
    //   return Math.ceil(totalTransactions / this.size);
    // },
    transactionTypes() {
      return this.transactions && this.transactions.length
        ? [...new Set(this.transactions.map((e) => e.type))]
        : [];
    },
    filteredTransactions() {
      return this.typeFilter.length
        ? this.transactions.filter((t) => this.typeFilter.includes(t.type))
        : this.transactions;
    },
    sortedTransactions() {
      return this.filteredTransactions && this.filteredTransactions.length
        ? [...this.filteredTransactions].sort((t1, t2) =>
            t1[this.sortBy] > t2[this.sortBy] ? -1 : 1
          )
        : [];
    },
    paginatedTransactions() {
      const start = this.pageNumber * this.size;
      const end = start + this.size;
      return this.sortedTransactions.slice(start, end);
    },
  },
  methods: {
    nextPage() {
      this.pageNumber += 1;
      if (this.pageNumber > this.pageCount - 2) {
        this.morePages = false;
      }
      if (this.pageNumber > 0) {
        this.prevPage = true;
      }
    },
    previousPage() {
      this.pageNumber -= 1;
      if (this.pageNumber < this.pageCount) {
        this.morePages = true;
      }
      if (this.pageNumber <= 0) {
        this.prevPage = false;
      }
    },
    resetModal() {
      this.typeFilter = [];
      this.prevPage = false;
      this.pageNumber = 0;
      this.morePages = true;
    },
    async getTransactions() {
      await this.$store.dispatch('account/getTransactions', {
        accRef: this.activeAccount?.account_details?.ExternalAccountRef,
      });
      this.resetModal();
      const totalTransactions = this.transactions.length;
      this.pageCount = Math.ceil(totalTransactions / this.size);
    },
  },
  mounted() {
    this.$refs.transactionsModal.addEventListener('show.bs.modal', () => {
      this.getTransactions();
    });
  },
};
</script>

<style scoped>
.receipt {
  background-color: #d1e7dd;
}

.reversal {
  background-color: #f8d7da;
}

.currency {
  font-variant-numeric: tabular-nums;
  text-align: right;
}
</style>
