<template>
  <div class="page">
    <div class="page-content">
      <Nav />
      <main class="px-3 my-5">
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Shared/Nav.vue';

export default {
  name: 'Page',
  components: {
    Nav,
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
}

.page-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex-grow: 1;
  }
}
</style>