<template>
  <Queue idKey="id" sortKey="due_date_time" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Admin Order </template>
    <template #title> Referral </template>
    <template #default="{ item }">
      <AdminOrderReferralQueueItem
        :account="item"
        @ctp="onCTP(item.id, $event)"
        @reject="onReject(item.id)"
        @defer="onDefer(item.id, $event)"
        @note="onNote"
      />
    </template>
  </Queue>
</template>

<script>
import useActionQueue from "@/composables/useActionQueue";
import useAccountActions from "@/composables/useAccountActions";
import { fetchReferralAccounts, createCTP, reject } from "@/api/adminOrder";

import Queue from "@/components/Shared/Queue.vue";
import AdminOrderReferralQueueItem from "@/components/Admin/AdminOrderReferralQueueItem.vue";
import { createNote } from "@/api/account";

export default {
  name: "AdminOrderReferralQueue",
  components: {
    AdminOrderReferralQueueItem,
    Queue,
  },
  setup() {
    // const { loadAccounts, removeAccount, accounts, isLoading } = useActionQueue(fetchReferralAccounts);
    const { accounts, isLoading, updateAccount, removeAccount } = useActionQueue(
      fetchReferralAccounts
    );

    // const { onDefer, onNote } = useAccountActions(updateAccount);
    const { onDefer } = useAccountActions(updateAccount);

    const onNote = (payload) => {
      createNote(payload.accountId, payload.note);
    };

    const onCTP = async (id, data) => {
      const undo = removeAccount(id);
      try {
        await createCTP(id, data);
      } catch (error) {
        console.error(error);
        undo();
      }
    };

    const onReject = async (id) => {
      const undo = removeAccount(id);
      try {
        await reject(id);
      } catch (error) {
        console.error(error);
        undo();
      }
    };

    return {
      accounts,
      isLoading,
      updateAccount,
      removeAccount,
      onCTP,
      onReject,
      onDefer,
      onNote,
    };
  },
};
</script>
