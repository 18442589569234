<template>
  <div id="Splash">
    <img class="img-fluid" alt="Qwise logo" src="@/assets/logo_x2.png" />
  </div>
</template>

<style lang="scss">
#Splash {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    animation: pulse 2s infinite;
    animation-timing-function: ease;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
</style>