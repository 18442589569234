<template>
  <section id="agentDash">
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col pb-4">
          <!-- <div class="row mb-4">
            <div class="col">
              <AgentAssignmentsCard/>
            </div>
          </div> -->

          <div class="row">
            <div class="col-3 pb-4">
              <AgentCTPsCard />
            </div>
            <div class="col-3">
              <AgentTargetsCard />
            </div>
            <div class="col-3">
              <AgentProductivityCard class="mb-4" />
            </div>
            <div class="col-3">
              <AgentTimeBreakdownCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

// import AgentAssignmentsCard from "@/components/Dash/AgentAssignmentsCard.vue";
import AgentCTPsCard from '@/components/Dash/AgentCTPsCard.vue';
import AgentTimeBreakdownCard from '@/components/Dash/AgentTimeBreakdownCard.vue';
import AgentTargetsCard from '@/components/Dash/AgentTargetsCard.vue';
import AgentProductivityCard from '@/components/Dash/AgentProductivityCard.vue';

export default {
  name: 'AgentDash',
  components: {
    // AgentAssignmentsCard,
    AgentCTPsCard,
    AgentTimeBreakdownCard,
    AgentTargetsCard,
    AgentProductivityCard,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isLoading: 'auth/isLoading',
      permissions: 'auth/permissions',
    }),
  },
  async mounted() {
    await this.$store.dispatch('activity/getActivityTypes');
    if (!this.currentActivityId) {
      this.$store.dispatch('activity/saveActivity', {
        userId: this.user.id,
        activityId: 6,
      });
    }
  },
};
</script>
