<template>
  <Queue idKey="id" sortKey="entry_date" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Legal </template>
    <template #title> Collections Writeoff </template>
    <template #default="{ item }">
      <CollectionsWriteoffQueueItem
        :account="item"
        @reject="onReject(item.id)"
        @note="onNote"
        @done="onDone(item.id)"
      />
    </template>
  </Queue>
</template>

<script>
import useActionQueue from "@/composables/useActionQueue";
import Queue from "@/components/Shared/Queue.vue";
import CollectionsWriteoffQueueItem from "@/components/Admin/CollectionsWriteoffQueueItem.vue";
import {
  fetchCollectionsWriteoffAccounts,
  rejectCollectionsWriteoffAccount,
  writeoffDone,
} from "@/api/collections";
import { createNote } from "@/api/account";

export default {
  components: { Queue, CollectionsWriteoffQueueItem },
  setup() {
    const { accounts, isLoading, removeAccount } = useActionQueue(
      fetchCollectionsWriteoffAccounts
    );

    const onNote = (payload) => {
      createNote(payload.accountId, payload.note);
    };

    const onReject = async (id) => {
      const undo = removeAccount(id);
      try {
        await rejectCollectionsWriteoffAccount(id);
      } catch (error) {
        console.error(error);
        undo();
      }
    };

    const onDone = async (id) => {
      const undo = removeAccount(id);
      try {
        await writeoffDone(id);
      } catch (error) {
        console.error(error);
        undo();
      }
    };

    return {
      accounts,
      isLoading,
      onNote,
      removeAccount,
      onReject,
      onDone,
    };
  },
};
</script>
