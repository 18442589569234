<template>
  <div class="init h-95 d-flex flex-column justify-content-center">
    <div class="d-grid gap-3">
      <button @click="$emit('choice', 'rpc')" class="btn btn-lg btn-primary">RPC</button>
      <button @click="$emit('choice', 'nrpc')" class="btn btn-lg btn-primary">
        NRPC
      </button>
      <button @click="$emit('choice', 'escalate')" class="btn btn-lg btn-primary">
        Escalate
      </button>
      <button @click="$emit('choice', 'journal')" class="btn btn-lg btn-primary">
        Journal
      </button>
    </div>
  </div>
  <AOD @submit="submit" />
</template>

<script>
import AOD from "./AOD.vue";

export default {
  components: { AOD },

  name: "Init",
  emits: ["choice", "done"],
  // emits: ['timeout'],
  data() {
    return {
      // interval: null,
      // duration: 30,
      choice: null,
    };
  },
  // activated() {
  // this.start();
  // },
  // deactivated() {
  // clearInterval(this.interval);
  // },
  methods: {
    submit(payload) {
      this.$emit("submit", payload);
    },
    // start() {
    // this.interval = setInterval(this.tick, 1000);
    // },
    // tick() {
    // this.duration -= 1;
    // this.checkTimer(this.duration);
    // },
    // checkTimer(duration) {
    // if (duration <= 0) {
    // this.timeout();
    // }
    // },
    // timeout() {
    // clearInterval(this.interval);
    // this.$emit('timeout');
    // },
  },
};
</script>

<style scoped>
.init {
  text-align: center;
}
.time {
  display: block;
  font-variant-numeric: tabular-nums;
  font-size: 3rem;
}
</style>
