<template>
  <div v-if="accounts.length > 0">
    <div class="card mb-3" v-for="(account, index) in accounts" :key="index">
      <div class="card-body">
        <div class="row">
          <div class="col-9">
            <h4>
              {{ account.Title }} {{ account.Firstname }} {{ account.Surname }}
            </h4>
            <h5 class="card-title">
              {{ account.ExternalAccountRef }}
            </h5>
            <span class="badge rounded-pill bg-primary mb-2">
              {{ account.Queue_Name }}
            </span>
            <ul class="list-unstyled m-0">
              <li>
                Arrears Amount:
                <span v-if="account.TotalArrears && account.TotalArrears > 0"
                  >{{ formatCurrency(account.TotalArrears) }}
                </span>
                <span v-else> N/A </span>
              </li>
              <li>
                Outstanding Balance:
                <span
                  v-if="
                    account.OutstandingBalance && account.OutstandingBalance > 0
                  "
                >
                  <!-- <span> -->
                  {{ formatCurrency(account.OutstandingBalance) }}
                </span>
                <span v-else> N/A </span>
              </li>
              <li>
                Situation:
                <span v-if="account.Situation">
                  {{ account.Situation }}
                </span>
                <span v-else> N/A </span>
              </li>
              <li>
                <span v-if="account.IDNumber">
                  ID Number:
                  {{ account.IDNumber }}
                </span>
                <span v-else-if="account.Passport_Number">
                  Passport Number:
                  {{ account.Passport_Number }}
                </span>
                <span v-else> ID/Passport Number: N/A </span>
              </li>
              <li>
                Client Number:
                <span v-if="account.ClientNumber">
                  {{ account.ClientNumber }}
                </span>
                <span v-else> N/A </span>
              </li>
            </ul>
          </div>
          <div class="d-flex flex-column gap-2 col-3">
            <button
              @click="viewAccount(account.AccountID)"
              class="btn btn-primary"
              type="button"
            >
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="mt-2 ms-2" v-else>Search returned nothing</p>
</template>

<script>
import { formatCurrency } from '@/helpers/format';

export default {
  name: 'AccountList',
  props: {
    accounts: Array,
  },
  setup() {
    return { formatCurrency };
  },
  methods: {
    viewAccount(id) {
      this.$router.push({ name: 'account', params: { id } });
    },
  },
};
</script>

<style scoped></style>
