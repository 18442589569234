<template>
  <div>
    <div class="mb-3">
      <div class="d-flex justify-content-between align-items-end">
        <h5>
          Payment Dispute <span v-if="accounts">({{ count }})</span>
        </h5>
      </div>
    </div>
  </div>

  <div v-if="accounts">
    <Queue
      idKey="id"
      sortKey="entry_date"
      :items="accounts"
      v-slot:default="{ item: account }"
    >
      <QueueItem :account="account">
        <template #tag>
          <span class="small d-block mb-3">
            On {{ account.entry_date.toLocaleString() }}
          </span>
        </template>
        <template #title>{{ account.reference }}</template>
        <template #body>
          <ul class="list-unstyled m-0">
            <li>Date: {{ account.event_note.date }}</li>
            <li>Amount: {{ account.event_note.amount }}</li>
            <li>Details: {{ account.event_note.details }}</li>
          </ul>
        </template>
        <template #actions>
          <button
            @click="action(account.id, eventTypes.ACCEPT_PAYMENT_DISPUTE)"
            class="btn btn-primary"
            type="button"
          >
            Accept
          </button>
          <button
            @click="action(account.id, eventTypes.REJECT_PAYMENT_DISPUTE)"
            class="btn btn-primary"
            type="button"
          >
            Reject
          </button>
        </template>
      </QueueItem>
    </Queue>
  </div>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>

  <!-- <div>
    <div class="position-relative" v-if="queue && accounts.length >= 1">
      <transition-group name="list-complete">
        <div v-for="account in accounts" :key="account.id" class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <span class="small d-block mb-3">
                  On {{ account.entry_date.toLocaleString() }}</span
                >

                <h5 class="card-title">
                  {{ account.reference }}
                </h5>
                <hr />
                <p class="lead">Details:</p>
                <ul class="list-unstyled m-0">
                  <li>Date: {{ account.event_note.date }}</li>
                  <li>Amount: {{ account.event_note.amount }}</li>
                  <li>Details: {{ account.event_note.details }}</li>
                </ul>
              </div>
              <div class="d-flex flex-column gap-2 col-3">
                <button
                  @click="action(account.id, eventTypes.ACCEPT_PAYMENT_DISPUTE.type)"
                  class="btn btn-primary"
                  type="button"
                >
                  Accept
                </button>
                <button
                  @click="action(account.id, eventTypes.REJECT_PAYMENT_DISPUTE.type)"
                  class="btn btn-primary"
                  type="button"
                >
                  Reject
                </button>
                <button @click="view(account.id)" class="btn btn-primary" type="button">
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <div v-else-if="queue.id && accounts.length === 0">Nothing in Queue</div>
    <div v-else>
      <LoadingSpinner />
    </div>
  </div> -->
</template>

<script>
import axios from 'axios';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
import Queue from '@/components/Shared/Queue.vue';
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import { mapGetters } from 'vuex';
import eventTypes from '@/helpers/eventTypes';

export default {
  name: 'PaymentDisputeQueue',
  components: { LoadingSpinner, Queue, QueueItem },
  emits: ['view'],

  computed: {
    ...mapGetters({
      positions: 'auth/permissions',
    }),
    count() {
      return this.accounts?.length;
    },
  },
  data() {
    return {
      accounts: null,
      isLoading: true,
      eventTypes,
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.queue = (await axios.get('api/admin/queues/payment-dipute')).data;
      this.accounts = this.queue.accounts.map((account) => {
        const tempAcc = account;
        tempAcc.event_note = JSON.parse(tempAcc.event_note);
        tempAcc.entry_date = new Date(tempAcc.entry_date);
        return tempAcc;
      });
      this.isLoading = false;
    },
    async action(id, action) {
      let activePositionId = this.positions.find(
        (position) => position.role.toUpperCase() === 'ADMIN'
      )?.user_position_id;
      if (!activePositionId) {
        activePositionId = this.positions.find(
          (position) => position.role.toUpperCase() === 'MANAGER'
        )?.user_position_id;
      }
      this.remove(id);
      try {
        await axios.post('api/admin/queues/payment-dipute', {
          eventTypeId: action.type,
          account_id: id,
          user_position_id: activePositionId,
        });
        this.$store.dispatch('notification/push', {
          body: `Action confirmed`,
        });
        this.$store.dispatch('account/clearCurrentAccount');
        this.queue = (await axios.get('api/admin/queues/payment-dipute')).data;
        this.accounts = this.queue.accounts.map((account) => {
          const tempAcc = account;
          tempAcc.event_note = JSON.parse(tempAcc.event_note);
          tempAcc.entry_date = new Date(tempAcc.entry_date);
          return tempAcc;
        });
      } catch (e) {
        this.$store.dispatch('notification/push', {
          body: `Action failed...`,
        });
        await this.getData();
      }
    },
    remove(accountId) {
      this.accounts = this.accounts.filter((a) => a.id !== accountId);
    },
  },
};
</script>
