<template>
  <Form @submit="createAssignPosition">
    <div class="form-floating mb-3">
      <Field
        name="team"
        class="form-select"
        as="select"
        id="team"
        placeholder="Team"
        v-model="team"
        :disabled="currentTeam"
      >
        <option v-if="currentTeam" :value="currentTeam.id">
          {{ currentTeam.name }}
        </option>
        <option
          v-else
          v-for="(team, index) in teamOptions"
          :key="index"
          :value="team.id"
        >
          {{ team.name }}
        </option>
      </Field>
      <label for="team">Team</label>
      <ErrorMessage name="team" class="error-message" />
    </div>
    <div class="form-floating mb-3" v-if="teamSelected || currentTeam">
      <Field
        name="role"
        class="form-select"
        as="select"
        id="role"
        placeholder="Role"
        v-model="role"
      >
        <option
          v-for="(role, index) in roleOptions"
          :key="index"
          :value="role.id"
        >
          {{ role.role }}
        </option>
      </Field>
      <label for="role">Role</label>
      <ErrorMessage name="role" class="error-message" />
    </div>
    <div v-if="showReportsTo && isPositionListLoading" class="mb-2 muted">
      Loading positions...
    </div>
    <div
      class="form-floating mb-3"
      v-if="showReportsTo && !isPositionListLoading"
    >
      <Field
        name="reportsTo"
        class="form-select"
        as="select"
        id="reportsTo"
        placeholder="Reports To"
        v-model="reportsTo"
      >
        <option
          v-for="(position, index) in reportsToUsernameList"
          :key="index"
          :value="position.id"
        >
          {{ position.username }}
        </option>
      </Field>
      <label for="reportsTo">Reports To</label>
      <ErrorMessage name="reportsTo" class="error-message" />
    </div>
    <button type="submit" class="btn btn-primary" :disabled="!assignReady">
      Create
    </button>
  </Form>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
  name: 'CreateUserForm',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  emits: ['position-created'],
  computed: {
    ...mapGetters({
      currentTeam: 'team/team',
      positionList: 'team/allPositions',
      reportsToUsernameList: 'team/reportsToUsernameList',
      isPositionListLoading: 'team/isPositionListLoading',
      roleOptions: 'auth/roleList',
    }),
  },
  data() {
    return {
      team: '',
      role: '',
      teamSelected: false,
      reportsTo: '',
      showReportsTo: false,
      assignReady: false,
      teamOptions: [],
    };
  },
  watch: {
    currentTeam(val) {
      if (val) this.team = val.id;
    },
    team(val) {
      if (val) {
        this.teamSelected = true;
      }
    },
    role(val) {
      if (val) {
        this.showReportsTo = true;
      }
    },
    reportsTo(val) {
      if (val) {
        this.assignReady = true;
      }
    },
  },
  methods: {
    createAssignPosition() {
      const newPosition = {
        team_id: this.team,
        role_id: this.role,
        reports_to: this.reportsTo,
      };
      this.$emit('position-created', newPosition);
    },
  },
  async mounted() {
    this.teamOptions = (await axios.get('api/teams')).data;
    if (this.currentTeam) {
      this.team = this.currentTeam.id;
    }
    this.$store.dispatch('team/getReportsToList', this.team?.name);
  },
};
</script>

<style></style>
