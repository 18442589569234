import axios from 'axios';
// import { wait } from '../helpers/general';

const accountStore = {
  namespaced: true,
  state() {
    return {
      activeAccount: null,
      isAssignedAccount: false,
      isLoading: false,
      errors: [],
      notes: [],
      isNotesLoading: false,
      events: [],
      isEventsLoading: false,
      docs: [],
      isDocsLoading: false,
      transactions: [],
      isTransactionsLoading: false,
      history: null,
      isHistoryLoading: false,
      phoneNumbers: [],
      docsToDelete: false,
    };
  },
  getters: {
    docsToDelete(state) {
      return state.docsToDelete;
    },
    phoneNumbers(state) {
      const tempPhoneNumbers = [
        state.activeAccount?.customer_details.Phone_Cell,
        state.activeAccount?.customer_details.Phone_Home,
        state.activeAccount?.customer_details.Phone_Work,
        state.activeAccount?.customer_details.Phone_work_alt,
      ].filter((n) => n);
      const uniquePhoneNumbers = [...new Set(tempPhoneNumbers)];
      return uniquePhoneNumbers;
    },
    isLoading(state) {
      return state.isLoading;
    },
    isAssignedAccount(state) {
      return state.isAssignedAccount;
    },
    activeAccount(state) {
      return state.activeAccount;
    },
    activeAccKey(state) {
      return state.activeAccount.account_details.AccountKey;
    },
    activeAccRef(state) {
      return state.activeAccount.account_details.ExternalAccountRef;
    },
    errors(state) {
      return state.errors;
    },
    // notes
    notes(state) {
      return state.notes;
    },
    isNotesLoading(state) {
      return state.isNotesLoading;
    },
    // events
    events(state) {
      return state.events;
    },
    isEventsLoading(state) {
      return state.isEventsLoading;
    },
    // docs
    docs(state) {
      return state.docs;
    },
    isDocsLoading(state) {
      return state.isDocsLoading;
    },
    // transactions
    transactions(state) {
      return state.tranactions;
    },
    isTransactionsLoading(state) {
      return state.isTransactionsLoading;
    },
    // history
    history(state) {
      return state.history;
    },
    isHistoryLoading(state) {
      return state.isHistoryLoading;
    },
  },
  mutations: {
    docsDownloaded(state) {
      state.docsToDelete = true;
    },
    docsDeleted(state) {
      state.docsToDelete = false;
    },
    accountRequest(state) {
      state.activeAccount = null;
      state.errors = [];
      state.isLoading = true;
    },
    accountLoaded(state, account) {
      state.isAssignedAccount = true;
      state.activeAccount = account;
      state.isLoading = false;
    },
    accountLoadFailed(state, error) {
      state.isAssignedAccount = false;
      state.activeAccount = null;
      state.isLoading = false;
      state.errors.push(error);
    },
    clearActiveAccount(state) {
      state.isAssignedAccount = false;
      state.activeAccount = null;
      state.phoneNumbers = [];
    },
    // notes
    notesRequest(state) {
      state.notes = [];
      state.errors = [];
      state.isNotesLoading = true;
    },
    notesLoaded(state, notes) {
      state.notes = notes;
      state.isNotesLoading = false;
    },
    // events
    eventsRequest(state) {
      state.isEventsLoading = true;
    },
    eventsLoaded(state, events) {
      state.isEventsLoading = false;
      state.events = events;
    },
    eventsRequestFailed(state, error) {
      state.isEventsLoading = false;
      state.errors.push(error);
    },
    // docs
    docsRequest(state) {
      state.isDocsLoading = true;
    },
    docsReturned(state, docs) {
      state.docs = docs;
      state.isDocsLoading = false;
    },
    docsLoaded(state, docs) {
      state.isDocsLoading = false;
      state.docs = docs;
    },
    clearDocs(state) {
      state.docs = [];
    },
    // transactions
    transactionsRequest(state) {
      state.transactions = [];
      state.errors = [];
      state.isTransactionsLoading = true;
    },
    transactionsLoaded(state, transactions) {
      state.tranactions = transactions;
      state.isTransactionsLoading = false;
    },
    // history
    historyRequest(state) {
      state.history = null;
      state.errors = [];
      state.isHistoryLoading = true;
    },
    historyLoaded(state, history) {
      state.history = history;
      state.isHistoryLoading = false;
    },
    historyRequestFailed(state, error) {
      state.isHistoryLoading = false;
      state.errors.push(error);
    },
  },
  actions: {
    async getAccount({ commit }, id) {
      commit('accountRequest');
      try {
        const account = (await axios.get(`/api/accounts/${id}`)).data;
        commit('accountLoaded', account);
      } catch (error) {
        commit('accountLoadFailed', { message: error.response.data.error });
      }
    },
    async getAccountInbound({ commit }, id) {
      commit('accountRequest');
      try {
        const account = (await axios.get(`/api/accounts/${id}`, { params: { inbound: true } }))
          .data;
        commit('accountLoaded', account);
      } catch (error) {
        commit('accountLoadFailed', { message: error.response.data.error });
      }
    },
    clearCurrentAccount({ commit, state }) {
      commit('clearDocs');
      // if (state.activeAccount) {
      if (state.activeAccount && state.docsToDelete) {
        axios.delete(`api/accounts/documents/list`, {
          params: {
            ils_ref: state.activeAccount.account_details.ExternalAccountRef,
          },
        });
        commit('docsDeleted');
      }
      commit('clearActiveAccount');
    },
    async getNotes({ commit, state }, payload) {
      commit('notesRequest');
      try {
        const notes = (
          await axios.get(`/api/account/${state.activeAccount.id}/notes`, { params: payload })
        ).data;
        commit('notesLoaded', notes);
      } catch {
        console.error('Something went wrong getting the notes');
      }
    },
    docsDownloadRequest({ commit }) {
      commit('docsDownloaded');
    },
    async getEvents({ commit }, id) {
      commit('eventsRequest');
      try {
        const events = (await axios.get(`/api/accounts/${id}/events`)).data;
        commit('eventsLoaded', events);
      } catch {
        commit('eventsRequestFailed', 'Something went wrong while getting the events');
      }
    },
    async getDocs({ commit }, accRef) {
      commit('docsRequest');

      const docs = (
        await axios.get(`api/accounts/documents/list`, {
          params: { acc_ref: accRef },
        })
      ).data;
      commit('docsReturned', docs);
    },
    async getTransactions({ commit }, payload) {
      commit('transactionsRequest');
      try {
        const transactions = (
          await axios.get('/api/account/transactions', {
            params: { accRef: payload.accRef },
          })
        ).data;
        commit('transactionsLoaded', transactions);
      } catch {
        console.error('Something went wrong getting the transactions');
      }
    },
    async getHistory({ commit }, id) {
      commit('historyRequest');
      try {
        const history = (await axios.get(`/api/accounts/${id}/history`)).data;
        commit('historyLoaded', history);
      } catch {
        commit('historyRequestFailed', 'Something went wrong while getting the history');
      }
    },
  },
};

export default accountStore;
