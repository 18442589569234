<template>
  <RoundButton
    @click="showNoteArea = !showNoteArea"
    large
    title="Create Note"
    class="btn-action text-secondary"
  >
    <span class="material-icons"> notes </span>
  </RoundButton>
  <form @submit.prevent="submit" v-if="showNoteArea">
    <textarea class="form-control mt-2" v-model="note"></textarea>
    <button class="btn btn-success mt-2" type="submit">Done</button>
  </form>
</template>

<script>
import eventTypes from '@/helpers/eventTypes';
import RoundButton from '@/components/Shared/_RoundButton.vue';

export default {
  name: 'ActionQueueNote',
  emits: ['done'],
  components: { RoundButton },
  data() {
    return {
      showNoteArea: false,
      note: '',
      ...eventTypes.FREE_FORM,
    };
  },
  methods: {
    submit() {
      const { name, type, closesAction } = eventTypes.FREE_FORM;
      const details = {
        note: this.note,
      };
      this.$emit('done', { type, name, closesAction, details });
      this.note = '';
      this.showNoteArea = false;
    },
  },
};
</script>

<style></style>
