<template>
  <Queue
    idKey="id"
    sortKey="entry_date"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #subtitle> Legal </template>
    <template #title> Pending Section 129 </template>

    <template #default="{ item }">
      <Section129PendingQueueItem
        :account="item"
        @generated="onGenerated(item.id, $event)"
      />
    </template>
  </Queue>
</template>

<script>
import useActionQueue from '@/composables/useActionQueue';
import Queue from '@/components/Shared/Queue.vue';
import Section129PendingQueueItem from '@/components/Legal/Section129PendingQueueItem.vue';

import {
  fetchSection129PendingAccounts,
  generatedSection129Letter,
} from '@/api/legal';

export default {
  components: { Queue, Section129PendingQueueItem },
  setup() {
    const { accounts, isLoading, removeAccount } = useActionQueue(
      fetchSection129PendingAccounts
    );

    const onGenerated = async (id, event) => {
      const undo = removeAccount(id);
      try {
        await generatedSection129Letter(id, event);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    return {
      accounts,
      isLoading,
      removeAccount,
      onGenerated,
    };
  },
};
</script>