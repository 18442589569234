<template>
  <h2 class="mb-4">Diarise</h2>
  <form @submit.prevent="submit">
    <div class="mb-3">
      <label class="form-label">Date</label>
      <input
        v-model="date"
        type="date"
        class="form-control"
        :min="getNowDateString()"
        :max="getISODateString(addMonths(new Date(), 1))"
        required
      />
    </div>
    <div class="mb-3">
      <label class="form-label">Time</label>
      <input
        v-model="time"
        type="time"
        min="08:00"
        max="19:30"
        class="form-control"
        required
      />
    </div>

    <div class="mb-3">
      <label class="form-label">Reason</label>
      <input v-model="reason" class="form-control" required />
    </div>

    <button type="submit" role="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import eventTypes from "@/helpers/eventTypes";
import {
  getNowDateString,
  getNowTimeString,
  addMonths,
  getISODateString,
} from "@/helpers/datetime";

export default {
  name: "RPCDiarise",
  emits: ["submit"],
  setup() {
    return { getISODateString, getNowDateString, getNowTimeString, addMonths };
  },
  data() {
    return {
      ...eventTypes.DIARISED,
      date: null,
      time: null,
      reason: null,
      dateLimit: null,
    };
  },
  methods: {
    submit() {
      const { name, type, closesAction } = eventTypes.DIARISED;
      const details = {
        date: this.date,
        time: this.time,
        reason: this.reason,
      };
      this.$emit("submit", { type, name, closesAction, details });
    },
  },
  mounted() {
    this.date = this.getNowDateString();
    this.time = this.getNowTimeString();
  },
};
</script>
