<template>
  <div>
    <form @submit.prevent="onSubmit">
      <div class="mb-3">
        <label class="form-label">Date</label>
        <input
          v-model="date"
          type="date"
          class="form-control"
          :min="getNowDateString()"
        />
      </div>
      <div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';

import { getNowDateString } from '@/helpers/datetime';

export default {
  setup(_, { emit }) {
    const date = ref('');

    const onSubmit = () => {
      emit('submitted', { date: date.value });
    };

    return {
      date,
      getNowDateString,
      onSubmit,
    };
  },
};
</script>