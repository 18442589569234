<template>
  <RoundButton v-bind="$attrs"
    ><span class="material-icons"> edit </span></RoundButton
  >
</template>

<script>
import RoundButton from '@/components/Shared/_RoundButton.vue';
// icons

export default {
  name: 'EditButton',
  components: { RoundButton },
};
</script>
