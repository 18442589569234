<template>
  <div class="table-responsive">
    <table class="table table-bordered align-middle">
      <thead>
        <tr>
          <!-- <th scope="col" class="w-25">Position</th> -->
          <th scope="col" class="w-10">Role</th>
          <th scope="col" class="w-30">Assigned to</th>
          <th scope="col" class="w-10">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="position in positions" :key="position.id">
          <!-- <td>{{ position.position }}</td> -->
          <td>{{ position.role }}</td>
          <td>{{ position.assigned_user }}</td>
          <td class="positionActions">
            <EditButton @click="$emit('edit', position.id)" class="me-1" />
            <UnassignButton @click="$emit('unassign', position.id)" class="me-1" />
            <DeleteButton @click="$emit('delete', position)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import EditButton from "@/components/Shared/EditButton.vue";
import DeleteButton from "@/components/Shared/DeleteButton.vue";
import UnassignButton from "../Shared/UnassignButton.vue";

export default {
  name: "PositionList",
  components: {
    EditButton,
    DeleteButton,
    UnassignButton,
  },
  props: { positions: Object },
  emits: ["edit", "delete", "unassign"],
};
</script>

<style lang="scss" scoped>
// table {
//   .positionNumber,
//   .positionActions {
//     width: 15%;
//     white-space: nowrap;
//   }
// }
</style>
