<template>
  <Page>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <!--  -->
          <AgentAssignmentsCard />
          <!--  -->
          <ul v-if="!hasSingleRole" class="nav nav-pills mt-4">
            <li v-if="userIs('AGENT')" class="nav-item">
              <router-link
                :to="{ name: 'agentDash' }"
                class="nav-link"
                active-class="active"
                >Agent</router-link
              >
            </li>
            <!-- <li v-if="userIs('MANAGER')" class="nav-item">
              <router-link
                :to="{ name: 'managerDash' }"
                class="nav-link"
                active-class="active"
                >Manager</router-link
              >
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </Page>
</template>

<script>
import { mapGetters } from "vuex";

import { userIs } from "@/helpers/permissions";

import Page from "@/components/Shared/_Page.vue";
import AgentAssignmentsCard from "@/components/Dash/AgentAssignmentsCard.vue";

export default {
  name: "Dash",
  components: {
    Page,
    AgentAssignmentsCard,
  },
  setup() {
    return { userIs };
  },
  computed: {
    ...mapGetters({
      roles: "auth/roles",
    }),
    hasSingleRole() {
      return this.roles.length === 1;
    },
  },
};
</script>
