import axios from 'axios';

export const fetchReferralAccounts = async () =>
  (await axios.get('api/admin/queues/admin-order-referral')).data.map(
    (account) => ({
      ...account,
      entry_date: new Date(account.entry_date),
      due_date_time: account.due_date_time
        ? new Date(account.due_date_time)
        : null,
    })
  );

export const fetchCTPBrokenAccounts = async () =>
  (await axios.get('api/admin/queues/admin-order-ctp-broken')).data.map(
    (account) => ({
      ...account,
      entry_date: new Date(account.entry_date),
      due_date_time: account.due_date_time
        ? new Date(account.due_date_time)
        : null,
    })
  );

export const createCTP = async (accountId, data) => {
  await axios.patch(`api/admin/queues/admin-order-referral/${accountId}`, {
    action: 'ctp',
    data,
  });
};

export const reject = async (accountId) => {
  await axios.patch(`api/admin/queues/admin-order-referral/${accountId}`, {
    action: 'reject',
  });
};
