<template>
  <teleport to="#modals">
    <div
      ref="modal"
      class="modal fade"
      id="confirmActionModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <slot name="header"></slot>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <slot name="body"></slot>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="$emit('cancel')"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              @click="$emit('confirm')"
              type="button"
              class="btn btn-primary"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'ConfirmActionModal',
  props: {
    show: Boolean,
  },
  emits: ['confirm', 'cancel'],
  watch: {
    show(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (newVal) this.modal.show();
      if (!newVal) this.modal.hide();
    },
  },
  data() {
    return {
      modal: null,
    };
  },
  mounted() {
    // this.$refs.modal.addEventListener('show.bs.modal', () => {});
    this.modal = new window.bootstrap.Modal(this.$refs.modal, {
      backdrop: 'static',
    });
  },
};
</script>

<style lang="scss" scoped>
</style>