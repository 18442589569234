<template>
  <Page>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-2">
          <div
            id="list-tab"
            role="tablist"
            class="nav flex-column nav-pills me-3"
            aria-orientation="vertical"
          >
            <router-link
              :to="{ name: 'collection_queues' }"
              active-class="active"
              class="nav-link mb-3"
            >
              Collection Queues
            </router-link>
            <router-link
              :to="{ name: 'admin_queues' }"
              active-class="active"
              class="nav-link mb-3"
            >
              Admin Queues
            </router-link>
            <router-link
              :to="{ name: 'other_queues' }"
              active-class="active"
              class="nav-link mb-3"
            >
              Other Queues
            </router-link>
            <router-link
              :to="{ name: 'pool_queue_order' }"
              active-class="active"
              class="nav-link mb-3"
            >
              Pool Order
            </router-link>
          </div>
        </div>
        <div class="col-sm-10">
          <div class="row mb-4">
            <div class="col">
              <h2 v-if="totalArrears" class="display-5">
                {{ formatCurrency(totalArrears) }}
              </h2>
              <p v-else>...loading</p>
              <div>Total Arrears</div>
            </div>
            <div class="col">
              <h2 v-if="totalArrears" class="display-5">
                {{ formatCurrency(totalArrears) }}
              </h2>
              <p v-else>...loading</p>
              <div>Total Arrears</div>
            </div>
            <div class="col">
              <h2 v-if="totalAccounts" class="display-5">
                {{ totalAccounts }}
              </h2>
              <p v-else>...loading</p>
              <div>Total Accounts</div>
            </div>
          </div>

          <router-view />
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Shared/_Page.vue';
import { mapGetters } from 'vuex';
import { formatCurrency } from '@/helpers/format';

export default {
  name: 'Queues',
  components: {
    Page,
  },
  setup() {
    return { formatCurrency };
  },
  computed: {
    ...mapGetters({
      queues: 'queue/queues',
    }),
    totalAccounts() {
      let accCount = 0;
      this.queues.forEach((queue) => {
        if (queue.account_count) {
          accCount += parseInt(queue.account_count, 10);
        }
      });
      return accCount;
    },
    totalArrears() {
      let arrTotal = 0;
      this.queues.forEach((queue) => {
        if (queue.account_count) {
          arrTotal += parseInt(queue.arrears_total, 10);
        }
      });
      return arrTotal;
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('queue/getQueues');
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.queue-summary-card {
  width: 100%;
  padding: 1rem;
  background: #dfe5e7;
  & + .queue-summary-card {
    margin-left: 0.5rem;
  }

  .title {
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
  }

  .value {
    display: block;
    font-size: 3rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }
}
</style>
