<template>
  <Page>
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="col-sm-8 h-100 pb-4">
          <iframe
            id="clickupEmbed"
            ref="clickupEmbed"
            class="clickup-embed"
            src="https://sharing.clickup.com/b/h/6-9602371-2/877e87c8d96c721"
            onwheel=""
            width="100%"
            height="100%"
            style="
              background: transparent;
              box-shadow: 0px 1px 10px -3px rgba(0, 0, 0, 0.3);
            "
          ></iframe>
        </div>
        <div class="col-sm-4">
          <div class="p-4 mt-3">
            <p class="lead">Support</p>
            <form @submit.prevent="submit" enctype="multipart/form-data">
              <div class="mb-3">
                <div class="form-check form-check-inline">
                  <input
                    v-model="subject"
                    class="form-check-input"
                    type="radio"
                    name="subject"
                    id="supportRadio"
                    value="support"
                  />
                  <label class="form-check-label" for="supportRadio"
                    >Support</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    v-model="subject"
                    class="form-check-input"
                    type="radio"
                    name="subject"
                    id="suggestionRadio"
                    value="suggestion"
                  />
                  <label class="form-check-label" for="suggestionRadio"
                    >Suggestion</label
                  >
                </div>
              </div>
              <div class="form-floating mb-3">
                <input
                  v-model="title"
                  type="text"
                  class="form-control"
                  name="title"
                  id="title"
                  placeholder="Title"
                />
                <label for="title"> Title </label>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  v-model="body"
                  :disabled="isSubmitting"
                  class="form-control"
                  id="description"
                  name="description"
                  placeholder="Description"
                  style="height: 5rem"
                ></textarea>
                <label for="description"> Description </label>
              </div>
              <div class="mb-3">
                <div class="form-check">
                  <input
                    v-model="addScreenshot"
                    class="form-check-input"
                    type="checkbox"
                    id="addScreenshot"
                  />
                  <label class="form-check-label" for="addScreenshot">
                    Add screenshot
                  </label>
                </div>
              </div>
              <div v-if="addScreenshot" class="mb-3">
                <input
                  :disabled="isSubmitting"
                  @change="fileChange"
                  type="file"
                  name="screenshot"
                  accept="image/*"
                  class="form-control"
                />
              </div>
              <div class="mb-3">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
              <p v-if="isSubmitting">Sending...</p>
            </form>
            <div v-if="sent" class="alert alert-success" role="alert">
              <p class="mb-0">
                We're glad to be of any assistance and will be sure to get back
                to you soon!
              </p>
            </div>
            <div v-else-if="error" class="alert alert-danger" role="alert">
              <p class="mb-0">
                Unfortunately something went wrong while trying to submit your
                support request. We're looking into it so please try again a
                little later.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import axios from 'axios';

import Page from '@/components/Shared/_Page.vue';

export default {
  name: 'Support',
  components: { Page },
  data() {
    return {
      addScreenshot: false,
      title: '',
      subject: 'support',
      body: '',
      formData: new FormData(),
      isSubmitting: false,
      sent: null,
      error: null,
    };
  },
  methods: {
    fileChange(e) {
      const { name, files } = e.target;
      for (let i = 0; i < files.length; i += 1) {
        this.formData.append(name, files[i]);
      }
    },
    clearForm() {
      this.title = '';
      this.subject = 'support';
      this.body = '';
      this.addScreenshot = false;
    },
    async submit() {
      this.isSubmitting = true;
      this.sent = null;
      this.error = null;
      this.formData.set('title', this.title);
      this.formData.set('subject', this.subject);
      this.formData.set('body', this.body);
      try {
        await axios.post('/api/support', this.formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        this.sent = true;
        // To refresh
        // this.$refs.clickupEmbed.src += '';
      } catch {
        this.error = 'Something went wrong...';
      }
      this.clearForm();
      this.isSubmitting = false;
    },
  },
};
</script>

<style>
</style>
