<template>
  <div>
    <form @submit.prevent="onSubmit">
      <div class="mb-3">
        <label class="form-label">Date</label>
        <input v-model="date" type="date" class="form-control" />
      </div>
      <div class="mb-3">
        <label class="form-label">Amount</label>
        <input v-model="amount" type="number" class="form-control" step="0.1" />
      </div>
      <div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'CreateCTPForm',
  setup(_, { emit }) {
    const date = ref('');
    const amount = ref(0);

    const onSubmit = () => {
      emit('submitted', { date: date.value, amount: amount.value });
    };

    return {
      date,
      amount,
      onSubmit,
    };
  },
};
</script>