<template>
  <canvas ref="chart"></canvas>
</template>

<script>
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);

export default {
  props: {
    labels: {
      type: Array,
    },
    datasets: {
      type: Array,
    },
    targets: {
      type: Array,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    datasets() {
      this.renderGraph();
    },
  },
  methods: {
    renderGraph() {
      if (!this.datasets) return;

      const lineDefaults = {
        type: 'line',
        borderColor: 'rgb(0, 97, 242)',
        borderWidth: 1,
      };

      const annotations = this.targets
        .map((t) => ({
          ...lineDefaults,
          yMin: t.amount,
          yMax: t.amount,
          label: {
            backgroundColor: '#0061f2',
            content: `R${t.commission}`,
            enabled: true,
          },
        }))
        .reduce((acc, curr, index) => {
          acc[`targetline${index}`] = curr;
          return acc;
        }, {});

      const maxTarget = Math.max(...this.targets.map((t) => t.amount));

      const ctx = this.$refs.chart.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: {
          scales: {
            y: {
              // suggestedMin:,
              suggestedMax: maxTarget + 5,
            },
          },
          plugins: {
            autocolors: false,
            annotation: {
              annotations,
            },
          },
        },
      });
    },
  },
  mounted() {
    this.renderGraph();
  },
};
</script>