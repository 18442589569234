<template>
  <Queue
    idKey="id"
    sortKey="entry_date"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #subtitle> Legal </template>
    <template #title> Send Section 129 </template>

    <template #default="{ item }">
      <Section129SendQueueItem
        :account="item"
        @sent="onSent(item.id, $event)"
      />
    </template>
  </Queue>
</template>


<script>
import useActionQueue from '@/composables/useActionQueue';
import Queue from '@/components/Shared/Queue.vue';
import Section129SendQueueItem from '@/components/Legal/Section129SendQueueItem.vue';
import {
  fetchSection129GeneratedAccounts,
  sentSection129Letter,
} from '../../api/legal';

export default {
  components: {
    Queue,
    Section129SendQueueItem,
  },
  setup() {
    const { accounts, isLoading, loadAccounts, removeAccount } = useActionQueue(
      fetchSection129GeneratedAccounts
    );

    const onSent = async (id, event) => {
      const undo = removeAccount(id);
      try {
        await sentSection129Letter(id, event);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    return {
      accounts,
      isLoading,
      loadAccounts,
      onSent,
    };
  },
};
</script>