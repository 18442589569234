<template>
  <Page>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5 class="mt-5 mb-4">Users</h5>
          <div class="card">
            <div class="card-body">
              <UserList />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Shared/_Page.vue';
import UserList from '@/components/User/UserList.vue';

export default {
  name: 'Users',
  components: { Page, UserList },
  async created() {
    await this.$store.dispatch('user/getUserList');
  },
};
</script>

<style></style>
