<template>
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-4 position-relative" style="background-color: #eef0f4">
        <div class="queue-view">
          <router-view @view="view"></router-view>
        </div>
      </div>
      <div class="col-8 position-relative">
        <div class="account-view h-100 d-flex">
          <div v-if="activeAccount">
            <AccountDisplay :account="activeAccount" />
          </div>
          <div v-else class="align-self-center flex-grow-1">
            <div v-if="isLoading">
              <LoadingSpinner />
            </div>
            <div
              v-else
              class="d-flex flex-column align-items-center align-self-center"
            >
              <span class="material-icons md-10x color-offwhite"
                >assignment</span
              >
              <p>Nothing is selected</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AccountDisplay from '@/components/Account/AccountDisplay.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

export default {
  name: 'ActionQueues',
  components: {
    AccountDisplay,
    LoadingSpinner,
  },
  computed: {
    ...mapGetters({
      activeAccount: 'account/activeAccount',
      isLoading: 'account/isLoading',
      errors: 'account/errors',
    }),
  },
  data() {
    return {
      currentAccId: null,
    };
  },
  watch: {
    $route() {
      this.$store.dispatch('account/clearCurrentAccount');
    },
  },
  methods: {
    async view(accId) {
      this.$store.dispatch('account/clearCurrentAccount');
      await this.$store.dispatch('account/getAccount', accId);
    },
  },
};
</script>

<style lang="scss" scoped>
.queue-view {
  // background: green;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3rem;
}
</style>
