<template>
  <div>
    <div class="options">
      <a
        href="#"
        class="option bg-blue text-white"
        data-bs-toggle="modal"
        data-bs-target="#transactionsModal"
      >
        <span class="material-icons"> payment </span>
        <div class="option-text">Transaction</div>
      </a>

      <a
        href="#"
        class="option bg-blue text-white"
        data-bs-toggle="modal"
        data-bs-target="#notesModal"
      >
        <span class="material-icons"> notes </span>

        Notes
      </a>

      <a
        href="#"
        class="option bg-blue text-white"
        data-bs-toggle="modal"
        data-bs-target="#eventsModal"
      >
        <span class="material-icons"> flare </span>

        Events
      </a>

      <a
        href="#"
        class="option bg-blue text-white"
        data-bs-toggle="modal"
        data-bs-target="#docsModal"
      >
        <span class="material-icons"> attach_file </span>

        Docs
      </a>
    </div>
    <NotesModal />
    <EventsModal />
    <DocsModal />
    <TransactionsModal />
  </div>
</template>

<script>
import EventsModal from '@/components/Account/EventsModal.vue';
import NotesModal from '@/components/Account/NotesModal.vue';
import DocsModal from '@/components/Account/DocsModal.vue';
import TransactionsModal from '@/components/Account/TransactionsModal.vue';

export default {
  name: 'AccountAdditionalInfo',
  components: {
    NotesModal,
    EventsModal,
    DocsModal,
    TransactionsModal,
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-bottom: 0rem;
}

.options {
  display: flex;
  gap: 2.5rem;
  .option {
    display: flex;
    // justify-content: center;
    flex: 1;
    text-decoration: none;
    color: inherit;
    color: #fff;
    padding: 1.5rem 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 20rem;

    .option-text {
      line-height: 1.6rem;
    }

    .material-icons {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-right: 0.75rem;
    }
  }
}

.date {
  white-space: nowrap;
}

button {
  margin: 5px;
}
</style>
