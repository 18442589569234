<template>
  <Queue
    idKey="id"
    sortKey="entry_date"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #subtitle> Legal </template>
    <template #title> Section 129 Sent</template>
    <template #default="{ item }">
      <Section129SentQueueItem :account="item" @ctp="onCTP(item.id, $event)" />
    </template>
  </Queue>
</template>


<script>
import useActionQueue from '@/composables/useActionQueue';
import Queue from '@/components/Shared/Queue.vue';
import Section129SentQueueItem from '@/components/Legal/Section129SentQueueItem.vue';
import { fetchSection129SentAccounts, createCTP } from '@/api/legal';

export default {
  components: { Queue, Section129SentQueueItem },
  setup() {
    const { accounts, isLoading, removeAccount } = useActionQueue(
      fetchSection129SentAccounts
    );

    const onCTP = async (accountId, data) => {
      const undo = removeAccount(accountId);
      try {
        await createCTP(accountId, data);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    return {
      accounts,
      isLoading,
      onCTP,
    };
  },
};
</script>