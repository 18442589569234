<template>
  <Queue idKey="id" sortKey="due_date_time" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Legal </template>
    <template #title> Confirmation </template>
    <template #default="{ item }">
      <ConfirmationQueueItem
        :account="item"
        @success="onSuccess(item.id)"
        @failure="onFailure(item.id)"
        @unconfirmed="onUnconfirmed(item.id)"
        @writeoff="onWriteoff(item.id)"
        @note="onNote"
      />
    </template>
  </Queue>
</template>

<script>
import useActionQueue from "@/composables/useActionQueue";
import Queue from "@/components/Shared/Queue.vue";
import ConfirmationQueueItem from "@/components/Legal/ConfirmationQueueItem.vue";
import {
  fetchConfirmationAccounts,
  successfulConfirmation,
  failedConfirmation,
  markUnconfirmedConfirmation,
  writeoffConfirmation,
} from "@/api/legal";
import { createNote } from "@/api/account";

export default {
  components: { Queue, ConfirmationQueueItem },
  setup() {
    const { accounts, isLoading, removeAccount } = useActionQueue(
      fetchConfirmationAccounts
    );

    const onSuccess = async (id) => {
      const undo = removeAccount(id);
      try {
        await successfulConfirmation(id);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onNote = (payload) => {
      createNote(payload.accountId, payload.note);
    };

    const onFailure = async (id) => {
      const undo = removeAccount(id);
      try {
        await failedConfirmation(id);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    const onUnconfirmed = async (id) => {
      const undo = removeAccount(id);
      try {
        await markUnconfirmedConfirmation(id);
      } catch (error) {
        undo();
        console.error(error);
      }
    }

    const onWriteoff = async (id) => {
      const undo = removeAccount(id);
      try {
        await writeoffConfirmation(id);
      } catch (error) {
        undo();
        console.error(error);
      }
    };

    return {
      accounts,
      isLoading,
      onSuccess,
      onFailure,
      onUnconfirmed,
      onWriteoff,
      onNote,
    };
  },
};
</script>
