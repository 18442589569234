<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span>
    </template>
    <template #title>{{ account.reference }}</template>
    <template #body>
      <p>
        Due:
        <span v-if="account?.due_date_time">
          {{ account.due_date_time?.toLocaleString() }}
        </span>
        <span v-else> - </span>
      </p>
      <EventNote
        v-if="account.event_note"
        :content="account.event_note"
        :multiline="true"
      />
    </template>
    <template #actions>
      <RoundButton
        @click="toggleForm('diarise')"
        large
        class="btn-action text-secondary"
        title="Diarise"
        ><span class="material-icons-outlined md-18"> event </span></RoundButton
      >
      <RoundButton
        @click="toggleForm('ctp')"
        large
        class="btn-action text-success"
        title="Create CTP"
        ><span class="material-icons-outlined md-18"> attach_money </span></RoundButton
      >
      <RoundButton
        @click="toggleForm('note')"
        large
        title="Create Note"
        class="btn-action text-secondary"
      >
        <span class="material-icons"> notes </span>
      </RoundButton>
      <RoundButton
        @click="cancelDebtReview"
        large
        title="Cancel Debt Review"
        class="btn-action text-danger"
      >
        <span class="material-icons md-18"> block </span>
      </RoundButton>
    </template>
    <template #footer>
      <CreateCTPForm v-if="showForm === 'ctp'" @submitted="ctp" />
      <div v-if="showForm === 'note'">
        <div class="mb-3">
          <label class="form-label">Note</label>
          <textarea v-model="noteForm.note" class="form-control" rows="3"></textarea>
        </div>
        <button @click="createNote" class="btn btn-primary">Submit</button>
      </div>

      <div v-if="showForm === 'diarise'">
        <div class="mb-3">
          <label class="form-label">Date</label>
          <input
            v-model="diariseForm.date"
            type="date"
            class="form-control"
            :min="getNowDateString()"
          />
        </div>
        <div>
          <button @click="diarise" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </template>
  </QueueItem>
</template>

<script>
import QueueItem from "@/components/Shared/QueueItem_v2.vue";
import RoundButton from "@/components/Shared/_RoundButton.vue";
import EventNote from "@/components/Shared/EventNote.vue";
import CreateCTPForm from "@/components/Shared/forms/CreateCTPForm.vue";

import { getNowDateString } from "@/helpers/datetime";

export default {
  name: "DebtReviewCounterProposalQueueItem",
  props: { account: Object },
  emits: ["action"],
  setup() {
    return {
      getNowDateString,
    };
  },
  components: { QueueItem, RoundButton, EventNote, CreateCTPForm },
  data() {
    return {
      showForm: "",
      noteForm: {
        note: "",
      },
      diariseForm: {
        date: "",
      },
    };
  },
  methods: {
    toggleForm(formName) {
      this.showForm = this.showForm !== formName ? formName : "";
    },
    ctp(data) {
      this.$emit("action", {
        accountId: this.account.id,
        payload: { action: "ctp", data },
      });
    },
    createNote() {
      const { note } = this.noteForm;
      this.$emit("note", { accountId: this.account.id, note });
      this.noteForm.note = "";
      this.showForm = "";
    },
    diarise() {
      const { diariseForm: data } = this;
      this.$emit("defer", {
        accountId: this.account.id,
        payload: { action: "diarise", data },
      });
      this.toggleForm("diarise");
    },
    cancelDebtReview() {
      this.$emit("action", {
        accountId: this.account.id,
        payload: { action: "cancel" },
      });
    },
  },
};
</script>
