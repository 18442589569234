<template>
  <table v-if="CTPs.length" class="table table-hover align-middle">
    <thead>
      <tr>
        <th scope="col">Status</th>
        <th scope="col">Amount</th>
        <th scope="col">Filled</th>
        <th scope="col">Due</th>
        <th scope="col">Final Due</th>
        <th scope="col">Created</th>
        <th scope="col">Reference Id</th>
        <th scope="col">Recurring</th>
        <th scope="col">Payment Method</th>
        <th scope="col" v-if="canEdit || canDelete">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(ctp, i) in CTPs" :key="i">
        <td>
          <span
            v-if="ctp.status_id === 1"
            class="badge rounded-pill bg-primary d-block"
            >Active</span
          >
          <span
            v-if="ctp.status_id === 2"
            class="badge rounded-pill bg-success d-block"
            >Honored</span
          >
          <span
            v-if="ctp.status_id === 3"
            class="badge rounded-pill bg-danger d-block"
            >Broken</span
          >
          <span
            v-if="ctp.status_id === 6"
            class="badge rounded-pill bg-secondary d-block"
            >Cancelled</span
          >
          <span
            v-if="ctp.status_id === 7"
            class="badge rounded-pill bg-secondary d-block"
            >Frozen</span
          >
          <span
            v-if="ctp.status_id === 8"
            class="badge rounded-pill bg-light text-dark d-block"
            >Ready</span
          >
        </td>
        <td>{{ formatCurrency(ctp.amount) }}</td>
        <td>{{ formatCurrency(ctp.filled) }}</td>
        <td>{{ formatDate(ctp.due_date) }}</td>
        <td>{{ formatDate(ctp.final_due_date) }}</td>
        <td>{{ formatDate(ctp.created_date) }}</td>
        <td>{{ ctp.reference }}</td>
        <td>{{ ctp.recurring_day ?? '-' }}</td>
        <td>{{ paymentMethod(ctp.payment_method) }}</td>
        <td v-if="canEdit || canDelete">
          <EditButton
            v-if="canEdit"
            v-on:click="$emit('edit', ctp.id)"
            :disabled="ctp.status_id !== 1"
          />
          <DeleteButton
            v-if="canDelete"
            v-on:click="$emit('delete', ctp.id)"
            :disabled="ctp.status_id !== 1"
          />
        </td>
      </tr>
    </tbody>
  </table>
  <p v-else class="text-muted small mb-0">No CTPs found</p>
</template>

<script>
import { formatDate, formatCurrency } from '@/helpers/format';
import EditButton from '@/components/Shared/EditButton.vue';
import DeleteButton from '@/components/Shared/DeleteButton.vue';

export default {
  name: 'CTPList',
  setup() {
    return { formatDate, formatCurrency };
  },
  props: {
    CTPs: Array,
    paymentMethods: Array,
    canEdit: Boolean,
    canDelete: Boolean,
  },
  emits: ['edit', 'delete'],
  components: { EditButton, DeleteButton },
  methods: {
    paymentMethod(id) {
      return this.paymentMethods.find((m) => m.id === id)?.method;
    },
  },
};
</script>