<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span>
    </template>
    <template #title>{{ account.reference }}</template>
    <template #body>
      <EventNote
        v-if="account.event_note"
        :content="account.event_note"
        :multiline="true"
      />
    </template>
    <template #actions>
      <RoundButton
        @click="toggleForm('acceptNote')"
        large
        class="btn-action text-success"
        title="Accept"
      >
        <span class="material-icons-outlined md-18"> done </span>
      </RoundButton>

      <RoundButton
        @click="toggleForm('rejectReason')"
        large
        class="btn-action text-danger"
        title="Reject"
      >
        <span class="material-icons-outlined md-18"> close </span>
      </RoundButton>

      <RoundButton
        @click="toggleForm('note')"
        large
        title="Create Note"
        class="btn-action text-secondary"
      >
        <span class="material-icons"> notes </span>
      </RoundButton>
    </template>
    <template #footer>
      <div v-if="showForm === 'note'">
        <div class="mb-3">
          <label class="form-label">Note</label>
          <textarea
            v-model="noteForm.note"
            class="form-control"
            rows="3"
          ></textarea>
        </div>
        <button @click="createNote" class="btn btn-primary">Submit</button>
      </div>
      <div v-if="showForm === 'rejectReason'">
        <div class="mb-3">
          <label class="form-label">Reason</label>
          <textarea
            v-model="reasonForm.note"
            class="form-control"
            rows="3"
          ></textarea>
        </div>
        <button @click="reject" class="btn btn-primary">Submit</button>
      </div>
      <div v-if="showForm === 'acceptNote'">
        <div class="mb-3">
          <label class="form-label">Note</label>
          <textarea
            v-model="reasonForm.note"
            class="form-control"
            rows="3"
          ></textarea>
        </div>
        <button @click="accept" class="btn btn-primary">Submit</button>
      </div></template
    >
  </QueueItem>
</template>

<script>
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import RoundButton from '@/components/Shared/_RoundButton.vue';
import EventNote from '@/components/Shared/EventNote.vue';

export default {
  props: {
    account: Object,
  },
  emits: ['note', 'action'],
  components: { RoundButton, QueueItem, EventNote },
  setup() {},
  data() {
    return {
      reasonForm: {
        note: '',
      },
      showForm: '',
      noteForm: {
        note: '',
      },
    };
  },
  methods: {
    toggleForm(formName) {
      this.showForm = this.showForm !== formName ? formName : '';
    },
    accept() {
      const { reasonForm: note } = this;
      this.$emit('action', {
        accountId: this.account.id,
        payload: { action: 'accept', note },
      });
    },
    reject() {
      const { reasonForm: note } = this;
      this.$emit('action', {
        accountId: this.account.id,
        payload: { action: 'reject', note },
      });
    },
    test() {
      const { reasonForm: reason } = this;
      console.log(reason);
    },
    createNote() {
      const { note } = this.noteForm;
      this.$emit('note', { accountId: this.account.id, note });
      this.noteForm.note = '';
      this.showForm = '';
    },
  },
};
</script>
