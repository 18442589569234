<template>
  <RoundButton v-bind="$attrs">
    <FontAwesomeIcon :icon="['fas', 'user-plus']" size="sm"
  /></RoundButton>
</template>

<script>
import RoundButton from '@/components/Shared/_RoundButton.vue';
// icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

library.add(faUserPlus);

export default {
  name: 'AssignButton',

  components: { RoundButton, FontAwesomeIcon },
};
</script>
