<template>
  <QueueItem :account="account">
    <template #title>{{ account.reference }}</template>
    <template #body> <p class="mb-0">Awaiting generation</p> </template>
    <template #actions>
      <RoundButton
        @click="$emit('generated')"
        large
        class="btn-action text-success"
        title="Sent"
      >
        <span class="material-icons-outlined md-18"> done </span>
      </RoundButton>
    </template>
  </QueueItem>
</template>

<script>
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import RoundButton from '@/components/Shared/_RoundButton.vue';

export default {
  props: {
    account: Object,
  },
  components: { QueueItem, RoundButton },
  setup() {
    return {};
  },
};
</script>