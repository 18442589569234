<template>
  <table class="table">
    <tbody>
      <tr>
        <th scope="row">Name</th>
        <td>{{ user.first_name }} {{ user.last_name }}</td>
      </tr>
      <tr>
        <th scope="row">Username</th>
        <td>{{ user.username }}</td>
      </tr>
      <tr>
        <th scope="row">Position</th>
        <td>{{ position.position }}</td>
      </tr>
      <tr>
        <th scope="row">Email</th>
        <td>{{ user.email }}</td>
      </tr>
      <tr>
        <th scope="row">Extension</th>
        <td>{{ user.extension }}</td>
      </tr>
    </tbody>
  </table>
  <button class="btn btn-primary mt-2" data-bs-dismiss="modal">Confirm</button>
</template>

<script>
export default {
  name: "CreateUserFinalise",
  props: {
    user: {
      type: Object,
      required: true,
    },
    position: {
      required: true,
    },
  },
};
</script>

<style></style>
