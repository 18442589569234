<template>
  <input
    class="btn-check"
    type="radio"
    name="SendDoc"
    id="SendDoc"
    data-bs-toggle="modal"
    data-bs-target="#SendDocModal"
  />
  <label class="btn btn-lg btn-primary" for="SendDoc"> Send Documents </label>

  <teleport to="#modals">
    <div
      class="modal fade"
      id="SendDocModal"
      tabindex="-1"
      aria-labelledby="SendDocModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="SendDocModalLabel">Send Document</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <!--  -->
            <div v-if="activeAccount.customer_details?.Email">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col">
                      <input class="form-check-input" type="checkbox" value="" id="" />
                    </th>
                    <th scope="col">Name</th>
                  </tr>
                </thead>

                <tbody v-if="!isDocsLoading">
                  <tr v-for="(doc, index) in docs" :key="index">
                    <th scope="row">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :value="doc.template_code"
                        v-model="send_files"
                      />
                    </th>
                    <td>
                      {{ doc.template_label }}
                    </td>
                  </tr>
                </tbody>
                <LoadingSpinner v-else />
              </table>
            </div>
            <div v-else>
              <p class="card-text text-muted my-2">
                * There is no email address on this account
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                @click="submit"
                :disabled="
                  !activeAccount.customer_details?.Email.length || !send_files.length
                "
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

export default {
  name: "SendDocsModal",
  props: {},
  components: { LoadingSpinner },
  computed: {
    ...mapGetters({
      docs: "account/docs",
      activeAccount: "account/activeAccount",
      isDocsLoading: "account/isDocsLoading",
      positions: "auth/permissions",
      actionId: "collection/actionId",
    }),
  },
  data() {
    return {
      send_files: [],
    };
  },
  methods: {
    fileChange(e) {
      const { name, files } = e.target;
      for (let i = 0; i < files.length; i += 1) {
        this.files.push(name, files[i]);
      }
    },
    async submit() {
      const files = this.send_files;
      const clientEmail = this.activeAccount.customer_details?.Email;
      const userPositionId = this.positions.filter(
        (p) =>
          p.role.toUpperCase() === "AGENT" ||
          "INBOUND AGENT" ||
          "ADMIN" ||
          "SUPERVISOR" ||
          "MANAGER" ||
          "SUPER USER"
      )[0].user_position_id;
      console.log(userPositionId[0]);
      try {
        const response = (
          await axios.post(`api/communications/send-documents/${this.activeAccount.id}`, {
            files,
            emailAddress: clientEmail,
            action_id: this.actionId,
            userPositionId,
          })
        ).data;
        if (response) {
          this.$store.dispatch("notification/push", { body: `Email Sent` });
          this.$store.dispatch("account/docsDownloadRequest");
        } else {
          this.$store.dispatch("notification/push", {
            body: `Email Failed`,
            type: "error",
          });
        }
      } catch {
        console.error("something went wrong");
      }
    },
  },
};
</script>

<style scoped>
table i.bi {
  font-size: 2rem;
}

.filename {
  display: flex;
  align-items: center;
}
</style>
