<template>
  <Queue idKey="id" sortKey="entry_date" :items="accounts" :isLoading="isLoading">
    <template #subtitle> Legal </template>
    <template #title> Legal Proposed Writeoff </template>
    <template #default="{ item }">
      <WriteoffReferralQueueItem
        :account="item"
        @accept="onAccept(item.id)"
        @reject="onReject(item.id)"
        @note="onNote"
      />
    </template>
  </Queue>
</template>

<script>
import useActionQueue from "@/composables/useActionQueue";
import Queue from "@/components/Shared/Queue.vue";
import WriteoffReferralQueueItem from "@/components/Legal/WriteoffReferralQueueItem.vue";
import {
  fetchWriteoffReferralAccounts,
  acceptWriteoffReferral,
  rejectWriteoffReferral,
} from "@/api/legal";
import { createNote } from "@/api/account";

export default {
  components: { Queue, WriteoffReferralQueueItem },
  setup() {
    const { accounts, isLoading, removeAccount } = useActionQueue(
      fetchWriteoffReferralAccounts
    );

    const onAccept = async (id) => {
      const undo = removeAccount(id);
      try {
        await acceptWriteoffReferral(id);
      } catch (error) {
        undo();
        console.log(error);
      }
    };

    const onReject = async (id) => {
      const undo = removeAccount(id);
      try {
        await rejectWriteoffReferral(id);
      } catch (error) {
        undo();
        console.log(error);
      }
    };

    const onNote = (payload) => {
      createNote(payload.accountId, payload.note);
    };

    return {
      accounts,
      isLoading,
      onAccept,
      onNote,
      onReject,
    };
  },
};
</script>
