<template>
  <div>
    <form @submit.prevent="onSubmit">
      <div class="mb-3">
        <label class="form-label">Note</label>
        <textarea v-model="note" class="form-control"></textarea>
      </div>
      <div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    accountId: Number,
  },
  setup(_, { emit }) {
    const note = ref('');

    const onSubmit = () => {
      emit('submitted', { note: note.value });
      note.value = '';
    };

    return {
      note,
      onSubmit,
    };
  },
};
</script>