<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span>
    </template>
    <template #title>{{ account.reference }}</template>
    <template #body>
      <DebtReviewCounsellorDetails
        v-if="account.counsellor_details"
        :details="account.counsellor_details"
      />
      <EventNote :content="account.event_note" :multiline="true" />
    </template>
    <template #actions>
      <RoundButton
        @click="accept"
        large
        class="btn-action text-success"
        title="Send COB letter"
      >
        <span class="material-icons-outlined md-18"> forward_to_inbox </span>
      </RoundButton>

      <RoundButton @click="reject" large class="btn-action text-danger" title="Reject">
        <span class="material-icons-outlined md-18"> close </span>
      </RoundButton>

      <RoundButton
        @click="toggleForm('note')"
        large
        title="Create Note"
        class="btn-action text-secondary"
      >
        <span class="material-icons"> notes </span>
      </RoundButton>

      <RoundButton
        @click="toggleForm('addCounsellor')"
        large
        title="Add Counsellor Details"
        class="btn-action text-secondary"
      >
        <span class="material-icons md-18"> add_ic_call </span>
      </RoundButton>

      <RoundButton
        @click="legalReferral"
        large
        title="Legal Referral"
        class="btn-action text-secondary"
      >
        <span class="material-icons-outlined md-18"> gavel </span>
      </RoundButton>
    </template>
    <template #footer>
      <div v-if="showForm === 'note'">
        <div class="mb-3">
          <label class="form-label">Note</label>
          <textarea v-model="noteForm.note" class="form-control" rows="3"></textarea>
        </div>
        <button @click="createNote" class="btn btn-primary">Submit</button>
      </div>
      <DebtReviewAddCounsellorForm
        v-if="showForm === 'addCounsellor'"
        @submitted="addCounsellor"
      />
    </template>
  </QueueItem>
</template>

<script>
import QueueItem from "@/components/Shared/QueueItem_v2.vue";
import EventNote from "@/components/Shared/EventNote.vue";
import RoundButton from "@/components/Shared/_RoundButton.vue";
import DebtReviewCounsellorDetails from "@/components/Admin/DebtReviewCounsellorDetails.vue";
import DebtReviewAddCounsellorForm from "@/components/Admin/DebtReviewAddCounsellorForm.vue";

export default {
  props: {
    account: Object,
  },
  emits: ["view", "note", "action", "addCounsellor"],
  components: {
    QueueItem,
    EventNote,
    RoundButton,
    DebtReviewCounsellorDetails,
    DebtReviewAddCounsellorForm,
  },
  data() {
    return {
      showForm: "",
      noteForm: {
        note: "",
      },
    };
  },
  methods: {
    toggleForm(formName) {
      this.showForm = this.showForm !== formName ? formName : "";
    },
    accept() {
      this.$emit("action", {
        accountId: this.account.id,
        payload: { action: "accept" },
      });
    },
    reject() {
      this.$emit("action", {
        accountId: this.account.id,
        payload: { action: "reject" },
      });
    },
    createNote() {
      const { note } = this.noteForm;
      this.$emit("note", { accountId: this.account.id, note });
      this.noteForm.note = "";
      this.showForm = "";
    },
    view() {
      this.$emit("view", this.account.id);
    },
    addCounsellor(details) {
      this.$emit("addCounsellor", {
        accountId: this.account.id,
        details,
      });
      this.showForm = "";
    },
    legalReferral() {
      this.$emit("action", {
        accountId: this.account.id,
        payload: { action: "legal" },
      });
    },
  },
};
</script>
