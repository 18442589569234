import { ref, onMounted } from 'vue';

const useActionQueue = (accountLoader) => {
  const accounts = ref([]);
  const isLoading = ref(false);

  const loadAccounts = async () => {
    isLoading.value = true;
    accounts.value = await accountLoader();
    isLoading.value = false;
  };

  const getAccount = (id) => accounts.value.find((a) => a.id === id);

  const updateAccount = (id, data) => {
    const account = getAccount(id);
    const previousState = { ...account };
    accounts.value = accounts.value.map((a) =>
      a === account ? { ...account, ...data } : a
    );

    return () => {
      accounts.value = accounts.value.map((a) =>
        a.id === id ? previousState : a
      );
    };
  };

  const removeAccount = (id) => {
    const account = getAccount(id);
    accounts.value = accounts.value.filter((a) => a.id !== id);

    return () => {
      accounts.value.push(account);
    };
  };

  onMounted(loadAccounts);

  return {
    loadAccounts,
    getAccount,
    updateAccount,
    removeAccount,
    accounts,
    isLoading,
  };
};

export default useActionQueue;
