import store from '@/store';

export default async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const userData = store.getters['auth/user'];
  if (isAuthenticated && !userData) {
    if (!(await store.dispatch('auth/loadUser'))) {
      store.dispatch('auth/logout');
    }
  }
  next();
};
