<template>
  <div class="card mb-4">
    <div class="card-body">
      <div class="row">
        <div class="col-12 d-flex">
          <div class="flex-grow-1 pe-4">
            <div class="text-muted small mb-2">
              <slot name="tag"></slot>
            </div>

            <h5 class="mb-3">
              <slot name="title">{{account.reference}}</slot>
            </h5>

            <slot name="body"></slot>
            <span
              v-if="account?.due_date_time"
              :class="[
                pastDue(account?.due_date_time) ? 'bg-danger' : 'bg-secondary',
              ]"
              class="badge rounded-pill mt-3"
              >Due {{ formatDate(account?.due_date_time) }}</span
            >
          </div>

          <div class="d-flex flex-column align-items-end">
            <RoundButton
              @click="view"
              large
              class="btn-action text-secondary"
              title="View"
            >
              <span class="material-icons-outlined md-18"> visibility </span>
            </RoundButton>
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoundButton from '@/components/Shared/_RoundButton.vue';
import { formatDate } from '@/helpers/format';

export default {
  name: 'QueueItem',
  components: { RoundButton },
  setup() {
    const pastDue = (date) => date < new Date();
    return {
      pastDue,
      formatDate,
    };
  },
  props: {
    account: Object,
  },
  methods: {
    async view() {
      this.$store.dispatch('account/clearCurrentAccount');
      await this.$store.dispatch('account/getAccount', this.account.id);
    },
  },
};
</script>