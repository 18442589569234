<template>
  <Page>
    <div class="container">
      <div class="row">
        <div class="col-12 mx-auto">
          <h5 class="mt-5 mb-4">Teams</h5>
          <div class="card">
            <div class="card-body"><TeamList /></div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Shared/_Page.vue';
import TeamList from '../components/Teams/TeamList.vue';

export default {
  components: { Page, TeamList },
  name: 'Teams',
};
</script>

<style></style>
