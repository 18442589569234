<template>
  <h1 class="mt-4 ms-3">
    <slot name="header"></slot>
  </h1>
  <hr />
</template>

<script>
export default {
  name: "_BasePageTitle",
};
</script>

<style></style>
