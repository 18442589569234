<template>
  <teleport to="#modals">
    <div
      ref="modal"
      class="modal fade"
      id="createPosition"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="createPositionLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="createPositionLabel">Create Position</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <CreatePositionForm @position-created="createPosition" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="$emit('cancel')"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { positionService } from "@/services/position.service";
import CreatePositionForm from "@/components/Teams/CreatePositionForm.vue";

export default {
  name: "CreatePositionModal",
  components: {
    CreatePositionForm,
  },
  props: {
    show: Boolean,
  },
  emits: ["confirm", "cancel", "close"],
  watch: {
    show(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (newVal) this.modal.show();
      if (!newVal) this.modal.hide();
    },
  },
  data() {
    return {
      modal: null,
    };
  },
  methods: {
    async createPosition(newPosition) {
      const response = await positionService.createPosition(
        newPosition.team_id,
        newPosition.role_id,
        newPosition.reports_to
      );
      if (response) {
        this.$store.dispatch("notification/push", {
          body: `Position Created`,
        });
      } else {
        this.$store.dispatch("notification/push", {
          body: `Position Create Falied`,
          type: "error",
        });
      }
      this.$store.dispatch("team/getUnassignedPositions");
      this.$emit("confirm");
    },
  },
  mounted() {
    this.$refs.modal.addEventListener("hidden.bs.modal", () => {
      this.$emit("close");
    });

    // get data
    this.$store.dispatch("auth/getRoleList");
    this.$store.dispatch("team/getPositions");

    this.modal = new window.bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
  },
};
</script>

<style lang="scss" scoped></style>
