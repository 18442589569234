import axios from 'axios';

export const setBaseURL = (url) => {
  if (url) {
    axios.defaults.baseURL = url;
  }
};

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};
