<template>
  <div v-if="account" id="account">
    <AccountCoreInfo :account="account" class="core-info" />
    <AccountClientInfo :account="account" class="client-info" />
    <AccountOtherPartiesInfo :account="account" class="parties-info" />
    <AccountBankInfo :account="account" class="bank-info" />
    <AccountAdditionalInfo class="more-info" />
    <AccountArrearsInfo :account="account" class="arrears-info" />
    <AccountLoanInfo :account="account" class="loan-info" />
    <AccountPaymentInfo :account="account" class="payment-info" />
    <AccountInstalmentInfo :account="account" class="instalment-info" />
  </div>
</template>

<script>
import AccountCoreInfo from '@/components/Account/AccountCoreInfo.vue';
import AccountClientInfo from '@/components/Account/AccountClientInfo.vue';
import AccountOtherPartiesInfo from '@/components/Account/AccountOtherPartiesInfo.vue';
import AccountBankInfo from '@/components/Account/AccountBankInfo.vue';
import AccountAdditionalInfo from '@/components/Account/AccountAdditionalInfo.vue';
import AccountArrearsInfo from '@/components/Account/AccountArrearsInfo.vue';
import AccountLoanInfo from '@/components/Account/AccountLoanInfo.vue';
import AccountPaymentInfo from '@/components/Account/AccountPaymentInfo.vue';
import AccountInstalmentInfo from '@/components/Account/AccountInstalmentInfo.vue';

export default {
  name: 'AccountDisplay',
  props: {
    account: Object,
  },
  components: {
    AccountCoreInfo,
    AccountClientInfo,
    AccountOtherPartiesInfo,
    AccountBankInfo,
    AccountAdditionalInfo,
    AccountArrearsInfo,
    AccountLoanInfo,
    AccountPaymentInfo,
    AccountInstalmentInfo,
  },
};
</script>

<style lang="scss" scoped>
#account {
  height: 100vh;
  width: 100%;
  display: grid;
  gap: 2.5rem;
  padding: 2.5rem;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas:
    'core      more       more       more       more      '
    'core      arrears    arrears    payment    instalment'
    'core    loan       loan       payment    instalment'
    'client    parties          bank          .          .';
}

.core-info {
  grid-area: core;
}

.client-info {
  grid-area: client;
}

.parties-info {
  grid-area: parties;
}

.bank-info {
  grid-area: bank;
}

.more-info {
  grid-area: more;
}

.arrears-info {
  grid-area: arrears;
}

.loan-info {
  grid-area: loan;
}

.payment-info {
  grid-area: payment;
}

.instalment-info {
  grid-area: instalment;
}

//
//
//
</style>
