<template>
  <div class="chart-container">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  // BarController,
  // BarElement,
  // LinearScale,
  registerables,
} from 'chart.js';
import { mapGetters } from 'vuex';

// Chart.register(LinearScale, BarController, BarElement);
Chart.register(...registerables);

export default {
  name: 'AgentTimeBreakdownChart',
  props: {
    labels: {
      required: true,
      type: Array,
    },
    data: {
      required: true,
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      activityTypes: 'activity/activityTypes',
    }),
    data_in_minutes() {
      return this.data.map((time) => parseInt(time, 10));
    },
    label() {
      return this.activityTypes.map((type) => type.activity_type);
    },
  },
  data() {
    return {
      barChart: null,
    };
  },
  watch: {
    data() {
      this.renderGraph();
    },
  },
  methods: {
    renderGraph() {
      const ctx = this.$refs.chart.getContext('2d');

      this.barChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: '# of Votes',
              data: this.data_in_minutes,
              backgroundColor: [
                '#012777',
                '#002d9c',
                '#005d5d',
                '#1192e8',
                '#009d9a',
                '#bae6ff',
                '#6fdc8c',
              ],
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          aspectRatio: 1.5,
          layout: {
            padding: {
              top: 0,
            },
          },
          plugins: {
            legend: {
              position: 'right',
              title: 'Time Breakdown',
            },
            // title: {
            //   display: true,
            //   text: "Time Breakdown",
            // },
          },
        },
      });
    },
  },
  mounted() {
    this.renderGraph();
  },
};
</script>

<style scoped>
/* .chart-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 0.85;
} */
</style>
