<template>
  <div class="card mt-5">
    <div class="card-body">
      <div class="row mb-5">
        <div class="col-2">
          <h5 class="mb-2">Country Priority:</h5>
          <div v-if="!isLoading">
            <select class="form-select form-select-sm" v-model="countryPriority">
              <option
                v-for="(country, index) in countryOptions"
                :key="index"
                :value="index"
              >
                {{ country }}
              </option>
            </select>
            <button
              class="btn btn-primary mt-2"
              v-if="countryPriority != oldPriority"
              @click="updateCountry"
            >
              Submit
            </button>
          </div>
          <LoadingSpinner v-else />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5>Current Queue Order:</h5>
          <table v-if="!isLoading" class="table align-middle">
            <thead>
              <tr>
                <th scope="col">Queue</th>
                <th scope="col">Default Order</th>
                <th scope="col">Effective order</th>
                <th scope="col">Adjust</th>
              </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
              <tr v-for="queue in sortedQueues" :key="queue.id">
                <td>{{ queue.queue_name }}</td>
                <td>{{ queue.default_order }}</td>
                <td>{{ queue.effective_order }}</td>
                <td>
                  <RoundButton v-bind="$attrs" @click="changePriority(queue.id, -1)"
                    ><FontAwesomeIcon :icon="['fas', 'angle-up']" size="sm"
                  /></RoundButton>
                  <RoundButton v-bind="$attrs" @click="changePriority(queue.id, 1)"
                    ><FontAwesomeIcon :icon="['fas', 'angle-down']" size="sm"
                  /></RoundButton>
                </td>
              </tr>
            </transition-group>
          </table>
          <LoadingSpinner v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";

import RoundButton from "@/components/Shared/_RoundButton.vue";
// icons
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

library.add(faAngleUp, faAngleDown);

export default {
  name: "PoolQueueOrder",
  components: { FontAwesomeIcon, RoundButton, LoadingSpinner },
  data() {
    return {
      queues: [],
      isLoading: true,
      countryPriority: null,
      countryOptions: { sa: "South Africa", nam: "Namibia", none: "None" },
      oldPriority: null,
    };
  },

  computed: {
    sortedQueues() {
      return [...this.queues]?.sort((a, b) =>
        a.effective_order > b.effective_order ? 1 : -1
      );
    },
  },
  methods: {
    async changePriority(id, amount) {
      const item = this.queues.find((q) => q.id === id);
      const oldOrder = item.effective_order;
      const newOrder = oldOrder + amount;
      item.effective_order = newOrder;
      const params = { effective_order: newOrder };
      try {
        await axios.patch(`/api/queues/${id}`, params);
      } catch (error) {
        item.effective_order = oldOrder;
        console.error(error);
      }
    },
    async updateCountry() {
      const undo = this.oldPriority;
      this.oldPriority = this.countryPriority;
      try {
        await axios.patch("/api/queues/collection/country-priority", {
          country: this.countryPriority,
        });
      } catch (error) {
        this.oldPriority = undo;
        console.error(error);
        this.$store.dispatch("notification/push", {
          body: `Something went wrong`,
          type: "error",
        });
      }
    },
  },
  async created() {
    try {
      this.queues = (
        await axios.get("/api/queues", {
          params: { label: "rankable" },
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
        })
      ).data;
      this.countryPriority = (
        await axios.get("/api/queues/collection/country-priority")
      ).data;
      this.oldPriority = this.countryPriority;
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style lang="scss" scoped>
.table {
  position: relative;
  tr {
    width: 100%;
  }
}

.flip-list-move {
  transition: transform 0.8s ease;
}
</style>
