<template>
  <div class="card">
    <div class="card-body">
      <h2 class="card-title mb-4">My Productivity</h2>
      <div v-if="!isLoading">
        <AgentProductivityChart :dataset="touched_stats" />
      </div>
      <div v-else><p>Loading...</p></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import AgentProductivityChart from '@/components/Dash/AgentProductivityChart.vue';

export default {
  name: 'AgentProductivityCard',
  components: { AgentProductivityChart },
  data() {
    return {
      isLoading: true,
      touched_stats: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  async mounted() {
    try {
      const url = `api/user/${this.user.id}/stats/accounts-touched`;
      this.touched_stats = (await axios.get(url)).data;
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
