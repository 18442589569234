<template>
  <Page>
    <div class="container">
      <div class="row">
        <div class="col mx-auto">
          <h5 class="mb-4">System Support</h5>
          <div class="card">
            <div class="card-body">
              <div class="row" v-if="!isLoading">
                <div class="col">
                  <p class="h6 mb-3">Push Account to Support</p>
                  <p class="card-text text-muted mt-4">
                    *A support call must be logged with the details -
                    QwiseSupport@ct-international.co.za
                  </p>
                  <form @submit.prevent="requestSupport">
                    <div class="row">
                      <div class="col-3">
                        <div class="form-floating mb-4">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="ILS Account Number"
                            min="10"
                            required
                            v-model="accNo"
                          />
                          <label for="firstname">ILS Account Number</label>
                        </div>
                      </div>
                      <div class="col-7">
                        <div class="form-floating mb-4">
                          <textarea
                            class="form-control"
                            placeholder="Notes"
                            v-model="notes"
                          >
                          </textarea>
                          <label for="firstname">Notes</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-2">
                        <button class="btn btn-primary" type="submit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col">
                  <LoadingSpinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/Shared/_Page.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { ref } from "vue";
import axios from "axios";
import { useStore } from "vuex";

export default {
  name: "SystemSupport",
  components: { Page, LoadingSpinner },
  setup() {
    const accNo = ref("");
    const notes = ref("");
    const isLoading = ref(false);

    const store = useStore();

    const requestSupport = async () => {
      isLoading.value = true;
      try {
        await axios.post("api/system-support", {
          notes: notes.value,
          ilsRef: accNo.value,
        });
        store.dispatch("notification/push", {
          body: `Success. The agent can refresh to get a new account`,
        });
        notes.value = "";
        accNo.value = "";
      } catch (error) {
        console.log(error.messahge);
        store.dispatch("notification/push", {
          body: `Action Failed.`,
          type: "error",
        });
      }
      isLoading.value = false;
    };

    return {
      accNo,
      notes,
      requestSupport,
      isLoading,
    };
  },
};
</script>

<style></style>
