export const getTimezoneAdjustedDate = (date) => {
  let d = date;
  if (typeof date === 'string') d = new Date(date.replace(/Z$/, ''));
  return d instanceof Date ? new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000) : undefined;
};

export const getISOString = (d) => getTimezoneAdjustedDate(d)?.toISOString();

export const getISODateString = (d) =>
  getTimezoneAdjustedDate(d)
    ?.toISOString()
    ?.split('T')[0];

export const getISOTimeString = (d) =>
  getTimezoneAdjustedDate(d)
    ?.toISOString()
    ?.split('T')[1];

export const getFormattedTime = (date) => {
  let ad = date;
  if (typeof date === 'string') ad = new Date(date.replace(/Z$/, ''));
  const h = ad.getHours() > 12 ? `${ad.getHours() - 12}` : `${ad.getHours()}`;
  const m = `${ad.getMinutes() < 10 ? `0${ad.getMinutes()}` : `${ad.getMinutes()}`}`;
  const ampm = ad.getHours() < 12 ? 'AM' : 'PM';
  return `${h}:${m} ${ampm}`;
};

export const getNowDateString = () => {
  const now = new Date();
  const nowTimeZoneAdjusted = new Date(now.getTime() - now.getTimezoneOffset() * 60 * 1000);
  const parts = nowTimeZoneAdjusted.toISOString().split('T');

  const date = parts[0];
  return date;
};

export const getNowTimeString = () => {
  const now = new Date();
  const nowTimeZoneAdjusted = new Date(now.getTime() - now.getTimezoneOffset() * 60 * 1000);
  const parts = nowTimeZoneAdjusted.toISOString().split('T');

  const timeWithMS = parts[1].split('.')[0];
  const time = timeWithMS.substring(0, timeWithMS.length - 3); // remove last three chars (':' and 2 digit ms)
  return time;
};

export const getFirstDayOfLastMonthISOString = () => {
  const d = new Date();
  return getISODateString(getTimezoneAdjustedDate(new Date(d.getFullYear(), d.getMonth() - 1, 1)));
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addMonths = (date, months) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
};
