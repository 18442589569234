<template>
  <Page>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5 class="mt-5 mb-4">CTPS</h5>

          <div class="card">
            <div class="card-body">
              <div class="row mb-4">
                <div :class="{ 'd-none': !canSelectUser }" class="col-md-2">
                  <label for="" class="form-label">User</label>
                  <select
                    v-if="canSelectUser"
                    class="form-select"
                    v-model="agent_user_id"
                  >
                    <option :value="null" disabled selected>
                      Select a user
                    </option>
                    <option
                      v-for="agent in teamAgents"
                      :key="agent.user_id"
                      :value="agent.user_id"
                    >
                      {{ agent.username }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label for="" class="form-label">From</label>
                  <input v-model="start" type="date" class="form-control" />
                </div>
                <div class="col-md-2">
                  <label for="" class="form-label">To</label>
                  <input v-model="end" type="date" class="form-control" />
                </div>
                <div class="col-md-2">
                  <label for="" class="form-label">Reference</label>
                  <input
                    v-model="accountRef"
                    type="text"
                    class="form-control"
                    placeholder="Account reference"
                  />
                </div>
                <div class="col-md-6 d-none">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="option1"
                    />
                    <label class="form-check-label" for="inlineRadio1"
                      >Created</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="option2"
                    />
                    <label class="form-check-label" for="inlineRadio2"
                      >Filled</label
                    >
                  </div>
                </div>
              </div>
              {{}}
              <div v-if="agent_user_id && start && end">
                <CTPList
                  v-if="!isLoading && CTPs && paymentMethods"
                  :CTPs="filteredCTPs"
                  :paymentMethods="paymentMethods"
                  :canEdit="userIs('SUPERVISOR') || userIs('MANAGER')"
                  :canDelete="userIs('SUPERVISOR') || userIs('MANAGER')"
                  @edit="promptEditCTP"
                  @delete="promptDeleteCTP"
                />
                <LoadingSpinner v-if="isLoading" />
                <CTPEditModal
                  v-if="currentCTP"
                  :show="showEdit"
                  :CTP="currentCTP"
                  :isSaving="isSaving"
                  :paymentMethods="paymentMethods"
                  @save="editCTP"
                  @hide="cancelEditCTP"
                />
                <CTPConfirmDeleteModal
                  :show="showConfirm"
                  :CTP="currentCTP"
                  @confirm="deleteCTP"
                  @cancel="cancelDeleteCTP"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import { computed } from 'vue';
import { mapGetters } from 'vuex';
import axios from 'axios';

import { userIs } from '@/helpers/permissions';
import { getNowDateString, getNowTimeString } from '@/helpers/datetime';
import Page from '@/components/Shared/_Page.vue';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
import CTPList from '@/components/Agent/CTPList.vue';
import CTPEditModal from '@/components/Agent/CTPEditModal.vue';
import CTPConfirmDeleteModal from '@/components/Agent/CTPConfirmDeleteModal.vue';

export default {
  name: 'CTPs',
  components: {
    Page,
    LoadingSpinner,
    CTPList,
    CTPEditModal,
    CTPConfirmDeleteModal,
  },
  setup() {
    const canSelectUser = computed(
      () => userIs('MANAGER') || userIs('LEGAL MANAGER') || userIs('SUPERVISOR')
    );

    return { userIs, canSelectUser, getNowDateString, getNowTimeString };
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      CTPs: [],
      start: null,
      end: null,
      accountRef: '',
      agent_user_id: null,
      teamAgents: null,
      paymentMethods: null,
      currentCTP: null,
      showEdit: false,
      showConfirm: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      permissions: 'auth/permissions',
    }),
    filteredCTPs() {
      if (this.accountRef) {
        return this.CTPs.filter((ctp) =>
          ctp.reference.toString().includes(this.accountRef)
        );
      }
      return this?.CTPs;
    },
    userPositionId() {
      return this.permissions.find(
        (position) => position.role.toUpperCase() === 'MANAGER' || 'SUPERVISOR'
      )?.user_position_id;
    },
    // activeCTPs() {
    //   return this?.CTPs.filter((ctp) => ctp.status === 1);
    // },
    // honoredCTPs() {
    //   return this?.CTPs.filter((ctp) => ctp.status === 2);
    // },
    // brokenCTPs() {
    //   return this?.CTPs.filter((ctp) => ctp.status === 3);
    // },
  },
  methods: {
    async fetchCTPs() {
      if (!this.agent_user_id || !this.start || !this.end) return;

      try {
        this.isLoading = true;
        const { start, end } = this;
        this.CTPs = (
          await axios.get(`/api/users/${this.agent_user_id}/ctps`, {
            params: { start, end },
          })
        ).data;
        this.CTPs = this.CTPs.map((ctp) => ({
          ...ctp,
          created_date: new Date(ctp.created_date),
          due_date: new Date(ctp.due_date),
          final_due_date: new Date(ctp.final_due_date),
        }));
        this.paymentMethods = (
          await axios.get('/api/ctps/payment_methods')
        ).data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },

    promptEditCTP(id) {
      this.currentCTP = this.CTPs.find((ctp) => ctp.id === id);
      this.showEdit = true;
    },

    cancelEditCTP() {
      this.currentCTP = null;
      this.showEdit = false;
    },

    promptDeleteCTP(id) {
      this.currentCTP = this.CTPs.find((ctp) => ctp.id === id);
      this.showConfirm = true;
    },

    cancelDeleteCTP() {
      this.currentCTP = null;
      this.showConfirm = false;
    },

    async deleteCTP() {
      this.showConfirm = false;
      console.log(this.userPositionId);

      const index = this.CTPs.findIndex((ctp) => ctp.id === this.currentCTP.id);
      const managementUserPositionId = this.userPositionId;
      try {
        this.CTPs[index].status_id = 6;
        await axios.delete(`/api/ctps/${this.currentCTP.id}`, {
          params: {
            managementUserPositionId,
          },
        });
      } catch (error) {
        this.CTPs[index].status_id = 1;
        console.error(error);
      }
    },

    async editCTP(payload) {
      this.isSaving = true;
      const { id, ...data } = payload;
      data.managementUserPositionId = this.userPositionId;

      try {
        const { status } = await axios.patch(`/api/ctps/${id}`, data);
        if (status >= 200 && status < 300) {
          this.showEdit = false;
          const index = this.CTPs.findIndex((c) => c.id === id);
          this.CTPs[index] = { ...this.CTPs[index], ...data };
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch('notification/push', {
          type: 'error',
          body: error.response.data.message || 'Error updating CTP',
        });
      }
      this.isSaving = false;
    },

    async fetchTeamAgents() {
      const teamIds = this.permissions
        .filter((p) =>
          ['MANAGER', 'LEGAL MANAGER', 'SUPERVISOR'].includes(
            p.role.toUpperCase()
          )
        )
        .map((p) => p.team_id);

      try {
        this.teamAgents = (
          await axios.all(teamIds.map((t) => axios.get(`/api/teams/${t}`)))
        )
          .map((r) => r.data)
          .map((p) => p.positions)
          .flat()
          .filter((p) =>
            ['AGENT', 'INBOUND AGENT', 'DEBT REVIEW AGENT'].includes(
              p.role.toUpperCase()
            )
          )
          .map((p) => ({
            user_id: p.assigned_user_id,
            username: p.assigned_user,
          }));
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    agent_user_id() {
      this.fetchCTPs();
    },
    start() {
      this.fetchCTPs();
    },
    end() {
      this.fetchCTPs();
    },
  },
  created() {
    if (this.userIs('AGENT')) {
      this.agent_user_id = this.user.id;
    }

    if (this.canSelectUser) {
      this.fetchTeamAgents();
    }

    this.start = this.getNowDateString();

    this.end = this.getNowDateString();
  },
};
</script>

<style scoped>
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
</style>
