<template>
  <div class="row">
    <div class="col-12">
      <table class="table align-middle mt-4">
        <thead>
          <tr>
            <th scope="col">Member</th>
            <th scope="col">Accounts Worked</th>
            <th scope="col">No. Accounts Searched</th>
            <th scope="col">No. CTP Made</th>
          </tr>
        </thead>
        <tbody v-if="!isLoading && teamMembers">
          <tr v-for="(member, index) in teamMembers" :key="index">
            <td>{{ member.username }}</td>
            <td>
              <span v-if="member.acc_worked">{{ member.acc_worked }}</span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="member.search_count">{{ member.search_count }}</span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="member.ctp_count">{{ member.ctp_count }}</span>
              <span v-else>N/A</span>
            </td>
          </tr>
        </tbody>
        <div class="row align-center" v-else-if="isLoading">
          <div class="col">
            <LoadingSpinner />
          </div>
        </div>
        <div v-else class="row">
          <div class="col">*This team has no members</div>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
import { fetchTeamStats } from "@/api/stats";
import { onMounted, ref } from "vue";

import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

export default {
  name: "TeamStats",
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  components: { LoadingSpinner },

  setup(props) {
    const teamMembers = ref({});
    const isLoading = ref(true);

    const getMembers = async () => {
      isLoading.value = true;
      teamMembers.value = await fetchTeamStats(props.id);
      isLoading.value = false;
    };

    onMounted(getMembers);

    return {
      teamMembers,
      isLoading,
    };
  },
};
</script>

<style></style>
