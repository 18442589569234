<template>
  <div>
    <Form @submit="formValidate" :validation-schema="addUserValidation">
      <div class="form-floating mb-3">
        <Field
          name="firstname"
          class="form-control"
          type="text"
          id="firstname"
          placeholder="First Name"
          v-model="user.firstname"
        />
        <label for="firstname">First Name</label>
        <ErrorMessage name="firstname" class="error-message" />
      </div>

      <div class="form-floating mb-3">
        <Field
          name="lastname"
          class="form-control"
          type="text"
          id="lastname"
          placeholder="lastname"
          v-model="user.lastname"
        />
        <label for="lastname">Last Name</label>
        <ErrorMessage name="lastname" class="error-message" />
      </div>

      <div class="form-floating mb-3">
        <Field
          name="email"
          class="form-control"
          type="email"
          id="email"
          placeholder="Email"
          v-model="user.email"
          v-on:blur="checkEmail"
        />
        <label for="email">Email</label>
        <ErrorMessage name="email" class="error-message" />
        <p v-if="isCheckingEmail" class="mt-1">Checking email address...</p>
        <p v-if="!uniqueEmail && !isCheckingEmail" class="error-message">
          This email is already in use
        </p>
      </div>

      <div class="form-floating mb-3">
        <Field
          name="extension"
          class="form-control"
          type="text"
          id="extension"
          placeholder="Extension"
          v-model="user.extension"
        />
        <label for="extension">Extension</label>
      </div>

      <div class="form-floating mb-3">
        <Field
          name="password"
          class="form-control"
          type="password"
          id="password"
          placeholder="Password"
          v-model="user.password"
        />
        <label for="password">Password</label>
        <ErrorMessage name="password" class="error-message" />
      </div>

      <div class="form-floating mb-3">
        <Field
          name="confirm_password"
          class="form-control"
          type="password"
          id="confirm_password"
          placeholder="Confirm Password"
          v-model="user.confirm_password"
        />
        <label for="confirm_password">Confirm Password</label>
        <ErrorMessage name="confirm_password" class="error-message" />
      </div>
      <button class="btn btn-primary" :disabled="!uniqueEmail || isCheckingEmail">
        Next
      </button>
    </Form>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import axios from "axios";

export default {
  name: "CreateUserForm",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  emits: ["new-user"],
  data() {
    const addUserValidation = yup.object({
      firstname: yup.string().required("First name is required"),
      lastname: yup.string().required("Last name is required"),
      email: yup
        .string()
        .required("Email is required")
        .email("This is not a valid email address"),
      password: yup
        .string()
        .required("Password is required")
        .min(4, "Password must be at least 4 characters")
        .max(6, "Password cannot be longer than 6 characters"),
      confirm_password: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("password")], "Passwords do not match"),
    });
    return {
      uniqueEmail: true,
      addUserValidation,
      isCheckingEmail: false,
      user: {
        firstname: "",
        lastname: "",
        email: "",
        extension: "",
        password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    formValidate() {
      if (this.uniqueEmail === true) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      const newUserData = (({ email, firstname, lastname, password, extension }) => ({
        email,
        firstname,
        lastname,
        password,
        extension,
      }))(this.user);
      const newUser = (await axios.post("api/users", newUserData)).data;
      this.$store.dispatch("notification/push", {
        body: `User Created`,
      });
      this.$emit("new-user", newUser);

      // this.$emit("new-user", this.user);
    },
    async checkEmail() {
      if (!this.user.email) return;
      this.isCheckingEmail = true;
      this.uniqueEmail = (
        await axios.get("api/users/email", {
          params: { email: this.user.email },
        })
      ).data;
      this.isCheckingEmail = false;
    },
  },
};
</script>

<style scoped>
option {
  font-size: 1rem;
}
</style>
