<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-between">
            <h4 class="mb-4">Client Personal Info</h4>
            <a href="#editClientModal" class="link-dark" data-bs-toggle="modal">
              <span class="material-icons md-18"> edit </span>
            </a>
          </div>

          <EditClientModal />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div v-if="account?.customer_details?.IDNumber" class="data-title">
            ID Number
          </div>
          <div v-if="account?.customer_details?.Passport" class="data-title">
            Passport
          </div>
          <div class="data-value">
            {{
              account?.customer_details?.IDNumber ||
              account?.customer_details?.Passport ||
              '-'
            }}
          </div>
        </div>
        <div class="col">
          <div class="data-title">Birthdate</div>
          <div class="data-value">
            {{ account.customer_details.DateOfBirth }}
          </div>
        </div>
      </div>

      <div class="data-title">Email</div>
      <div class="data-value">
        {{ account.customer_details.Email || '-' }}
      </div>

      <div class="row">
        <div class="col">
          <div class="data-title">Occupation</div>
          <div class="data-value">
            <span v-if="account.customer_details.Occupation">
              {{ account.customer_details.Occupation }}
            </span>
            <span v-else> - </span>
          </div>
        </div>
        <div class="col">
          <div class="data-title">Salary Date</div>
          <div class="data-value">
            {{ account.customer_details.SalaryDate }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="data-title">Address</div>
          <div class="data-value mb-0">
            <span v-if="account.customer_details.StreetAddress1">
              {{ account.customer_details.StreetAddress1 }}
            </span>
            <span v-if="account.customer_details.StreetAddress2"
              >, {{ account.customer_details.StreetAddress2 }}
            </span>
            <span v-if="account.customer_details.StreetAddress3"
              >, {{ account.customer_details.StreetAddress3 }}
            </span>
            <span v-if="account.customer_details.StreetAddress4"
              >, {{ account.customer_details.StreetAddress4 }}
            </span>
            <span v-if="account.customer_details.StreetPostCode"
              >, {{ account.customer_details.StreetPostCode }}
            </span>
          </div>
        </div>
        <div class="col">
          <div class="data-title">Postal Address</div>
          <div class="data-value mb-0">
            <span v-if="account.customer_details.PostalAddress1">
              {{ account.customer_details.PostalAddress1 }}
            </span>
            <span v-if="account.customer_details.PostalAddress"
              >, {{ account.customer_details.PostalAddress }}
            </span>
            <span v-if="account.customer_details.PostalAddress3"
              >, {{ account.customer_details.PostalAddress3 }}
            </span>
            <span v-if="account.customer_details.PostalAddress4"
              >, {{ account.customer_details.PostalAddress4 }}
            </span>
            <span v-if="account.customer_details.PostalCode"
              >, {{ account.customer_details.PostalCode }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditClientModal from '@/components/Account/EditClientModal.vue';

export default {
  name: 'AccountClientInfo',
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  components: { EditClientModal },
};
</script>

<style lang="scss" scoped></style>
