<template>
  <nav class="navbar navbar-expand">
    <div class="container-fluid">
      <!-- Add check below to see if user has these rights -->
      <template
        v-if="
          userIn('Debt Review') ||
          userIn('Trace') ||
          userIn('Legal') ||
          userIs('INBOUND AGENT') ||
          userIs('ADMIN') ||
          userIs('MANAGER') ||
          userIs('SUPERVISOR')
        "
      >
        <SearchTimedInput @search="searchAccounts" />
      </template>
      <div class="ms-auto"></div>
      <span v-if="currentActivity" class="navbar-text px-2 ms-auto"
        ><span v-if="false">
          <FontAwesomeIcon :icon="['far', 'dot-circle']" /> Active
        </span>
        <span v-else>
          <FontAwesomeIcon :icon="['far', 'circle']" /> {{ currentActivity }}
        </span></span
      >

      <ul class="navbar-nav">
        <router-link :to="{ name: 'support' }" class="nav-link">Support</router-link>

        <li class="nav-item dropdown">
          <a
            href="#"
            class="nav-link dropdown-toggle"
            id="profileDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span v-if="user">{{ user.firstname }}</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
            <li>
              <router-link :to="{ name: 'view_profile' }" class="dropdown-item"
                >Profile</router-link
              >
            </li>

            <li><hr class="dropdown-divider" /></li>
            <li><a @click="logout" class="dropdown-item">Sign out</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

import { userIs, userIn } from "@/helpers/permissions";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import SearchTimedInput from "@/components/Shared/SearchTimedInput.vue";

library.add(faCircle, faDotCircle, faSearch);

export default {
  name: "AppNav",
  components: { FontAwesomeIcon, SearchTimedInput },
  setup() {
    return { userIs, userIn };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      currentActivity: "activity/currentActivity",
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" });
    },
    searchAccounts(term) {
      if (term) {
        this.$router.push({ name: "accounts", params: { search: term } });
      } else {
        this.$router.push({ name: "dash" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  padding: 0.75rem 0;
  background: transparent;
  .nav-link {
    color: inherit;
  }
}
#search::-webkit-search-cancel-button {
  position: relative;
  color: #333;
}
</style>
