<template>
  <div class="table-responsive">
    <table
      class="table table-bordered align-middle"
      v-if="filteredPositionOptions.length"
    >
      <thead>
        <tr>
          <!-- <th scope="col" class="w-25">Position</th> -->
          <th scope="col" class="w-10">Role</th>
          <th scope="col" class="w-30">Assigned to</th>
          <th scope="col" class="w-10">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="position in filteredPositionOptions" :key="position.id">
          <!-- <td>{{ position.position }}</td> -->
          <td>{{ position.role }}</td>
          <AssignPosition
            :position="position"
            @user-assigned="refreshTeam"
            @delete="$emit('delete', position)"
          />
        </tr>
      </tbody>
    </table>
    <p v-else class="mt-4 ms-3">*There are no open positions. Please create a new one.</p>
  </div>
</template>

<script>
import AssignPosition from "./UnassignedPositionsActions.vue";

export default {
  components: { AssignPosition },
  name: "UnassignedPositionList",
  props: {
    unassignedPositions: {
      required: true,
      type: Object,
    },
    team_id: {
      required: true,
    },
    user_list: {
      required: true,
      type: Array,
    },
  },
  computed: {
    filteredPositionOptions() {
      // Filter all unassigned positions by team_id
      return this.team_id
        ? this.unassignedPositions.filter(
            (position) => position.team_id === parseInt(this.team_id, 10)
          )
        : this.unassignedPositions;
    },
  },
  data() {
    return {
      assigned_user: "",
    };
  },
  methods: {
    async refreshTeam() {
      await this.$store.dispatch("team/getTeam", this.team_id);
      await this.$store.dispatch("team/getUnassignedPositions");
    },
  },
};
</script>

<style lang="scss" scoped></style>
