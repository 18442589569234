<template>
  <!-- <Page> -->
  <div id="Collect">
    <div v-if="ready">
      <transition name="fade" mode="out-in" appear>
        <template v-if="activeAccount">
          <div class="d-flex">
            <div class="main">
              <AccountDisplay :account="activeAccount" />
            </div>

            <div class="sidebar">
              <InboundActionPanel :ready="ready" @confirm="confirm" />
            </div>
          </div>
        </template>
      </transition>
    </div>
    <div v-if="!ready" style="width: 100%; height: 100vh">
      <LoadingSpinner />
    </div>
  </div>
  <!-- <div v-if="isLoading">
    <LoadingSpinnerFull />
  </div>
  <div v-if="!isLoading && activeAccount" id="collect">
    <div style="width: 100%; height: 100vh">
      <transition name="fade" mode="out-in" appear>
        <div v-if="activeAccount" class="d-flex flex-column h-100">
          <div class="d-flex flex-grow-1">
            <div class="main">
              <AccountDisplay :account="activeAccount" />
            </div>
            <div class="sidebar">
              <ActionPanel :ready="ready" :inbound="true" @confirm="confirm" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div> -->
  <!-- </Page> -->
</template>

<script>
import { mapGetters } from "vuex";

import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

import AccountDisplay from "@/components/Account/AccountDisplay.vue";
import InboundActionPanel from "@/components/Collect/ActionPanel/InboundActionPanel.vue";

export default {
  name: "Account",
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  // components: { AccountDisplay, LoadingSpinnerFull, ActionPanel },
  components: { LoadingSpinner, AccountDisplay, InboundActionPanel },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      activeAccount: "account/activeAccount",
      //   isLoading: 'account/isLoading',
      error: "collection/error",
      positions: "auth/permissions",
    }),
  },
  methods: {
    async confirm(payload) {
      // find first user position id for agent role
      let agentPosition = this.positions.find((p) => p.role.toUpperCase() === "AGENT");
      if (!agentPosition) {
        agentPosition = this.positions.find(
          (p) => p.role.toUpperCase() === "INBOUND AGENT"
        );
      }
      if (!agentPosition?.user_position_id) {
        agentPosition = this.positions.find((p) => p.role.toUpperCase() === "ADMIN");
      }
      if (!agentPosition?.user_position_id) {
        agentPosition = this.positions.find((p) => p.role.toUpperCase() === "SUPERVISOR");
      }
      if (!agentPosition?.user_position_id) {
        agentPosition = this.positions.find((p) => p.role.toUpperCase() === "MANAGER");
      }
      if (!agentPosition?.user_position_id) {
        agentPosition = this.positions.find((p) => p.role.toUpperCase() === "SUPER USER");
      }
      if (!agentPosition?.user_position_id) {
        agentPosition = this.positions.find(
          (p) => p.role.toUpperCase() === "LEGAL MANAGER"
        );
      }
      if (!agentPosition?.user_position_id) {
        agentPosition = this.positions.find((p) => p.role.toUpperCase() === "PARALEGAL");
      }
      if (!agentPosition?.user_position_id) {
        agentPosition = this.positions.find(
          (p) => p.role.toUpperCase() === "SENIOR LEGAL ADMIN"
        );
      }
      if (!agentPosition?.user_position_id) {
        agentPosition = this.positions.find(
          (p) => p.role.toUpperCase() === "LEGAL ADMIN"
        );
      }
      if (!agentPosition?.user_position_id) {
        agentPosition = this.positions.find(
          (p) => p.role.toUpperCase() === "DEBT REVIEW AGENT"
        );
      }

      if (!agentPosition) {
        this.$store.dispatch("notification/push", {
          type: "error",
          body: `Action failed, incorrect user position`,
        });
        return;
      }

      const { closesAction, ...p } = payload;
      const finalPayload = {
        ...p,
        user_position_id: agentPosition?.user_position_id,
        account_id: this.activeAccount?.id,
      };

      const success = await this.$store.dispatch(
        "collection/confirmInboundAccountTreatment",
        finalPayload
      );
      if (success) {
        if (closesAction) {
          this.$router.push({ name: "collectPortal" });
        }
      } else {
        this.$store.dispatch("notification/push", {
          type: "error",
          body: this.error,
        });
      }

      // //
      // // TODO - Make sure user_position_id is one for "Incoming Agent" position
      // //
      // const success = await axios.post('api/treatment/inbound', {
      //   ...payload,
      //   account_id: this.activeAccount.id,
      //   user_position_id: this.positions[0].user_position_id,
      // });
      // if (success) {
      //   this.$store.dispatch('notification/push', {
      //     body: `Action confirmed`,
      //   });
      //   this.$store.dispatch('account/clearCurrentAccount');
      //   this.$router.push({ name: 'Dash' });
      // } else {
      //   this.$store.dispatch('notification/push', {
      //     body: `Action failed...`,
      //   });
      // }
    },
  },
  async created() {
    await this.$store.dispatch("telephony/login", {
      extension: this.user.extension,
    });
    await this.$store.dispatch("account/getAccountInbound", this.id);
    this.ready = true;
    // this.$store.dispatch('activity/saveActivity', {
    //   userId: this.user.id,
    //   activityId: 7,
    // });
  },
  mounted() {
    // (async () => {
    //   await this.$store.dispatch('account/getAccount', this.id);
    // })();
    // this.ready = true;
  },
  beforeUnmount() {
    this.$store.dispatch("account/clearCurrentAccount");
  },
};
</script>
<style scoped lang="scss">
#Collect {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.main {
  width: 100%;
}

.sidebar {
  width: 30rem;
  position: sticky;
  flex-shrink: 0;
  right: 0;
  top: 0;
  padding: 2.5rem 2.5rem 2.5rem 0;
}
</style>
