<template>
  <Queue
    idKey="id"
    sortKey="due_date_time"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #subtitle> Debt Review </template>
    <template #title> Counter Proposal </template>
    <template v-slot:default="{ item: account }">
      <DebtReviewCounterProposalQueueItem
        :account="account"
        @action="action"
        @note="createNote"
        @defer="defer"
      />
    </template>
  </Queue>
</template>

<script>
import axios from 'axios';

import { createNote, deferAccount } from '@/api/account';

import Queue from '@/components/Shared/Queue.vue';
import DebtReviewCounterProposalQueueItem from '@/components/Admin/DebtReviewCounterProposalQueueItem.vue';

export default {
  name: 'DebtReviewCounterProposalQueue',
  components: { Queue, DebtReviewCounterProposalQueueItem },
  data() {
    return {
      accounts: null,
      isLoading: true,
    };
  },
  async mounted() {
    const queue = (
      await axios.get('api/admin/queues/debt-review-counter-proposal')
    ).data;
    this.accounts = queue.accounts.map((account) => {
      const tempAcc = account;
      tempAcc.event_note = JSON.parse(tempAcc.event_note);
      tempAcc.entry_date = new Date(tempAcc.entry_date);
      tempAcc.due_date_time = tempAcc.due_date_time
        ? new Date(tempAcc.due_date_time)
        : null;
      return tempAcc;
    });
    this.isLoading = false;
  },

  methods: {
    createNote({ accountId, note }) {
      createNote(accountId, note);
    },
    async defer(event) {
      const { accountId, payload } = event;
      try {
        this.accounts = this.accounts.map((account) => {
          const tempAccount = account;
          if (account.id === accountId) {
            tempAccount.due_date_time = new Date(
              `${payload.data.date} 00:00:00`
            );
          }
          return tempAccount;
        });
        deferAccount(accountId, payload);
      } catch (error) {
        this.accounts = await this.getAccounts();
        this.$store.dispatch('notification/push', {
          body: `Action Failed`,
          type: 'error',
        });
      }
      this.$store.dispatch('account/clearCurrentAccount');
    },
    async action(event) {
      const { accountId, payload } = event;
      this.$store.dispatch('account/clearCurrentAccount');
      try {
        this.accounts = this.accounts.filter((acc) => acc.id !== accountId);
        const url = `/api/admin/queues/debt-review-counter-proposal/${accountId}`;
        await axios.patch(url, payload);
      } catch (error) {
        this.loadAccounts();
        this.$store.dispatch('notification/push', {
          body: `Action Failed`,
          type: 'error',
        });
      }
    },
  },
};
</script>
