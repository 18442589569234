<template>
  <div class="h-100 d-flex flex-column gap-5">
    <div class="card flex-grow-1">
      <div class="card-header">
        <ul class="nav nav-pills" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Treatment
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              Actions
            </button>
          </li>
        </ul>
      </div>
      <div class="card-body d-flex flex-column">
        <div
          v-if="ready && !accountErrors.length"
          class="d-flex flex-column h-100 mb-4"
        >
          <div class="tab-content h-100" id="pills-tabContent">
            <div
              class="tab-pane fade show active h-100"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <!-- <transition name="component-fade" mode="out-in"> -->
              <keep-alive>
                <component
                  :is="currentView"
                  :event="name"
                  :details="details"
                  :fresh="fresh"
                  :inbound="inbound"
                  @choice="update"
                  @timeout="initiateCall"
                  @init="init"
                  @back="back"
                  @submit="done"
                  @confirm="confirm"
                  @exit="exit"
                />
              </keep-alive>
              <!-- </transition> -->

              <button
                @click="back"
                id="back"
                class="btn btn-round btn-primary"
                v-if="restartVisible"
                :disabled="isTreatmentSaving"
              >
                <span class="material-icons"> arrow_back </span>
              </button>
            </div>

            <div
              class="tab-pane fade h-100"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div class="d-flex flex-column justify-content-center h-100">
                <div class="d-grid gap-3">
                  <AuxActions :actionId="actionId" />
                  <CreateNote @done="done" />
                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="ready && accountErrors.length">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <h2>No Account</h2>
                <p v-for="(error, index) in accountErrors" :key="index">
                  {{ error.message }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!ready">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <LoadingSpinner :color="'#fff'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Phone
      @done="nextAccount"
      @NRPC="NRPCNextAccount"
      v-on:call="initiateCall"
      v-on:hangup="endCall"
      :number="callNumber"
      :is-dialing="isDialing"
      :isReturning="isReturning"
      :callButton="callButton"
      @callSameNumber="callBack"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import RPCDiarise from '@/components/Account/ActionPanel/Steps/Choices/RPCDiarise.vue';
import RPCCTP from '@/components/Account/ActionPanel/Steps/Choices/RPCCTP.vue';
import RPCRefusal from '@/components/Account/ActionPanel/Steps/Choices/RPCRefusal.vue';
import RPCPaymentDispute from '@/components/Account/ActionPanel/Steps/Choices/RPCPaymentDispute.vue';
import RPCOther from '@/components/Account/ActionPanel/Steps/Choices/RPCOther.vue';
import NRPCLeftMessage from '@/components/Account/ActionPanel/Steps/Choices/NRPCLeftMessage.vue';
import NRPCOther from '@/components/Account/ActionPanel/Steps/Choices/NRPCOther.vue';
import CreateNote from '@/components/Account/ActionPanel/AuxActions/CreateNote.vue';

import eventTypes from '@/helpers/eventTypes';
import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';

import RPC from '@/components/Account/ActionPanel/Steps/RPC.vue';
import NRPC from '@/components/Account/ActionPanel/Steps/NRPC.vue';
import Escalate from '@/components/Account/ActionPanel/Steps/Escalate.vue';
import Journal from '@/components/Account/ActionPanel/Steps/Journal.vue';
import Init from '@/components/Account/ActionPanel/Steps/Init.vue';
import Calling from '@/components/Account/ActionPanel/Steps/Calling.vue';
import Confirm from '@/components/Account/ActionPanel/Steps/Confirm.vue';

import Phone from './Phone.vue';
import AuxActions from './AuxActions/AuxActions.vue';

const initState = () => ({
  type: null,
  details: {},
  currentView: Init,
  history: [],
  fresh: true,
  closesAction: null,
  numberCount: 0,
  eventTypes,
  callNumber: null,
  callButton: false,
  eventCount: 0,
});

const incomingState = () => ({
  type: null,
  details: {},
  currentView: Init,
  fresh: true,
  closesAction: null,
});

export default {
  name: 'actionPanel',
  props: {
    ready: Boolean,
    inbound: Boolean,
    actionId: Number,
    isReturning: Boolean,
  },
  components: {
    LoadingSpinner,
    //
    RPC,
    NRPC,
    Escalate,
    Journal,
    Init,
    Calling,
    Confirm,
    //
    RPCDiarise,
    RPCCTP,
    RPCRefusal,
    RPCPaymentDispute,
    RPCOther,
    NRPCLeftMessage,
    NRPCOther,
    //
    Phone,
    AuxActions,
    CreateNote,
  },
  data() {
    if (this.inbound) {
      return incomingState();
    }
    return initState();
  },
  computed: {
    ...mapGetters({
      isAssignedAccount: 'account/isAssignedAccount',
      activeAccount: 'account/activeAccount',
      accountErrors: 'account/errors',

      phoneNumbers: 'account/phoneNumbers',
      username: 'auth/username',
      isDialing: 'telephony/isDialing',
      isTreatmentSaving: 'collection/isLoading',
    }),
    restartVisible() {
      return this.history?.length > 0;
      // return this.currentView.name !== 'Init';
    },
  },
  methods: {
    nextAccount() {
      this.$emit('next');
    },
    NRPCNextAccount() {
      const noContact = {
        eventTypeId: this.eventTypes.NO_RIGHT_PERSON_CONTACT.type,
        closesAction: this.eventTypes.NO_RIGHT_PERSON_CONTACT.closesAction,
      };
      this.$emit('confirm', noContact);
      // this.$store.dispatch('account/clearCurrentAccount');
    },
    callBack() {
      this.$store.dispatch('notification/push', {
        body: `Calling ${this.callNumber}`,
      });
      this.$store.dispatch('telephony/call', this.callNumber);
    },
    async initiateCall() {
      // this.currentView = Calling;
      this.callNumber = this.phoneNumbers[this.numberCount];
      this.$store.dispatch('notification/push', {
        body: `Calling ${this.callNumber}`,
      });
      this.numberCount += 1;
      this.$store.dispatch('telephony/call', this.callNumber);

      if (this.numberCount >= this.phoneNumbers.length) {
        this.callButton = true;
      }
    },
    async endCall() {
      if (this.numberCount < this.phoneNumbers.length) {
        this.callButton = false;
      }
      await this.$store.dispatch('telephony/endCall');
    },
    update(payload) {
      const routes = {
        rpc: RPC,
        nrpc: NRPC,
        escalate: Escalate,
        journal: Journal,
        //
        rpc_diarise: RPCDiarise,
        rpc_CTP: RPCCTP,
        rpc_refusal: RPCRefusal,
        rpc_paymentDispute: RPCPaymentDispute,
        rpc_other: RPCOther,
        nrpc_leftMessage: NRPCLeftMessage,
        nrpc_other: NRPCOther,
      };

      this.history.push(this.currentView);

      this.currentView = routes[payload];
    },
    done(payload) {
      this.type = payload.type;
      this.name = payload.name;
      this.details = payload?.details;
      this.closesAction = payload.closesAction;
      if (payload.name === 'Note') {
        const notePayload = {
          eventTypeId: this.type,
          details: this.details,
        };
        this.$emit('confirm', notePayload);
      } else if (payload.name === 'AOD') {
        const aodPayload = {
          eventTypeId: this.type,
        };
        this.$emit('confirm', aodPayload);
      } else {
        this.currentView = Confirm;
        this.fresh = false;
      }
    },
    back() {
      this.details = null;
      this.currentView = this.history.pop() ?? Init;
    },
    async confirm() {
      // this.$store.dispatch('telephony/endCall');
      const eventNumber = this.phoneNumbers[this.eventCount];
      this.eventCount += 1;
      if ((this.onCall || this.isDialing) && this.closesAction) {
        await this.endCall();
      }

      const payload = {
        eventTypeId: this.type,
        details: this.details,
        number: eventNumber,
        closesAction: this.closesAction,
      };

      this.$emit('confirm', payload);
      if (this.closesAction) {
        await this.$store.dispatch('telephony/resetCallBack');
      } else if (this.numberCount < this.phoneNumbers.length) {
        this.history = [];
        this.currentView = Init;
        this.initiateCall();
      } else if (this.eventCount >= this.numberCount) {
        // const noContact = {
        //   eventTypeId: this.eventTypes.NO_RIGHT_PERSON_CONTACT.type,
        // };
        // await this.$store.dispatch("telephony/resetCallBack");
        // this.$emit("confirm", noContact);
        // this.$store.dispatch("account/clearCurrentAccount");
        // this.endCall();
      }
    },
    init() {
      Object.assign(this.$data, initState());
    },
    // requestNext() {
    //   this.init();
    //   this.$emit("requestNext");
    // },
    exit() {
      this.$emit('exit');
    },
  },
};
</script>

<style lang="scss" scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#back {
  position: absolute;
  bottom: 3rem;
  right: 3rem;
}
</style>
