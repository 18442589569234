<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span> </template
    ><template #title>{{ account.reference }}</template>
    <template #body>
      <EventNote :content="account.event_note" :multiline="true" />
    </template>
    <template #actions>
      <RoundButton
        @click="$emit('trace')"
        large
        class="btn-action text-primary"
        title="Trace"
      >
        <span class="material-icons"> search </span>
      </RoundButton>
    </template>
  </QueueItem>
</template>


<script>
import { ref } from 'vue';
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import EventNote from '@/components/Shared/EventNote.vue';
import RoundButton from '@/components/Shared/_RoundButton.vue';

export default {
  props: {
    account: Object,
  },
  components: {
    QueueItem,
    EventNote,
    RoundButton,
  },
  emits: ['trace'],
  setup() {
    const showForm = ref('');
    const toggleForm = (formName) => {
      showForm.value = showForm.value !== formName ? formName : '';
    };

    return {
      showForm,
      toggleForm,
    };
  },
};
</script>