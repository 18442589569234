<template>
  <div>
    <div class="mb-3">
      <div class="d-flex justify-content-between align-items-end">
        <h5>
          Proposed Writeoff <span v-if="accounts">({{ count }})</span>
        </h5>
      </div>
    </div>
    <div v-if="accounts">
      <Queue
        idKey="id"
        sortKey="entry_date"
        :items="accounts"
        v-slot:default="{ item: account }"
      >
        <QueueItem :account="account">
          <template #tag
            ><span class="small d-block mb-3"
              >On {{ account.entry_date.toLocaleString() }} by
              {{ account.position }} (Team {{ account.team_id }})</span
            ></template
          >
          <template #title>
            <span v-if="account.event_note">{{ account.event_note }} </span>
          </template>
          <template #body>
            <ul class="list-unstyled m-0">
              <li>Account ID {{ account.id }}</li>
              <li>Account Key {{ account.account_key }}</li>
              <li>Account Ref {{ account.reference }}</li>
              <li>Arrears {{ account.arrears_amount }}</li>
              <li>
                Active Agent {{ account.active_agent }} | Owned by
                {{ account.position }}
              </li>
              <li>Call {{ account.call_url }}</li>
            </ul>
          </template>
          <template #actions>
            <RoundButton
              @click="action('accept', account.id)"
              large
              class="btn-action text-success"
              title="Accept"
              ><span class="material-icons-outlined md-18">
                done
              </span></RoundButton
            >

            <RoundButton
              @click="action('reject', account.id)"
              large
              class="btn-action text-danger"
              title="Reject"
              ><span class="material-icons-outlined md-18">
                close
              </span></RoundButton
            >
          </template>
        </QueueItem>
      </Queue>
    </div>

    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import LoadingSpinner from '@/components/Shared/LoadingSpinner.vue';
import Queue from '@/components/Shared/Queue.vue';
import QueueItem from '@/components/Shared/QueueItem_v2.vue';
import RoundButton from '@/components/Shared/_RoundButton.vue';
import eventTypes from '@/helpers/eventTypes';

export default {
  name: 'WriteoffRequestQueue',
  components: { LoadingSpinner, Queue, QueueItem, RoundButton },
  setup() {
    return { eventTypes };
  },
  data() {
    return {
      accounts: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      positions: 'auth/permissions',
    }),
    count() {
      return this.accounts?.length;
    },
  },
  methods: {
    async action(action, accountId) {
      this.accounts = this.accounts.filter((a) => a.id !== accountId);
      try {
        const url = `api/manager/queues/proposed-writeoff/${accountId}`;
        await axios.patch(url, { action });
      } catch (error) {
        console.error(error);
        this.$store.dispatch('notification/push', {
          body: `Action Failed`,
          type: 'error',
        });
      }
    },
  },
  async mounted() {
    try {
      const url = `api/manager/queues/proposed-writeoff`;
      this.accounts = (await axios.get(url)).data.map((a) => ({
        ...a,
        due_date_time: new Date(a.due_date_time),
        entry_date: new Date(a.entry_date),
      }));
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  },
};
</script>
