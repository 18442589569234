<template>
  <Queue
    idKey="id"
    sortKey="due_date_time"
    :items="accounts"
    :isLoading="isLoading"
  >
    <template #subtitle> Debt Review </template>
    <template #title> Referral </template>
    <template v-slot:default="{ item: account }">
      <DebtReviewReferralQueueItem
        :account="account"
        @action="action"
        @note="createNote"
        @defer="defer"
      />
    </template>
  </Queue>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { createNote, deferAccount } from '@/api/account';

import Queue from '@/components/Shared/Queue.vue';
import DebtReviewReferralQueueItem from '@/components/Admin/DebtReviewReferralQueueItem.vue';

export default {
  name: 'DebtReviewReferralQueue',
  components: {
    DebtReviewReferralQueueItem,
    Queue,
  },
  data() {
    return {
      accounts: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      positions: 'auth/permissions',
    }),
    userPositionIdDR() {
      return this.positions.find(
        (position) => position.team.toUpperCase() === 'DEBT REVIEW'
      )?.user_position_id;
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async defer(event) {
      const { accountId, payload } = event;
      try {
        this.accounts = this.accounts.map((account) => {
          const tempAccount = account;
          if (account.id === accountId) {
            tempAccount.due_date_time = new Date(
              `${payload.data.date} 00:00:00`
            );
          }
          return tempAccount;
        });
        deferAccount(accountId, payload);
      } catch (error) {
        this.accounts = await this.getAccounts();
        this.$store.dispatch('notification/push', {
          body: `Action Failed`,
          type: 'error',
        });
      }
      this.$store.dispatch('account/clearCurrentAccount');
    },
    remove(accountId) {
      this.accounts = this.accounts.filter((a) => a.id !== accountId);
    },
    async fetchData() {
      const queue = (await axios.get('api/admin/queues/debt-review-referral'))
        .data;
      this.accounts = queue.accounts.map((account) => {
        const tempAcc = account;
        tempAcc.entry_date = new Date(tempAcc.entry_date);
        tempAcc.due_date_time = tempAcc.due_date_time
          ? new Date(tempAcc.due_date_time)
          : null;
        return tempAcc;
      });
      this.isLoading = false;
    },
    view(id) {
      this.$store.dispatch('account/clearCurrentAccount');
      this.$emit('view', id);
    },
    createNote({ accountId, note }) {
      createNote(accountId, note);
    },

    async action(event) {
      const { accountId, payload } = event;
      this.$store.dispatch('account/clearCurrentAccount');
      if (payload.action === 'accept') {
        await this.accept(accountId);
      } else if (payload.action === 'reject') {
        await this.reject(accountId);
      }
    },

    async reject(accountId) {
      this.remove(accountId);
      try {
        const result = (
          await axios.post('api/admin/queues/debt-review-referral', {
            user_position_id: this.userPositionIdDR,
            account_id: accountId,
            outcome: 'Reject',
          })
        ).data;
        if (!result) {
          this.$store.dispatch('notification/push', {
            body: `Something went wrong`,
            type: 'error',
          });
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/push', {
          body: error,
          type: 'error',
        });
        this.fetchData();
      }
    },
    async accept(accountId) {
      this.remove(accountId);
      try {
        const result = (
          await axios.post('api/admin/queues/debt-review-referral', {
            user_position_id: this.userPositionIdDR,
            account_id: accountId,
            outcome: 'Accept',
          })
        ).data;
        if (!result) {
          this.$store.dispatch('notification/push', {
            body: `Something went wrong. Check that the situation on ILS has been changed.`,
            type: 'error',
          });
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/push', {
          body: 'Something went wrong. Check that the situation on ILS has been changed.',
          type: 'error',
        });
        this.fetchData();
      }
    },
  },
};
</script>
