<template>
  <QueueItem :account="account">
    <template #tag>
      <span class="small d-block mb-3">
        On {{ account.entry_date.toLocaleString() }}
      </span>
    </template>
    <template #title>{{ account.reference }}</template>
    <template #body>
      <EventNote
        v-if="account.event_note"
        :content="account.event_note"
        :multiline="true"
      />
    </template>
    <template #actions>
      <RoundButton @click="accept" large class="btn-action text-success" title="Done"
        ><span class="material-icons-outlined md-18">
          forward_to_inbox
        </span></RoundButton
      >

      <RoundButton
        @click="toggleForm('note')"
        large
        title="Create Note"
        class="btn-action text-secondary"
      >
        <span class="material-icons"> notes </span>
      </RoundButton>
      <RoundButton
        @click="cancelDebtReview"
        large
        title="Cancel Debt Review"
        class="btn-action text-danger"
      >
        <span class="material-icons md-18"> block </span>
      </RoundButton>
    </template>
    <template #footer>
      <div v-if="showForm === 'note'">
        <div class="mb-3">
          <label class="form-label">Note</label>
          <textarea v-model="noteForm.note" class="form-control" rows="3"></textarea>
        </div>
        <button @click="createNote" class="btn btn-primary">Submit</button>
      </div>
    </template>
  </QueueItem>
</template>

<script>
import RoundButton from "@/components/Shared/_RoundButton.vue";
import QueueItem from "@/components/Shared/QueueItem_v2.vue";
import EventNote from "@/components/Shared/EventNote.vue";

export default {
  setup() {},
  props: {
    account: Object,
  },
  emits: ["note", "action"],
  components: {
    QueueItem,
    RoundButton,
    EventNote,
  },
  data() {
    return {
      showForm: "",
      noteForm: {
        note: "",
      },
    };
  },
  methods: {
    toggleForm(formName) {
      this.showForm = this.showForm !== formName ? formName : "";
    },
    accept() {
      this.$emit("action", {
        accountId: this.account.id,
        payload: { action: "accept" },
      });
    },
    createNote() {
      const { note } = this.noteForm;
      this.$emit("note", { accountId: this.account.id, note });
      this.noteForm.note = "";
      this.showForm = "";
    },
    cancelDebtReview() {
      this.$emit("action", {
        accountId: this.account.id,
        payload: { action: "cancel" },
      });
    },
  },
};
</script>
